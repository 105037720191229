import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PublicIcon from "@mui/icons-material/Public";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, Chip, CircularProgress, IconButton, Link, Snackbar, Tooltip } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import GoogleIcon from "assets/google-icon.svg";
import SlackIcon from "assets/slack-icon.svg";
import { getActivityIcon, getDefaultTitle } from "components/home_card_helpers";
import Layout from "components/layout/layout";
import { HourMinutePickerPopover, TimezonePickerPopover, WeekdayPickerPopover } from "components/pickers";
import usePickerPopover from "components/pickers/picker_popover_hook";
import Switch from "components/switch";
import format from "date-fns/format";
import {
  ActionPreferenceType,
  ActivityType,
  AutoReminderPreference,
  CustomReminderInput,
  DoSaveCustomReminderMutation,
  DoSaveCustomReminderMutationVariables,
  DoUnlinkConnectedAccountMutation,
  DoUnlinkConnectedAccountMutationVariables,
  DoUpdatePersonalReminderSettingsMutation,
  DoUpdatePersonalReminderSettingsMutationVariables,
  GetReminderSettingsQuery,
  PersonalReminderSettingOptionField,
  ReminderSchedule,
  useDoApplyPersonalReminderSettingsMutation,
  useDoSaveCustomReminderMutation,
  useDoUnlinkConnectedAccountMutation,
  useDoUpdatePersonalReminderSettingsMutation,
  useGetReminderSettingsQuery,
} from "generated/graphql";
import { useAppDispatch } from "hooks";
import { logError } from "logger";
import { useOptimisticQuery } from "optimistic_query";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { showModal } from "store/plan_reducer";
import {
  DefaultTimezone,
  delay,
  formatTimezone,
  formatTimezoneLong,
  useCanConnectCalendar,
  useHasSlack,
  useLoggedIn,
} from "utils";
import CustomReminderModal from "./custom_reminder_modal";
import "./index.sass";

const TimeFormat = "h:mm a";
const DefaultDays = "0111110";

function minToDate(min: number): Date {
  const time = new Date();
  time.setHours(min / 60);
  time.setMinutes(min % 60);
  return time;
}

const ReminderSettings = () => {
  const dispatch = useAppDispatch();
  const hasSlack = useHasSlack();
  const loggedIn = useLoggedIn();

  const [saving, setSaving] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | undefined | null>();

  const canConnectCalendar = useCanConnectCalendar();

  // query
  const queryClient = useQueryClient();
  const reminderSettings = useGetReminderSettingsQuery(undefined, { enabled: loggedIn, staleTime: 60 * 60 * 1000 });
  const allRemindersDisabled = reminderSettings?.data?.currentUser?.personalReminderSettings?.enabled === false;
  const allRemindersStartMin = reminderSettings?.data?.currentUser?.personalReminderSettings?.startMinute ?? 60 * 10;
  const allRemindersEndMin = reminderSettings?.data?.currentUser?.personalReminderSettings?.endMinute ?? 60 * 18;

  const allRemindersDays = reminderSettings?.data?.currentUser?.personalReminderSettings?.days ?? DefaultDays;
  const allRemindersTimezone =
    reminderSettings?.data?.currentUser?.personalReminderSettings?.timezone ?? DefaultTimezone;

  // popovers

  const [weekdayPickerAnchor, weekdayPickerDays, openWeekdayPicker, confirmWeekdayPicker, closeWeekdayPicker] =
    usePickerPopover<string>();

  const [
    hourMinutePickerAnchor,
    hourMinutePickerHourMinute,
    openHourMinutePicker,
    confirmHourMinutePicker,
    closeHourMinutePicker,
  ] = usePickerPopover<number>();

  const [timezonePickerAnchor, timezonePickerTimezone, openTimezonePicker, confirmTimezonePicker, closeTimezonePicker] =
    usePickerPopover<string>();

  // mutations

  const applyPersonalReminderSettings = useDoApplyPersonalReminderSettingsMutation();

  const unlinkConnectedAccountMutation = useOptimisticQuery<
    DoUnlinkConnectedAccountMutation,
    GetReminderSettingsQuery,
    DoUnlinkConnectedAccountMutationVariables
  >(
    queryClient,
    useDoUnlinkConnectedAccountMutation,
    ["getReminderSettings"],
    (data: GetReminderSettingsQuery, variables: DoUnlinkConnectedAccountMutationVariables) => {
      if (data.currentUser?.connectedAccounts?.accounts) {
        data.currentUser.connectedAccounts.accounts = data.currentUser.connectedAccounts.accounts.filter(
          (a) => a.id !== variables.accountID
        );
      }
      return data;
    }
  );

  const saveCustomReminder = useOptimisticQuery<
    DoSaveCustomReminderMutation,
    GetReminderSettingsQuery,
    DoSaveCustomReminderMutationVariables
  >(
    queryClient,
    useDoSaveCustomReminderMutation,
    ["getReminderSettings"],
    (data: GetReminderSettingsQuery, variables: DoSaveCustomReminderMutationVariables) => {
      if (data.currentUser?.personalReminderSettings) {
        const settings = data.currentUser?.personalReminderSettings;
        let customReminders = settings.reminders ?? ([] as ReminderSchedule[]);
        if (!settings.reminders) {
          settings.reminders = customReminders;
        }

        const existing = customReminders.find((r) => r.key !== "" && r.key === variables.reminder.key);

        if (variables.remove) {
          // remove the reminder from the list
          customReminders = customReminders.filter((r) => r.key !== variables.reminder.key);
        } else if (variables.reminder.key === "") {
          if (!existing) {
            // add the new reminder to the list
            customReminders.unshift(variables.reminder);
          }
        } else {
          // remove then add
          customReminders = customReminders.filter((r) => r.key !== variables.reminder.key);
          customReminders.unshift(variables.reminder);
        }

        // sort
        customReminders.sort((a, b) => {
          if (a.minute === b.minute) {
            return a.action < b.action ? -1 : 1;
          }

          return a.minute < b.minute ? -1 : 1;
        });
      }

      return data;
    }
  );

  const updatePersonalReminderSettingsMutation = useOptimisticQuery<
    DoUpdatePersonalReminderSettingsMutation,
    GetReminderSettingsQuery,
    DoUpdatePersonalReminderSettingsMutationVariables
  >(
    queryClient,
    useDoUpdatePersonalReminderSettingsMutation,
    ["getReminderSettings"],
    (data: GetReminderSettingsQuery, variables: DoUpdatePersonalReminderSettingsMutationVariables) => {
      if (data.currentUser?.personalReminderSettings) {
        const settings = data.currentUser?.personalReminderSettings;
        const preferences = settings.preferences ?? ([] as AutoReminderPreference[]);
        if (!settings.preferences) {
          settings.preferences = preferences;
        }
        const changes = Array.isArray(variables.input) ? variables.input : [variables.input];
        for (let change of changes) {
          switch (change.field) {
            case PersonalReminderSettingOptionField.Days:
              settings.days = change.stringVal;
              break;
            case PersonalReminderSettingOptionField.EndMinute:
              settings.endMinute = change.intVal;
              break;
            case PersonalReminderSettingOptionField.GlobalReminder:
              settings.enabled = change.boolVal ?? true;
              break;
            case PersonalReminderSettingOptionField.StartMinute:
              settings.startMinute = change.intVal;
              break;
            case PersonalReminderSettingOptionField.Timezone:
              settings.timezone = change.stringVal;
              break;
            case PersonalReminderSettingOptionField.SmartBreath:
              preferences.forEach((p) => {
                if (p.action === ActivityType.Breath) {
                  p.preference = change.boolVal === false ? ActionPreferenceType.Off : ActionPreferenceType?.Auto;
                }
              });
              break;
            case PersonalReminderSettingOptionField.SmartEyeBreak:
              preferences.forEach((p) => {
                if (p.action === ActivityType.EyeBreak) {
                  p.preference = change.boolVal === false ? ActionPreferenceType.Off : ActionPreferenceType?.Auto;
                }
              });
              break;
            case PersonalReminderSettingOptionField.SmartStretch:
              preferences.forEach((p) => {
                if (p.action === ActivityType.Stretch) {
                  p.preference = change.boolVal === false ? ActionPreferenceType.Off : ActionPreferenceType?.Auto;
                }
              });
              break;
            case PersonalReminderSettingOptionField.SmartWater:
              preferences.forEach((p) => {
                if (p.action === ActivityType.Water) {
                  p.preference = change.boolVal === false ? ActionPreferenceType.Off : ActionPreferenceType?.Auto;
                }
              });
              break;
          }
        }
      }
      return data;
    }
  );

  // handlers

  async function unlinkConnectedAccount(gid: string) {
    if (saving) {
      return;
    }

    setSaving(true);
    try {
      await unlinkConnectedAccountMutation.mutateAsync({
        accountID: gid,
      });

      setChanged(true);
    } catch (error) {
      logError(error);
    } finally {
      setSaving(false);
    }
  }

  async function updateScheduleDays(days: string) {
    if (saving) {
      return;
    }

    setSaving(true);
    try {
      await updatePersonalReminderSettingsMutation.mutateAsync({
        input: {
          field: PersonalReminderSettingOptionField.Days,
          stringVal: days,
        },
      });

      setChanged(true);
    } catch (error) {
      logError(error);
    } finally {
      setSaving(false);
    }
  }

  async function updateScheduleTimezone(timezone: string) {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      await updatePersonalReminderSettingsMutation.mutateAsync({
        input: {
          field: PersonalReminderSettingOptionField.Timezone,
          stringVal: timezone,
        },
      });

      setChanged(true);
    } catch (error) {
      logError(error);
    } finally {
      setSaving(false);
    }
  }

  async function updateScheduleTime(start: boolean, minute: number) {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      await updatePersonalReminderSettingsMutation.mutateAsync({
        input: {
          field: start ? PersonalReminderSettingOptionField.StartMinute : PersonalReminderSettingOptionField.EndMinute,
          intVal: minute,
        },
      });

      setChanged(true);
    } catch (error) {
      logError(error);
    } finally {
      setSaving(false);
    }
  }

  async function toggleGlobalSettings(field: PersonalReminderSettingOptionField, boolVal?: boolean | null) {
    // let animation finish before updating saving state
    delay(200).then(async () => {
      if (saving) {
        return;
      }

      setSaving(true);

      try {
        await updatePersonalReminderSettingsMutation.mutateAsync({
          input: {
            field,
            boolVal,
          },
        });

        setChanged(true);
      } catch (error) {
        logError(error);
      } finally {
        setSaving(false);
      }
    });
  }

  async function toggleSmartReminders(action: ActivityType, boolVal?: boolean | null) {
    let field: PersonalReminderSettingOptionField | undefined;
    switch (action) {
      case ActivityType.Water:
        field = PersonalReminderSettingOptionField.SmartWater;
        break;
      case ActivityType.Stretch:
        field = PersonalReminderSettingOptionField.SmartStretch;
        break;
      case ActivityType.EyeBreak:
        field = PersonalReminderSettingOptionField.SmartEyeBreak;
        break;
      case ActivityType.Breath:
        field = PersonalReminderSettingOptionField.SmartBreath;
        break;
    }

    if (!field) {
      return;
    }

    await toggleGlobalSettings(field, boolVal);
  }

  //  modal

  const [editCustomReminderModalOpen, setEditCustomReminderModalOpen] = useState<boolean>(false);
  const [editCustomReminderModalInitialReminder, setEditCustomReminderModalInitialReminder] = useState<
    ReminderSchedule | undefined
  >();

  function openEditCustomReminderModal(r?: ReminderSchedule) {
    setEditCustomReminderModalOpen(true);
    setEditCustomReminderModalInitialReminder(r);
  }

  function onCloseEditCustomReminderModal() {
    setEditCustomReminderModalOpen(false);
    setEditCustomReminderModalInitialReminder(undefined);
  }

  async function onSaveCustomReminder(reminder: CustomReminderInput, remove: boolean) {
    onCloseEditCustomReminderModal();
    reminder.timezone =
      reminder.timezone ?? reminderSettings?.data?.currentUser?.personalReminderSettings?.timezone ?? DefaultTimezone;

    try {
      await saveCustomReminder.mutateAsync({
        reminder,
        remove,
      });
      setChanged(true);
    } catch (error) {}
  }

  async function rescheduleReminders() {
    try {
      setSaving(true);
      await applyPersonalReminderSettings.mutateAsync({});
      setChanged(false);
      setStatusMessage("Reminder settings applied successfully!");
    } catch (error) {
    } finally {
      setSaving(false);
    }
  }

  const closeSnackbar = () => {
    setStatusMessage(null);
  };

  return (
    <Layout
      className="ReminderSettingsPage"
      header={
        <div className="PageHeader">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" className="PageTitle">
              Reminder Settings
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={2} alignItems="center">
              {saving && <CircularProgress size={24} />}
              <Button disabled={!changed || saving} variant="contained" onClick={rescheduleReminders}>
                Apply Changes
              </Button>
            </Stack>
          </Stack>
        </div>
      }
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!statusMessage}
        onClose={closeSnackbar}
        message={statusMessage}
        key={statusMessage}
      />
      <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} spacing={2} sx={{ maxWidth: 1200 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h5">Calendar accounts</Typography>
                <Chip className="UpgradeBadge" label="Pro" />
              </Stack>
              <Typography variant="body1">
                Connect your Google calendar to get daily overview and personalized reminders.
              </Typography>
            </div>
            <Link
              href={
                canConnectCalendar
                  ? reminderSettings?.data?.currentUser?.connectedAccounts?.googleAuthURL ?? ""
                  : undefined
              }
              target="_blank"
            >
              <Button
                disabled={!reminderSettings?.data?.currentUser?.connectedAccounts?.googleAuthURL}
                startIcon={<AddIcon />}
                onClick={
                  canConnectCalendar
                    ? undefined
                    : () => {
                        dispatch(showModal());
                      }
                }
                variant="contained"
              >
                Connect
              </Button>
            </Link>
          </Stack>
          <Grid
            sx={{ pt: 2 }}
            container
            item
            className="ReminderSettingsPage__connectedCalendars"
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            {(reminderSettings?.data?.currentUser?.connectedAccounts?.accounts ?? []).map((c) => {
              return (
                <Grid xs={12} sm={6} md={3} lg={3} xl={3} item key={`${c.type}-${c.email}-${c.id}`}>
                  <Stack sx={{ display: "flex", flexDirection: "column" }} className="ReminderSettingsPage__toggle">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <img className="ReminderSettingsPage__activityIcon" src={GoogleIcon} alt="Google" />
                      <IconButton onClick={() => unlinkConnectedAccount(c.id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                      <Typography variant="body1" fontWeight={500}>
                        {c.email}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>
              <Typography variant="h5">Custom reminders</Typography>
              <Typography variant="body1">Reminders at specific times of the day and the week.</Typography>
            </div>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => {
                openEditCustomReminderModal();
              }}
            >
              Add
            </Button>
          </Stack>
          <Grid
            sx={{ pt: 2 }}
            container
            item
            className="ReminderSettingsPage__customReminders"
            columns={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
            alignItems="stretch"
            justifyContent="flex-start"
            spacing={2}
          >
            {(reminderSettings?.data?.currentUser?.personalReminderSettings?.reminders ?? []).map((r) => {
              return (
                <Grid
                  onClick={() => openEditCustomReminderModal(r)}
                  xs={8}
                  sm={8}
                  md={4}
                  lg={2}
                  xl={2}
                  item
                  display="flex"
                  key={`${r.action}-${r.contentID}-${r.days}-${r.minute}`}
                >
                  <Stack className="ReminderSettingsPage__toggle" flex={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      {getActivityIcon(r.action, r.action, "ReminderSettingsPage__activityIcon")}
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body1" fontWeight={500}>
                          {getDefaultTitle(r.action)}
                        </Typography>
                      </Stack>
                      <Button size="small">{format(minToDate(r.minute), TimeFormat)}</Button>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={hasSlack ? "space-between" : "flex-end"}
                      sx={{ pt: 1 }}
                      spacing={1}
                      className="ReminderSettingsPage__channel"
                    >
                      {hasSlack && (
                        <img src={SlackIcon} alt="Slack channel" className="ReminderSettingsPage__channelIcon" />
                      )}
                      <Stack direction="row" className="ReminderSettingsPage__days">
                        {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
                          <span
                            key={`${r.action}-${r.contentID}-${r.days}__days-${i}`}
                            className={
                              (r.days ?? DefaultDays)[i] === "1"
                                ? "ReminderSettingsPage__selectedDay"
                                : "ReminderSettingsPage__day"
                            }
                          >
                            {d}
                          </span>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          className="ReminderSettingsPage__schedule"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ mb: 2 }}
        >
          <Stack sx={{ flex: 1 }}>
            <Typography variant="h5">Work schedule</Typography>
            <Typography variant="body1" sx={{ pt: 0.5 }}>
              Customize your work schedule so we can send you reminders during working hours only. We don't like to
              bother you when you're enjoying your life!
            </Typography>
            <Grid
              sx={{ pt: 2 }}
              container
              item
              className="ReminderSettingsPage__schedule"
              columns={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
              alignItems="stretch"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid sx={{ display: "flex" }} xs={8} sm={4} md={4} lg={2} xl={2} item>
                <Stack className="ReminderSettingsPage__toggle" flex={1}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <LightModeIcon className="ReminderSettingsPage__activityIcon" />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                    <Typography fontWeight={500}>Day start</Typography>
                    <Button
                      size="small"
                      onClick={(event) =>
                        openHourMinutePicker(allRemindersStartMin, event.currentTarget, (val) => {
                          if (val) {
                            updateScheduleTime(true, val);
                          }
                        })
                      }
                    >
                      {format(minToDate(allRemindersStartMin), TimeFormat)}
                    </Button>
                  </Box>
                </Stack>
              </Grid>

              <Grid sx={{ display: "flex" }} xs={8} sm={4} md={4} lg={2} xl={2} item>
                <Stack className="ReminderSettingsPage__toggle" flex={1}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <NightsStayIcon className="ReminderSettingsPage__activityIcon" />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                    <Typography fontWeight={500}>Day end</Typography>
                    <Button
                      size="small"
                      onClick={(event) =>
                        openHourMinutePicker(allRemindersEndMin, event.currentTarget, (val) => {
                          if (val) {
                            updateScheduleTime(false, val);
                          }
                        })
                      }
                    >
                      {format(minToDate(allRemindersEndMin), TimeFormat)}
                    </Button>
                  </Box>
                </Stack>
              </Grid>

              <Grid sx={{ display: "flex" }} xs={8} sm={8} md={4} lg={2} xl={2} item>
                <Stack className="ReminderSettingsPage__toggle" flex={1}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <CalendarViewWeekIcon className="ReminderSettingsPage__activityIcon" />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1, height: 44.5 }}>
                    <Typography fontWeight={500}>Weekdays</Typography>
                    <Stack
                      direction="row"
                      className="ReminderSettingsPage__days"
                      onClick={(event) =>
                        openWeekdayPicker(allRemindersDays, event.currentTarget, (val) => {
                          if (val) {
                            updateScheduleDays(val);
                          }
                        })
                      }
                    >
                      {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
                        <span
                          key={`ReminderSettingsPage__schedule__days-${i}`}
                          className={
                            allRemindersDays[i] === "1"
                              ? "ReminderSettingsPage__selectedDay"
                              : "ReminderSettingsPage__day"
                          }
                        >
                          {d}
                        </span>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </Grid>

              {/* time zone */}

              <Grid sx={{ display: "flex" }} xs={8} sm={8} md={4} lg={2} xl={2} item>
                <Stack className="ReminderSettingsPage__toggle" flex={1}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <PublicIcon className="ReminderSettingsPage__activityIcon" />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1, height: 44.5 }}>
                    <Typography fontWeight={500}>Timezone</Typography>
                    <Tooltip title={formatTimezoneLong(allRemindersTimezone)}>
                      <Button
                        size="small"
                        onClick={(event) =>
                          openTimezonePicker(allRemindersTimezone, event.currentTarget, (val) => {
                            if (val) {
                              updateScheduleTimezone(val);
                            }
                          })
                        }
                      >
                        {formatTimezone(allRemindersTimezone)}
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid
          className="ReminderSettingsPage__smart"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ mb: 2 }}
        >
          <Stack sx={{ flex: 1 }}>
            <Typography variant="h5">Smart reminders</Typography>
            <Typography variant="body1" sx={{ pt: 0.5 }}>
              Cuely will automatically adjust the times and frequencies of these reminders based on your habit and
              schedule.
            </Typography>
            <Grid
              sx={{ pt: 2 }}
              container
              item
              className="ReminderSettingsPage__smartToggles"
              columns={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {(reminderSettings?.data?.currentUser?.personalReminderSettings?.preferences ?? []).map((p) => (
                <Grid xs={8} sm={4} md={4} lg={2} xl={2} item key={`${p.action}-${p.preference}`}>
                  <Stack sx={{ display: "flex", flexDirection: "column" }} className="ReminderSettingsPage__toggle">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      {getActivityIcon(p.action, p.action, "ReminderSettingsPage__activityIcon")}
                      <Switch
                        size="medium"
                        defaultChecked={p.preference !== "off"}
                        color="primary"
                        onChange={(_, checked) => toggleSmartReminders(p.action, checked)}
                      />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                      <Typography fontWeight={500}>{getDefaultTitle(p.action)}</Typography>
                      <Typography fontWeight={500}>{p.preference === "off" ? "Off" : "Smart"}</Typography>
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>

        <Grid
          className="ReminderSettingsPage__global"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ mb: 2 }}
        >
          <Stack sx={{ flex: 1 }}>
            <Typography variant="h5">Enable reminders?</Typography>
            <Typography variant="body1" sx={{ pt: 0.5 }}>
              Need to take a break from Cuely reminders? You can turn reminders off completely.
            </Typography>
            <Grid
              sx={{ pt: 2 }}
              container
              item
              className="ReminderSettingsPage__global"
              columns={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
              alignItems="stretch"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid xs={8} sm={4} md={2} lg={2} xl={2} item>
                <Stack
                  sx={{ display: "flex", flexDirection: "column" }}
                  className="ReminderSettingsPage__toggle"
                  flex={1}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    {allRemindersDisabled ? (
                      <NotificationsOffIcon className="ReminderSettingsPage__activityIcon" />
                    ) : (
                      <NotificationsActiveIcon className="ReminderSettingsPage__activityIcon" />
                    )}
                    <Switch
                      size="medium"
                      checked={!allRemindersDisabled}
                      color="primary"
                      onChange={(_, checked) =>
                        toggleGlobalSettings(PersonalReminderSettingOptionField.GlobalReminder, checked)
                      }
                    />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
                    <Typography fontWeight={500}>Receiving reminders?</Typography>
                    <Typography>{allRemindersDisabled ? "No" : "Yes"}</Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <CustomReminderModal
        open={editCustomReminderModalOpen}
        onConfirm={(input) => onSaveCustomReminder(input, false)}
        onDelete={(input) => onSaveCustomReminder(input, true)}
        onClose={onCloseEditCustomReminderModal}
        customReminder={editCustomReminderModalInitialReminder}
      />

      <WeekdayPickerPopover
        open={!!weekdayPickerAnchor}
        className="WeekdayPicker"
        onClose={closeWeekdayPicker}
        days={weekdayPickerDays ?? DefaultDays}
        anchorEl={weekdayPickerAnchor}
        onConfirm={confirmWeekdayPicker}
      />

      <HourMinutePickerPopover
        open={!!hourMinutePickerAnchor}
        className="HourminutePicker"
        onClose={closeHourMinutePicker}
        minute={hourMinutePickerHourMinute ?? 10 * 60}
        anchorEl={hourMinutePickerAnchor}
        onConfirm={confirmHourMinutePicker}
      />

      <TimezonePickerPopover
        open={!!timezonePickerAnchor}
        className="TimezonePicker"
        onClose={closeTimezonePicker}
        timezone={timezonePickerTimezone ?? DefaultTimezone}
        anchorEl={timezonePickerAnchor}
        onConfirm={confirmTimezonePicker}
      />
    </Layout>
  );
};

export default ReminderSettings;
