import { Events, track } from "analytics";
import { queryClient } from "api";
import { EnterRoomContext } from "app";
import classnames from "classnames";
import AudioControl, { AudioState, AudioTrack, AudioType, cloneState, defaultAudioState } from "components/audio";
import Completion from "components/completion";
import RecentUsers from "components/recent_users";
import SharingBox from "components/sharing/sharing_box";
import SharingButton from "components/sharing/sharing_button";
import SuccessRing from "components/success_ring";
import Timer, { DurationOption } from "components/timer";
import { ActivityType, useDoLogActivityMutation } from "generated/graphql";
import { useAppSelector } from "hooks";
import { useCallback, useContext, useMemo, useState } from "react";
import { selectAuth } from "store/auth_reducer";
import { selectUI } from "store/ui_reducer";
import { getStaticFile, Scene, useLoggedIn, useSearchReminderID } from "utils";
import Nav from "../nav/nav";
import Breath from "./breath";
import "./index.sass";
import BreathSubtitle from "./subtitle";

const scenes: Scene[] = [
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/4e38d60a-5e15-4c27-9b7e-0a8d36f2e260", "-wave.m4a"),
          getStaticFile("/s/4e38d60a-5e15-4c27-9b7e-0a8d36f2e260", "-wave.ogg"),
        ],
        maxVolume: 0.3,
        type: AudioType.Sound,
      },
    ],
    videos: [
      getStaticFile("/v/84f14a65-2111-4f40-86b0-6f6c726a5d01-island", ".mp4"),
      getStaticFile("/v/84f14a65-2111-4f40-86b0-6f6c726a5d01-island", ".ogv"),
    ],
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/c225e395-e5dc-4149-ae87-3ea25b885ea7", "-rain.m4a"),
          getStaticFile("/s/c225e395-e5dc-4149-ae87-3ea25b885ea7", "-rain.ogg"),
        ],
        maxVolume: 0.6,
        type: AudioType.Sound,
      },
    ],
    videos: [
      getStaticFile("/v/c9615671-af07-4e92-a77d-a7d743353910-leaves", ".mp4"),
      getStaticFile("/v/c9615671-af07-4e92-a77d-a7d743353910-leaves", ".ogv"),
    ],
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/573f2ee7-aab6-4eb8-a08f-8dc10cba213e", "-fog.m4a"),
          getStaticFile("/s/573f2ee7-aab6-4eb8-a08f-8dc10cba213e", "-fog.ogg"),
        ],
        maxVolume: 1,
        type: AudioType.Sound,
      },
    ],
    videos: [
      getStaticFile("/v/df94f989-737b-4279-9993-12a475fe6423", ".mp4"),
      getStaticFile("/v/df94f989-737b-4279-9993-12a475fe6423", ".ogv"),
    ],
  },
];

const voiceOver: AudioTrack = {
  sources: [
    getStaticFile("/s/e6195bff-7681-4478-8409-1f0fdc6fd1a2", "-breath-m.m4a"),
    getStaticFile("/s/e6195bff-7681-4478-8409-1f0fdc6fd1a2", "-breath-m.ogg"),
  ],
  maxVolume: 1,
  type: AudioType.VoiceOver,
};

function BreathRoom() {
  const auth = useAppSelector(selectAuth);
  const ui = useAppSelector(selectUI);
  const [state, setState] = useState<AudioState>(defaultAudioState(ui.volume));
  const loggedIn = useLoggedIn();
  const playEnterRoom = useContext(EnterRoomContext);
  const [showSharingBox, setShowSharingBox] = useState<boolean>(false);
  const isGuest = auth?.currentAccount?.isGuest ?? false;
  const reminderID = useSearchReminderID();

  let scene = scenes[useMemo(() => (Math.random() * scenes.length) | 0, [])];

  const logActivityMutation = useDoLogActivityMutation();
  const logBreath = useCallback(
    async (duration: number) => {
      if (loggedIn) {
        const result = await logActivityMutation.mutateAsync({
          input: {
            action: ActivityType.Breath,
            duration: duration,
            reminderID,
          },
        });

        if (result?.logActivity?.gif !== "") {
          // show gif
        }

        queryClient.invalidateQueries(["getCurrentUser"]);

        if (auth.currentAccount?.isGuest) {
          track(Events.SharingPage.Started, {});
        }
      }
    },
    [loggedIn, logActivityMutation, reminderID, auth.currentAccount?.isGuest]
  );

  function start(duration: number) {
    const newState = cloneState(state);
    newState.inProgress = true;
    newState.started = true;
    newState.completed = false;
    setState(newState);

    logBreath(duration);
  }

  const showBreath = state.inProgress;
  const showStart = !state.started;
  const showCelebration = !showStart && state.completed;
  const shadeClass = classnames("Shade", { inProgress: showBreath });
  const breathCard = ui.homeActivitCards ? ui.homeActivitCards.find((c) => c.action === ActivityType.Breath) : null;
  const showSharingButton = !showStart && !isGuest;

  return (
    <div className="BreathRoom">
      <div className="Bg">
        <video autoPlay muted loop playsInline>
          {scene.videos?.map((v) => (
            <source key={v} src={v} />
          ))}
        </video>
        <div
          className={shadeClass}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0))`,
            backgroundSize: "auto 200px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></div>
      </div>
      {showCelebration && <SuccessRing />}
      <div className="Control">
        {showBreath && <Breath />}
        {showBreath && <BreathSubtitle />}
        <Nav teamName={auth.currentAccount?.team} currentRoom="Breath" />
        {state.started && (
          <AudioControl
            state={state}
            audios={scene.audios}
            voiceOver={voiceOver}
            onTimeupdate={() => {}}
            onAdjust={(muted: boolean, volume: number) => {
              if (muted) {
                const newState = cloneState(state);
                // reset subtitle
                newState.key = new Date().valueOf();
                newState.muted = true;
                newState.volume = volume;
                setState(() => newState);
              } else {
                const newState = cloneState(state);
                newState.muted = false;
                newState.volume = volume;
                setState(() => newState);
              }
            }}
          />
        )}
        {showStart && (
          <div className="Intro">
            <h1>Guided breathwork</h1>
          </div>
        )}
        <Timer
          clicked={playEnterRoom?.clicked}
          state={state}
          defaultDuration={60}
          durations={
            [
              { duration: 30, label: "30s" },
              { duration: 60, default: true, label: "1 Min" },
              { duration: 120, last: true, label: "2 Min" },
            ] as DurationOption[]
          }
          onComplete={() => {
            const newState = cloneState(state);
            newState.completed = true;
            newState.inProgress = false;
            setState(newState);
          }}
          onPause={() => {
            const newState = cloneState(state);
            newState.inProgress = false;
            setState(newState);
          }}
          onResume={() => {
            const newState = cloneState(state);
            newState.inProgress = true;
            setState(newState);
          }}
          onStart={start}
        />
        {breathCard && breathCard?.socialProof?.profilePictures && (
          <div className="Social">
            <RecentUsers profilePictures={breathCard?.socialProof?.profilePictures} />
          </div>
        )}
        {showSharingButton && (
          <div className="InActivitySharingButton">
            <SharingButton
              askForName={() => {
                setShowSharingBox(true);
              }}
            />
          </div>
        )}
        <SharingBox
          action={ActivityType.Breath}
          open={showSharingBox}
          close={() => {
            setShowSharingBox(false);
          }}
        />
      </div>
      {showCelebration && <Completion />}
    </div>
  );
}

export default BreathRoom;
