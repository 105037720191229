import { addDays, addMonths, format, getDaysInMonth, getISODay, isWeekend, parse, parseISO } from "date-fns";

export const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getNumberOfDaysInMonth(year: number, month: number) {
  return getDaysInMonth(parseISODate(`${year}-${month}-01`));
}

export function createDaysForCurrentMonth(year: number, month: number) {
  return [...Array(getNumberOfDaysInMonth(year, month))].map((_, index) => {
    return {
      dateString: DateToISOString(parseISODate(`${year}-${month}-${index + 1}`)),
      dayOfMonth: index + 1,
      isCurrentMonth: true,
    };
  });
}

export function createDaysForPreviousMonth(year: number, month: number, currentMonthDays: any) {
  const previousMonth = addDays(parseISODate(`${year}-${month}-01`), -1);
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString);
  const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;

  const previousMonthLastMondayDayOfMonth = parseInt(
    format(addDays(parseISODate(currentMonthDays[0].dateString), -1 * visibleNumberOfDaysFromPreviousMonth), "dd")
  );

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => ({
    dateString: DateToISOString(
      parseISODate(
        `${previousMonth.getFullYear()}-${previousMonth.getMonth() + 1}-${previousMonthLastMondayDayOfMonth + index}`
      )
    ),
    dayOfMonth: previousMonthLastMondayDayOfMonth + index,
    isCurrentMonth: false,
    isPreviousMonth: true,
  }));
}

export function createDaysForNextMonth(year: number, month: number, currentMonthDays: any) {
  const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`);
  const nextMonth = addMonths(parseISODate(`${year}-${month}-01`), 1);
  const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;
  if (visibleNumberOfDaysFromNextMonth < 0) return [];
  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
    return {
      dateString: DateToISOString(parseISODate(`${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-${index + 1}`)),
      dayOfMonth: index + 1,
      isCurrentMonth: false,
      isNextMonth: true,
    };
  });
}

export function getWeekday(dateString: string): number {
  return getISODay(parseISODate(dateString));
}

export function isWeekendDay(dateString: string) {
  return isWeekend(parseISODate(dateString));
}

export function parseISODate(dateString: string): Date {
  return parse(dateString, "yyyy-MM-dd", new Date());
}

export function DateToISOString(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function DateToSimpleString(date: Date): string {
  return format(date, "MMMM do");
}

export function DateToSimpleHourString(date: Date): string {
  return format(date, "p");
}

export function StringDateToSimpleHourString(dateString: string): string {
  return DateToSimpleHourString(parseISO(dateString));
}
