import IconBenchmark from "assets/v2/icon-benchmark.svg";
import { EvalCase, Evaluator, Template, TemplateVariant, useDoBenchmarkTemplateMutation } from "generated/graphql";
import { selectEval } from "store/eval_reducer";
import { useAppSelector } from "hooks";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SELECT_ALL_VALUE = "___ALL___";
const DESELECT_ALL_VALUE = "___0___";

export default function BenchmarkFunctionModal({
  template,
  open,
  onClose,
  onSucceed,
}: {
  template?: Template;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
}) {
  const [saving, setSaving] = useState<boolean>(false);
  const { evaluators } = useAppSelector(selectEval);

  const [checkedVariants, setCheckedVariants] = useState<Set<string>>(
    new Set(template?.variants?.map((v) => v.id) ?? [])
  );

  const variantIdToVariant = (template?.variants ?? []).reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {} as { [key: string]: TemplateVariant });

  const validTestCases = template?.evalCases?.filter((v) => !!v.fields?.referenceResponse) ?? [];
  const [checkedTestCases, setCheckedTestCases] = useState<Set<string>>(new Set(validTestCases.map((v) => v.id) ?? []));

  const [checkedEvaluators, setCheckedEvaluators] = useState<Set<string>>(
    new Set(evaluators.filter((v) => v.active).map((v) => v.type) ?? [])
  );

  const [multiplier, setMultiplier] = useState<number>(1);

  const benchmarkTemplateMutation = useDoBenchmarkTemplateMutation();

  function close() {
    onClose();
  }

  function onChangeMultiplier(event: SelectChangeEvent<number>) {
    try {
      if (typeof event.target.value === "string") {
        setMultiplier(parseInt(event.target.value));
        return;
      }

      setMultiplier(event.target.value | 0);
    } catch {
      setMultiplier(1);
    }
  }

  async function runBenchmark() {
    if (!saving && template?.id) {
      setSaving(true);

      try {
        // evaluators?: InputMaybe<Array<EvaluatorInput>>;
        // runAllCases?: InputMaybe<Scalars['Boolean']>;
        const response = await benchmarkTemplateMutation.mutateAsync({
          input: {
            runClientID: uuidv4(),
            templateID: template.id,
            templateVariantIDs: Array.from(checkedVariants),
            evalCaseIDs: Array.from(checkedTestCases),
            evaluators: evaluators.filter((v) => checkedEvaluators.has(v.type)) ?? [],
            multiplier,
          },
        });

        if (response.benchmarkTemplate) {
          console.log("benchmarkTemplate", response.benchmarkTemplate);
        }
        close();
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  const allVariantsChecked = checkedVariants.size === template?.variants?.length;
  const noVariantsChecked = checkedVariants.size === 0;
  function updateVariants(evt: SelectChangeEvent<string[]>) {
    const {
      target: { value },
    } = evt;
    const checked = typeof value === "string" ? value.split(",") : value;
    const newSet = new Set(checked ?? []);
    if (newSet.has(SELECT_ALL_VALUE)) {
      setCheckedVariants(new Set(template?.variants?.map((v) => v.id) ?? []));
      return;
    } else if (newSet.has(DESELECT_ALL_VALUE)) {
      setCheckedVariants(new Set());
      return;
    }
    setCheckedVariants(newSet);
  }

  const allEvaluatorsChecked = checkedEvaluators.size === evaluators.length;
  const noEvaluatorsChecked = checkedEvaluators.size === 0;
  function updateEvaluators(evt: SelectChangeEvent<string[]>) {
    const {
      target: { value },
    } = evt;
    const checked = typeof value === "string" ? value.split(",") : value;
    const newSet = new Set(checked ?? []);
    if (newSet.has(SELECT_ALL_VALUE)) {
      setCheckedEvaluators(new Set((evaluators ?? []).map((v) => v.type) ?? []));
      return;
    } else if (newSet.has(DESELECT_ALL_VALUE)) {
      setCheckedEvaluators(new Set());
      return;
    }
    setCheckedEvaluators(newSet);
  }

  function toggleAllTestCases() {
    if (checkedTestCases.size === validTestCases.length) {
      setCheckedTestCases(new Set());
    } else {
      setCheckedTestCases(new Set(validTestCases.map((v) => v.id) ?? []));
    }
  }

  function toggleTestCase(id: string) {
    if (checkedTestCases.has(id)) {
      checkedTestCases.delete(id);
    } else {
      checkedTestCases.add(id);
    }

    setCheckedTestCases(new Set(checkedTestCases));
  }

  const totalCount = multiplier * checkedVariants.size * checkedTestCases.size * (1 + checkedEvaluators.size);

  return (
    <Dialog maxWidth="lg" className="BenchmarkFunctionModal" open={open} onClose={onClose}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconBenchmark} alt="Benchmark" />
          <Typography variant="h4">Run Benchmark</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{ py: 1 }}>
          <FormControl sx={{ minWidth: 200 }} fullWidth size="small">
            <InputLabel id="benchmark-variant-picker-label">Variants to benchmark</InputLabel>
            <Select
              labelId="benchmark-variant-picker-label"
              id="benchmark-variant-picker"
              multiple
              size="small"
              value={Array.from(checkedVariants)}
              onChange={updateVariants}
              input={<OutlinedInput id="select-multiple-variants" label="Variants to benchmark" />}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        size="small"
                        key={`benchmark-variant-chip-selected-${value}`}
                        label={variantIdToVariant[value].name}
                      />
                    ))}
                    {selected.length === 0 && <Chip size="small" label="None" />}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {!allVariantsChecked && (
                <MenuItem key={`benchmark-variant-select-item-select-all`} value={SELECT_ALL_VALUE}>
                  Select all
                </MenuItem>
              )}
              {!noVariantsChecked && (
                <MenuItem key={`benchmark-variant-select-item-deselect-all`} value={DESELECT_ALL_VALUE}>
                  Deselect all
                </MenuItem>
              )}
              {template?.variants?.map((v: TemplateVariant) => (
                <MenuItem key={`benchmark-variant-select-item-${v.id}`} value={v.id}>
                  <Checkbox size="small" checked={checkedVariants.has(v.id)} />
                  <ListItemText primary={v.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }} fullWidth size="small">
            <InputLabel id="benchmark-evaluators-picker-label">Evaluators to use</InputLabel>
            <Select
              labelId="benchmark-evaluators-picker-label"
              id="benchmark-evaluators-picker"
              multiple
              size="small"
              displayEmpty
              value={Array.from(checkedEvaluators)}
              onChange={updateEvaluators}
              input={<OutlinedInput id="select-multiple-evaluators" label="Evaluators to use" />}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={`benchmark-evaluator-chip-selected-${value}`}
                        label={value}
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                      />
                    ))}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {!allEvaluatorsChecked && (
                <MenuItem key={`benchmark-evaluator-select-item-select-all`} value={SELECT_ALL_VALUE}>
                  Select all
                </MenuItem>
              )}
              {!noEvaluatorsChecked && (
                <MenuItem key={`benchmark-evaluator-select-item-deselect-all`} value={DESELECT_ALL_VALUE}>
                  Deselect all
                </MenuItem>
              )}
              {evaluators.map((e: Evaluator) => (
                <MenuItem
                  sx={{ textTransform: "capitalize" }}
                  key={`benchmark-evaluator-select-item-${e.type}`}
                  value={e.type}
                >
                  <Checkbox size="small" checked={checkedEvaluators.has(e.type)} />
                  <ListItemText primary={e.type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TableContainer
            sx={{ border: 1, borderColor: "divider", borderRadius: 2 }}
            style={{ width: "100%", maxHeight: 240, overflowX: "auto" }}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={60}>
                    <Checkbox
                      size="small"
                      checked={checkedTestCases.size > 0 && checkedTestCases.size === validTestCases.length}
                      onClick={toggleAllTestCases}
                    />
                  </TableCell>
                  <TableCell>Test Case</TableCell>
                  <TableCell>Values</TableCell>
                  <TableCell>Expected result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(template?.evalCases?.length ?? 0) > 0 ? (
                  template?.evalCases?.map((r: EvalCase, i) => (
                    <TableRow
                      hover
                      key={`benchmark-variant-list-item-${i}`}
                      sx={{ height: 44, "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" width={60}>
                        <Checkbox
                          size="small"
                          checked={checkedTestCases.has(r.id)}
                          onChange={() => toggleTestCase(r.id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" sx={{ whiteSpace: "nowrap", wordBreak: "keep-all" }}>
                          {r.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{ whiteSpace: "pre", wordBreak: "keep-all" }}>
                          {r.variables
                            ?.filter((v) => !!v.value)
                            ?.map((v) => `${v.name}=${v.value}`)
                            .join("\n") || "<empty>"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{ whiteSpace: "nowrap", wordBreak: "keep-all" }}>
                          {r.fields?.referenceResponse || "<empty>"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Select size="small" value={multiplier | 0} onChange={onChangeMultiplier} required>
              <MenuItem value={1}>1x</MenuItem>
              <MenuItem value={5}>5x</MenuItem>
              <MenuItem value={10}>10x</MenuItem>
              <MenuItem value={20}>20x</MenuItem>
            </Select>
            <Typography variant="body1">This benchmark will generate {totalCount} LLM requests.</Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={saving || totalCount === 0}
              onClick={() => {
                runBenchmark();
              }}
              variant="contained"
            >
              Run
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
