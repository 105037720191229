import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomeActivityCard } from "generated/graphql";
import { RootState } from "./store";

export interface UIState {
  volume: number;
  homeActivitCards?: HomeActivityCard[];
}
const initialState: UIState = { volume: 0.3 };

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    setHomeActivityCards: (state, action: PayloadAction<HomeActivityCard[]>) => {
      state.homeActivitCards = action.payload;
    },
  },
});

export const { setVolume, setHomeActivityCards } = uiSlice.actions;

export const selectUI = (state: RootState) => state.ui;

export default uiSlice.reducer;
