import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { Template, TemplateInputField, useDoUpdateTemplateMutation } from "generated/graphql";
import { useAppDispatch, useAppSelector } from "hooks";
import debounce from "lodash.debounce";
import { useMemo } from "react";
import { inputFieldChanged, selectEval } from "store/eval_reducer";

function VariableDescriptionField({
  row,
  onBlur,
}: {
  row: { name: string };
  onBlur: (change: { name: string; description: string }) => void;
}) {
  const dispatch = useAppDispatch();
  const { fields } = useAppSelector(selectEval);
  async function onValueChanged(change: { name: string; description: string }) {
    dispatch(inputFieldChanged(change));
  }

  async function blur(change: { name: string; description: string }) {
    onBlur(change);
  }

  return (
    <TextField
      fullWidth
      multiline
      autoComplete="off"
      id={`{variable-descripton-field-${row.name}`}
      InputProps={{ disableUnderline: true, id: `{variable-description-input-${row.name}` }}
      variant="standard"
      size="small"
      value={fields[row.name]?.description ?? ""}
      onChange={(event) => {
        onValueChanged({ name: row.name, description: event.currentTarget.value });
      }}
      onBlur={(event) => {
        blur({ name: row.name, description: event.currentTarget.value });
      }}
      placeholder="Description"
    />
  );
}

export default function FunctionVariables({ template }: { template?: Template }) {
  const { usedVariables, unusedVariables, fields } = useAppSelector(selectEval);
  const rows = [...usedVariables, ...unusedVariables].map((v) => ({ name: v }));
  const updateTemplateMutation = useDoUpdateTemplateMutation();

  const debouncedOnChange = useMemo(() => {
    return debounce(async (change: { name: string; description: string }) => {
      if (!template?.id) {
        return;
      }

      const usedVariableFields = usedVariables.map((v) => ({ name: v, description: "" }));
      const allFields = [...usedVariableFields, ...Object.values(fields), ...(template?.fields?.inputs ?? [])];
      const dedupe: { [key: string]: string } = {};
      allFields.forEach((i) => {
        if (i.name === change.name) {
          dedupe[change.name] = change.description;
        } else {
          // later ones will overwrite earlier ones
          dedupe[i.name] = i.description;
        }
      }, [] as TemplateInputField[]);

      const newInputs = Object.keys(dedupe).map((k) => ({ name: k, description: dedupe[k] }));
      await updateTemplateMutation.mutateAsync({
        input: {
          id: template.id,
          fields: {
            inputs: newInputs,
          },
        },
      });
    }, 500);
  }, [fields, template?.fields?.inputs, template?.id, updateTemplateMutation, usedVariables]);

  function onBlur(change: { name: string; description: string }) {
    debouncedOnChange(change);
  }

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        background: "#f6f7f8",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        overflow: "hidden",
      }}
    >
      <TableContainer style={{ height: "100%" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Variable</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={`variable-description-row-${row.name}`}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <VariableDescriptionField row={row} onBlur={onBlur} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
