import React, { createContext, useEffect } from "react";
import RouteNames from "route_names";
import { currentUserInitialized } from "store/current_user_reducer";
import { customerLoaded } from "store/plan_reducer";
import { identify } from "analytics";
import { initialized as currentCompanyInitialized } from "store/current_company_reducer";
import { Outlet, useLocation } from "react-router-dom";
import { selectAuth, updateUserAccountToTeam } from "store/auth_reducer";
import { useAppDispatch, useAppNavigate, useAppSelector, useVisualHeight } from "hooks";
import { useGetCurrentCompanyQuery } from "generated/graphql";
import { useGetCurrentUserQuery } from "generated/graphql";
import { useLoggedIn } from "utils";
import "./app.sass";

export type PlayEnterRoom = {
  clicked: boolean;
  play: () => void;
};

export const EnterRoomContext = createContext<PlayEnterRoom | undefined>(undefined);

function isAuthRequired(path: string) {
  return !new Set([RouteNames.NewPrompt]).has(path);
}

function isOnboarding(path: string) {
  return new Set([RouteNames.Onboarding]).has(path);
}

const onboardingEnforced = true;

function App(props: { children?: JSX.Element[] | JSX.Element }) {
  const navigate = useAppNavigate();
  const location = useLocation();
  const auth = useAppSelector(selectAuth);
  const loggedIn = useLoggedIn();
  const dispatch = useAppDispatch();
  const visualHeight = useVisualHeight();

  const currentUserResult = useGetCurrentUserQuery(undefined, { enabled: loggedIn });
  const currentCompanyResult = useGetCurrentCompanyQuery(undefined, { enabled: loggedIn });

  useEffect(() => {
    if (auth.initialized) {
      if (!auth.currentAccount) {
        if (isAuthRequired(location.pathname)) {
          navigate(RouteNames.SignIn);
        }
      }
    }
  }, [auth, dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (currentUserResult && currentUserResult.data) {
      const currentUser = currentUserResult.data.currentUser;
      const currentCustomer = currentUserResult.data.currentCustomer;
      if (currentUser) {
        if (onboardingEnforced && currentUser?.onboarding?.needWebOnboarding && !isOnboarding(location.pathname)) {
          navigate(RouteNames.Onboarding);
        } else {
          dispatch(currentUserInitialized(currentUser));
        }
      }
      if (currentCustomer) {
        dispatch(customerLoaded(currentCustomer));
      }
    }
  }, [currentUserResult, dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (currentCompanyResult && currentCompanyResult.data) {
      const currentCompany = currentCompanyResult.data.currentCompany;
      if (currentCompany) {
        dispatch(currentCompanyInitialized(currentCompany));
      }
    }
  }, [currentCompanyResult, dispatch]);

  useEffect(() => {
    if (auth.currentAccount && currentUserResult?.data?.currentUser) {
      identify(currentUserResult?.data?.currentUser, auth.currentAccount);
    }
  }, [auth, currentUserResult?.data?.currentUser]);

  useEffect(() => {
    if (auth.currentAccount && !auth.currentAccount?.team && currentCompanyResult?.data?.currentCompany?.name) {
      dispatch(updateUserAccountToTeam({ team: currentCompanyResult.data.currentCompany.name }));
    }
  }, [auth.currentAccount, currentCompanyResult?.data?.currentCompany?.name, dispatch]);

  return (
    <div className="App" style={{ minHeight: visualHeight }}>
      <Outlet />
    </div>
  );
}

export default App;
