import React, { useCallback, useEffect, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { authorizeWithSlackOauth, selectAuth, SLACK_OAUTH_STATE_KEY } from "../../store/auth_reducer";
import Branding from "components/branding";
import RouteNames from "route_names";
import { useAppDispatch, useAppSelector } from "hooks";
import { DEEP_LINK_PATH_SLACK_SIGN_IN } from "values";

function useAuthorize(): Error | undefined {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { code, desktop, error: oauthError, state } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [error, setError] = useState<Error | undefined>();
  const [codeUsed, setCodeUsed] = useState<boolean>(false);

  const openDesktopApp = useCallback(() => {
    if (typeof code === "string" && typeof state === "string") {
      const link = document.createElement("a");
      const params = new URLSearchParams();
      params.set("code", code);
      params.set("state", state);
      link.href = `gistsai://${DEEP_LINK_PATH_SLACK_SIGN_IN}?${params.toString()}`;
      document.body.appendChild(link);
      link.click();
    }
  }, [code, state]);

  const authorize = useCallback(async () => {
    dispatch(authorizeWithSlackOauth(`${code}`));
  }, [code, dispatch]);

  useEffect(() => {
    if (auth.initialized && typeof code === "string" && code) {
      if (!codeUsed) {
        setCodeUsed(true);
        if (oauthError) {
          setError(new Error(`${oauthError}`));
        } else {
          if (desktop === "true") {
            openDesktopApp();
          }

          // check state
          const existingState = localStorage.getItem(SLACK_OAUTH_STATE_KEY);
          if (state === "" || state !== existingState) {
            setError(new Error(`Invalid state. Please sign in again`));
            return;
          }

          authorize();
        }
        localStorage.removeItem(SLACK_OAUTH_STATE_KEY);
        return;
      }

      if (auth.currentAccount) {
        navigate(RouteNames.Home);
        return;
      }
    }
  }, [
    auth.currentAccount,
    auth.initialized,
    authorize,
    codeUsed,
    oauthError,
    state,
    navigate,
    code,
    desktop,
    openDesktopApp,
  ]);

  return error ?? auth.error;
}

function SlackOAuthRedirect() {
  const error = useAuthorize();

  return (
    <div className="Loading">
      {error && (
        <div>
          <div>Oops...it's our fault. Please try again.</div>
          <div>
            <a href="mailto:team@cuely.ai">Contact support</a>
          </div>
        </div>
      )}
      {!error && "One sec..."}
      <Branding />
    </div>
  );
}

export default SlackOAuthRedirect;
