import notifyAuthChangeToExtension from "lib/notify_extension";
import RouteNames from "route_names";
import variables from "../variables";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { logError } from "logger";
import { RootState } from "./store";

export interface AuthAccount {
  token: string;
  name?: string | null | undefined;
  team?: string | null | undefined;
  teamID?: string | null | undefined;
  appID?: string | null | undefined;
  createdAt: number;
  isGuest?: boolean | null | undefined;
  source?: string | null | undefined;
  sourceUserName?: string | null | undefined;
  contentPath?: string | null | undefined;
  email?: string | null | undefined;
  roles?: string[] | null | undefined;
  permissions?: string[] | null | undefined;
}

export const AUTH_COOKIE_NAME = "auth";
const TOKEN_COOKIE_NAME = "token";

export interface AuthState {
  initialized: boolean;
  currentAccount?: AuthAccount;
  accounts: AuthAccount[];
  error?: Error;
  loggedIn: boolean;
}

const initialState: AuthState = { initialized: false, accounts: [], loggedIn: false };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addAccounts: (state, action: PayloadAction<AuthAccount[]>) => {
      const accounts = action.payload;
      if (accounts.length > 0) {
        state.currentAccount = accounts[0];
        localStorage.setItem(TOKEN_COOKIE_NAME, state.currentAccount.token);
      }

      const teamIDSet = new Set<string>(
        accounts.filter((account) => !!account.teamID).map((account) => account.teamID!)
      );

      const createdAtSet = new Set<number>(accounts.map((account) => account.createdAt));

      // remove existing
      const removedIfExist = state.accounts.filter(
        (a) => !teamIDSet.has(a.teamID ?? "") && !createdAtSet.has(a.createdAt)
      );

      // add the new ones
      for (const account of accounts) {
        removedIfExist.unshift(account);
      }

      state.accounts = removedIfExist;

      localStorage.setItem(AUTH_COOKIE_NAME, JSON.stringify(state));

      state.loggedIn = true;

      // let extension know about the new auth;
      notifyAuthChangeToExtension(state);

      return state;
    },
    switchAccount: (state, action: PayloadAction<AuthAccount>) => {
      state.currentAccount = action.payload;
      localStorage.setItem(TOKEN_COOKIE_NAME, state.currentAccount.token);
      localStorage.setItem(AUTH_COOKIE_NAME, JSON.stringify(state));

      // let extension know about the new auth;
      notifyAuthChangeToExtension(state);

      return state;
    },
    updateUserAccountToTeam: (state, action: PayloadAction<{ team: string }>) => {
      if (state.currentAccount) {
        state.currentAccount.team = action.payload.team;
      }
      return state;
    },
    initialized: (state, action: PayloadAction<AuthState>) => {
      state.initialized = true;
      state.accounts = action.payload?.accounts ?? [];
      state.currentAccount = action.payload?.currentAccount;
      if (state.currentAccount) {
        localStorage.setItem(TOKEN_COOKIE_NAME, state.currentAccount.token);
      } else {
        localStorage.removeItem(TOKEN_COOKIE_NAME);
      }
      return state;
    },
    authorizeFailed: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      return state;
    },
    removeAccounts: (state, action: PayloadAction<string[]>) => {
      // remove accounts whose token exists in the given tokens
      const removedIfExist = state.accounts.filter((a) => !action.payload.find((t) => t === a.token));
      state.accounts = removedIfExist;

      // if current account exists in the remove list, it also needs to be removed
      if (action.payload.find((t) => t === state.currentAccount?.token)) {
        state.currentAccount = undefined;
        localStorage.removeItem(TOKEN_COOKIE_NAME);
      }

      if (state.currentAccount && state.accounts?.length === 0) {
        // current account is available but not in accounts, add it
        state.accounts = [state.currentAccount];
      } else if (!state.currentAccount && state.accounts?.length > 0) {
        // current account is empty but we have valid accounts, set it
        state.currentAccount = state.accounts[0];
        localStorage.setItem(TOKEN_COOKIE_NAME, state.currentAccount.token);
      }

      localStorage.setItem(AUTH_COOKIE_NAME, JSON.stringify(state));

      if (state.currentAccount) {
        state.loggedIn = true;
      } else {
        state.loggedIn = false;
      }

      // let extension know about the new auth;
      notifyAuthChangeToExtension(state);

      return state;
    },
    logout: (state) => {
      localStorage.removeItem(AUTH_COOKIE_NAME);
      localStorage.removeItem(TOKEN_COOKIE_NAME);
      state = initialState;

      return state;
    },
  },
});

export const {
  addAccounts,
  switchAccount,
  updateUserAccountToTeam,
  initialized,
  authorizeFailed,
  removeAccounts,
  logout,
} = authSlice.actions;

let initializing = false;

export const initializeAuth = () => async (dispatch: Dispatch<PayloadAction<AuthState | string[]>>) => {
  if (initializing) {
    return;
  }

  try {
    initializing = true;
    const val = localStorage.getItem(AUTH_COOKIE_NAME);
    if (val) {
      const auth = JSON.parse(val) as AuthState;
      if (auth) {
        dispatch(initialized(auth));

        console.log("initializing auth");
        // skip verifying tokens if we're authorizing a user or a guest now
        if (window.location.pathname !== RouteNames.SlackTokenRedirect) {
          // find invalid tokens and remove them
          const tokenVerificationResults = await Promise.all(
            [auth.currentAccount, ...auth.accounts].map((a) => verifyToken(a?.token))
          );

          const tokensToBeRemoved = tokenVerificationResults.filter((s) => !!s) as string[];
          dispatch(removeAccounts(tokensToBeRemoved));
        }

        return;
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    initializing = false;
  }

  const empty: AuthState = { initialized: true, accounts: [], loggedIn: false };
  dispatch(initialized(empty));
};

export const authorizeWithAuthToken =
  (authToken: string) => async (dispatch: Dispatch<PayloadAction<AuthAccount[] | Error>>) => {
    const resp = await fetch(`${variables.SERVER_HOST}/auth/authorize`, {
      method: "POST",
      headers: {
        "X-Cuely-Auth-Token": authToken,
        "X-Gists-Auth-Token": authToken,
      },
    });
    const json = await resp.json();
    const {
      token,
      name,
      email,
      roles,
      permissions,
      team,
      teamID,
      appID,
      createdAt,
      isGuest,
      source,
      sourceUserName,
      contentPath,
      error,
    } = json;

    if (error) {
      logError(error);
      dispatch(authorizeFailed(new Error(error)));
    } else {
      let account: AuthAccount = {
        token,
        name,
        team,
        teamID,
        appID,
        createdAt,
        isGuest,
        source,
        sourceUserName,
        contentPath,
        email,
        roles,
        permissions,
      };
      dispatch(addAccounts([account]));
    }
  };

export function slackOauthRedirectURI(): string {
  return `${window.location.origin}/slack/oauth/redirect`;
}

export function slackOauthDesktopRedirectURI(): string {
  return `${variables.WEB_HOST}/slack/oauth/redirect?desktop=true`;
}

export function googleOauthRedirectURI(): string {
  return `${window.location.origin}/google/oauth/redirect`;
}

export const SLACK_OAUTH_STATE_KEY = "slack-oauth-state";

export function slackSignInURL(oauthState: string, redirectURI?: string): string {
  const scopes = ["email", "openid", "profile"].join(",");
  return `https://slack.com/openid/connect/authorize?response_type=code&scope=${scopes}&client_id=${
    variables.SLACK_CLIENT_ID
  }&redirect_uri=${redirectURI ?? slackOauthRedirectURI()}&state=${oauthState}`;
}

export const authorizeWithSlackOauth =
  (code: string, redirectURI?: string) => async (dispatch: Dispatch<PayloadAction<AuthAccount[] | Error>>) => {
    const authURL = "/slack/oauth/v2/authorize";
    const resp = await fetch(`${variables.SERVER_HOST}${authURL}`, {
      method: "POST",
      body: JSON.stringify({
        code,
        client_id: variables.SLACK_CLIENT_ID,
        redirect_uri: redirectURI ?? slackOauthRedirectURI(),
      }),
    });
    const json = await resp.json();
    const { token, name, email, roles, permissions, team, teamID, appID, createdAt, error } = json;

    if (error) {
      logError(error);
      dispatch(authorizeFailed(new Error(error)));
    } else {
      let account: AuthAccount = {
        token,
        name,
        email,
        roles,
        permissions,
        team,
        teamID,
        appID,
        createdAt,
      };
      dispatch(addAccounts([account]));
    }
  };

async function verifyToken(token: string | undefined): Promise<string | null> {
  if (!token) {
    return null;
  }

  try {
    const res = await fetch(`${variables.SERVER_HOST}/auth/verifyToken`, {
      method: "get",
      headers: {
        "x-cuely-token": token,
        "x-gists-token": token,
      },
    });

    if (res.ok) {
      return null;
    }

    // server error, don't log user out
    if (res.status >= 500) {
      return null;
    }
  } catch (error) {
    // it's okay
    if (error instanceof Error) {
      // local network error, don't invalidate the token
      if (
        error.message === "Failed to fetch" ||
        error.message === "Timeout" ||
        error.message === "Network request failed"
      ) {
        return null;
      }
    }
  }

  // token failed, return the value so it can be removed
  return token;
}

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
