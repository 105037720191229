import React from "react";
import Typography from "@mui/material/Typography/Typography";
import { selectAuth } from "store/auth_reducer";
import SignInButtons from "components/auth/sign_in_buttons";
import "./index.sass";
import { useAppSelector } from "hooks";

export default function Completion() {
  const { currentAccount } = useAppSelector(selectAuth);

  if (currentAccount?.isGuest) {
    return (
      <div className="Completion">
        <div className="Completion__fullscreen">
          <Typography variant="h3">Well done!</Typography>
          <div>
            <Typography className="Completion__SignInPrompt" variant="h6">
              Wanna set up your own break reminders?&nbsp;
              <b>Create an account now!</b>
            </Typography>
            <SignInButtons showBranding={false} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Completion">
      <Typography variant="h3">Well done!</Typography>
    </div>
  );
}
