import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignInButtons from "./sign_in_buttons";
import RouteNames from "route_names";
import { selectAuth } from "store/auth_reducer";
import { useAppSelector } from "hooks";
import { Headerify } from "components/layout/layout";
import { Stack, Typography } from "@mui/material";
import Branding from "components/branding";

export default function SignInPage() {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);

  useEffect(() => {
    if (auth.initialized) {
      if (auth.currentAccount && !(auth.currentAccount?.isGuest ?? false)) {
        navigate(RouteNames.Home);
      }
    }
  }, [auth, navigate]);

  return (
    <Headerify>
      <div className="SignIn-Wrapper">
        <div className="SignIn-Left">
          <Stack className="SignIn-Left__content" gap={2}>
            <Branding white />
            <Typography variant="h1">Ship trustworthy AI products</Typography>
            <Typography variant="h5" style={{ opacity: 0.5 }}>
              Sign up to claim 1,000,000 free GPT-3.5-Turbo tokens
            </Typography>
          </Stack>
        </div>
        <div className="SignIn-Right">
          <SignInButtons showBranding={false} />
        </div>
      </div>
    </Headerify>
  );
}
