import EvaluationSuite from "./evaluation_suite";
import { ApiPanel } from "./api_panel";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { TemplateVariantFieldsInput } from "generated/graphql";
import { useState } from "react";
import Logs from "./logs";
import Benchmarks from "./benchmarks";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function BottomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      // elevation={0}
      style={{ borderRadius: 6, borderTopLeftRadius: 0, borderTopRightRadius: 0, flex: 1, overflow: "hidden" }}
      role="tabpanel"
      hidden={value !== index}
      id={`bottom-tabpanel-${index}`}
      aria-labelledby={`bottom-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `bottom-tab-${index}`,
    "aria-controls": `bottom-tabpanel-${index}`,
  };
}

export default function BottomTabs({
  runTemplate,
}: {
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    values: { [key: string]: string };
    fields: TemplateVariantFieldsInput;
    evalCaseIDs?: string[];
  }) => void;
}) {
  const [value, setValue] = useState<number>(0);
  const onChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack alignItems="stretch" justifyContent="stretch" flex={1} style={{ height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={onChange} aria-label="basic tabs example">
          <Tab label="Run" {...a11yProps(0)} />
          {/* <Tab label="Guardrails" {...a11yProps(1)} /> */}
          {/* <Tab label="Feedback" {...a11yProps(2)} /> */}
          <Tab label="API" {...a11yProps(1)} />
          {/* <Tab label="Metrics" {...a11yProps(4)} />
          <Tab label="Monitoring" {...a11yProps(5)} /> */}
          <Tab label="Logs" {...a11yProps(2)} />
          <Tab label="Benchmarks" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <BottomTabPanel value={value} index={0}>
        <EvaluationSuite runTemplate={runTemplate} />
      </BottomTabPanel>
      {/* <BottomTabPanel value={value} index={1}>
        Guardrails
      </BottomTabPanel> */}
      {/* <BottomTabPanel value={value} index={2}>
        Feedback
      </BottomTabPanel> */}
      <BottomTabPanel value={value} index={1}>
        <ApiPanel />
      </BottomTabPanel>
      {/* <BottomTabPanel value={value} index={4}>
        Metrics
      </BottomTabPanel>
      <BottomTabPanel value={value} index={5}>
        Monitoring
      </BottomTabPanel>*/}
      <BottomTabPanel value={value} index={2}>
        <Logs />
      </BottomTabPanel>
      <BottomTabPanel value={value} index={3}>
        <Benchmarks />
      </BottomTabPanel>
    </Stack>
  );
}
