import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Box from "@mui/material/Box/Box";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Stack from "@mui/material/Stack/Stack";
import TextField from "@mui/material/TextField/TextField";
import timezones from "timezones-list";
import { ActivityType } from "generated/graphql";
import { formatHour12Hour, formatMin12Hour, replaceCharAtIndex } from "utils";
import { getDefaultTitle } from "components/home_card_helpers";
import { useEffect, useRef, useState } from "react";
import "./index.sass";
import {
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Slider,
  ToggleButton,
} from "@mui/material";

interface TimeZone {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
}

export function TimezonePicker({
  timezone,
  onChange,
}: {
  timezone: string | null;
  onChange: (newTimezone?: string | null) => void;
}) {
  const defaultOption = timezones.find((t) => t.tzCode === timezone);
  const [selectedTimezone, setSelectedTimezone] = useState<TimeZone | null | undefined>(defaultOption);

  function onSelect(value: TimeZone | null) {
    setSelectedTimezone(value);
    onChange(value?.tzCode);
  }

  return (
    <Autocomplete
      options={timezones}
      getOptionLabel={(option) => option.name}
      onChange={(_, option) => onSelect(option)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      sx={{ width: 320 }}
      value={selectedTimezone}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Timezone"
          InputLabelProps={{ shrink: false }}
          value={selectedTimezone?.name}
          size="small"
          autoFocus
        />
      )}
    />
  );
}

export function TimezonePickerPopover({
  open,
  className,
  timezone,
  onClose,
  anchorEl,
  onConfirm,
}: {
  open: boolean;
  className: string;
  timezone: string;
  onClose: () => void;
  onConfirm: (newTimezone?: string | null) => void;
  anchorEl: Element | null;
}) {
  let newTimezone = useRef<string | null | undefined>(timezone);

  return (
    <Popover
      className="TimezonePickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
      elevation={0}
    >
      <Stack direction="row" sx={{ p: 2 }}>
        <TimezonePicker timezone={timezone} onChange={(newVal) => (newTimezone.current = newVal)} />
        <IconButton
          sx={{ ml: 2, mr: 1 }}
          onClick={() => {
            onConfirm(newTimezone.current);
            onClose();
          }}
          color="success"
        >
          <CheckIcon />
        </IconButton>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Stack>
    </Popover>
  );
}

export function HourMinutePicker({
  orientation,
  minute,
  onChange,
}: {
  orientation: "horizontal" | "vertical";
  minute: number;
  onChange: (minute: number) => void;
}) {
  const vertical = orientation === "vertical";
  const [currentHour, setCurrentHour] = useState<number>((minute / 60) | 0);
  const [currentMin, setCurrentMin] = useState<number>(minute % 60);

  function scaleHour(val: number): number {
    return val;
    // return vertical ? 23 - val : val;
  }

  function scaleMin(val: number): number {
    return val;
    // return vertical ? 59 - val : val;
  }

  const hourMarks = Array.from(Array(13))
    .map((_, i) => (vertical ? 6 + i : 6 + i))
    .map((el) => ({
      value: el,
      label: scaleHour(el) % (vertical ? 2 : 3) === 0 ? formatHour12Hour(scaleHour(el)) : undefined,
    }));

  const minuteMarks = Array.from(Array(11))
    .map((_, i) => (vertical ? 4 + i * 5 : 5 + i * 5))
    .map((el) => ({ value: el, label: scaleMin(el) % 15 === 0 && scaleMin(el) > 0 ? `:${scaleMin(el)}` : undefined }));

  return (
    <Stack direction={vertical ? "row" : "column"} position="relative" spacing={1} sx={{ mb: 1 }}>
      <Slider
        track={false}
        orientation={orientation}
        sx={vertical ? { height: 360 } : { width: 360 }}
        getAriaValueText={(hour) => formatHour12Hour(hour)}
        step={-1}
        min={0}
        max={23}
        value={currentHour}
        marks={hourMarks}
        valueLabelDisplay="auto"
        valueLabelFormat={(hour) => `${formatMin12Hour(hour * 60 + currentMin)}`}
        onChange={(_, val) => {
          const newHour = Array.isArray(val) ? val[0] : val;
          onChange(newHour * 60 + currentMin);
          setCurrentHour(newHour);
        }}
      />
      <Slider
        track={false}
        orientation={orientation}
        sx={vertical ? { height: 360 } : { width: 360 }}
        getAriaValueText={(min) => `${formatMin12Hour(currentHour * 60 + min)}`}
        step={-1}
        min={0}
        max={59}
        value={currentMin}
        marks={minuteMarks}
        valueLabelDisplay="auto"
        valueLabelFormat={(min) => `${formatMin12Hour(currentHour * 60 + min)}`}
        onChange={(_, val) => {
          const newMin = Array.isArray(val) ? val[0] : val;
          onChange(currentHour * 60 + newMin);
          setCurrentMin(newMin);
        }}
      />
    </Stack>
  );
}

export function HourMinutePickerPopover({
  open,
  className,
  minute,
  onClose,
  anchorEl,
  onConfirm,
}: {
  open: boolean;
  className: string;
  minute: number;
  onClose: () => void;
  onConfirm: (newMinute: number) => void;
  anchorEl: Element | null;
}) {
  const [newMinute, setNewMinute] = useState<number>(minute ?? 10 * 60);

  useEffect(() => {
    setNewMinute(minute ?? 10 * 60);
  }, [minute]);

  return (
    <Popover
      className="HourMinutePickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
      elevation={0}
    >
      <Stack sx={{ p: 2 }} alignItems="stretch">
        <Box sx={{ mt: 2, ml: 2, mr: 2, mb: 2 }}>
          <HourMinutePicker orientation="horizontal" minute={minute} onChange={(min) => setNewMinute(min)} />
        </Box>
        <Stack sx={{ ml: 2, mr: 2 }} direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Box className="HourMinutePickerPopoverValueDisplay" sx={{ borderRadius: 20 }}>
            {formatMin12Hour(newMinute)}
          </Box>
          <Stack direction="row" spacing={1} alignItems="flex-end">
            <IconButton
              onClick={() => {
                onConfirm(newMinute);
                onClose();
              }}
              color="success"
            >
              <CheckIcon />
            </IconButton>
            <IconButton onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
}

export function WeekdayPicker({
  className,
  days,
  onChange,
}: {
  className: string;
  days: string;
  onChange: (newDays: string) => void;
}) {
  const [newDays, setNewDays] = useState<string>(days ?? "0111110");
  function dayToggled(day: number) {
    const val = newDays[day];
    if (val === "0" || val === "1") {
      const replacement = val === "0" ? "1" : "0";
      const newVal = replaceCharAtIndex(newDays, day, replacement);
      setNewDays(newVal);
      onChange(newVal);
    }
  }
  return (
    <Stack direction="row" spacing={1} className={`${className} WeekdayPicker__days`}>
      {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((d, i) => (
        <ToggleButton
          selected={newDays[i] === "1"}
          value={i}
          onChange={() => dayToggled(i)}
          key={`${className}__days-${i}`}
          className={newDays[i] === "1" ? `${className} WeekdayPicker__selectedDay` : `${className} WeekdayPicker__day`}
        >
          {d}
        </ToggleButton>
      ))}
    </Stack>
  );
}

export function WeekdayPickerPopover({
  open,
  className,
  days,
  onClose,
  anchorEl,
  onConfirm,
}: {
  open: boolean;
  className: string;
  days: string;
  onClose: () => void;
  onConfirm: (newDays: string) => void;
  anchorEl: Element | null;
}) {
  let [newDays, setNewDays] = useState<string>(days ?? "0111110");

  useEffect(() => {
    setNewDays(days ?? "0111110");
  }, [days]);

  return (
    <Popover
      className="WeekdayPickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
      elevation={0}
    >
      <Stack direction="row" sx={{ p: 2 }}>
        <WeekdayPicker {...{ className, days: newDays, onChange: (newVal) => setNewDays(newVal) }} />
        <IconButton
          sx={{ ml: 2, mr: 1 }}
          onClick={() => {
            onConfirm(newDays);
            onClose();
          }}
          color="success"
        >
          <CheckIcon />
        </IconButton>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Stack>
    </Popover>
  );
}

const Activities = [
  ActivityType.Routine,
  ActivityType.Journal,
  ActivityType.Breath,
  ActivityType.Stretch,
  ActivityType.EyeBreak,
  ActivityType.Boundary,
  ActivityType.Water,
  ActivityType.Lunch,
  ActivityType.Walk,
];

export function ActivityPicker({
  activity,
  onChange,
  className,
}: {
  className: string;
  activity?: ActivityType | null | "";
  onChange: (newActivity: ActivityType | null) => void;
}) {
  const [newActivity, setNewActivity] = useState<ActivityType | undefined | null | "">(activity);

  function handleChange(event: SelectChangeEvent<ActivityType | null | "">) {
    try {
      if (event.target.value === "") {
        setNewActivity(null);
        onChange(null);
        return;
      }

      setNewActivity(event.target.value as ActivityType);
      onChange(event.target.value as ActivityType);
    } catch {
      setNewActivity(null);
      onChange(null);
    }
  }

  useEffect(() => {
    if (activity !== newActivity) {
      setNewActivity(activity);
    }
  }, [activity, newActivity]);

  return (
    <FormControl sx={{ minWidth: 160 }} size="small">
      <Select
        className={className}
        labelId="activity-picker-select-label"
        value={newActivity ?? ""}
        onChange={handleChange}
        required
      >
        <MenuItem disabled value="">
          Reminder type
        </MenuItem>
        {Activities.map((a) => (
          <MenuItem selected={a === newActivity} key={a} value={a}>
            {getDefaultTitle(a)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function ChannelPicker() {}
