import { CurrentUser } from "generated/graphql";
import { AuthAccount } from "store/auth_reducer";
import variables from "variables";

declare global {
  interface Window {
    analytics: any;
  }
}

export const Events = {
  SharingButton: {
    Clicked: "sharing-button-clicked",
    LinkCreated: "sharing-button-link-created",
    LinkCopied: "sharing-button-link-copied",
  },
  SharingPage: {
    Landed: "sharing-page-landed",
    Started: "sharing-page-started",
  },
  SubscriptionModal: {
    Shown: "subscription-modal-shown",
  },
  SelectPlan: {
    Clicked: "select-plan-clicked",
  },
  ProtectedFeature: {
    Clicked: "protected-feature-clicked",
  },
};

async function init() {
  let token = localStorage.getItem("gists_mtoken");
  if (token) {
    return;
  }
  token = localStorage.getItem("cuely_mtoken");
  if (token) {
    return;
  }

  // get storage/cookie, if has token, skip. otherwise, initialize token
  try {
    const newGistsUser = {
      marketing_uuid: window.analytics.user().anonymousId(),
      landing_page: window.location.toString(),
      utm: {},
    };
    const res = await fetch(`${variables.SERVER_HOST}/m/u`, { method: "POST", body: JSON.stringify(newGistsUser) });
    const json = await res.json();
    if (json.token && json.token.length > 0) {
      localStorage.setItem("cuely_mtoken", json.token);
      localStorage.setItem("gists_mtoken", json.token);
    }
  } catch (e) {
    console.error(e);
  }
}

window.analytics?.ready(() => {
  init();
});

export function track(event: string, data: Object) {
  try {
    const params = { page: window.location.pathname, domain: window.location.host };
    window.analytics.track(event, Object.assign(params, data));
  } catch (e) {}
}

export function identify(user: CurrentUser, auth: AuthAccount) {
  try {
    window.analytics.identify(user.id, { email: auth.email, company: { id: auth.teamID, name: auth.team } });
  } catch (e) {}
}
