import React from "react";
import { motion } from "framer-motion";

function BlinkSubtitle() {
  return (
    <div className="blink-subtitle">
      <motion.div
        animate={{
          opacity: [0, 1, 0, 0, 1, 0, 0, 1, 0],
          y: [0, 0, 0, -30, -30, -30, -60, -60, -60],
        }}
        transition={{ duration: 11.935057, repeat: Infinity, times: [0, 1] }}
      >
        <div>Close your eyes</div>
        <div>Gently squeeze your eyelids</div>
        <div>Open and relax</div>
      </motion.div>
    </div>
  );
}

export default BlinkSubtitle;
