import Box from "@mui/material/Box/Box";
import Layout from "components/layout/layout";
import Loader from "react-spinners/ScaleLoader";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import useCopy from "@react-hook/copy";
import { Button, Chip, Paper, Snackbar, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { companyNameUpdated, selectCurrentCompany } from "store/current_company_reducer";
import { maskApiKey } from "utils";
import { MembersTabPanel } from "./members";
import { queryClient } from "api";
import { selectAuth } from "store/auth_reducer";
import { selectCurrentUser, userProfileUpdated } from "store/current_user_reducer";
import { TabPanelProps } from "components/function/bottom_tab";
import { useAppDispatch, useAppSelector, useIsElectron } from "hooks";
import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import "./index.sass";
import {
  ApiKey,
  useDoSetOpenAiKeyMutation,
  useDoUpdateCompanyMutation,
  useDoUpdateProfileMutation,
} from "generated/graphql";

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

function SettingsTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Paper
      elevation={0}
      style={{ borderRadius: 6, borderTopLeftRadius: 0, borderTopRightRadius: 0, flex: 1, overflow: "hidden" }}
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Paper>
  );
}

function SettingsTabs({ setStatusMessage }: { setStatusMessage: (message: string | null | undefined) => void }) {
  const isElectron = useIsElectron();
  const { hash } = useLocation();
  const index = (() => {
    switch (hash) {
      case "#personal":
        return 0;
      case "#organization":
        return 1;
      case "#members":
        return 2;
      case "#developer":
        return 3;
      case "#openai":
        return 4;
      default:
        return 0;
    }
  })();

  const [value, setValue] = useState<number>(index);
  const onChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box flex={1}>
      <Stack flex={1} style={{ height: "100%", margin: "0 auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {isElectron ? (
            <Tabs value={value} onChange={onChange} aria-label="">
              <Tab label="Personal" {...a11yProps(0)} />
              <Tab label="Organization" {...a11yProps(1)} />
              <Tab label="Members" {...a11yProps(2)} />
              <Tab label="Developer" {...a11yProps(3)} />
              <Tab label="OpenAI" {...a11yProps(4)} />
            </Tabs>
          ) : (
            <Tabs value={value} onChange={onChange} aria-label="">
              <Tab href="#personal" label="Personal" {...a11yProps(0)} />
              <Tab href="#organization" label="Organization" {...a11yProps(1)} />
              <Tab href="#members" label="Members" {...a11yProps(2)} />
              <Tab href="#developer" label="Developer" {...a11yProps(3)} />
              <Tab href="#openai" label="OpenAI" {...a11yProps(4)} />
            </Tabs>
          )}
        </Box>
        <SettingsTabPanel value={value} index={0}>
          <UserTabPanel setStatusMessage={setStatusMessage} />
        </SettingsTabPanel>
        <SettingsTabPanel value={value} index={1}>
          <CompanyTabPanel setStatusMessage={setStatusMessage} />
        </SettingsTabPanel>
        <SettingsTabPanel value={value} index={2}>
          <MembersTabPanel setStatusMessage={setStatusMessage} />
        </SettingsTabPanel>
        <SettingsTabPanel value={value} index={3}>
          <ApiTabPanel setStatusMessage={setStatusMessage} />
        </SettingsTabPanel>
        <SettingsTabPanel value={value} index={4}>
          <OpenAiTabPanel setStatusMessage={setStatusMessage} />
        </SettingsTabPanel>
      </Stack>
    </Box>
  );
}

function UserTabPanel({ setStatusMessage }: { setStatusMessage: (message: string | null | undefined) => void }) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectCurrentUser);
  const { currentAccount } = useAppSelector(selectAuth);

  const [name, setName] = useState<string>(currentUser?.profile?.fullName || currentAccount?.name || "");

  const [changed, setChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const updateUserMutation = useDoUpdateProfileMutation();

  async function onSave() {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const resp = await updateUserMutation.mutateAsync({
        input: {
          fullName: name,
        },
      });

      if (resp.updateProfile) {
        dispatch(userProfileUpdated(resp.updateProfile));
        setChanged(false);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setStatusMessage(e.message);
      }
    } finally {
      setSaving(false);
    }
  }

  return (
    <Stack sx={{ p: 6 }} gap={3}>
      <TextField
        id="user-full-name"
        required
        size="small"
        disabled={saving}
        className="UserProfile__full_name"
        onChange={(e) => {
          setChanged(true);
          setName(e.target.value);
        }}
        placeholder="Your full name"
        label="Full name"
        value={name ?? ""}
      />

      <TextField
        id="user-email"
        size="small"
        contentEditable={false}
        type="email"
        disabled={true}
        className="UserProfile__email"
        placeholder="Email"
        label="Email"
        value={currentAccount?.email ?? ""}
      />

      <Button variant="contained" disabled={!changed || saving} onClick={onSave}>
        Save
      </Button>
    </Stack>
  );
}

function CompanyTabPanel({ setStatusMessage }: { setStatusMessage: (message: string | null | undefined) => void }) {
  const dispatch = useAppDispatch();
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const { currentAccount } = useAppSelector(selectAuth);

  const [name, setName] = useState<string>(currentCompany?.name ?? currentAccount?.team ?? "");
  const [changed, setChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const updateCompany = useDoUpdateCompanyMutation();

  async function onSave() {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const resp = await updateCompany.mutateAsync({
        input: {
          id: currentCompany?.id ?? "",
          name,
        },
      });

      if (resp.updateCompany) {
        dispatch(companyNameUpdated(resp.updateCompany.name));
        setChanged(false);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setStatusMessage(e.message);
      }
    } finally {
      setSaving(false);
    }
  }

  return (
    <Stack sx={{ p: 6 }} gap={3}>
      <TextField
        id="company-name"
        size="small"
        disabled={saving}
        className="Company__name"
        onChange={(e) => {
          setChanged(true);
          setName(e.target.value);
        }}
        placeholder="Organization name"
        label="Name"
        value={name ?? ""}
      />
      <Button variant="contained" disabled={!changed || saving} onClick={onSave}>
        Save
      </Button>
    </Stack>
  );
}

function ApiKeyRow({ apiKey }: { apiKey: ApiKey }) {
  const { copied, copy } = useCopy(apiKey.id);

  return (
    <Stack key={apiKey.id} direction="row" alignItems="center" gap={2}>
      <Box>{apiKey.name}</Box>
      <Tooltip placement="right" title={copied ? "Copied!" : "Click to copy"}>
        <Chip
          label={maskApiKey(apiKey.id)}
          onClick={copy}
          style={{
            fontSize: 11,
            fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, "Source Code Pro", source-code-pro, monospace',
          }}
        />
      </Tooltip>
    </Stack>
  );
}

function ApiTabPanel({ setStatusMessage }: { setStatusMessage: (message: string | null | undefined) => void }) {
  const { currentCompany } = useAppSelector(selectCurrentCompany);

  // const [saving, setSaving] = useState<boolean>(false);

  const apiKeys = currentCompany?.apiKeys ?? [];

  return (
    <Stack sx={{ p: 6 }} gap={3}>
      {apiKeys.length === 0
        ? "Create"
        : apiKeys.map((apiKey) => <ApiKeyRow key={`api-key-${apiKey.id}`} apiKey={apiKey} />)}
    </Stack>
  );
}

function OpenAiTabPanel({ setStatusMessage }: { setStatusMessage: (message: string | null | undefined) => void }) {
  const { currentCompany } = useAppSelector(selectCurrentCompany);

  const ref = useRef<HTMLInputElement>(null);
  const [apiKey, setApiKey] = useState<string>(currentCompany?.openAiKey ?? "");
  const [updating, setUpdating] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const updateOpenAiKey = useDoSetOpenAiKeyMutation();

  async function onSave() {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const resp = await updateOpenAiKey.mutateAsync({
        key: apiKey,
      });

      if (resp.setOpenAiKey) {
        setChanged(false);
        // refetch company
        await queryClient.invalidateQueries(["currentCompany"]);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setStatusMessage(e.message);
      }
    } finally {
      setSaving(false);
      setUpdating(false);
    }
  }

  return (
    <Stack sx={{ p: 6 }} gap={3}>
      <Stack gap={2} alignItems="stretch">
        <TextField
          ref={ref}
          autoComplete="off"
          id="openai-key"
          name="openai-key"
          size="small"
          disabled={saving || !updating}
          className="Company__openai_key"
          onChange={(e) => {
            setChanged(true);
            setApiKey(e.target.value);
          }}
          autoFocus
          placeholder="Enter a new OpenAI API Key"
          label="OpenAI API Key"
          value={apiKey ?? ""}
        />
        <Stack direction="row" justifyContent="space-between" gap={2}>
          {updating && (
            <Stack direction="row" gap={2}>
              <Button variant="contained" disabled={!changed || saving} onClick={onSave}>
                Save
              </Button>
              <Button
                disabled={saving}
                onClick={() => {
                  setUpdating(false);
                  setApiKey(currentCompany?.openAiKey ?? "");
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
          {!updating && (
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setApiKey("");
                  setUpdating(true);
                }}
              >
                Replace
              </Button>
            </Stack>
          )}
          <Button href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">
            Find your API key on OpenAI
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

const SettingsHome = () => {
  const { initialized: ci, currentCompany } = useAppSelector(selectCurrentCompany);
  const { initialized: ui, currentUser } = useAppSelector(selectCurrentUser);

  const [statusMessage, setStatusMessage] = useState<string | undefined | null>();

  //  modal

  const closeSnackbar = () => {
    setStatusMessage(null);
  };

  return (
    <Layout
      className="SettingsHome"
      header={
        <div className="PageHeader">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" className="PageTitle">
              Settings
            </Typography>
          </Stack>
        </div>
      }
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!statusMessage}
        onClose={closeSnackbar}
        message={statusMessage}
        key={statusMessage}
      />
      {ui && ci ? (
        <SettingsTabs
          key={`user-settings-${currentCompany?.id}-${currentUser?.id}`}
          setStatusMessage={setStatusMessage}
        />
      ) : (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <Loader color="#ccc" />
        </Stack>
      )}
    </Layout>
  );
};

export default SettingsHome;
