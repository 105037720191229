import MetricChart from "components/chart";
import { addDays, endOfWeek, PeriodLimit, periodRange } from "utils";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { Grid } from "@mui/material";
import { useState } from "react";
import {
  GetPersonalTotalBreaksCountQueryVariables,
  TimePeriod,
  useGetPersonalTotalBreaksCountQuery,
} from "generated/graphql";

const PersonalTotalBreaks = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  // GraphQL data call
  const totalBreaksCount = useGetPersonalTotalBreaksCountQuery({
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetPersonalTotalBreaksCountQueryVariables);

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "MMM do",
    description = `for selected time period`; /*,
      actionToBeTakenOnClick = () => {
        navigate(RouteNames.ReminderSettings);
      }*/
  let title = "Total Breaks",
    subtitle = undefined, //To allow chart component to show a circle progress when loading ;)
    data = factoryDefaultData();

  if (!totalBreaksCount.isLoading && totalBreaksCount.isSuccess) {
    title = totalBreaksCount.data?.personalDashboard?.totalBreaksCount.name ?? title;
    subtitle = totalBreaksCount.data?.personalDashboard?.totalBreaksCount?.total;
    const rawData = totalBreaksCount.data?.personalDashboard?.totalBreaksCount?.values;

    data = {
      labels: rawData?.map((tb) => {
        const start = format(parseISO(tb.date), dateFmt);
        const end = format(addDays(endOfWeek(parseISO(tb.date)), 1), dateFmt);

        return `${start}-${end}`;
      }),
      datasets: [
        {
          label: title,
          data: rawData?.map((tb) => tb.value),
          backgroundColor: rawData?.map((tb) => "#F584DE"),
        },
      ],
    };
  }
  const hasEnoughData = totalBreaksCount.data?.personalDashboard?.totalBreaksCount.values
    ? totalBreaksCount.data?.personalDashboard?.totalBreaksCount.values.length > 0
    : false;
  return (
    <Grid item container xs={12} md={6} lg={6}>
      <MetricChart
        requireUpgrade={false}
        type={type}
        title={title}
        subtitle={subtitle}
        description={description}
        dataSuccess={totalBreaksCount.isSuccess}
        dataLoading={totalBreaksCount.isLoading}
        hasEnoughData={hasEnoughData}
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                callback: function (value: any, index: any, ticks: any) {
                  const startDate = format(
                    parseISO(totalBreaksCount?.data?.personalDashboard?.totalBreaksCount?.values[index].date),
                    "MMM do"
                  );
                  return startDate;
                },
              },
            },
          },
        }}
      />
    </Grid>
  );
};

export default PersonalTotalBreaks;
