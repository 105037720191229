import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prompt, Prompts } from "generated/graphql";
import { RootState } from "./store";

export interface PromptState {
  initialized: boolean;
  prompts: Prompt[];
  cursor?: string | null;
  loaded: boolean;
  limit: number;
}

export function appendResult(responses: Prompt[], newResponses: Prompt[]): Prompt[] {
  const set = new Set(responses.map((r) => r.id));
  const filtered = newResponses.filter((r) => !set.has(r.id));
  return [...responses, ...filtered];
}

const initialState: PromptState = { initialized: false, prompts: [], cursor: null, loaded: false, limit: 24 };

export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    addPrompts: (state, action: PayloadAction<Prompts | null | undefined>) => {
      state.initialized = true;
      const newCursor = action.payload?.cursor;
      const newToOld = [...(action.payload?.prompts ?? [])];
      if (newCursor && newCursor !== state.cursor && newToOld.length > 0) {
        // we loaded a new page. let's push the result
        state.prompts = appendResult(state.prompts, newToOld);
        // now it won't load again
        state.cursor = newCursor;
      }

      if (newToOld.length < state.limit) {
        state.loaded = true;
      }

      return state;
    },
    // add a single prompt, it doesn't change the cursor
    createPrompt: (state, action: PayloadAction<Prompt>) => {
      if (action.payload) {
        state.prompts = [action.payload, ...state.prompts];
      }
      return state;
    },
    removePrompts: (state, action: PayloadAction<string[]>) => {
      const toBeDeleted = new Set<string>(action.payload ?? []);
      state.prompts = state.prompts.filter((p) => !toBeDeleted.has(p.id));
      return state;
    },
    clearPrompts: (state) => {
      state.initialized = false;
      state.prompts = [];
      state.loaded = false;
      state.cursor = null;
      return state;
    },
  },
});

export const { addPrompts, createPrompt, removePrompts, clearPrompts } = promptsSlice.actions;

export const selectPrompts = (state: RootState) => state.prompts;

export default promptsSlice.reducer;
