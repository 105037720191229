import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ActivityPicker, HourMinutePickerPopover, WeekdayPickerPopover } from "components/pickers";
import usePickerPopover from "components/pickers/picker_popover_hook";
import { ActivityType, CustomReminderInput, ReminderSchedule } from "generated/graphql";
import { useEffect, useState } from "react";
import { formatMin12Hour } from "utils";

const DefaultDays = "0111110";

function currentHour() {
  return new Date().getHours();
}

function nextFullHourInMins() {
  return ((currentHour() + 1) % 24) * 60;
}

function reminderScheduleToCustomReminderInput(reminder: ReminderSchedule): CustomReminderInput {
  return {
    key: reminder.key,
    active: reminder.active ?? true,
    days: reminder.days ?? DefaultDays,
    timezone: reminder.timezone,
    minute: reminder.minute,
    action: reminder.action,
  } as CustomReminderInput;
}

function newCustomReminderInput(): CustomReminderInput {
  return {
    key: "",
    active: true,
    days: DefaultDays,
    minute: nextFullHourInMins(),
  } as CustomReminderInput;
}

export default function CustomReminderModal({
  open,
  onClose,
  customReminder,
  onConfirm,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  customReminder?: ReminderSchedule;
  onConfirm: (reminder: CustomReminderInput) => void;
  onDelete: (reminder: CustomReminderInput) => void;
}) {
  const [weekdayPickerAnchor, weekdayPickerDays, openWeekdayPicker, confirmWeekdayPicker, closeWeekdayPicker] =
    usePickerPopover<string>();

  const [
    hourMinutePickerAnchor,
    hourMinutePickerHourMinute,
    openHourMinutePicker,
    confirmHourMinutePicker,
    closeHourMinutePicker,
  ] = usePickerPopover<number>();

  const [wipReminder, setWipReminder] = useState<CustomReminderInput | undefined>(
    customReminder ? reminderScheduleToCustomReminderInput(customReminder) : newCustomReminderInput()
  );

  const itemKey = customReminder
    ? customReminder.key ?? `${customReminder.action}-${customReminder.contentID}-${customReminder.days}`
    : "createNewCustomer";

  function validate(): boolean {
    if (wipReminder && wipReminder.action && wipReminder.days && wipReminder.minute) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    setWipReminder(customReminder ? reminderScheduleToCustomReminderInput(customReminder) : newCustomReminderInput());
  }, [customReminder]);

  function close() {
    setWipReminder(undefined);
    onClose();
  }

  return (
    <Dialog className="CustomReminder" open={open} onClose={close}>
      <DialogTitle>{customReminder ? "Edit reminder" : "Add reminder"}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText>{customReminder ? "Change reminder details" : "Specify reminder details"}</DialogContentText>
        <Stack>
          <Stack sx={{ pt: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight={500} className="CustomReminder__label">
              Reminder type
            </Typography>
            <Box>
              <ActivityPicker
                className="CustomReminder__activityPicker"
                activity={wipReminder?.action}
                onChange={(val) => {
                  const newReminder = Object.assign({}, wipReminder);
                  newReminder.action = val ?? ("" as ActivityType);
                  setWipReminder(newReminder);
                }}
              />
            </Box>
          </Stack>
          <Stack sx={{ pt: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight={500} className="CustomReminder__label">
              Reminder time
            </Typography>
            <Button
              size="small"
              className="CustomReminder__timehour"
              onClick={(event) =>
                openHourMinutePicker(wipReminder?.minute ?? nextFullHourInMins(), event.currentTarget, (val) => {
                  const newReminder = Object.assign({}, wipReminder);
                  newReminder.minute = val ?? nextFullHourInMins();
                  setWipReminder(newReminder);
                })
              }
            >
              {formatMin12Hour(wipReminder?.minute ?? nextFullHourInMins())}
            </Button>
          </Stack>
          <Stack sx={{ pt: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight={500} className="CustomReminder__label">
              Reminder active days
            </Typography>
            <Stack
              direction="row"
              className="CustomReminder__days"
              onClick={(event) =>
                openWeekdayPicker(wipReminder?.days ?? DefaultDays, event.currentTarget, (val) => {
                  const newReminder = Object.assign({}, wipReminder);
                  newReminder.days = val ?? DefaultDays;
                  setWipReminder(newReminder);
                })
              }
            >
              {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
                <span
                  key={`${itemKey}__days-${i}`}
                  className={
                    (wipReminder?.days ?? DefaultDays)[i] === "1"
                      ? "CustomReminder__selectedDay"
                      : "CustomReminder__day"
                  }
                >
                  {d}
                </span>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ p: 1 }}>
          {wipReminder && (
            <Box justifySelf="flex-start" flex={1}>
              <Button onClick={() => onDelete(wipReminder)} color="error" variant="contained">
                Delete
              </Button>
            </Box>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close}>Cancel</Button>
            <Button
              disabled={!validate()}
              onClick={wipReminder ? () => onConfirm(wipReminder) : undefined}
              variant="contained"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogActions>

      <WeekdayPickerPopover
        open={!!weekdayPickerAnchor}
        className="WeekdayPicker"
        onClose={closeWeekdayPicker}
        days={weekdayPickerDays ?? DefaultDays}
        anchorEl={weekdayPickerAnchor}
        onConfirm={confirmWeekdayPicker}
      />

      <HourMinutePickerPopover
        open={!!hourMinutePickerAnchor}
        className="HourminutePicker"
        onClose={closeHourMinutePicker}
        minute={hourMinutePickerHourMinute ?? 10 * 60}
        anchorEl={hourMinutePickerAnchor}
        onConfirm={confirmHourMinutePicker}
      />
    </Dialog>
  );
}
