import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

export function imageURL(url: string): string {
  // no need to transform
  return url;
}

export function pageURL(url: string, id?: string): string {
  return id ? `${url}?reminderId=${id}` : url;
}

export function formatReminderStartTime(startAt: Date): string {
  if (!startAt) {
    return "";
  }

  if (startAt && startAt < new Date()) {
    return `${formatDistanceToNowStrict(startAt)} ago`;
  }

  return `in ${formatDistanceToNowStrict(startAt)}`;
}

export function formatTimeRange(startAt: Date, endAt?: Date | null): string {
  const startTime = format(startAt, "hh:mmaaa");
  if (endAt) {
    const endTime = format(endAt, "hh:mmaaa");
    return `${startTime} - ${endTime}`;
  }
  return startTime;
}

export function formatDate(date: Date): string {
  return format(date, "MMM dd, yyyy");
}

export function formatDateTime(date: Date): string {
  return format(date, "MMM dd, yyyy hh:mm:ssaaa");
}

export function formatTime(date: Date): string {
  return format(date, "hh:mm:ssaaa");
}
