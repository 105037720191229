import { Paper, Typography } from "@mui/material";
import { GetTeamEnergySummaryQueryVariables, TimePeriod, useGetTeamEnergySummaryQuery } from "generated/graphql";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import DOMPurify from "isomorphic-dompurify";
import { replaceEmoji, formatText, periodRange, PeriodLimit } from "utils";

const EnergySummary = ({
  hidden,
  companyIds,
  teamIds,
  startAt,
  endAt,
}: {
  hidden?: boolean;
  companyIds?: string | string[] | undefined | null;
  teamIds?: string | string[] | undefined | null;
  startAt: Date;
  endAt: Date;
}) => {
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const energySummary = useGetTeamEnergySummaryQuery({
    companyIds,
    teamIds,
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetTeamEnergySummaryQueryVariables);

  const htmlContent: string = formatText(replaceEmoji(energySummary.data?.teamDashboard?.energySummary ?? ""));

  return (!hidden && energySummary.data?.teamDashboard?.energySummary) || energySummary.isLoading ? (
    <Paper className="EnergySummaryItem" sx={{ p: 2, mb: 2 }} elevation={0}>
      <Typography variant="body2">
        {energySummary.isLoading ? (
          <Skeleton />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }} />
        )}
      </Typography>
    </Paper>
  ) : null;
};

export default EnergySummary;
