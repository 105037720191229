import CodeEditor from "@uiw/react-textarea-code-editor";
import IconAi from "assets/v2/icon-ai.svg";
import IconDelete from "assets/v2/icon-delete.svg";
import IconDrag from "assets/v2/icon-drag.svg";
import IconSystem from "assets/v2/icon-system.svg";
import IconUser from "assets/v2/icon-personal.svg";
import React, { CSSProperties } from "react";
import { AiModelType, GptParams, GptPromptMessage, GptPromptMessageRole } from "generated/graphql";
import { Box, Button, IconButton, Paper, Skeleton, Stack, Tooltip } from "@mui/material";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { v4 as uuidv4 } from "uuid";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
  ResponderProvided,
} from "react-beautiful-dnd";

const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function PromptPane({
  model,
  gpt,
  isLoading,
  onChangePrompt,
}: {
  model?: AiModelType;
  gpt: GptParams;
  isLoading: boolean;
  onChangePrompt: (gpt: GptParams) => void;
}) {
  const system = gpt.systemMessage
    ? ({ role: GptPromptMessageRole.System, text: gpt.systemMessage } as GptPromptMessage)
    : ({} as GptPromptMessage);

  const other =
    gpt.messages?.map((m) => {
      if (!m.uid) {
        return { ...m, uid: uuidv4() };
      }
      return m;
    }) ?? [];

  function addMessage() {
    let roleToAdd = GptPromptMessageRole.User;
    const lastRole = other[other.length - 1]?.role;
    if (lastRole === GptPromptMessageRole.User) {
      roleToAdd = GptPromptMessageRole.Assistant;
    }
    onChangePrompt({ ...gpt, systemMessage: system.text, messages: [...other, { role: roleToAdd, text: "" }] });
  }

  function deleteMessage(otherIndex: number) {
    const newOther = [...other];
    newOther.splice(otherIndex, 1);
    onChangePrompt({ ...gpt, systemMessage: system.text, messages: newOther });
  }

  function switchRole(otherIndex: number) {
    const newOther = other.map((o) => ({ ...o }));
    newOther[otherIndex].role =
      newOther[otherIndex].role === GptPromptMessageRole.Assistant
        ? GptPromptMessageRole.User
        : GptPromptMessageRole.Assistant;
    onChangePrompt({ ...gpt, systemMessage: system.text, messages: newOther });
  }

  function onSystemPromptChange(newPrompt: string) {
    let newSystem = system ? { ...system } : ({ role: GptPromptMessageRole.System, text: "" } as GptPromptMessage);
    newSystem.text = newPrompt;
    onChangePrompt({
      ...gpt,
      systemMessage: newSystem.text,
      messages: [...other],
    });
  }

  function onPromptChange(newPrompt: string, otherIndex: number) {
    const newOther = other.map((o) => ({ ...o }));
    newOther[otherIndex].text = newPrompt;
    onChangePrompt({ ...gpt, systemMessage: system.text, messages: newOther });
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const newOther = other.map((o) => ({ ...o }));
    const [removed] = newOther.splice(startIndex, 1);
    newOther.splice(endIndex, 0, removed);
    onChangePrompt({ ...gpt, systemMessage: system.text, messages: newOther });
  }

  // const validSchema = (() => {
  //   try {
  //     return JSON.stringify(JSON.parse(template?.fields?.output?.schema ?? ""));
  //   } catch {
  //     return "";
  //   }
  // })();

  return (
    <Stack gap={1} flex={1}>
      {isLoading ? (
        <Paper elevation={0}>
          <Stack direction="row" gap={2} alignItems="center" sx={{ p: 1 }}>
            <Box className="MessageRoleToggle MessageRoleToggleSystem">
              <img src={IconSystem} alt="settings" height={16} />
            </Box>
            <Skeleton width={240} />
          </Stack>
        </Paper>
      ) : (
        <Paper elevation={0}>
          <Stack direction="row" gap={1} alignItems="flex-start" sx={{ p: 1 }}>
            <Tooltip title="System prompt" placement="left" key={"prompt-editor-tooltip-system"}>
              <Box className="MessageRoleToggle MessageRoleToggleSystem">
                <img src={IconSystem} alt="settings" height={16} />
              </Box>
            </Tooltip>
            <Stack flex="1">
              <CodeEditor
                id={`prompt-message-system-field-${system?.uid}`}
                value={system?.text ?? ""}
                language="handlebars"
                data-color-mode="light"
                placeholder="Enter the system prompt here"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onSystemPromptChange(e.target.value)}
                style={{
                  flex: 1,
                  borderRadius: 6,
                  fontSize: 13,
                  backgroundColor: "#fff",
                }}
              />
              {/* {validSchema && (
                <Paper elevation={0} sx={{ p: 1.25 }}>
                  Format the result as json with schema: {validSchema}.<br />
                  Make sure the json format is valid and complete.
                </Paper>
              )} */}
            </Stack>
          </Stack>
        </Paper>
      )}

      {isLoading ? (
        Array(3)
          .fill(0)
          .map((_, i) => (
            <Paper key={`prompt-message-placeholder-${i}`} elevation={0}>
              <Stack direction="row" gap={2} alignItems="center" sx={{ p: 1 }}>
                <Box className="MessageRoleToggle"></Box>
                <Skeleton width={240} />
              </Stack>
            </Paper>
          ))
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Stack>
                  {other.map((p, i) => (
                    <Draggable key={`${p.uid}`} draggableId={`${p.uid}`} index={i}>
                      {(provided, snapshot) => (
                        <Box
                          sx={{ mb: 1 }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style) as CSSProperties}
                        >
                          <Paper key={`prompt-variant-chat-message-${i}`} elevation={0}>
                            <Stack
                              direction="row"
                              gap={1}
                              alignItems="flex-start"
                              key={`prompt-editor-${i}`}
                              sx={{ p: 1 }}
                            >
                              <Tooltip
                                title={p.role === GptPromptMessageRole.User ? "User" : "Assistant"}
                                placement="left"
                                key={`prompt-editor-tooltip-${i}`}
                              >
                                <Button
                                  color="secondary"
                                  className={`MessageRoleToggle MessageRoleToggle${
                                    p.role === GptPromptMessageRole.User ? "User" : "Assistant"
                                  }`}
                                  onClick={() => {
                                    switchRole(i);
                                  }}
                                >
                                  <img
                                    src={
                                      p.role === GptPromptMessageRole.System
                                        ? IconSystem
                                        : p.role === GptPromptMessageRole.Assistant
                                        ? IconAi
                                        : IconUser
                                    }
                                    alt="settings"
                                    height={16}
                                  />
                                </Button>
                              </Tooltip>
                              <CodeEditor
                                id={`prompt-message-field-${p.uid}`}
                                value={p.text}
                                language="handlebars"
                                placeholder={
                                  p.role === GptPromptMessageRole.User
                                    ? "Enter the user prompt here"
                                    : "Enter the assistant prompt here"
                                }
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                  onPromptChange(e.target.value, i)
                                }
                                style={{
                                  flex: 1,
                                  borderRadius: 6,
                                  fontSize: 13,
                                  backgroundColor: "#fff",
                                }}
                                data-color-mode="light"
                              />
                              <Stack
                                justifyContent="center"
                                alignItems="space-between"
                                style={{ alignSelf: "flex-end" }}
                              >
                                <Box sx={{ px: 1 }} {...provided.dragHandleProps}>
                                  <img src={IconDrag} alt="Drag" height={14} style={{ opacity: 0.6 }} />
                                </Box>
                                <IconButton
                                  style={{ alignSelf: "flex-end" }}
                                  color="error"
                                  onClick={() => deleteMessage(i)}
                                >
                                  <img src={IconDelete} alt="Delete message" height={14} style={{ opacity: 0.6 }} />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Paper>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      )}

      <Button disabled={isLoading} size="small" color="secondary" variant="contained" onClick={addMessage}>
        Add Message
      </Button>
    </Stack>
  );
}
