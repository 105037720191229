import { Events, track } from "analytics";
import { queryClient } from "api";
import { EnterRoomContext } from "app";
import classnames from "classnames";
import Completion from "components/completion";
import RecentUsers from "components/recent_users";
import SharingBox from "components/sharing/sharing_box";
import SharingButton from "components/sharing/sharing_button";
import SuccessRing from "components/success_ring";
import Timer, { DurationOption } from "components/timer";
import { ActivityType, useDoLogActivityMutation } from "generated/graphql";
import { useAppSelector } from "hooks";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { selectAuth } from "store/auth_reducer";
import { selectUI } from "store/ui_reducer";
import { getStaticFile, Scene, useLoggedIn, useSearchReminderID } from "utils";
import AudioControl, { AudioState, AudioTrack, AudioType, cloneState, defaultAudioState } from "../audio";
import Nav from "../nav/nav";
import "./index.sass";
import BlinkSubtitle from "./subtitle";

const scenes: Scene[] = [
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/62956791-b7fa-4aef-9627-affdfcf420ad", "-bird.m4a"),
          getStaticFile("/s/62956791-b7fa-4aef-9627-affdfcf420ad", "-bird.ogg"),
        ],
        maxVolume: 0.8,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/559d232b-90bd-4bcb-8bff-0d12aae1dfb6-plant.jpg"),
    firstBlobColor: "#80DB7AAA",
    secondBlobColor: "#BAA3E9AA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/d5f49009-185e-4d94-90da-1e1d1e0881da", "-fire.m4a"),
          getStaticFile("/s/d5f49009-185e-4d94-90da-1e1d1e0881da", "-fire.ogg"),
        ],
        maxVolume: 0.8,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/e45d36a2-2532-44d6-85a9-569a9d6382d4-fire.jpg"),
    firstBlobColor: "#EB634CAA",
    secondBlobColor: "#FFE77EAA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/498503fb-d3ca-4c8b-bc9b-4cd4608058fb", "-flowing.m4a"),
          getStaticFile("/s/498503fb-d3ca-4c8b-bc9b-4cd4608058fb", "-flowing.ogg"),
        ],
        maxVolume: 0.8,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/be8e8618-b7ce-418e-98d6-bc3e872755e2-creek.jpg"),
    firstBlobColor: "#32634AAA",
    secondBlobColor: "#B4AF99AA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/694543b6-431f-4458-9b27-272a70eaefce", "-waterfall.m4a"),
          getStaticFile("/s/694543b6-431f-4458-9b27-272a70eaefce", "-waterfall.ogg"),
        ],
        maxVolume: 0.4,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/c53b2414-7cdb-4600-bd87-bba8459b32b0-cascade.jpg"),
    firstBlobColor: "#476645AA",
    secondBlobColor: "#7898F9AA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/96211516-90a2-4b5c-bd06-0f543c305daf", "-rain.m4a"),
          getStaticFile("/s/96211516-90a2-4b5c-bd06-0f543c305daf", "-rain.ogg"),
        ],
        maxVolume: 1,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/8149f0a6-1f25-458d-a142-791c259062ff-rain.jpg"),
    firstBlobColor: "#BBCDFFAA",
    secondBlobColor: "#C7FFF8AA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/573f2ee7-aab6-4eb8-a08f-8dc10cba213e", "-fog.m4a"),
          getStaticFile("/s/573f2ee7-aab6-4eb8-a08f-8dc10cba213e", "-fog.ogg"),
        ],
        maxVolume: 1,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/29ba23fb-6ee6-4b1b-9110-fac87c268927-fog.jpg"),
    firstBlobColor: "#365170AA",
    secondBlobColor: "#FFD5C8AA",
  },
  {
    audios: [
      {
        sources: [
          getStaticFile("/s/ae6d688a-5db5-4275-9949-bf92295842c6", "-cafe.m4a"),
          getStaticFile("/s/ae6d688a-5db5-4275-9949-bf92295842c6", "-cafe.ogg"),
        ],
        maxVolume: 1,
        type: AudioType.Sound,
      },
    ],
    bg: getStaticFile("/b/9b2ba6c2-0e74-43bf-9852-ac49ac79d344-espresso.jpg"),
    firstBlobColor: "#8C3522AA",
    secondBlobColor: "#F4E1BCAA",
  },
];

const voiceOver: AudioTrack = {
  sources: [
    getStaticFile("/s/2608effa-7014-4826-8853-6c422b4f3655", "-eye-f.m4a"),
    getStaticFile("/s/2608effa-7014-4826-8853-6c422b4f3655", "-eye-f.ogg"),
  ],
  maxVolume: 1,
  type: AudioType.VoiceOver,
};

// https://codepen.io/shshaw/pen/MXvLzm
// http://www.petecorey.com/blog/2019/08/19/animating-a-canvas-with-react-hooks/
// https://codepen.io/elifitch/pen/MrWYvN
function BlinkRoom() {
  const auth = useAppSelector(selectAuth);
  const ref = useRef<HTMLCanvasElement | null>(null);
  const [windowSize, setWindowSize] = useState<number[]>([window.innerWidth, window.innerHeight]);
  const ui = useAppSelector(selectUI);
  const [state, setState] = useState<AudioState>(defaultAudioState(ui.volume));
  const loggedIn = useLoggedIn();
  const playEnterRoom = useContext(EnterRoomContext);
  const [showSharingBox, setShowSharingBox] = useState<boolean>(false);
  const isGuest = auth?.currentAccount?.isGuest ?? false;
  const reminderID = useSearchReminderID();

  const logActivityMutation = useDoLogActivityMutation();

  let scene = scenes[useMemo(() => (Math.random() * scenes.length) | 0, [])];

  useEffect(() => {
    const canvas = ref.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas?.getContext("2d");
    if (!ctx) {
      return;
    }

    const circ = (4 * (Math.sqrt(2) - 1)) / 3;
    let count1 = Math.PI;
    let count2 = Math.PI / 3;

    function drawBezierCircle(
      ctx: CanvasRenderingContext2D,
      count: number,
      cx: number,
      cy: number,
      r: number,
      color: string
    ): number {
      let c;
      let offsetX = 20 * Math.sin(count);
      let offsetY = 15 * Math.cos(count * 2);
      r = r / 2;

      count += 0.005;

      ctx.translate(cx, cy); // translate to centerpoint

      ctx.beginPath();

      // top right
      c = circ + 0.2 * Math.sin(count);
      ctx.moveTo(offsetX + 0, offsetY + -r);
      ctx.bezierCurveTo(offsetX + c * r, offsetY + -r, offsetX + r, offsetY + -c * r, offsetX + r, offsetY + 0);

      // bottom right
      c = circ + 0.2 * Math.cos(count);
      ctx.bezierCurveTo(offsetX + r, offsetY + c * r, offsetX + c * r, offsetY + r, offsetX + 0, offsetY + r);

      // bottom left
      c = circ + 0.2 * Math.sin(count * 2);
      ctx.bezierCurveTo(offsetX + -c * r, offsetY + r, offsetX + -r, offsetY + c * r, offsetX + -r, offsetY + 0);

      // top left
      c = circ + 0.2 * Math.cos(count + 1);
      ctx.bezierCurveTo(offsetX + -r, offsetY + -c * r, offsetX + -c * r, offsetY + -r, offsetX + 0, offsetY + -r);

      ctx.fillStyle = color;

      ctx.fill();
      return count + 0.01;
    }

    let width = (canvas.width = windowSize[0]),
      height = (canvas.height = windowSize[1]);

    function handleResize() {
      if (width !== window.innerWidth || height !== window.innerHeight) {
        setWindowSize([window.innerWidth, window.innerHeight]);
      }
    }

    window.addEventListener("resize", handleResize);

    function render() {
      if (!ctx) {
        return;
      }

      requestAnimationFrame(render);

      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.globalCompositeOperation = "lighter";
      ctx.clearRect(0, 0, width, height);

      // #B8D5FFAA
      count1 = drawBezierCircle(ctx, count1, width / 2 + 60, height / 2 + 30, 320, scene.firstBlobColor || "white");
      // second would be relative to the last circle
      count2 = drawBezierCircle(ctx, count2, -60, -30, 320, scene.secondBlobColor || "white");
    }

    render();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize, ref, state.inProgress, scene.firstBlobColor, scene.secondBlobColor]);

  const logEyeBreak = useCallback(
    async (duration: number) => {
      if (loggedIn) {
        const result = await logActivityMutation.mutateAsync({
          input: {
            action: ActivityType.EyeBreak,
            duration: duration,
            reminderID,
          },
        });

        if (result?.logActivity?.gif !== "") {
          // show gif
        }

        queryClient.invalidateQueries(["getCurrentUser"]);

        if (auth.currentAccount?.isGuest) {
          track(Events.SharingPage.Started, {});
        }
      }
    },
    [loggedIn, logActivityMutation, reminderID, auth.currentAccount?.isGuest]
  );

  function start(duration: number) {
    const newState = cloneState(state);
    newState.inProgress = true;
    newState.started = true;
    newState.completed = false;
    setState(newState);

    logEyeBreak(duration);
  }

  const showBlink = state.inProgress;
  const showStart = !state.started;
  const showCelebration = !showStart && state.completed;
  const bgClass = classnames("Bg", { inProgress: state.inProgress });
  const eyeBreakCard = ui.homeActivitCards ? ui.homeActivitCards.find((c) => c.action === ActivityType.EyeBreak) : null;
  const showSharingButton = !showStart && !isGuest;

  return (
    <div className="BlinkRoom">
      <div className={bgClass}>
        <img src={scene.bg} alt="Plant" />
      </div>
      {state.inProgress && <canvas ref={ref} key="canvas" />}
      {showCelebration && <SuccessRing />}
      <div
        className="Control"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0))`,
          backgroundSize: "auto 180px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <Nav teamName={auth.currentAccount?.team} currentRoom="Eye Break" />
        {showBlink && <BlinkSubtitle key={state.key} />}
        {state.started && (
          <AudioControl
            state={state}
            audios={scene.audios}
            voiceOver={voiceOver}
            onTimeupdate={() => {}}
            onAdjust={(muted: boolean, volume: number) => {
              if (muted) {
                const newState = cloneState(state);
                // reset subtitle
                newState.key = new Date().valueOf();
                newState.muted = true;
                newState.volume = volume;
                setState(() => newState);
              } else {
                const newState = cloneState(state);
                newState.muted = false;
                newState.volume = volume;
                setState(() => newState);
              }
            }}
          />
        )}
        {showStart && (
          <div className="Intro">
            <h1>Guided eye break</h1>
          </div>
        )}
        <Timer
          clicked={playEnterRoom?.clicked}
          state={state}
          defaultDuration={60}
          durations={
            [
              { duration: 30, label: "30s" },
              { duration: 60, default: true, label: "1 Min" },
              { duration: 120, last: true, label: "2 Min" },
            ] as DurationOption[]
          }
          onComplete={() => {
            const newState = cloneState(state);
            newState.completed = true;
            newState.inProgress = false;
            setState(newState);
          }}
          onPause={() => {
            const newState = cloneState(state);
            newState.inProgress = false;
            setState(newState);
          }}
          onResume={() => {
            const newState = cloneState(state);
            newState.inProgress = true;
            setState(newState);
          }}
          onStart={start}
        />
        {eyeBreakCard && eyeBreakCard.socialProof?.profilePictures && (
          <div className="Social">
            <RecentUsers profilePictures={eyeBreakCard.socialProof?.profilePictures} />
          </div>
        )}
        {showSharingButton && (
          <div className="InActivitySharingButton">
            <SharingButton
              askForName={() => {
                setShowSharingBox(true);
              }}
            />
          </div>
        )}
        <SharingBox
          action={ActivityType.EyeBreak}
          open={showSharingBox}
          close={() => {
            setShowSharingBox(false);
          }}
        />
      </div>
      {showCelebration && <Completion />}
    </div>
  );
}

export default BlinkRoom;
