import classnames from "classnames";
import { ProfilePicture } from "generated/graphql";
import React from "react";
import "./profile_pictures.sass";

function ProfilePictures({ profilePictures }: { profilePictures: ProfilePicture[] }) {
  let limit = 9;
  let extra = profilePictures.length > limit ? profilePictures.length - limit : 0;
  if (extra === 1) {
    limit += 1;
    extra = 0;
  }

  const avatarClass = classnames("avatar");

  return (
    <div className="ProfilePictures">
      {profilePictures.length > 0 &&
        profilePictures.map((val: ProfilePicture) => (
          <div key={val.url} className={avatarClass}>
            <img src={val.url} alt="User profile" />
          </div>
        ))}
      {extra > 0 && <span>and {extra} others</span>}
    </div>
  );
}

export default ProfilePictures;
