import Layout from "components/layout/layout";
import PersonalActivitySummary from "./charts/personal_activity_summary";
import PersonalEnergyPatternByDOW from "./charts/personal_energy_patterns_by_dow";
import PersonalEnergyPatternByHOD from "./charts/personal_energy_patterns_by_hod";
import PersonalEnergySummary from "./charts/personal_energy_summary";
import PersonalEnergyTrend from "./charts/personal_energy_trend";
import PersonalMeetingBreakdown from "./charts/personal_meeting_breakdown";
import PersonalMeetingTrends from "./charts/personal_meeting_trends";
import PersonalNegativeContributingFactors from "./charts/personal_negative_contributing_factors";
import PersonalPositiveContributingFactors from "./charts/personal_positive_contributing_factors";
import PersonalTotalBreaks from "./charts/personal_total_breaks";
import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Divider, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { endOfDay, endOfToday, goBackWeeksFromNow, startOfDay } from "utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./index.sass";

const Insights = () => {
  const [startAt, setStartAt] = React.useState<Date>(goBackWeeksFromNow(8));
  const [endAt, setEndAt] = React.useState<Date>(endOfToday);

  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Layout
      className="InsightsPage"
      header={
        <div className="PageHeader">
          <Grid container className="PageHeaderContainer" alignItems="center" spacing={1}>
            <Grid item sm={12} md={6} lg={4}>
              <Typography variant="h4" className="PageTitle">
                My Insights
              </Typography>
            </Grid>
            <Grid item container sm={12} md={6} lg={8}>
              <Stack
                direction="row"
                justifyContent={narrow ? "flex-start" : "flex-end"}
                className="DatePickerContainer"
              >
                <Grid item className="DatePickerTextField">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startAt}
                      onChange={(newValue: any) => {
                        setStartAt(startOfDay(newValue));
                      }}
                      renderInput={(params: any) => <TextField size="small" {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Divider className="DatePickerSeparator">
                  <Typography variant="body2">to</Typography>
                </Divider>
                <Grid item className="DatePickerTextField">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={endAt}
                      onChange={(newValue: any) => {
                        setEndAt(endOfDay(newValue));
                      }}
                      renderInput={(params: any) => (
                        <TextField className="DatePickerTextField" size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Stack gap={2} sx={{ mr: -2 }}>
        <Typography variant="h2" className="PageSubtitle">
          How are you feeling?
        </Typography>

        {/* Summary */}
        <PersonalEnergySummary startAt={startAt} endAt={endAt} />

        <Grid container spacing={2} columns={12} alignItems="stretch">
          <PersonalEnergyTrend startAt={startAt} endAt={endAt} />

          <PersonalPositiveContributingFactors startAt={startAt} endAt={endAt} />

          <PersonalNegativeContributingFactors startAt={startAt} endAt={endAt} />
        </Grid>

        {/* <Typography variant="h6" className="PageSubtitle" sx={{ mt: 4, mb: 2 }}>
          How active are you?
        </Typography> */}

        <Grid container spacing={2} columns={12} alignItems="stretch">
          <PersonalEnergyPatternByHOD startAt={startAt} endAt={endAt} />

          <PersonalEnergyPatternByDOW startAt={startAt} endAt={endAt} />
        </Grid>

        {/* <Typography variant="h6" className="PageSubtitle" sx={{ mt: 4, mb: 2 }}>
          How meetings impact your schedule?
        </Typography> */}

        <Grid container spacing={2} columns={12} alignItems="stretch">
          <PersonalMeetingTrends startAt={startAt} endAt={endAt} />

          <PersonalMeetingBreakdown startAt={startAt} endAt={endAt} />
        </Grid>

        {/* <Typography variant="h6" className="PageSubtitle" sx={{ mt: 4, mb: 2 }}>
          How active are you?
        </Typography> */}

        <Grid container spacing={2} columns={12} alignItems="stretch">
          {/* Activity trend */}
          <PersonalTotalBreaks startAt={startAt} endAt={endAt} />

          {/* Activity summary */}
          <PersonalActivitySummary startAt={startAt} endAt={endAt} />
        </Grid>
      </Stack>
    </Layout>
  );
};

export default Insights;
