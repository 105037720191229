import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./auth_reducer";
import planReducer from "./plan_reducer";
import uiReducer from "./ui_reducer";
import currentUserReducer from "./current_user_reducer";
import currentCompanyReducer from "./current_company_reducer";
import currentTeamReducer from "./current_team_reducer";
import promptsReducer from "./prompts_reducer";
import modelsReducer from "./models_reducer";
import charactersReducer from "./characters_reducer";
import templatesReducer from "./templates_reducer";
import evalReducer from "./eval_reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    plan: planReducer,
    currentUser: currentUserReducer,
    currentCompany: currentCompanyReducer,
    currentTeam: currentTeamReducer,
    prompts: promptsReducer,
    models: modelsReducer,
    characters: charactersReducer,
    templates: templatesReducer,
    eval: evalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
