import { AiModelType, Prompt } from "generated/graphql";
import {
  CuelyApp,
  Connection,
  FlowGraph,
  Integration,
  OutputType,
  Stage,
  StageInput,
  StageOutput,
  StageType,
} from "store/app_builder_reducer";
import variables from "variables";

export const sampleApp = {
  id: "sample",
  name: "Lead-gen pro",
  graph: {
    source: {
      id: "typeform",
      type: StageType.Text,
      name: "Typeform",
      promptId: "prompt1",
      prompt: {
        id: "prompt1",
        aiModelType: AiModelType.Chatgpt_3_5Turbo,
        promptType: "text",
        userPrompt: `
          Please extract these information for the json text:
          email: {email}
          name: {name}
          url: {url}
          output it in json as well.
          `,
      } as Prompt,
      inputs: [{}] as StageInput[],
      outputs: [
        { name: "name", type: OutputType.Text },
        { name: "email", type: OutputType.Text },
        { name: "url", type: OutputType.Url },
      ] as StageOutput[],
    } as Stage,
    stages: [
      {
        id: "emaildraft-1",
        type: StageType.Text,
        name: "Email draft",
        promptId: "prompt2",
        prompt: {
          id: "prompt2",
          aiModelType: AiModelType.Chatgpt_3_5TurboInstructFinetuning, // assume it's fine tuing
          promptType: "text",
          userPrompt: `
          write a simple email that has only 3 lines:
          - relevant news
          - value prop
          - book a call
          `,
        } as Prompt,
        inputs: [{}] as StageInput[],
        outputs: [
          {
            type: OutputType.Integration,
            name: "Create email sequence",
            value: {
              id: "emailintegration",
              name: "Smartlead",
              type: "smartlead",
              action: "send-email",
              args: [{ name: "template-id" }],
            } as Integration,
          },
        ] as StageOutput[],
      },
      {
        id: "emaildraft-2",
        type: StageType.Text,
        name: "Email draft",
        promptId: "prompt3",
        prompt: {
          id: "prompt3",
          aiModelType: AiModelType.Chatgpt_3_5TurboInstructFinetuning, // assume it's fine tuing
          promptType: "text",
          userPrompt: `
          write a simple email that has only 3 lines as a follow up:
          - relevant news
          - value prop
          - book a call
          - offer free credits
          `,
        } as Prompt,
        inputs: [{}] as StageInput[],
        outputs: [
          {
            type: OutputType.Integration,
            name: "Create email sequence",
            value: {
              id: "emailintegration",
              name: "Smartlead",
              type: "smartlead",
              action: "add-email-sequence",
              args: [{ name: "campaign-id" }],
            } as Integration,
          },
        ] as StageOutput[],
      },
    ] as Stage[],
    connections: [{}] as Connection[],
  } as FlowGraph,
  webhooks: [`${variables.WEB_HOST}/workflow?secretKey`],
} as CuelyApp;
