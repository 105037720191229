import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { Events, track } from "analytics";
import { ActivityType, useDoShareActivityMutation } from "generated/graphql";
import { useAppSelector } from "hooks";
import { logError } from "logger";
import { useRef, useState } from "react";
import { selectAuth } from "store/auth_reducer";
import useCopy from "@react-hook/copy";

export default function SharingBox({
  action,
  open,
  close,
}: {
  action: ActivityType;
  open: boolean;
  close: () => void;
}) {
  const { currentAccount } = useAppSelector(selectAuth);
  const [name, setName] = useState<string | undefined | null>(currentAccount?.name);
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string | null | undefined>();
  const { copied, copy, reset: resetCopied } = useCopy(link ?? "");

  const shareActivityMutation = useDoShareActivityMutation();

  const copyLink = () => {
    if (!link || link === "") {
      return;
    }

    copy();
    track(Events.SharingButton.LinkCopied, {});
    setTimeout(() => {
      resetCopied();
    }, 3000);
  };
  const ref = useRef<HTMLInputElement | null>(null);

  async function getLink() {
    if (!name) {
      return;
    }

    setLoading(true);
    try {
      const result = await shareActivityMutation.mutateAsync({
        input: {
          action,
          name,
          path: window.location.pathname,
        },
      });
      setLink(result.shareActivity);
      track(Events.SharingButton.LinkCreated, {});
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog className="SharingBox" open={open} onClose={close}>
      <DialogTitle>Share the activity link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thinking of someone who also need a healthy break? Share this break with them!
        </DialogContentText>
        <TextField
          required
          className="SharingBox__name"
          ref={ref}
          onChange={(e) => setName(e.target.value)}
          label="Your name"
          placeholder="Let them know who sent the link"
          value={name ?? ""}
          size="small"
          fullWidth
        />
        {link && (
          <Tooltip placement="bottom" title="Click to copy">
            <Box className="SharingBox__link" onClick={copyLink}>
              <div>{link}</div>
              {copied && <Chip label="Copied to clipboard" />}
            </Box>
          </Tooltip>
        )}
      </DialogContent>
      {!link && (
        <DialogActions>
          <LoadingButton loading={loading} onClick={getLink} variant="contained">
            Get sharable link
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
