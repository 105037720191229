import { Grid } from "@mui/material";
import MetricChart from "components/chart";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { GetTeamTotalBreaksCountQueryVariables, TimePeriod, useGetTeamTotalBreaksCountQuery } from "generated/graphql";
import { periodRange, PeriodLimit, addDays, endOfWeek } from "utils";
import { useState } from "react";

const TotalBreaks = ({
  companyIds,
  teamIds,
  startAt,
  endAt,
}: {
  companyIds?: string | string[] | undefined | null;
  teamIds?: string | string[] | undefined | null;
  startAt: Date;
  endAt: Date;
}) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  // GraphQL data call
  const totalBreaksCount = useGetTeamTotalBreaksCountQuery({
    companyIds,
    teamIds,
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetTeamTotalBreaksCountQueryVariables);

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "MMM do",
    description = `breaks for the selected time period`,
    actionToBeTakenText = `Set up channel reminders 🔔 to remind your team to take more healthy micro-breaks`; /*,
    actionToBeTakenOnClick = () => {
      navigate(RouteNames.ReminderSettings);
    }*/
  let title = "Total Breaks",
    subtitle = undefined, //To allow chart component to show a circle progress when loading ;)
    data = factoryDefaultData();

  if (!totalBreaksCount.isLoading && totalBreaksCount.isSuccess) {
    title = totalBreaksCount.data?.teamDashboard?.totalBreaksCount.name ?? title;
    subtitle = totalBreaksCount.data?.teamDashboard?.totalBreaksCount?.total;
    const rawData = totalBreaksCount.data?.teamDashboard?.totalBreaksCount?.values;
    data = {
      labels: rawData?.map((tb) => {
        const start = format(parseISO(tb.date), dateFmt);
        const end = format(addDays(endOfWeek(parseISO(tb.date)), 1), dateFmt);

        return `${start}-${end}`;
      }),
      datasets: [
        {
          label: title,
          data: rawData?.map((tb) => tb.value),
          backgroundColor: rawData?.map((tb) => "#F584DE"),
        },
      ],
    };
  }

  return (
    <Grid item container xs={12} md={6} lg={6}>
      <MetricChart
        requireUpgrade={false}
        type={type}
        title={title}
        subtitle={subtitle}
        description={description}
        actionToBeTakenText={actionToBeTakenText}
        // actionToBeTakenOnClick={actionToBeTakenOnClick}
        dataSuccess={totalBreaksCount.isSuccess}
        dataLoading={totalBreaksCount.isLoading}
        hasEnoughData={totalBreaksCount.data?.teamDashboard?.hasEnoughData}
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                callback: function (value: any, index: any, ticks: any) {
                  const startDate = format(
                    parseISO(totalBreaksCount?.data?.teamDashboard?.totalBreaksCount?.values[index].date),
                    "MMM do"
                  );
                  return startDate;
                },
              },
            },
          },
        }}
      />
    </Grid>
  );
};

export default TotalBreaks;
