import { electronRouteWrapper } from "hooks";
import { Link, LinkProps } from "react-router-dom";

export function AppLink({ children, to, ...args }: LinkProps) {
  return (
    <Link to={electronRouteWrapper(to.toString())} {...args}>
      {children}
    </Link>
  );
}
