import IconDownArrow from "assets/v2/icon-down-arrow.svg";
import IconUpArrow from "assets/v2/icon-up-arrow.svg";
import React from "react";
import { addBenchmarks, selectEval } from "store/eval_reducer";
import {
  Benchmark,
  BenchmarkTemplateEvalCaseResult,
  PaginatedQuery,
  RunTemplateCaseResult,
  useGetTemplateBenchmarksQuery,
} from "generated/graphql";
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { formatDateTime } from "lib/util";
import { formatPercentage } from "utils";
import { OutputRow } from "./evaluation_suite";

export function BenchmarkRow({
  benchmark,
  templateID,
  evalCaseNameMap,
  setMessage,
}: {
  benchmark: Benchmark;
  templateID?: string;
  evalCaseNameMap: { [key: string]: string };
  setMessage: (message: string) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);

  const inProgress = benchmark?.progress !== 1;

  return (
    <React.Fragment>
      <TableRow style={{ background: "white" }}>
        <TableCell>
          <Box>{benchmark.createdAt ? formatDateTime(new Date(benchmark.createdAt)) : "Unknown time"}</Box>
        </TableCell>
        <TableCell>
          <Chip
            key={`benchmark-result-row-${benchmark.id}-successRate`}
            size="small"
            label={inProgress ? "In progress" : formatPercentage(benchmark.results.successRate ?? 0)}
          />
        </TableCell>
        <TableCell align="right" style={{ verticalAlign: "top" }}>
          <Stack alignItems="flex-end">
            <IconButton disabled={inProgress} aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? (
                <img src={IconUpArrow} alt="Close row" height={16} />
              ) : (
                <img src={IconDownArrow} alt="Expand row" height={16} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0, borderBottom: open ? "none" : "unset" }} colSpan={4}>
          <Collapse in={!inProgress && open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ pl: 4 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Eval Cases</TableCell>
                    <TableCell width={120}>Success Rate</TableCell>
                    <TableCell width={60}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {benchmark.results?.results?.map((evalCase) => (
                    <BenchmarkEvalCaseRow
                      key={`benchmark-${benchmark.id}-eval-case-result-${evalCase.evalCaseID}-row`}
                      templateID={templateID}
                      benchmark={benchmark}
                      evalCase={evalCase}
                      evalCaseNameMap={evalCaseNameMap}
                      setMessage={() => {}}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function BenchmarkEvalCaseRow({
  evalCase,
  benchmark,
  templateID,
  evalCaseNameMap,
  setMessage,
}: {
  evalCase: BenchmarkTemplateEvalCaseResult;
  benchmark: Benchmark;
  templateID?: string;
  evalCaseNameMap: { [key: string]: string };
  setMessage: (message: string) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);

  // const detailRows = (r.valuesUsed ?? []).length > 0 ? r.valuesUsed ?? [] : [null];
  // const totalRows = detailRows.length;

  return (
    <React.Fragment>
      <TableRow style={{ background: "white" }}>
        <TableCell>
          <Box>{evalCaseNameMap[evalCase.evalCaseID] || "Deleted eval case"}</Box>
        </TableCell>
        <TableCell width={120}>
          <Chip
            key={`benchmark-${benchmark.id}-eval-case-result-${evalCase.evalCaseID}-successRate`}
            size="small"
            color={evalCase.successRate === 1 ? "success" : undefined}
            label={formatPercentage(evalCase.successRate ?? 0)}
          />
        </TableCell>
        <TableCell align="right" style={{ verticalAlign: "top" }} width={60}>
          <Stack alignItems="flex-end">
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? (
                <img src={IconUpArrow} alt="Close row" height={16} />
              ) : (
                <img src={IconDownArrow} alt="Expand row" height={16} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0, borderBottom: open ? "none" : "unset" }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ pl: 4 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Runs</TableCell>
                    <TableCell width={120}>Result</TableCell>
                    <TableCell width={60}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {evalCase.results?.map((run, i) => (
                    <BenchmarkEvalCaseRunRow
                      key={`benchmark-run-${run.id}}`}
                      templateID={templateID}
                      run={run}
                      i={i}
                      setMessage={() => {}}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function BenchmarkEvalCaseRunRow({
  i,
  run,
  templateID,
  setMessage,
}: {
  i: number;
  run: RunTemplateCaseResult;
  templateID?: string;
  setMessage: (message: string) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);

  // const detailRows = (r.valuesUsed ?? []).length > 0 ? r.valuesUsed ?? [] : [null];
  // const totalRows = detailRows.length;

  const pass = !run.evaluatorResults?.find((r) => !r.pass);

  return (
    <React.Fragment>
      <TableRow style={{ background: "white" }}>
        <TableCell>
          <Box>Run #{i + 1}</Box>
        </TableCell>
        <TableCell width={120}>
          <Chip
            key={`benchmark-run-${run.id}-result`}
            color={pass ? "success" : undefined}
            size="small"
            label={pass ? "Pass" : "Fail"}
          />
        </TableCell>
        <TableCell align="right" style={{ verticalAlign: "top" }} width={60}>
          <Stack alignItems="flex-end">
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? (
                <img src={IconUpArrow} alt="Close row" height={16} />
              ) : (
                <img src={IconDownArrow} alt="Expand row" height={16} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0, borderBottom: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ pl: 4 }}>
              <Table size="small">
                <TableBody>
                  <OutputRow
                    key={`benchmark-run-${run.id}`}
                    templateID={templateID}
                    row={run}
                    setMessage={setMessage}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Benchmarks() {
  const dispatch = useAppDispatch();
  const { variant, benchmarks, benchmarksCursor, benchmarksLoaded, benchmarksLimit } = useAppSelector(selectEval);
  const templateID = variant?.templateID;
  const [query, setQuery] = useState<PaginatedQuery>({ id: templateID, query: variant?.id, limit: benchmarksLimit });
  const templateBenchmarksQuery = useGetTemplateBenchmarksQuery({ query }, { enabled: !!templateID && !!variant?.id });

  const { template } = useAppSelector(selectEval);
  const evalCases = template?.evalCases ?? [];
  const evalCaseNameMap = evalCases.reduce((acc, cur) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {} as { [key: string]: string });

  useEffect(() => {
    if (templateBenchmarksQuery.isFetched) {
      dispatch(addBenchmarks(templateBenchmarksQuery?.data?.templateBenchmarks));
    }
  }, [dispatch, templateBenchmarksQuery]);

  const loadMore = useCallback(() => {
    if (!benchmarksLoaded) {
      if (benchmarksCursor) {
        // load more page
        setQuery({ ...query, cursor: benchmarksCursor });
      }
    }
  }, [benchmarksCursor, benchmarksLoaded, query]);

  return (
    <Box flex={1} style={{ background: "#f6f7f8", height: "100%", overflow: "hidden" }}>
      <TableContainer style={{ height: "100%" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Benchmarks</TableCell>
              <TableCell width={120}>Success Rate</TableCell>
              <TableCell width={60}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benchmarks.map((benchmark) => (
              <BenchmarkRow
                key={`benchmark-result-${benchmark.id}`}
                templateID={variant?.templateID}
                benchmark={benchmark}
                setMessage={() => {}}
                evalCaseNameMap={evalCaseNameMap}
              />
            ))}

            {!benchmarksLoaded && !templateBenchmarksQuery.isLoading && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Button onClick={loadMore} size="small">
                    Load more...
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Benchmarks;
