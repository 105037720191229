import { Grid } from "@mui/material";
import { chartWellnessAreaColors } from "components/chart/colors";
import { chartWellnessLabels } from "components/chart/labels";
import { DoughnutTooltipCallbacks } from "components/chart/helpers";
import { factoryDefaultData } from "components/chart/types";
import {
  GetTeamNegativeContributingFactorsQueryVariables,
  TimePeriod,
  useGetTeamNegativeContributingFactorsQuery,
} from "generated/graphql";
import { periodRange, PeriodLimit, useCanAccessTeamDashboard } from "utils";
import { useState } from "react";
import PremiumMetricChart from "components/chart/premium_chart";

const NegativeContributingFactors = ({
  companyIds,
  teamIds,
  startAt,
  endAt,
}: {
  companyIds?: string | string[] | undefined | null;
  teamIds?: string | string[] | undefined | null;
  startAt: Date;
  endAt: Date;
}) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const canAccessTeamDashboard = useCanAccessTeamDashboard();

  // GraphQL data call
  const negativeFactors = useGetTeamNegativeContributingFactorsQuery(
    {
      companyIds,
      teamIds,
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
      frequency,
    } as GetTeamNegativeContributingFactorsQueryVariables,
    { enabled: canAccessTeamDashboard }
  );

  // Chart props and variables
  const type = "doughnut",
    title = "What led to low energy in your team?",
    subtitle = "Low energy areas";

  let chartData = factoryDefaultData();

  if (!negativeFactors.isLoading && negativeFactors.isSuccess) {
    const rawData = negativeFactors.data?.teamDashboard?.negativeContributingFactors?.values;
    if (rawData?.length)
      chartData = {
        labels: rawData?.map((f) => chartWellnessLabels[f.label]),
        datasets: [
          {
            label: title,
            data: rawData?.map((f) => f.value),
            backgroundColor: rawData?.map((f) => chartWellnessAreaColors[f.label]),
          },
        ],
      };
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <PremiumMetricChart
        canAccess={canAccessTeamDashboard}
        type={type}
        title={title}
        subtitle={subtitle}
        dataSuccess={negativeFactors.isSuccess}
        dataLoading={negativeFactors.isLoading}
        hasEnoughData={negativeFactors.data?.teamDashboard?.hasEnoughData}
        data={chartData}
        options={{
          plugins: {
            tooltip: {
              callbacks: DoughnutTooltipCallbacks(),
            },
          },
        }}
      />
    </Grid>
  );
};

export default NegativeContributingFactors;
