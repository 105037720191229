import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import {
  daysOfWeek,
  createDaysForCurrentMonth,
  createDaysForNextMonth,
  createDaysForPreviousMonth,
  isWeekendDay,
  StringDateToSimpleHourString,
} from "./calendar-helper";
import "./calendar.sass";
import { JournalEntry, JournalInfo } from "./journal-helper";

const Calendar = ({
  yearAndMonth,
  onYearAndMonthChange,
  content,
  renderDay = () => null,
}: {
  yearAndMonth: any;
  onYearAndMonthChange: any;
  content: JournalInfo | undefined;
  renderDay: any;
}) => {
  const [year, month] = yearAndMonth;
  const [openSelectedDayModal, setOpenSelectedDayModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState<JournalEntry | undefined>();
  let currentMonthDays = createDaysForCurrentMonth(year, month);
  let previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays);
  let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
  let calendarGridDayObjects = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

  const close = () => {
    setOpenSelectedDayModal(false);
    setTimeout(() => setSelectedDay(undefined), 99);
  };

  return (
    <div className="calendar-root">
      <Dialog className="selected-day" open={openSelectedDayModal} onClose={close}>
        <DialogTitle>{`${selectedDay ? selectedDay.day : "Selected day"}`}</DialogTitle>
        <DialogContent sx={{ width: 420 }}>
          {selectedDay &&
            selectedDay.entries?.map((value, index) => (
              <Stack className="content--stack" direction={"row"} key={`desc${index}`} justifyContent={"space-between"} alignContent={"stretch"}>
                <div className="content--description">{value.description}</div>
                <div className="content--created_at">{StringDateToSimpleHourString(value.createdAt)}</div>
              </Stack>
            ))}        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="days-of-week">
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames("day-of-week-header-cell", {
              "weekend-day": [6, 0].includes(index),
            })}
          >
            {day.slice(0, 3)}
          </div>
        ))}
      </div>
      <div className="days-grid">
        {calendarGridDayObjects.map((day) => {
          return (
            <div
              key={day.dateString}
              className={classNames("day-grid-item-container", {
                "weekend-day": isWeekendDay(day.dateString),
                "current-month": day.isCurrentMonth,
              })}
            >
              <Stack direction={"column"} className="day-content-wrapper">
                {renderDay(day)}
                <div className="day-content">
                  <Stack direction={"row"} alignItems={"flex-end"}>
                    <div
                      className={`day-content-energy s${
                        content && parseInt(content[day.dateString]?.getDailyEnergy())
                      }`}
                    >
                      <div className="day-content-energy-text">
                        {content &&
                          parseFloat(content[day.dateString]?.getDailyEnergy()) !== 0.0 &&
                          content[day.dateString]?.getDailyEnergy()}
                      </div>
                    </div>
                    <div
                      className="day-content-entries"
                      onClick={() => {
                        if (content) {
                          setOpenSelectedDayModal(true);
                          setSelectedDay(content[day.dateString]);
                        }
                      }}
                    >
                      <Stack direction={"column"}>
                        {content &&
                          content[day.dateString]?.entries?.map((value, index) => (
                            <span key={`d${index}`}>{value.description}</span>
                          ))}
                      </Stack>
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export function CalendarDayHeader({ calendarDayObject }: { calendarDayObject: any }) {
  return <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>;
}

export default Calendar;
