import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prompt } from "generated/graphql";
import { RootState } from "./store";

export enum InputType {
  Text = "text",
  Url = "url",
}

export enum OutputType {
  Text = "text",
  Url = "url",
  Integration = "integration",
}

export enum StageType {
  Text = "text",
  Image = "image",
  Webhook = "webhook",
  Batch = "batch",
}

export interface Integration {
  id: string;
  name: string;
  type: string;
  action: string;
  args: any[];
}

export interface StageInput {
  name: string;
  description: string;
  type: InputType;
  value: string | Integration;
}

export interface StageOutput {
  name: string;
  description: string;
  type: OutputType;
  value: string | Integration;
}

export interface Stage {
  id: string;
  type: StageType;
  inputs: StageInput[];
  outputs: StageOutput[];
  name: string;
  prompt: Prompt;
  promptId: string;
}

export interface Connection {
  id: string;
  type: string;
  name: string;
  sourceStage: string;
  destinationStage: string;
}

export interface FlowGraph {
  source?: Stage;
  stages: Stage[];
  connections: Connection[];
}

export interface CuelyApp {
  id: string;
  name: string;
  graph: FlowGraph;
  webhooks: string[];
}

export interface StagePreviewData {
  order: number;
  sampleInputs: StageInput[];
  prompt: string;
  sampleOutputs: StageOutput[];
}

export interface AppPreviewData {
  stages: StagePreviewData[];
}

export interface AppBuilderState {
  appId?: string;
  appPreview?: AppPreviewData;
}
const initialState: AppBuilderState = {};

export const appBuilderSlice = createSlice({
  name: "appBuilder",
  initialState,
  reducers: {
    setAppId: (state, action: PayloadAction<string>) => {
      if (state.appId !== action.payload) {
        state.appPreview = undefined;
        state.appId = action.payload;
      }
    },
  },
});

export const { setAppId } = appBuilderSlice.actions;

export const selectAppBuilder = (state: RootState) => state.ui;

export default appBuilderSlice.reducer;
