import Box from "@mui/material/Box/Box";
import DraggableHeader from "components/draggble_header";
import electronStyles from "./electron.module.sass";
import Menu from "components/menu/menu";
import IconMenu from "assets/v2/icon-menu.svg";
import React, { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import { useIsElectron, useVisualHeight } from "hooks";
import "./layout.sass";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { electronHeaderHeight } from "utils";

function BodyLayout({
  className,
  children,
  header,
  showMenu,
  oldMenu,
  workspace,
}: {
  className: string;
  children: React.ReactElement | (React.ReactElement | null)[];
  header?: React.ReactElement | null;
  showMenu?: boolean;
  oldMenu?: boolean;
  workspace?: boolean;
}) {
  const isElectron = useIsElectron();
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const contentRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const visualHeight = useVisualHeight();
  const startOffset = useRef<number>(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [winWidth, setWinWidth] = useState<number>(window.innerWidth);

  const [mobileShowMenu, setMobileShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const header = headerRef.current;
    if (!header) {
      return;
    }

    if (workspace) {
      return;
    }

    const sticky = header.offsetTop;
    const scrollCallBack = () => {
      if (isSticky) {
        if (window.scrollY < startOffset.current) {
          setIsSticky(false);
        }
      } else {
        if (window.scrollY >= sticky) {
          startOffset.current = sticky;
          setIsSticky(true);
        }
      }
    };

    window.addEventListener("scroll", scrollCallBack);
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [isSticky, workspace]);

  useEffect(() => {
    const resizeCallback = () => {
      setWinWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeCallback);
    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, []);

  return (
    <Stack
      className={`${className} AppFrame ${isSticky ? "Sticky" : ""} ${isElectron ? "Electron" : ""} ${
        workspace ? "Workspace" : ""
      } ${mobile ? "Mobile" : ""}`}
      direction="row"
      alignItems="flex-start"
      style={{ minHeight: visualHeight }}
    >
      {showMenu !== false && (!mobile || mobileShowMenu) ? (
        <Menu ref={menuRef} oldMenu={oldMenu} mobile={mobile} closeMenu={() => setMobileShowMenu(false)} />
      ) : null}

      {mobile && (
        <Box position="fixed" right={12} top={isElectron ? 12 + electronHeaderHeight : 12} sx={{ zIndex: 200 }}>
          <IconButton onClick={() => setMobileShowMenu(true)}>
            <img src={IconMenu} alt="Menu" />
          </IconButton>
        </Box>
      )}

      <Box
        ref={contentRef}
        id="AppFrame__content"
        className={`${className}__content AppFrame__content`}
        sx={{
          flex: 1,
          width: workspace ? undefined : winWidth - (menuRef.current?.getBoundingClientRect().width ?? 180),
        }}
      >
        {header && (
          <Box
            className={`${className}__header AppFrame__header`}
            ref={headerRef}
            style={{
              left: workspace
                ? undefined
                : isSticky && menuRef.current
                ? menuRef.current.getBoundingClientRect().width
                : 0,
            }}
          >
            {header}
          </Box>
        )}
        <Box
          className={`${className}__body AppFrame__body`}
          style={{
            paddingTop: isSticky && headerRef.current ? headerRef.current.getBoundingClientRect().height : undefined,
          }}
        >
          {children}
        </Box>
      </Box>
    </Stack>
  );
}

export function Headerify(props: { children: React.ReactElement | React.ReactElement[] }) {
  const isElectron = useIsElectron();
  if (isElectron) {
    return (
      <Stack direction="column" className={electronStyles.ElectronApp}>
        <DraggableHeader className={electronStyles.ElectronHeader} />
        <div style={{ flex: 1 }} className={electronStyles.ElectronAppBody}>
          {props.children}
        </div>
      </Stack>
    );
  }
  return <>{props.children}</>;
}

export default function Layout(props: {
  className: string;
  children: React.ReactElement | (React.ReactElement | null)[];
  header?: React.ReactElement | null;
  showMenu?: boolean;
  oldMenu?: boolean;
  workspace?: boolean;
}) {
  return (
    <Headerify>
      <BodyLayout {...props} />
    </Headerify>
  );
}
