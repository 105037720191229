const Angry = { label: "Angry", value: "feeling-angry" };
const Anxious = { label: "Anxious", value: "feeling-anxious" };
const Hurt = { label: "Hurt", value: "feeling-hurt" };
const Judged = { label: "Judged", value: "feeling-judged" };
const Disappointed = { label: "Disappointed", value: "feeling-disappointed" };
const Lonely = { label: "Lonely", value: "feeling-lonely" };
const Sad = { label: "Sad", value: "feeling-sad" };
const Unfair = { label: "Unfair", value: "feeling-unfair" };
const Exhausted = { label: "Exhausted", value: "feeling-exhausted" };
const Shocked = { label: "Shocked", value: "feeling-shocked" };

const Annoyed = { label: "Annoyed", value: "feeling-annoyed" };
const Bored = { label: "Bored", value: "feeling-bored" };
const Impatient = { label: "Impatient", value: "feeling-impatient" };
const Nervous = { label: "Nervous", value: "feeling-nervous" };
const Insecure = { label: "Insecure", value: "feeling-insecure" };
const Stressed = { label: "Stressed", value: "feeling-stressed" };
const Tired = { label: "Tired", value: "feeling-tired" };
const Frustrated = { label: "Frustrated", value: "feeling-frustrated" };

const Busy = { label: "Busy", value: "feeling-busy" };
const Fine = { label: "Fine", value: "feeling-fine" };
const Calm = { label: "Calm", value: "feeling-calm" };
const Rested = { label: "Rested", value: "feeling-rested" };
const Recharged = { label: "Recharged", value: "feeling-recharged" };

const Happy = { label: "Happy", value: "feeling-happy" };
const Comfortable = { label: "Comfortable", value: "feeling-comfortable" };
const Confident = { label: "Confident", value: "feeling-confident" };
const Hopeful = { label: "Hopeful", value: "feeling-hopeful" };
const Inspired = { label: "Inspired", value: "feeling-inspired" };
const Excited = { label: "Excited", value: "feeling-excited" };
const Grateful = { label: "Grateful", value: "feeling-grateful" };
const Relieved = { label: "Relieved", value: "feeling-relieved" };

const Creative = { label: "Creative", value: "feeling-creative" };
const Supported = { label: "Supported", value: "feeling-supported" };
const Stoked = { label: "Stoked", value: "feeling-stoked" };
const Proud = { label: "Proud", value: "feeling-proud" };
const Supercharged = { label: "Supercharged", value: "feeling-supercharged" };
const Cared = { label: "Cared", value: "feeling-cared" };
const Appreciated = { label: "Appreciated", value: "feeling-appreciated" };

const FeelingMap = {
  1: [Angry, Anxious, Hurt, Judged, Disappointed, Lonely, Sad, Unfair, Exhausted, Shocked, Insecure],
  2: [Annoyed, Bored, Impatient, Nervous, Insecure, Stressed, Tired, Frustrated, Busy, Anxious, Hurt, Lonely],
  3: [Happy, Busy, Fine, Calm, Rested, Recharged, Comfortable, Grateful, Relieved, Supported],
  4: [Happy, Confident, Hopeful, Inspired, Excited, Grateful, Supported, Appreciated, Proud],
  5: [
    Happy,
    Confident,
    Hopeful,
    Inspired,
    Excited,
    Grateful,
    Creative,
    Stoked,
    Proud,
    Supercharged,
    Cared,
    Appreciated,
    Recharged,
  ],
};

export default FeelingMap;
