import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Vimeo, { TimeUpdateEvent } from "@u-wave/react-vimeo";
import Player from "@vimeo/player";
import { Events, track } from "analytics";
import { queryClient } from "api";
import { EnterRoomContext } from "app";
import classnames from "classnames";
import AudioControl, { AudioState, cloneState, defaultAudioState } from "components/audio";
import Completion from "components/completion";
import Nav from "components/nav/nav";
import RecentUsers from "components/recent_users";
import SharingBox from "components/sharing/sharing_box";
import SharingButton from "components/sharing/sharing_button";
import SuccessRing from "components/success_ring";
import Timer from "components/timer";
import { ActivityType, useDoLogActivityMutation } from "generated/graphql";
import { useAppSelector } from "hooks";
import { logError } from "logger";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { selectAuth } from "store/auth_reducer";
import { selectUI } from "store/ui_reducer";
import { useLoggedIn, useSearchReminderID } from "utils";
import "./index.sass";

type VideoRoomProps = {
  action: ActivityType;
  contentID: number;
  videoURL?: string | null;
  keepProgress: boolean;
  roomName: string;
  children?: React.ReactNode[] | React.ReactNode;
  celebration?: React.ReactNode | null;
};

function VideoRoom({ action, contentID, videoURL, keepProgress, roomName, children, celebration }: VideoRoomProps) {
  const auth = useAppSelector(selectAuth);
  const { currentAccount } = useAppSelector(selectAuth);
  const ui = useAppSelector(selectUI);
  const [state, setState] = useState<AudioState>(defaultAudioState(ui.volume));
  const [videoRect, setVideoRect] = useState<[number, number, number, number]>([0, 0, 800, 600]);
  const [player, setPlayer] = useState<Player>();
  const [duration, setDuration] = useState<number>(60 * 15);
  const [timePlayed, setTimePlayed] = useState<number>(0);
  const bgRef = useRef<HTMLDivElement | null>(null);
  const playEnterRoom = useContext(EnterRoomContext);
  const [showSharingBox, setShowSharingBox] = useState<boolean>(false);
  const isGuest = auth?.currentAccount?.isGuest ?? false;
  const newViewport$ = useMemo(() => new BehaviorSubject<[number, number, number]>([0, 0, 1 / 0.5625]), []);
  const loggedIn = useLoggedIn();
  const reminderID = useSearchReminderID();

  const logActivityMutation = useDoLogActivityMutation();

  const resizeVideo = useCallback(
    (windowWidth: number, windowHeight: number, aspectRatio: number) => {
      if (!windowHeight || !windowWidth || !aspectRatio) {
        return;
      }

      const windowAspectRatio = windowWidth / windowHeight;

      if (aspectRatio > windowAspectRatio) {
        // video is wider than window, match height
        const videoHeight = windowHeight;
        const videoWidth = videoHeight * aspectRatio;

        const videoX = (windowWidth - videoWidth) / 2;
        const videoY = 0;
        setVideoRect([videoX, videoY, videoWidth, videoHeight]);
      } else {
        // video is taller than window, match width
        const videoWidth = windowWidth;
        const videoHeight = videoWidth / aspectRatio;

        const videoX = 0;
        const videoY = (windowHeight - videoHeight) / 2;
        setVideoRect([videoX, videoY, videoWidth, videoHeight]);
      }
    },
    [setVideoRect]
  );

  useEffect(() => {
    const sub = newViewport$.pipe(debounceTime(500)).subscribe(([windowWidth, windowHeight, aspectRatio]) => {
      resizeVideo(windowWidth, windowHeight, aspectRatio);
    });
    return () => sub.unsubscribe();
  }, [newViewport$, resizeVideo]);

  useEffect(() => {
    function handleResize() {
      const [, , aspectRatio] = newViewport$.value;
      newViewport$.next([window.innerWidth, window.innerHeight, aspectRatio]);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [newViewport$, resizeVideo]);

  async function onVideoReady(player: Player) {
    setPlayer(player);

    try {
      await Promise.all([
        player.getVideoWidth(),
        player.getVideoHeight(),
        player.getDuration(),
        player.getTextTracks(),
      ]).then(function ([width, height, duration, textTracks]) {
        if (width > 0 && height > 0) {
          const [windowWidth, windowHeight] = newViewport$.value;
          newViewport$.next([windowWidth, windowHeight, width / height]);
        }

        if (duration > 0) {
          setDuration(duration);
        }

        if (textTracks && textTracks.length > 0) {
          player.enableTextTrack(textTracks[0].language, textTracks[0].kind);
        }
      });
    } catch (e) {
      logError(e);
    }
  }

  const logVideoActivity = useCallback(async () => {
    if (loggedIn && contentID && action) {
      const result = await logActivityMutation.mutateAsync({
        input: {
          action: action,
          contentID: contentID,
          reminderID,
        },
      });

      if (result?.logActivity?.gif !== "") {
        // show gif
      }

      queryClient.invalidateQueries(["getCurrentUser"]);

      if (auth.currentAccount?.isGuest) {
        track(Events.SharingPage.Started, {});
      }
    }
  }, [loggedIn, contentID, action, logActivityMutation, reminderID, auth.currentAccount?.isGuest]);

  function start(duration: number) {
    const newState = cloneState(state);
    newState.inProgress = true;
    newState.started = true;
    newState.completed = false;
    setState(newState);

    logVideoActivity();
  }

  function onSaveProgress(event: TimeUpdateEvent) {
    if (keepProgress) {
      if (videoURL) {
        const timePlayed = event.seconds;
        localStorage.setItem(videoURL, timePlayed.toString());
      }
    }
  }

  useEffect(() => {
    if (videoURL) {
      const timePlayed = parseInt(localStorage.getItem(videoURL) ?? "", 10);
      if (timePlayed > 0) {
        player?.setCurrentTime(timePlayed);
        setTimePlayed(timePlayed);
      }
    }
  }, [player, videoURL]);

  const showStart = !state.started;
  const showCelebration = !showStart && state.completed;
  const shadeClass = classnames("Shade", { inProgress: state.inProgress });
  const activityCard = ui.homeActivitCards ? ui.homeActivitCards.find((c) => c.action === action) : null;
  const showSharingButton = !showStart && !isGuest;

  return (
    <div className="VideoRoom">
      <div className="Bg" ref={bgRef}>
        {videoURL && (
          <Vimeo
            className="ClassVideo"
            video={videoURL}
            style={{ left: videoRect[0], top: videoRect[1], width: videoRect[2], height: videoRect[3] }}
            volume={state.muted ? 0 : state.volume}
            paused={!(state.started && state.inProgress)}
            onReady={onVideoReady}
            showByline={false}
            controls={false}
            onTimeUpdate={onSaveProgress}
            responsive
          />
        )}
        <div
          className={shadeClass}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0))`,
            backgroundSize: "auto 200px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></div>
      </div>
      {showCelebration && <SuccessRing />}
      <div className="Control">
        <Nav teamName={currentAccount?.team} currentRoom={roomName} />
        {state.started && (
          <AudioControl
            state={state}
            onTimeupdate={() => {}}
            onAdjust={(muted: boolean, volume: number) => {
              if (muted) {
                const newState = cloneState(state);
                // reset subtitle
                newState.key = new Date().valueOf();
                newState.muted = true;
                newState.volume = volume;
                setState(() => newState);
              } else {
                const newState = cloneState(state);
                newState.muted = false;
                newState.volume = volume;
                setState(() => newState);
              }
            }}
          />
        )}
        {showStart && (
          <div className="Intro">
            {!player && <CircularProgress sx={{ color: "white" }} />}
            {children}
          </div>
        )}
        <Timer
          clicked={playEnterRoom?.clicked}
          state={state}
          defaultDuration={duration - timePlayed}
          onComplete={() => {
            if (videoURL) {
              const newState = cloneState(state);
              newState.completed = true;
              newState.inProgress = false;
              setState(newState);
              localStorage.removeItem(videoURL);
              setTimePlayed(0);
            }
          }}
          onPause={() => {
            const newState = cloneState(state);
            newState.inProgress = false;
            setState(newState);
          }}
          onResume={() => {
            const newState = cloneState(state);
            newState.inProgress = true;
            setState(newState);
          }}
          onStart={start}
        />
        {activityCard && activityCard?.socialProof?.profilePictures && (
          <div className="Social">
            <RecentUsers profilePictures={activityCard?.socialProof?.profilePictures} />
          </div>
        )}
        {showSharingButton && (
          <div className="InActivitySharingButton">
            <SharingButton
              askForName={() => {
                setShowSharingBox(true);
              }}
            />
          </div>
        )}
        <SharingBox
          action={action}
          open={showSharingBox}
          close={() => {
            setShowSharingBox(false);
          }}
        />
      </div>
      {showCelebration && (celebration ? celebration : <Completion />)}
    </div>
  );
}

export default VideoRoom;
