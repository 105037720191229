import { parseISO } from "date-fns";
import { ActivityType, PersonalScheduleItem } from "generated/graphql";
import { formatReminderStartTime, formatTimeRange, pageURL } from "lib/util";
import RouteNames from "route_names";

export interface ReminderTypeConfig {
  background: string;
  color: string;
  description: string;
  button: string;
  link?: string;
  time: string;
  relativeTime: string;
}

export function getReminderTypeConfig(item: PersonalScheduleItem): ReminderTypeConfig | null | undefined {
  const action = item.action;
  const title = item.title;
  const startAt = parseISO(item.startAt);
  const endAt = item.endAt ? parseISO(item.endAt) : null;
  const time = formatTimeRange(startAt, endAt);
  const relativeTime = formatReminderStartTime(startAt);
  switch (action) {
    case ActivityType.JoinMeeting:
      return {
        time,
        relativeTime,
        background: "#f1f3ffff",
        color: "#626680",
        description: `join ${title}`,
        button: "Join now",
        link: (item.urls ?? []).find((u) => u.canJoin)?.url,
      };
    case ActivityType.Breath:
      return {
        time,
        relativeTime,
        background: "#C9F2EA77",
        color: "#24574A",
        description: `${title?.toLowerCase()}`,
        button: "Start a 1-min break",
        link: pageURL(RouteNames.Breath, item.id),
      };
    case ActivityType.EyeBreak:
      return {
        time,
        relativeTime,
        background: "#F4D2F9",
        color: "#392878",
        description: `${title?.toLowerCase()}`,
        button: "Start a 1-min break",
        link: pageURL(RouteNames.Eyebreak, item.id),
      };
    case ActivityType.Stretch:
      return {
        time,
        relativeTime,
        background: "#F6CBB9",
        color: "#11315E",
        description: `${title?.toLowerCase()}`,
        button: "Start a 1-min break",
        link: pageURL(RouteNames.Stretch, item.id),
      };
    case ActivityType.Walk:
      return {
        time,
        relativeTime,
        background: "#D5F2FD",
        color: "#6F0056",

        description: `${title?.toLowerCase()}`,
        button: "I did it",
      };
    case ActivityType.Lunch:
      return {
        time,
        relativeTime,
        background: "#F7CF7B77",
        color: "#BF4D39",

        description: `${title?.toLowerCase()}`,
        button: "I did it",
      };
    case ActivityType.Water:
      return {
        time,
        relativeTime,
        background: "#C8F4FE77",
        color: "#4537A5",
        description: `${title?.toLowerCase()}`,
        button: "I did it",
      };
    case ActivityType.Journal:
      return {
        time,
        relativeTime,
        background: "#FEF5A7",
        color: "#52248D",
        description: `${title?.toLowerCase()}`,
        button: "Reflect now",
        link: pageURL(RouteNames.EnergyModal, item.id),
      };
    case ActivityType.Boundary:
      return {
        time,
        relativeTime,
        background: "#F7CF7B77",
        color: "#BF4D39",

        description: `${title?.toLowerCase()}`,
        button: "I did it",
      };
    case ActivityType.Gratitude:
      return {
        time,
        relativeTime,
        background: "#FEF5A7",
        color: "#52248D",

        description: `${title?.toLowerCase()}`,
        button: "Reflect now",
      };
    case ActivityType.Priority:
      return {
        time,
        relativeTime,
        background: "#FEF5A7",
        color: "#52248D",

        description: `${title?.toLowerCase()}`,
        button: "Set intention",
      };
    case ActivityType.Routine:
      return {
        time,
        relativeTime,
        background: "#FEF5A7",
        color: "#52248D",

        description: `${title?.toLowerCase()}`,
        button: "Check in now",
        link: pageURL(RouteNames.RoutineModal, item.id),
      };
    case ActivityType.CustomReminder:
      return {
        time,
        relativeTime,
        background: "#ddd",
        color: "#555",
        description: `${title?.toLowerCase()}`,
        button: "Join link",
        link: (item.urls ?? []).find((u) => u.canJoin)?.url,
      };
  }
}
