import { Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconClipboard from "assets/v2/icon-clipboard.svg";

import IconMore from "assets/v2/icon-more.svg";
import { forwardRef, MutableRefObject, useRef, useState } from "react";
import useCopy from "@react-hook/copy";
import Loader from "react-spinners/BeatLoader";
import styles from "./index.module.sass";
import { Prompt } from "generated/graphql";
import SignInButtons from "components/auth/sign_in_buttons";
import { PromptEditorMode } from ".";

export function AIMessage({ prompt }: { prompt: Prompt }) {
  const { copied, copy, reset: resetCopied } = useCopy(prompt!.texts![0] ?? "");

  function copyResponse() {
    copy();
    setTimeout(() => {
      resetCopied();
    }, 3000);
  }

  return (
    <Stack gap={1} direction="row" alignItems="flex-start">
      <Box className={styles.Message}>{prompt!.texts![0].trim()}</Box>

      <IconButton onClick={copyResponse}>
        <Tooltip placement="bottom" title={copied ? "Copied" : "Copy this response"}>
          <img src={IconClipboard} alt="Copy" width={16} />
        </Tooltip>
      </IconButton>
    </Stack>
  );
}

export function UserMessage({ prompt, editPrompt }: { prompt: Prompt; editPrompt: (prompt: Prompt) => void }) {
  const { copied, copy, reset: resetCopied } = useCopy(prompt.userPrompt ?? "");
  const [open, setOpen] = useState<boolean>(false);
  const el = useRef<HTMLAnchorElement | null>(null);

  function copyPrompt() {
    copy();
    setOpen(false);

    setTimeout(() => {
      resetCopied();
    }, 3000);
  }

  function showMenu(event: React.MouseEvent) {
    setOpen(true);
    event.preventDefault();
    return false;
  }

  return (
    <Stack
      gap={1}
      sx={{ alignSelf: "flex-end", justifyContent: "flex-end" }}
      direction="row"
      key={`prompt-${prompt.id}`}
      alignItems="flex-start"
    >
      <IconButton href={""} onClick={showMenu} ref={el}>
        <Tooltip placement="bottom" title={copied ? "Copied" : ""}>
          <img src={IconMore} alt="More" width={16} />
        </Tooltip>
      </IconButton>
      <Box className={styles.UserMessage}>{prompt.userPrompt}</Box>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={el.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={copyPrompt}>
          <span>Copy</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            editPrompt(prompt);
            setOpen(false);
          }}
        >
          <span>Edit</span>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export const Messages = forwardRef(
  (
    {
      isNew,
      responses,
      editPrompt,
      busy,
      loaded,
      loadMore,
      mode,
      isGuest,
    }: {
      isNew: boolean;
      mode: PromptEditorMode;
      loaded: boolean;
      loadMore: () => void;
      responses: Prompt[];
      busy: boolean;
      editPrompt: (prompt: Prompt) => void;
      isGuest: boolean;
    },
    ref?:
      | ((instance: HTMLDivElement | undefined | null) => void)
      | MutableRefObject<HTMLDivElement | undefined | null>
      | null
  ) => {
    const busyRef = useRef<HTMLDivElement>();

    const rows: { user: boolean; prompt: Prompt }[] = responses.reduce((array, r) => {
      if (!!r.texts && r.texts.length > 0) {
        array.push({ prompt: r, user: true });
        array.push({ prompt: r, user: false });
      }
      return array;
    }, [] as { user: boolean; prompt: Prompt }[]);

    return (
      <Stack className={styles.Messages} flexGrow={1} style={{ overflowY: "auto" }} ref={ref}>
        {!loaded && !isNew && (
          <Stack sx={{ mb: 2 }} alignItems="center">
            <Button onClick={loadMore} size="small">
              Load more...
            </Button>
          </Stack>
        )}

        <Stack flex={1} alignItems="center" justifyContent="center" sx={{ mx: 8, pb: 10, textAlign: "center" }}>
          <Box>
            <img
              style={{ width: 240 }}
              src={
                mode === PromptEditorMode.raw
                  ? "https://staticgists.com/astronaut-working-v1.png"
                  : "https://staticgists.com/astronaut-reading-v1.png"
              }
              alt="illustration"
            />
          </Box>
          <Typography variant="h5">
            You are in the {mode === PromptEditorMode.raw ? "message" : "guided"} mode
          </Typography>
          <Typography sx={{ mb: 4 }}>
            {mode === PromptEditorMode.raw
              ? "Use this mode if you are experienced with prompt-engineering."
              : "Use this mode if you are new to prompt-engineering."}
          </Typography>

          {responses.length === 0 && !isGuest && (
            <Typography>
              {mode === PromptEditorMode.raw
                ? "Start typing in the input box below."
                : "Follow the guided questions on the left side."}
            </Typography>
          )}

          {responses.length === 0 && !isGuest && (
            <Typography>
              {mode === PromptEditorMode.raw
                ? "Click send or press Shift + Enter to generate responses."
                : "Click on send to generate responses."}
            </Typography>
          )}

          {isGuest && (
            <Box sx={{ mt: 6 }}>
              <SignInButtons showBranding={false} singleColumn />
            </Box>
          )}
        </Stack>

        <Stack gap={2} alignItems="flex-start">
          {rows.map((r) =>
            r.user ? (
              <UserMessage key={`userprompt-${r.prompt.id}`} prompt={r.prompt} editPrompt={editPrompt} />
            ) : (
              <AIMessage key={`response-${r.prompt.id}`} prompt={r.prompt} />
            )
          )}
          {busy && (
            <Box className={styles.Message} ref={busyRef}>
              <Loader speedMultiplier={0.5} size={6} color="#cccccc" style={{ margin: 2 }} />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  }
);
