import { ChartData, ChartType, DefaultDataPoint } from "chart.js";

/**
 * Creates a default data object for a chart.js chart.
 * Also, if this is declared as a (var|let) variable allows the IDE to pick up the type information.
 * @returns {ChartData} A default data object for a chart.js chart.
 */
export function factoryDefaultData(): ChartData<
  ChartType,
  DefaultDataPoint<ChartType | any> | any, // "any"  allows assignment of gql hooks that return undefined on "optional" fields
  unknown
> {
  return {
    labels: [],
    datasets: [],
  };
}
