import CreateFunctionModal from "./create_function_modal";
import CreateFunctionVariantModal from "./create_function_variant_modal";
import FunctionFieldTabs from "./function_tab";
import IconBenchmark from "assets/v2/icon-benchmark.svg";
import IconEdit from "assets/v2/icon-edit.svg";
import IconMore from "assets/v2/icon-more.svg";
import IconTemplateVariant from "assets/v2/icon-template-variant.svg";
import Layout from "../layout/layout";
import Loader from "react-spinners/ScaleLoader";
import RouteNames from "route_names";
import VariantEditor from "./variant_editor";
import { generatePath, Link, useParams } from "react-router-dom";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { queryClient } from "api";
import { useAppDispatch, useAppSelector, useSafeModelName } from "hooks";
import { useEffect, useRef, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import "./index.sass";
import "react-loading-skeleton/dist/skeleton.css";
import {
  refreshTemplate,
  selectEval,
  setEvaluating,
  setTemplateResult,
  templateChanged,
  variantChanged,
  variantPercentageChanged,
} from "store/eval_reducer";

import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  EvaluatorInput,
  GetPromptTemplateVariantsQuery,
  RunTemplateResult,
  Template,
  TemplateVariant,
  TemplateVariantFieldsInput,
  useDoDeleteTemplateVariantsMutation,
  useDoRunTemplateMutation,
  useDoUpdateTemplateMutation,
  useGetPromptTemplateVariantsQuery,
  useGetPromptTemplateVariantStatsQuery,
  UserFacingError,
} from "generated/graphql";
import BenchmarkFunctionModal from "./benchmark_modal";
import { formatPercentage, userfacingError } from "utils";

export async function invalidateTemplateQuery(templateId: string | undefined) {
  await queryClient.invalidateQueries(["getPromptTemplateVariants", { query: { id: templateId } }]);
}

function FunctionVariantCard({
  variant,
  traffic,
  calls,
  accuracy,
  editFunction,
}: {
  variant: TemplateVariant;
  traffic: number;
  calls: number;
  accuracy: number;
  editFunction?: (variant: TemplateVariant) => void;
}) {
  const { id: templateId, variantId } = useParams();
  const dispatch = useAppDispatch();
  const [modelName] = useSafeModelName(variant.fields?.aiModelType, variant.fields?.customModelID);

  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const deleteMutation = useDoDeleteTemplateVariantsMutation();

  async function deleteTemplateVariants() {
    try {
      await deleteMutation.mutateAsync({
        ids: [variant.id],
      });
      await invalidateTemplateQuery(templateId);
      dispatch(refreshTemplate());
    } catch (error) {}
  }

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }} selected={variantId === variant.id}>
      <TableCell component="th" scope="row">
        <Link
          to={generatePath(RouteNames.GistVariant, { id: variant.templateID, variantId: variant.id })}
          style={{ textDecoration: "none" }}
        >
          {variant.name}
        </Link>
      </TableCell>
      <TableCell align="left">
        <VariantPercentageField row={variant} traffic={traffic} />
      </TableCell>
      <TableCell align="left">{modelName}</TableCell>
      <TableCell align="right">{formatPercentage(accuracy)}</TableCell>
      {/* <TableCell align="right">1%</TableCell> */}
      <TableCell align="right">{calls}</TableCell>
      <TableCell align="right">
        <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
          <img src={IconMore} alt="Card options" style={{ height: 16 }} />
        </IconButton>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={showCardMenu}
          anchorEl={el.current}
          onClose={() => setShowCardMenu(false)}
        >
          <MenuItem
            onClick={() => {
              setShowCardMenu(false);
              if (editFunction) {
                editFunction(variant);
              }
            }}
          >
            <span>Edit</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowCardMenu(false);
              setShowConfirmDeleteMenu(true);
            }}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={showConfirmDeleteMenu}
          anchorEl={el.current}
          onClose={() => setShowConfirmDeleteMenu(false)}
        >
          <MenuItem
            onClick={() => {
              deleteTemplateVariants();
              setShowConfirmDeleteMenu(false);
            }}
          >
            <span>Yes, delete!</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowConfirmDeleteMenu(false);
            }}
          >
            <span>No, cancel</span>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

function FunctionVariantCardPlaceholder() {
  return (
    <TableRow>
      <TableCell component="th" scope="row" width="25%">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      {/* <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell> */}
    </TableRow>
  );
}

function NewFunctionVariant({ onAddNewTemplate }: { onAddNewTemplate: () => void }) {
  return (
    <Stack alignItems="center" flex={1} gap={4} sx={{ mt: 0, mb: 4 }}>
      <img src="https://staticgists.com/astronaut-template-variant-v1.png" alt="New variant" style={{ width: 240 }} />
      <Typography textAlign="center">
        No variant has been created yet.
        <br />
        Create a new gist variant and it'll show up here.
      </Typography>
      <Button onClick={onAddNewTemplate} variant="outlined" size="medium">
        <img src={IconTemplateVariant} alt="New Variant" height={20} />
        &nbsp;New Variant
      </Button>
    </Stack>
  );
}

function VariantPercentageField({ row, traffic }: { row: TemplateVariant; traffic: number }) {
  const dispatch = useAppDispatch();
  async function onValueChanged(change: { id: string; traffic: string }) {
    const value = parseInt(change.traffic.replaceAll(/[^0-9]/g, ""));
    const safeValue = isNaN(value) ? 0 : value;
    if (safeValue >= 0 && safeValue <= 100) {
      dispatch(variantPercentageChanged({ id: change.id, traffic: safeValue }));
    }
  }

  return (
    <TextField
      multiline
      style={{ width: 50 }}
      autoComplete="off"
      id={`{variant-percentage-field-${row.id}`}
      InputProps={{
        disableUnderline: true,
        id: `{variable-percentage-field-input-${row.name}`,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        style: { textAlign: "right" },
      }}
      variant="standard"
      size="small"
      value={traffic ? `${traffic}` : ""}
      onChange={(event) => {
        onValueChanged({ id: row.id, traffic: event.currentTarget.value });
      }}
    />
  );
}

function FunctionVariantList({
  promptTemplateQuery,
  template,
  templateVariants,
  setEditingVariant,
  setCreateModalOpen,
}: {
  promptTemplateQuery: UseQueryResult<GetPromptTemplateVariantsQuery, unknown>;
  template: Template;
  templateVariants: TemplateVariant[];
  setEditingVariant: (variant: TemplateVariant) => void;
  setCreateModalOpen: (open: boolean) => void;
}) {
  const { percentages: trafficMap } = useAppSelector(selectEval);
  const promptTemplateStatsQuery = useGetPromptTemplateVariantStatsQuery(
    { query: { id: template?.id } },
    { enabled: !!template?.id }
  );

  const templateVariantCalls =
    promptTemplateStatsQuery?.data?.templateVariantStats?.stats?.reduce((acc, cur) => {
      acc[cur.variantId] = cur.callCount ?? 0;
      return acc;
    }, {} as { [key: string]: number }) ?? {};

  const templateVariantAccuracy =
    promptTemplateStatsQuery?.data?.templateVariantStats?.stats?.reduce((acc, cur) => {
      acc[cur.variantId] = cur.successRate ?? 0;
      return acc;
    }, {} as { [key: string]: number }) ?? {};

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Variants</TableCell>
          <TableCell align="left">Traffic</TableCell>
          <TableCell align="left">Model</TableCell>
          <TableCell align="right">Success Rate</TableCell>
          {/* <TableCell align="right">Feedback</TableCell> */}
          <TableCell align="right">Calls</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {promptTemplateQuery.isLoading && templateVariants.length === 0 ? (
          Array(3)
            .fill(0)
            .map((_, i) => <FunctionVariantCardPlaceholder key={`function-variant-placeholder-${i}`} />)
        ) : templateVariants.length > 0 ? (
          templateVariants.map((r: TemplateVariant) => (
            <FunctionVariantCard
              key={r.id}
              variant={r}
              calls={templateVariantCalls[r.id] ?? 0}
              accuracy={templateVariantAccuracy[r.id] ?? 0}
              traffic={trafficMap[r.id] ?? 0}
              editFunction={(variant) => {
                setEditingVariant(variant);
              }}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={8}>
              <NewFunctionVariant onAddNewTemplate={() => setCreateModalOpen(true)} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function FunctionVariantMiniList({
  variantId,
  promptTemplateQuery,
  templateVariants,
  setEditingVariant,
  setCreateModalOpen,
}: {
  variantId: string;
  promptTemplateQuery: UseQueryResult<GetPromptTemplateVariantsQuery, unknown>;
  templateVariants: TemplateVariant[];
  setEditingVariant: (variant: TemplateVariant) => void;
  setCreateModalOpen: (open: boolean) => void;
}) {
  const { percentages } = useAppSelector(selectEval);

  return (
    <TableContainer style={{ height: "100%" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Variants</TableCell>
            <TableCell align="right">Traffic</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promptTemplateQuery.isLoading && templateVariants.length === 0 ? (
            Array(3)
              .fill(0)
              .map((_, i) => (
                <TableRow
                  hover
                  key={`variant-placeholder-${i}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
          ) : templateVariants.length > 0 ? (
            templateVariants.map((r: TemplateVariant, i) => (
              <TableRow
                hover
                key={`variant-list-item-${i}`}
                sx={{ height: 44, "&:last-child td, &:last-child th": { border: 0 } }}
                selected={variantId === r.id}
              >
                <TableCell component="th" scope="row">
                  <Link
                    to={generatePath(RouteNames.GistVariant, { id: r.templateID, variantId: r.id })}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant={variantId === r.id ? "h4" : "body1"}>{r.name}</Typography>
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <VariantPercentageField row={r} traffic={percentages[r.id] ?? 0} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function FunctionEditor() {
  const { id, variantId } = useParams();
  const dispatch = useAppDispatch();
  const isVariantEditor = !!variantId;
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  const [createVariantModalOpen, setCreateVariantModalOpen] = useState<boolean>(false);
  const [editFunctionModalOpen, setEditFunctionModalOpen] = useState<boolean>(false);
  const [benchmarkModalOpen, setBenchmarkModalOpen] = useState<boolean>(false);
  const [editingVariant, setEditingVariant] = useState<TemplateVariant | undefined>();
  const promptTemplateQuery = useGetPromptTemplateVariantsQuery({ query: { id } });

  const {
    needsRefresh,
    template: evalTemplate,
    variant: evalVariant,
    evaluators,
    percentages,
    percentagesValid,
    percentagesChanged,
  } = useAppSelector(selectEval);

  const runTemplateMutation = useDoRunTemplateMutation();
  const updateTemplateMutation = useDoUpdateTemplateMutation();

  async function onUpdateTemplate() {
    if (!percentagesValid || updateTemplateMutation.isLoading || saving || !template?.id) {
      return;
    }
    setSaving(true);
    try {
      const percentagesArray = Object.keys(percentages).map((k) => ({
        variantID: k,
        percentage: percentages[k],
      }));

      const response = await updateTemplateMutation.mutateAsync({
        input: {
          id: template.id,
          fields: {
            percentages: percentagesArray,
          },
        },
      });

      if (response.updateTemplate) {
        await invalidateTemplateQuery(template.id);
        dispatch(refreshTemplate());
      }
    } catch (error) {
    } finally {
      setSaving(false);
    }
  }

  async function runTemplate({
    variantID,
    templateID,
    values,
    fields,
    evalCaseIDs,
  }: {
    variantID: string;
    templateID: string;
    fields: TemplateVariantFieldsInput;
    values?: { [key: string]: string };
    evalCaseIDs?: string[];
  }) {
    try {
      dispatch(setEvaluating(true));
      if (variantID) {
        const valueArray = values ? Object.keys(values).map((k) => ({ name: k, value: values[k] })) : undefined;
        const response = await runTemplateMutation.mutateAsync({
          input: {
            runClientID: uuidv4(),
            templateID: templateID,
            templateVariantID: variantID,
            overrideFields: fields,
            adhocEvalValues: valueArray,
            evalCaseIDs,
            evaluators: evaluators as EvaluatorInput[],
          },
        });

        if (response.runTemplate.error) {
          setMessage(response.runTemplate.error);
        } else if (response.runTemplate?.results) {
          dispatch(setTemplateResult(response.runTemplate as RunTemplateResult));
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setMessage(error.message);
      }
    } finally {
      dispatch(setEvaluating(false));
    }
  }

  // testing loading skeleton
  // const promptTemplateQuery = {
  //   isLoading: true,
  //   isFetched: false,
  //   data: { templates: { templates: [] as Template[] } },
  // };

  const templates = promptTemplateQuery.data?.templates?.templates ?? [];
  const template = templates[0];
  const templateName = template?.name ?? "";
  const templateDescription = template?.description ?? "";

  const currentVariant = (template?.variants ?? []).find((v) => v.id === variantId);

  useEffect(() => {
    if (template && (needsRefresh || !evalTemplate || template.id !== evalTemplate.id)) {
      dispatch(templateChanged(template));
    }
  }, [dispatch, evalTemplate, needsRefresh, template]);

  useEffect(() => {
    if (currentVariant && (!evalVariant || evalVariant.id !== currentVariant.id)) {
      dispatch(variantChanged(currentVariant));
    }
  }, [currentVariant, dispatch, evalVariant]);

  return (
    <Layout
      workspace
      showMenu={!isVariantEditor}
      className="TemplateVariantList"
      header={
        <Stack direction="row" className="PageHeader" alignItems="flex-start" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link to={RouteNames.Gists}>Gists</Link>
              <Stack direction="row" alignItems="center" gap={1}>
                <Tooltip placement="bottom-start" title={templateDescription ?? ""}>
                  <Link to={generatePath(RouteNames.Gist, { id })}>
                    <Typography>{promptTemplateQuery.isLoading ? <Skeleton width={60} /> : templateName}</Typography>
                  </Link>
                </Tooltip>
                <IconButton onClick={() => setEditFunctionModalOpen(true)}>
                  <img src={IconEdit} alt="Edit" height={16} />
                </IconButton>
              </Stack>
              {isVariantEditor && currentVariant && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Tooltip placement="bottom-start" title={currentVariant?.description ?? ""}>
                    <Typography>
                      {promptTemplateQuery.isLoading ? <Skeleton width={60} /> : currentVariant?.name ?? ""}
                    </Typography>
                  </Tooltip>
                  <IconButton onClick={() => setEditingVariant(currentVariant)}>
                    <img src={IconEdit} alt="Edit" height={16} />
                  </IconButton>
                </Stack>
              )}
            </Breadcrumbs>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <Typography>
              {promptTemplateQuery.isLoading ? (
                <Skeleton width={60} />
              ) : percentagesValid ? (
                ""
              ) : (
                `Total traffic ${Object.values(percentages).reduce((a, b) => a + b, 0)}%`
              )}
            </Typography>
            {percentagesChanged && (
              <Button
                onClick={onUpdateTemplate}
                variant="contained"
                size="medium"
                disabled={!percentagesChanged || !percentagesValid || updateTemplateMutation.isLoading || saving}
              >
                Save traffic
              </Button>
            )}
            <Button onClick={() => setBenchmarkModalOpen(true)} variant="outlined" size="medium">
              <img src={IconBenchmark} alt="Benchmark" height={14} />
              &nbsp;&nbsp;Benchmark
            </Button>
            <Button onClick={() => setCreateVariantModalOpen(true)} variant="outlined" size="medium">
              <img src={IconTemplateVariant} alt="New variant" height={14} />
              &nbsp;&nbsp;New Variant
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Stack alignItems="stretch" justifyContent="stretch" style={{ flex: 1, width: "100%", maxWidth: "100%" }}>
        {!isVariantEditor && (
          <TableContainer component={Paper} elevation={0} style={{ borderRadius: 6 }}>
            <FunctionVariantList
              promptTemplateQuery={promptTemplateQuery}
              template={template}
              templateVariants={template?.variants ?? []}
              setEditingVariant={setEditingVariant}
              setCreateModalOpen={setCreateVariantModalOpen}
            />
          </TableContainer>
        )}

        {!isVariantEditor && promptTemplateQuery.isFetched && templates.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {(template?.variants ?? []).length} Gist Variants
          </Stack>
        )}

        {isVariantEditor && (
          <PanelGroup autoSaveId="variantEditorListContent" direction="horizontal">
            <Panel defaultSize={25} maxSize={50}>
              <PanelGroup autoSaveId="variantEditorVariantsInputs" direction="vertical">
                <Panel defaultSize={30} maxSize={50}>
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "#f6f7f8",
                      borderRadius: 6,
                      overflow: "hidden",
                      flex: 1,
                    }}
                  >
                    <FunctionVariantMiniList
                      key={variantId}
                      variantId={variantId}
                      promptTemplateQuery={promptTemplateQuery}
                      templateVariants={template?.variants ?? []}
                      setEditingVariant={setEditingVariant}
                      setCreateModalOpen={setCreateVariantModalOpen}
                    />
                  </Box>
                </Panel>
                <PanelResizeHandle style={{ height: 8 }} />
                <Panel defaultSize={70} maxSize={80}>
                  <FunctionFieldTabs template={template} runTemplate={runTemplate} />
                </Panel>
              </PanelGroup>
            </Panel>
            <PanelResizeHandle style={{ width: 16 }} />
            <Panel defaultSize={75} maxSize={85}>
              <VariantEditor
                key={`variant-editor-${currentVariant?.id}`}
                setEditingVariant={setEditingVariant}
                runTemplate={runTemplate}
              />
            </Panel>
          </PanelGroup>
        )}

        {promptTemplateQuery.isLoading && templates.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        {template && (
          <CreateFunctionVariantModal
            key={editingVariant ? `editVariant-${editingVariant.id}` : "createVariant"}
            template={template}
            variant={editingVariant}
            open={createVariantModalOpen || !!editingVariant}
            onClose={() => {
              setCreateVariantModalOpen(false);
              setEditingVariant(undefined);
            }}
            onSucceed={async (variant?: TemplateVariant) => {
              setCreateVariantModalOpen(false);
              setEditingVariant(undefined);
            }}
          />
        )}

        {template && (
          <CreateFunctionModal
            key={`editTemplate-${template.id}`}
            template={template}
            open={editFunctionModalOpen}
            onClose={() => {
              setEditFunctionModalOpen(false);
            }}
            onSucceed={async () => {
              setEditFunctionModalOpen(false);
              await invalidateTemplateQuery(template.id);
            }}
          />
        )}

        {template && (
          <BenchmarkFunctionModal
            key={`benchmarkTemplate-${template.id}`}
            template={template}
            open={benchmarkModalOpen}
            onClose={() => {
              setBenchmarkModalOpen(false);
            }}
            onSucceed={async () => {
              setBenchmarkModalOpen(false);
              await invalidateTemplateQuery(template.id);
            }}
          />
        )}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!message}
          onClose={() => setMessage(undefined)}
          message={userfacingError(message as UserFacingError)}
          key="function-editor-bottom-right"
          autoHideDuration={3000}
        />
      </Stack>
    </Layout>
  );
}

export default FunctionEditor;
