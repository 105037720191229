import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Template, Templates } from "generated/graphql";
import { RootState } from "./store";

export interface TemplateState {
  initialized: boolean;
  templates: Template[];
  cursor?: string | null;
  loaded: boolean;
  limit: number;
}

export function appendResult(responses: Template[], newResponses: Template[]): Template[] {
  const set = new Set(responses.map((r) => r.id));
  const filtered = newResponses.filter((r) => !set.has(r.id));
  return [...responses, ...filtered];
}

const initialState: TemplateState = { initialized: false, templates: [], cursor: null, loaded: false, limit: 24 };

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    addTemplates: (state, action: PayloadAction<Templates | null | undefined>) => {
      state.initialized = true;
      const newCursor = action.payload?.cursor;
      const newToOld = [...(action.payload?.templates ?? [])];
      if (newCursor && newCursor !== state.cursor && newToOld.length > 0) {
        // we loaded a new page. let's push the result
        state.templates = appendResult(state.templates, newToOld);
        // now it won't load again
        state.cursor = newCursor;
      }

      if (newToOld.length < state.limit) {
        state.loaded = true;
      }

      return state;
    },
    // add a single template, it doesn't change the cursor
    createTemplate: (state, action: PayloadAction<Template>) => {
      if (action.payload) {
        state.templates = [action.payload, ...state.templates];
      }
      return state;
    },
    updateTemplate: (state, action: PayloadAction<Template>) => {
      const newResponses = state.templates.map((m) => (m.id === action.payload.id ? action.payload : m));
      state.templates = newResponses;
      return state;
    },
    removeTemplates: (state, action: PayloadAction<string[]>) => {
      const toBeDeleted = new Set<string>(action.payload ?? []);
      state.templates = state.templates.filter((p) => !toBeDeleted.has(p.id));
      return state;
    },
    clearTemplates: (state) => {
      state.initialized = false;
      state.templates = [];
      state.loaded = false;
      state.cursor = null;
      return state;
    },
  },
});

export const { addTemplates, createTemplate, updateTemplate, removeTemplates, clearTemplates } = templatesSlice.actions;

export const selectTemplates = (state: RootState) => state.templates;

export default templatesSlice.reducer;
