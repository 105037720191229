import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useCreateTeamMutation, useUpdateTeamMutation, Team } from "generated/graphql";
import { selectCurrentCompany } from "store/current_company_reducer";
import { useAppSelector } from "hooks";
import "./index.sass";

const validationSchema = yup.object().shape({
  id: yup.string().notRequired(),
  name: yup.string().required("Name is required"),
  description: yup.string().notRequired(),
});

export default function TeamFormModal({
  open,
  currentTeam,
  onClose,
  onConfirm,
}: {
  open: boolean;
  currentTeam?: Team;
  onClose: () => void;
  onConfirm: (result?: any) => void;
}) {
  const [submitError, setSubmitError] = useState<string>();
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const createTeam = useCreateTeamMutation();
  const updateTeam = useUpdateTeamMutation();
  const formik = useFormik({
    initialValues: {
      id: currentTeam?.id,
      name: currentTeam?.name ?? "",
      description: currentTeam?.description ?? "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        if (currentTeam) {
          const res = await updateTeam.mutateAsync({
            teamId: currentTeam.id,
            input: { name: values.name, description: values.description },
          });
          if (res) confirm(res);
        } else {
          const res = await createTeam.mutateAsync({
            input: {
              companyId: currentCompany?.id ?? "",
              name: values.name,
              description: values.description,
            },
          });
          if (res.createTeam.id) confirm(res);
        }
      } catch (err: any) {
        setSubmitError(err.message);
      }
    },
  });

  function close() {
    formik.resetForm();
    setSubmitError("");
    onClose();
  }
  function confirm(res: any) {
    onConfirm(res);
    close();
  }

  if (open && currentTeam && formik.values.id !== currentTeam?.id) {
    formik.setValues({
      id: currentTeam?.id,
      name: currentTeam?.name ?? "",
      description: currentTeam?.description ?? "",
    });
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{currentTeam ? "Edit team" : "Add team"}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack direction="column" spacing={2} paddingTop={1} style={{ minWidth: 420 }}>
            <TextField
              id="name"
              name="name"
              label="Name"
              autoFocus={formik.touched.name}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              type="description"
              multiline={true}
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.errors.description?.toString()}
            />
          </Stack>
          {submitError && (
            <Stack className="errorMessageContainer">
              <Typography className="errorMessage" variant="body2" flex={1}>
                {submitError}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1} paddingTop={2}>
            <Button onClick={close}>Cancel</Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
