import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import { useAppDispatch, useAppNavigate } from "hooks";
import IconAi from "assets/v2/icon-ai.svg";
import { createModel, updateModel } from "store/models_reducer";
import { AiModel, AiModelType, useDoCreateModelMutation, useDoUpdateModelMutation } from "generated/graphql";
import { generatePath } from "react-router-dom";
import RouteNames from "route_names";

export default function CreateModelModal({
  model,
  open,
  onClose,
  onSucceed,
}: {
  model?: AiModel;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
}) {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(model?.name ?? "My new custom AI model");
  const [description, setDescription] = useState<string>(model?.description ?? "");
  const [saving, setSaving] = useState<boolean>(false);

  const createModelMutation = useDoCreateModelMutation();
  const updateModelMutation = useDoUpdateModelMutation();

  function close() {
    onClose();
  }

  function validate(): boolean {
    return name.trim() !== "";
  }

  async function saveAndClose() {
    if (validate()) {
      setSaving(true);

      try {
        if (model?.id) {
          const response = await updateModelMutation.mutateAsync({
            input: {
              id: model.id,
              aiModelType: AiModelType.Chatgpt_3_5TurboInstructFinetuning,
              description,
              name,
            },
          });

          if (response.updateModel) {
            dispatch(updateModel(response.updateModel));
            onSucceed?.();
          }
        } else {
          const response = await createModelMutation.mutateAsync({
            input: {
              aiModelType: AiModelType.Chatgpt_3_5TurboInstructFinetuning,
              description,
              name,
            },
          });

          if (response.createModel) {
            dispatch(createModel(response.createModel));
            navigate(generatePath(RouteNames.ModelEditor, { id: response.createModel.id }));
          }
        }

        close();
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="CreateModelModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconAi} alt="Create new model" />
          <Typography variant="h4">{!!model?.id ? "Edit AI model" : "New AI model"}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2} sx={{ py: 1 }}>
          <Box>
            <TextField
              required
              disabled={saving}
              label="Name of the model"
              className="CreateModelModal__name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Choose a name of your model"
              value={name}
              size="small"
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              disabled={saving}
              className="CreateModelModal__description"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the purpose of your model"
              value={description ?? ""}
              size="small"
              fullWidth
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={!validate() || saving}
              onClick={() => {
                saveAndClose();
              }}
              variant="contained"
            >
              Create
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
