import React from "react";
import "./success_rings.sass";

function SuccessRing() {
  return (
    <div className="successRings">
      <div className="ring largeRing" />
      <div className="ring smallRing" />
    </div>
  );
}

export default SuccessRing;
