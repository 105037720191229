import PremiumMetricChart from "components/chart/premium_chart";
import { chartWellnessAreaColors } from "components/chart/colors";
import { chartWellnessLabels } from "components/chart/labels";
import { DoughnutTooltipCallbacks } from "components/chart/helpers";
import { factoryDefaultData } from "components/chart/types";
import { Grid } from "@mui/material";
import { PeriodLimit, periodRange, useCanAccessPersonalDashboard } from "utils";
import { useState } from "react";
import {
  GetPersonalPositiveContributingFactorsQueryVariables,
  TimePeriod,
  useGetPersonalPositiveContributingFactorsQuery,
} from "generated/graphql";

// const defaultPeriod = "this week";

const PersonalPositiveContributingFactors = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const canAccessPersonalDashboard = useCanAccessPersonalDashboard();

  // GraphQL data call
  const positiveFactors = useGetPersonalPositiveContributingFactorsQuery(
    {
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
      frequency,
    } as GetPersonalPositiveContributingFactorsQueryVariables,
    { enabled: canAccessPersonalDashboard }
  );

  // Chart props and variables
  const type = "doughnut",
    title = "What contributed to your high energy?",
    subtitle = "High energy areas";

  let chartData = factoryDefaultData();

  if (!positiveFactors.isLoading && positiveFactors.isSuccess) {
    const rawData = positiveFactors.data?.personalDashboard?.positiveContributingFactors?.values;
    if (rawData?.length)
      chartData = {
        labels: rawData?.map((f) => chartWellnessLabels[f.label]),
        datasets: [
          {
            label: title,
            data: rawData?.map((f) => f.value),
            backgroundColor: rawData?.map((f) => chartWellnessAreaColors[f.label]),
          },
        ],
      };
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <PremiumMetricChart
        canAccess={canAccessPersonalDashboard}
        type={type}
        title={title}
        subtitle={subtitle}
        dataSuccess={positiveFactors.isSuccess}
        dataLoading={positiveFactors.isLoading}
        hasEnoughData={positiveFactors.isSuccess} // for personal, always should have enough data
        data={chartData}
        options={{
          plugins: {
            tooltip: {
              callbacks: DoughnutTooltipCallbacks(),
            },
          },
        }}
      />
    </Grid>
  );
};

export default PersonalPositiveContributingFactors;
