export const chartWellnessAreaColors: { [area: string]: string } = {
  "area-wellness": "#392978",
  "area-sleep": "#21E0BB",
  "area-physical": "#F584DE",
  "area-food": "#42CBFF",
  "area-personal": "#392978",
  "area-work": "#49EFF2",
  "area-colleague": "#FFAD33",
  "area-company": "#FC8857",
  "area-other": "#FF1234",
  "energy-level": "#392978",
  "breath": "#21E0BB",
  "live-class": "#F584DE",
  "water": "#42CBFF",
  "eye-break": "#49EFF2",
  "lunch": "#FFAD33",
  "priority": "#FC8857",
  "gratitude": "#FF1234",
  "walk": "#392978",
  "exercise": "#21E0BB",
  "boundary": "#F584DE",
};

export const chartBreakdownByParticipantsColors: { [area: string]: string } = {
  "1:1": "#49EFF2",
  "+3": "#21E0BB",
  "blocker": "#F584DE",
};

export const chartBreakdownByTypeColors: { [area: string]: string } = {
  recurring: "#42CBFF",
  "non-recurring": "#FFAD33",
};
