import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectEval, valueChanged } from "store/eval_reducer";

function EvalField({ row }: { row: { name: string } }) {
  const dispatch = useAppDispatch();
  const { values } = useAppSelector(selectEval);
  async function onValueChanged(change: { name: string; value: string }) {
    dispatch(valueChanged(change));
  }

  return (
    <TextField
      fullWidth
      multiline
      autoComplete="off"
      id={`{value-field-${row.name}`}
      InputProps={{ disableUnderline: true, id: `{value-input-${row.name}` }}
      variant="standard"
      size="small"
      value={values[row.name] ?? ""}
      onChange={(event) => {
        onValueChanged({ name: row.name, value: event.currentTarget.value });
      }}
      placeholder="<empty>"
    />
  );
}

export default function VariableEditor() {
  const { usedVariables, unusedVariables } = useAppSelector(selectEval);
  const rows = [...usedVariables, ...unusedVariables].map((v) => ({ name: v }));

  return (
    <TableContainer style={{ height: "100%" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Variable</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={`value-row-${row.name}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <EvalField row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
