import BottomTabs from "./bottom_tab";
import debounce from "lodash.debounce";
import IconAi from "assets/v2/icon-ai.svg";
import PromptPane from "./prompt_pane";
import RouteNames from "route_names";
import { Box, Chip, Divider, Paper, Skeleton, Snackbar, Stack, Typography } from "@mui/material";
import { generatePath } from "react-router-dom";
import { invalidateTemplateQuery } from "./function";
import { LoadingButton } from "@mui/lab";
import { ModelOptionsPopover } from "components/prompt_editor_v2/prompt_box";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useAppDispatch, useAppNavigate, useAppSelector, useSafeModelName } from "hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { refreshTemplate, selectEval, variantChanged } from "store/eval_reducer";

import {
  AiModelType,
  GptParams,
  TemplateVariant,
  TemplateVariantFields,
  TemplateVariantFieldsInput,
  useDoDuplicateTemplateVariantMutation,
  useDoUpdateTemplateMutation,
  useDoUpdateTemplateVariantMutation,
} from "generated/graphql";
import usePickerPopover from "components/pickers/picker_popover_hook";
import { NumberPickerPopover } from "components/pickers/number_slider";
import { getModelMaxToken } from "store/models_reducer";

export default function VariantEditor({
  setEditingVariant,
  runTemplate,
}: {
  setEditingVariant: (variant: TemplateVariant) => void;
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    values: { [key: string]: string };
    fields: TemplateVariantFieldsInput;
    evalCaseIDs?: string[];
  }) => void;
}) {
  const { template, usedVariables, fields, variant } = useAppSelector(selectEval);
  const stagingVariantFields = { ...(variant?.fields ?? {}) } as TemplateVariantFields;

  const isLoading = !variant;
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const [changed, setChanged] = useState<boolean>(false);

  const [message, setMessage] = useState<string | undefined>(undefined);

  const [modelName, customModel] = useSafeModelName(
    stagingVariantFields?.aiModelType,
    stagingVariantFields?.customModelID ?? stagingVariantFields?.aiModelType ?? ""
  );
  const modelSwitcherRef = useRef<HTMLDivElement>();
  const [modelOptionsOpen, setModelOptionsOpen] = useState<boolean>(false);

  const maxTokensMax = getModelMaxToken(stagingVariantFields?.aiModelType);

  const temperature = stagingVariantFields.gpt?.temperature;
  const maxTokens = stagingVariantFields.gpt?.maxTokens;
  const topP = stagingVariantFields.gpt?.topP;

  const [
    temperaturePickerAnchor,
    temperaturePickerTemperature,
    openTemperaturePicker,
    confirmTemperaturePicker,
    closeTemperaturePicker,
  ] = usePickerPopover<number>();

  const [
    maxTokensPickerAnchor,
    maxTokensPickerMaxTokens,
    openMaxTokensPicker,
    confirmMaxTokensPicker,
    closeMaxTokensPicker,
  ] = usePickerPopover<number>();

  const [topPPickerAnchor, topPPickerTopP, openTopPPicker, confirmTopPPicker, closeTopPPicker] =
    usePickerPopover<number>();

  const updateTemplateVariantMutation = useDoUpdateTemplateVariantMutation();
  const duplicateTemplateVariantMutation = useDoDuplicateTemplateVariantMutation();
  const updateTemplateMutation = useDoUpdateTemplateMutation();

  const debounceOnVariantChanged = useMemo(
    () =>
      debounce((variant: TemplateVariant) => {
        dispatch(variantChanged(variant));
      }, 250),
    [dispatch]
  );

  const onUpdateModel = (model: string) => {
    if (model && stagingVariantFields) {
      if (variant) {
        dispatch(
          variantChanged({
            ...variant,
            fields: { ...stagingVariantFields, aiModelType: model as AiModelType },
          } as TemplateVariant)
        );
      }
      setChanged(true);
    }

    setChanged(true);
  };

  const onUpdateTemperature = (temperature: number) => {
    onUpdatePrompt({ ...(stagingVariantFields.gpt ?? {}), temperature });
  };

  const onUpdateMaxTokens = (maxTokens: number) => {
    onUpdatePrompt({ ...(stagingVariantFields.gpt ?? {}), maxTokens });
  };

  const onUpdateTopP = (topP: number) => {
    onUpdatePrompt({ ...(stagingVariantFields.gpt ?? {}), topP });
  };

  const onUpdatePrompt = (gpt: GptParams) => {
    if (gpt && stagingVariantFields) {
      if (variant) {
        debounceOnVariantChanged({ ...variant, fields: { ...stagingVariantFields, gpt } } as TemplateVariant);
      }
      setChanged(true);
    }
  };

  const onSaveVariant = async () => {
    if (!variant) {
      return;
    }

    try {
      // check if custom model is selected
      let aiModelType = stagingVariantFields?.aiModelType;
      if (customModel) {
        aiModelType = customModel.aiModelType;
      }

      const response = await updateTemplateVariantMutation.mutateAsync({
        input: {
          id: variant.id,
          templateID: variant.templateID,
          fields: {
            aiModelType: aiModelType,
            customModelID: customModel?.id,
            gpt: stagingVariantFields?.gpt ?? {},
          } as TemplateVariantFieldsInput,
        },
      });

      if (response.updateTemplateVariant) {
        // await invalidateTemplateQuery(variant.templateID);
        dispatch(variantChanged(response.updateTemplateVariant));
        setChanged(false);
        setMessage("Saved");
      }

      // also update template inputs
      if (template?.id) {
        const usedVariableFields = usedVariables.map((v) => ({ name: v, description: "" }));
        const allFields = [...usedVariableFields, ...Object.values(fields), ...(template?.fields?.inputs ?? [])];
        await updateTemplateMutation.mutateAsync({
          input: {
            id: template?.id,
            fields: {
              inputs: allFields,
            },
          },
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        // alert user about the error
        setMessage(`Failed to save variant: ${error.message}`);
      }
    }
  };

  const onDuplicateVariant = async () => {
    if (!variant) {
      return;
    }

    try {
      const response = await duplicateTemplateVariantMutation.mutateAsync({
        input: {
          id: variant.id,
          templateID: variant.templateID,
        },
      });

      if (response.duplicateTemplateVariant) {
        await invalidateTemplateQuery(variant.templateID);
        dispatch(refreshTemplate());
        navigate(
          generatePath(RouteNames.GistVariant, {
            id: variant.templateID,
            variantId: response.duplicateTemplateVariant.id,
          })
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        // alert user about the error
        setMessage(`Failed to duplicate variant: ${error.message}`);
      }
    }
  };

  const deferredReload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
  }, []);

  useEffect(() => {
    if (changed) {
      console.log("blocked");
      window.addEventListener("beforeunload", deferredReload);
    }

    return () => window.removeEventListener("beforeunload", deferredReload);
  }, [changed, deferredReload]);

  return (
    <PanelGroup autoSaveId="variantEditorPromptEval" direction="vertical" style={{ flex: 1 }}>
      <Panel maxSize={80}>
        <Stack flex={1} direction="row" alignItems="flex-start" gap={2} style={{ height: "100%" }}>
          <Paper elevation={0} sx={{ flex: 1, height: "100%" }}>
            <Stack flex={1} style={{ height: "100%" }}>
              <Stack sx={{ py: 0.5, px: 2, flexShrink: 0 }} direction="row" gap={isLoading ? 1 : 0} alignItems="center">
                {variant?.name ? (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="h4">{variant.name}</Typography>
                  </Stack>
                ) : (
                  <Skeleton width={60} />
                )}
                {isLoading ? (
                  <Skeleton width={240} />
                ) : variant?.description ? (
                  <Typography>&nbsp; | {variant.description}</Typography>
                ) : (
                  ""
                )}
                <Box flex={1} />
                <Stack direction="row" gap={1} alignItems="center">
                  {variant?.templateID ? (
                    <LoadingButton
                      size="small"
                      onClick={onDuplicateVariant}
                      loading={duplicateTemplateVariantMutation.isLoading}
                    >
                      Duplicate
                    </LoadingButton>
                  ) : (
                    <Skeleton width={60} />
                  )}
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={!changed || isLoading}
                    onClick={onSaveVariant}
                    loading={updateTemplateVariantMutation.isLoading}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>

              <Divider />

              <Stack
                className="VariantModelParams"
                sx={{ py: 1, px: 2, flexShrink: 0 }}
                direction="row"
                gap={1}
                alignItems="center"
              >
                {!isLoading && modelName ? (
                  <Box ref={modelSwitcherRef}>
                    <Chip
                      size="small"
                      onClick={() => setModelOptionsOpen(true)}
                      icon={<img src={IconAi} alt="model" style={{ paddingLeft: 4 }} height={16} />}
                      label={modelName}
                    />
                  </Box>
                ) : (
                  <Skeleton width={60} height={32} />
                )}

                {!isLoading ? (
                  <Chip
                    size="small"
                    onClick={(event) =>
                      openTemperaturePicker(temperature, event.currentTarget, (val) => {
                        const valNum = val ?? 0;
                        if (valNum >= 0 && valNum <= 2) {
                          onUpdateTemperature(valNum);
                        }
                      })
                    }
                    label={`Temperature: ${temperature ?? 1}`}
                  />
                ) : (
                  <Skeleton width={60} height={32} />
                )}

                {!isLoading ? (
                  <Chip
                    size="small"
                    onClick={(event) =>
                      openMaxTokensPicker(maxTokens, event.currentTarget, (val) => {
                        if (val) {
                          onUpdateMaxTokens(val);
                        }
                      })
                    }
                    label={`Max length: ${maxTokens ?? 1}`}
                  />
                ) : (
                  <Skeleton width={60} height={32} />
                )}

                {!isLoading ? (
                  <Chip
                    size="small"
                    onClick={(event) =>
                      openTopPPicker(topP, event.currentTarget, (val) => {
                        const valNum = val ?? 0;
                        if (valNum >= 0 && valNum <= 1) {
                          onUpdateTopP(valNum);
                        }
                      })
                    }
                    label={`TopP: ${topP ?? 1}`}
                  />
                ) : (
                  <Skeleton width={60} height={32} />
                )}
              </Stack>

              <Divider />

              <Stack
                sx={{ py: 2, px: 2, background: "#f6f7f8" }}
                style={{ borderRadius: "6px", overflow: "auto", flex: 1 }}
              >
                <PromptPane
                  isLoading={isLoading}
                  model={stagingVariantFields?.aiModelType}
                  gpt={stagingVariantFields?.gpt ?? {}}
                  onChangePrompt={onUpdatePrompt}
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>

        <ModelOptionsPopover
          dropup
          model={stagingVariantFields?.aiModelType ?? AiModelType.Chatgpt_3_5Turbo}
          onChange={onUpdateModel}
          anchorEl={modelSwitcherRef.current}
          open={modelOptionsOpen}
          onClose={() => setModelOptionsOpen(false)}
        />

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!message}
          onClose={() => setMessage(undefined)}
          message={message}
          key="bottom-right"
          autoHideDuration={3000}
        />

        <NumberPickerPopover
          min={0}
          max={2}
          step={0.01}
          minLabel="Deterministic"
          maxLabel="Creative"
          open={!!temperaturePickerAnchor}
          className="TemperaturePicker"
          onClose={closeTemperaturePicker}
          value={temperature ?? temperaturePickerTemperature ?? 1}
          anchorEl={temperaturePickerAnchor}
          onConfirm={confirmTemperaturePicker}
        />

        <NumberPickerPopover
          int
          min={0}
          max={maxTokensMax}
          step={1}
          minLabel="1"
          maxLabel={`${maxTokensMax}`}
          open={!!maxTokensPickerAnchor}
          className="MaxTokensPicker"
          onClose={closeMaxTokensPicker}
          value={maxTokens ?? maxTokensPickerMaxTokens ?? 1}
          anchorEl={maxTokensPickerAnchor}
          onConfirm={confirmMaxTokensPicker}
        />

        <NumberPickerPopover
          min={0}
          max={1}
          step={0.01}
          minLabel="Conventional"
          maxLabel="Diverse"
          open={!!topPPickerAnchor}
          className="TopPPicker"
          onClose={closeTopPPicker}
          value={topP ?? topPPickerTopP ?? 1}
          anchorEl={topPPickerAnchor}
          onConfirm={confirmTopPPicker}
        />
      </Panel>
      <PanelResizeHandle style={{ height: 8 }} />
      <Panel defaultSize={30} maxSize={80}>
        <BottomTabs runTemplate={runTemplate} />
      </Panel>
    </PanelGroup>
  );
}
