import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import IconDashboard from "assets/v2/icon-dashboard.svg";
import IconArrowDown from "assets/v2/icon-arrow-down.svg";
import RouteNames from "route_names";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import styles from "./index.module.sass";
import TextareaAutosize from "react-textarea-autosize";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@mui/material";
import { electronHeaderHeight } from "utils";
import { Headerify } from "components/layout/layout";
import { Link } from "react-router-dom";
import { sampleApp } from "components/workflow_builder/model";
import React, { useEffect, useRef, useState } from "react";
import { useIsElectron, useVisualHeight } from "hooks";
import { useTheme } from "@mui/material/styles";
import { PromptType, useDoGenerateMutation } from "generated/graphql";
import mustache from "mustache";
import { LoadingButton } from "@mui/lab";

const sampleInput = `{
  "event_id": "01GWAG8AMDG5SH0DCBSYWPZK4A",
  "event_type": "form_response",
  "form_response": {
    "form_id": "lKkm8SVu",
    "token": "xp10rreg45sag1snmxp10rqk38s08lij",
    "landed_at": "2023-03-24T19:05:50Z",
    "submitted_at": "2023-03-24T19:06:47Z",
    "definition": {
      "id": "lKkm8SVu",
      "title": "Demo lead value add form",
      "fields": [
        {
          "id": "Wiohu5FyGlQz",
          "ref": "01GVZXQ17XA6ZPK0JED5PDRSKN",
          "type": "multiple_choice",
          "title": "What are you contacting us about?",
          "properties": {},
          "choices": [
            {
              "id": "s7WkMpqu46cP",
              "label": "Product info"
            },
            {
              "id": "xqwoLsom8C7v",
              "label": "Lead generation"
            },
            {
              "id": "71K41WNQVmR2",
              "label": "Support"
            },
            {
              "id": "LVRBg5eWCulJ",
              "label": "Free guide"
            }
          ]
        },
        {
          "id": "3PkcoFg5CynK",
          "ref": "a6e3335d-d78b-4b54-889b-1d9b3a70ca19",
          "type": "website",
          "title": "What's your linked in url?",
          "properties": {}
        },
        {
          "id": "YJxk6W3XEauB",
          "ref": "c5523833-dadf-4a47-a52e-d33ad4d7a2b0",
          "type": "short_text",
          "title": "Tell us about your company",
          "properties": {}
        },
        {
          "id": "n98GIOOZ8BGS",
          "ref": "f528fd2c-e14e-4a3e-a566-c7e751ddf959",
          "type": "short_text",
          "title": "Awesome. Who are some of your existing customers?",
          "properties": {}
        },
        {
          "id": "68CsRYQfhaWc",
          "ref": "0272bb8b-742e-41c2-8875-a2211d7e3a9f",
          "type": "multiple_choice",
          "title": "Great. What are you goals?",
          "properties": {},
          "choices": [
            {
              "id": "iLsk5MfqdJRP",
              "label": "Increase revenue"
            },
            {
              "id": "O3nfaKd6RmQn",
              "label": "Increase awareness"
            },
            {
              "id": "jwX7DHutXNGx",
              "label": "Improve conversion"
            },
            {
              "id": "glYAzK9N4xLh",
              "label": "Follow up"
            }
          ]
        },
        {
          "id": "tuDMC55Cr3kQ",
          "ref": "b7df4acc-a4d5-4231-9a30-d97f7ae327dc",
          "type": "short_text",
          "title": "What's your role",
          "properties": {}
        },
        {
          "id": "qy8TS8oi5iyC",
          "ref": "f2aa05a8-c66e-44e7-8307-429375d1f6fb",
          "type": "short_text",
          "title": "Address",
          "properties": {}
        },
        {
          "id": "oUGaqOSubbpf",
          "ref": "c60d51d8-5ff8-4b01-b228-fe9aa15dac33",
          "type": "short_text",
          "title": "Address line 2",
          "properties": {}
        },
        {
          "id": "QF0LdPy0MnZi",
          "ref": "3120d25e-fd47-48d4-8648-b5a88cb10e12",
          "type": "short_text",
          "title": "City/Town",
          "properties": {}
        },
        {
          "id": "GPKmBVUtQLcn",
          "ref": "e1b2dfd8-db62-4136-9919-a1684a8bcc8d",
          "type": "short_text",
          "title": "State/Region/Province",
          "properties": {}
        },
        {
          "id": "Iz8jNVPQ0gL4",
          "ref": "c879b906-2917-49a6-810d-934a8a8b337b",
          "type": "short_text",
          "title": "Zip/Post Code",
          "properties": {}
        },
        {
          "id": "6KI3BKoAxCO8",
          "ref": "fcfb8279-7de5-46d7-8ce6-c1341d80aa60",
          "type": "short_text",
          "title": "Country",
          "properties": {}
        }
      ],
      "endings": [
        {
          "id": "HMgDFkXqfFIS",
          "ref": "01GVZXQ17XM1CESFHBBYVADZHZ",
          "title": "All done. We'll be in touch",
          "type": "thankyou_screen",
          "properties": {
            "button_text": "Create a typeform",
            "show_button": true,
            "share_icons": true,
            "button_mode": "default_redirect"
          }
        }
      ]
    },
    "answers": [
      {
        "type": "choice",
        "choice": {
          "label": "Support"
        },
        "field": {
          "id": "Wiohu5FyGlQz",
          "type": "multiple_choice",
          "ref": "01GVZXQ17XA6ZPK0JED5PDRSKN"
        }
      },
      {
        "type": "url",
        "url": "https://www.linkedin.com/in/terry-xu-aba18926b/",
        "field": {
          "id": "3PkcoFg5CynK",
          "type": "website",
          "ref": "a6e3335d-d78b-4b54-889b-1d9b3a70ca19"
        }
      },
      {
        "type": "text",
        "text": "Softbank",
        "field": {
          "id": "YJxk6W3XEauB",
          "type": "short_text",
          "ref": "c5523833-dadf-4a47-a52e-d33ad4d7a2b0"
        }
      },
      {
        "type": "text",
        "text": "WeWork",
        "field": {
          "id": "n98GIOOZ8BGS",
          "type": "short_text",
          "ref": "f528fd2c-e14e-4a3e-a566-c7e751ddf959"
        }
      },
      {
        "type": "choice",
        "choice": {
          "label": "Follow up"
        },
        "field": {
          "id": "68CsRYQfhaWc",
          "type": "multiple_choice",
          "ref": "0272bb8b-742e-41c2-8875-a2211d7e3a9f"
        }
      },
      {
        "type": "text",
        "text": "Chief Janitor",
        "field": {
          "id": "tuDMC55Cr3kQ",
          "type": "short_text",
          "ref": "b7df4acc-a4d5-4231-9a30-d97f7ae327dc"
        }
      },
      {
        "type": "text",
        "text": "1 Broadway",
        "field": {
          "id": "qy8TS8oi5iyC",
          "type": "short_text",
          "ref": "f2aa05a8-c66e-44e7-8307-429375d1f6fb"
        }
      },
      {
        "type": "text",
        "text": "14th Floor",
        "field": {
          "id": "oUGaqOSubbpf",
          "type": "short_text",
          "ref": "c60d51d8-5ff8-4b01-b228-fe9aa15dac33"
        }
      },
      {
        "type": "text",
        "text": "Cambridge",
        "field": {
          "id": "QF0LdPy0MnZi",
          "type": "short_text",
          "ref": "3120d25e-fd47-48d4-8648-b5a88cb10e12"
        }
      },
      {
        "type": "text",
        "text": "Massachusetts",
        "field": {
          "id": "GPKmBVUtQLcn",
          "type": "short_text",
          "ref": "e1b2dfd8-db62-4136-9919-a1684a8bcc8d"
        }
      },
      {
        "type": "text",
        "text": "02142",
        "field": {
          "id": "Iz8jNVPQ0gL4",
          "type": "short_text",
          "ref": "c879b906-2917-49a6-810d-934a8a8b337b"
        }
      },
      {
        "type": "text",
        "text": "US",
        "field": {
          "id": "6KI3BKoAxCO8",
          "type": "short_text",
          "ref": "fcfb8279-7de5-46d7-8ce6-c1341d80aa60"
        }
      }
    ],
    "ending": {
      "id": "HMgDFkXqfFIS",
      "ref": "01GVZXQ17XM1CESFHBBYVADZHZ"
    }
  }
}`;

const samplePrompt1 = `You're an answer extractor. I will feed you some input and you will extract the values:
{{{input}}}
Extract the answers from the json above:
{What are you contact us about}
{Tell us about your company}
{Who are some of your existing customers}
{What are you goals}
{Address}
{LinkedIn URL}
`;

const samplePrompt2 = `Extract the latest news
{{{input}}}
`;

const samplePrompt3 = `You're an email writer. I will feed you some information and you will write the simplest cold email to make them interested.
User information
{{{input1}}}, {{{input2}}}
`;

function NavHeader({ appName }: { appName: string | undefined }) {
  const [localAppName, setLocalAppName] = useState<string>(appName ?? "");

  function updateAppName(event: React.ChangeEvent<HTMLInputElement>) {
    setLocalAppName(event.target.value);
  }

  function saveAppName(event: React.FocusEvent<HTMLInputElement>) {
    // save to graph
  }

  return (
    <Stack direction="row" alignItems="center" style={{ width: "100%" }}>
      <Link to={RouteNames.Home}>
        <IconButton>
          <img src={IconDashboard} alt="home" />
        </IconButton>
      </Link>
      <Divider flexItem orientation="vertical" sx={{ ml: 1, mr: 2 }} />
      <Typography variant="h5">App Builder</Typography>
      <Stack direction="row" justifyContent="flex-end" flex={1} gap={2}>
        <Box className={styles.WebhookURL}>https://cuely-txu.ngrok.io/webhook/sample?secretKey</Box>
        <TextField size="small" label="App name" value={localAppName} onChange={updateAppName} onBlur={saveAppName} />
      </Stack>
    </Stack>
  );
}

function AccordianTextArea({
  onSave,
  placeholder,
  field,
  value,
}: {
  onSave: (value: string) => void;
  placeholder: string;
  field: string;
  value: string;
}) {
  const [localValue, setLocalValue] = useState<string>(value);

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setLocalValue(event.target.value);
  }

  return (
    <Accordion elevation={0} defaultExpanded disableGutters>
      <AccordionSummary expandIcon={<img src={IconArrowDown} alt="expand" />}>
        <Typography>{field}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextareaAutosize
          className={styles.Text}
          cacheMeasurements
          value={localValue}
          onChange={onChange}
          maxRows={5}
          autoFocus
          placeholder={placeholder}
          onBlur={() => onSave(localValue)}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function AccordianElement({ field, children }: { field: string; children: React.ReactElement }) {
  return (
    <Accordion elevation={0} defaultExpanded disableGutters>
      <AccordionSummary expandIcon={<img src={IconArrowDown} alt="expand" />}>
        <Typography>{field}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

function Trigger({ onResponse }: { onResponse: (response: string) => void }) {
  const generateMutation = useDoGenerateMutation();
  const [id, setId] = useState<string>();
  const [response, setResponse] = useState<string>();
  const [input, setInput] = useState<string>(sampleInput);
  const [prompt, setPrompt] = useState<string>(samplePrompt1);
  const [busy, setBusy] = useState<boolean>(false);

  async function run() {
    if (busy) {
      return;
    }
    setBusy(true);
    setResponse("");
    try {
      const result = await generateMutation.mutateAsync({
        input: {
          id,
          type: PromptType.Text,
          prompt: mustache.render(prompt, { input }),
        },
      });

      if (result && result.generate.prompt?.texts) {
        setResponse(result.generate.prompt?.texts[0]);
        onResponse(result.generate.prompt?.texts[0]);
      }
      if (result.generate.prompt) {
        setId(result.generate.prompt?.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Stack className={styles.Stage} gap={1}>
      <Typography variant="h6">START</Typography>
      <Stack gap={1}>
        <AccordianTextArea
          value={input}
          placeholder="Paste the sample input here"
          field="Sample input"
          onSave={(text) => {
            setInput(text);
          }}
        />
        <AccordianTextArea
          value={prompt}
          placeholder="Enter prompt here"
          field="Input processing prompt"
          onSave={(text) => {
            setPrompt(text);
          }}
        />
        <AccordianElement field="Sample response">
          <Box className={styles.Output}>{response ?? ""}</Box>
        </AccordianElement>
      </Stack>
      <Box>
        <LoadingButton loading={busy} onClick={run} variant="contained">
          Run
        </LoadingButton>
      </Box>
    </Stack>
  );
}

function Destination() {
  return (
    <Stack className={styles.Stage} gap={1}>
      <Typography variant="h6">FINISH</Typography>
      <Box>Send email</Box>
    </Stack>
  );
}

function Step1({ inputs, onResponse }: { inputs: string[]; onResponse: (response: string) => void }) {
  const generateMutation = useDoGenerateMutation();
  const [id, setId] = useState<string>();
  const [response, setResponse] = useState<string>();
  const [prompt, setPrompt] = useState<string>(samplePrompt2);
  const [busy, setBusy] = useState<boolean>(false);

  async function run() {
    if (busy) {
      return;
    }
    setBusy(true);
    setResponse("");
    try {
      const result = await generateMutation.mutateAsync({
        input: {
          id,
          type: PromptType.Text,
          prompt: mustache.render(prompt, { input: inputs[0] }),
          visitLinks: true,
        },
      });

      if (result && result.generate.prompt?.texts) {
        setResponse(result.generate.prompt?.texts[0]);
        onResponse(result.generate.prompt?.texts[0]);
      }
      if (result.generate.prompt) {
        setId(result.generate.prompt?.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Stack className={styles.Stage} gap={1}>
      <Typography variant="h6">Fetch user info</Typography>
      <Stack gap={1}>
        <AccordianElement field="Input source">
          <Box className={styles.Output}>
            Response from the previous step
            <br />
            {inputs[0]}
          </Box>
        </AccordianElement>
        <AccordianTextArea
          value={prompt}
          placeholder="Enter prompt here"
          field="Prompt"
          onSave={(text) => {
            console.log(text);
            setPrompt(text);
          }}
        />
        <AccordianElement field="Sample response">
          <Box className={styles.Output}>{response ?? ""}</Box>
        </AccordianElement>
      </Stack>
      <Box>
        <LoadingButton disabled={!inputs || inputs.length === 0} loading={busy} onClick={run} variant="contained">
          Run
        </LoadingButton>
      </Box>
    </Stack>
  );
}

function Step2({ inputs }: { inputs: string[] }) {
  const generateMutation = useDoGenerateMutation();
  const [id, setId] = useState<string>();
  const [response, setResponse] = useState<string>();
  const [prompt, setPrompt] = useState<string>(samplePrompt3);
  const [busy, setBusy] = useState<boolean>(false);

  async function run() {
    if (busy) {
      return;
    }
    setBusy(true);
    setResponse("");
    try {
      const result = await generateMutation.mutateAsync({
        input: {
          id,
          type: PromptType.Text,
          prompt: mustache.render(prompt, { input1: inputs[0], input2: inputs[1] }),
        },
      });

      if (result && result.generate.prompt?.texts) {
        setResponse(result.generate.prompt?.texts[0]);
      }
      if (result.generate.prompt) {
        setId(result.generate.prompt?.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Stack className={styles.Stage} gap={1}>
      <Typography variant="h6">Personalize email</Typography>
      <Stack gap={1}>
        <AccordianElement field="Input source">
          <Box className={styles.Output}>
            Response from the trigger
            <br />
            {inputs[0]}
          </Box>
        </AccordianElement>
        <AccordianElement field="Input source">
          <Box className={styles.Output}>
            Response from the previous step
            <br />
            {inputs[1]}
          </Box>
        </AccordianElement>
        <AccordianTextArea
          value={prompt}
          placeholder="Enter prompt here"
          field="Prompt"
          onSave={(text) => {
            console.log(text);
            setPrompt(text);
          }}
        />
        <AccordianElement field="Sample response">
          <Box className={styles.Output}>{response ?? ""}</Box>
        </AccordianElement>
      </Stack>
      <Box>
        <LoadingButton disabled={!inputs || inputs.length === 0} loading={busy} onClick={run} variant="contained">
          Run
        </LoadingButton>
      </Box>
    </Stack>
  );
}

export default function AppBuilder() {
  const [message, setMessage] = useState<string>();
  const isElectron = useIsElectron();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const headerRef = useRef<HTMLDivElement>();
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const _visualHeight = useVisualHeight();
  const visualHeight = isElectron ? _visualHeight - electronHeaderHeight : _visualHeight;

  const [response1, setResponse1] = useState<string>("");
  const [response2, setResponse2] = useState<string>("");

  // listen to header size change
  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries[0] && entries[0].target) {
        const headerRect = entries[0].target.getBoundingClientRect();
        setHeaderHeight(headerRect.height ?? 0);
      }
    });

    resizeObserver.observe(headerRef.current);
    const headerRect = headerRef?.current?.getBoundingClientRect();
    setHeaderHeight(headerRect?.height ?? 0);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Headerify>
      <div className={`AppFrame ${isElectron ? "Electron" : ""}`}>
        <Box className={`${styles.AppBuilder} ${mobile ? styles.Mobile : ""}`}>
          <Stack
            direction="row"
            className={styles.SectionHeader}
            alignItems="center"
            justifyContent="space-between"
            ref={headerRef}
          >
            <NavHeader appName={sampleApp.name} />
          </Stack>
          <Stack
            className={styles.ColumnWrapper}
            position="relative"
            direction="row"
            alignItems="flex-start"
            justifySelf="stretch"
          >
            <Stack
              direction="row"
              className={`${styles.Columns} Columns`}
              style={{ paddingTop: headerHeight, width: "100%", height: mobile ? "undefined" : visualHeight }}
            >
              <Trigger onResponse={(response) => setResponse1(response)} />
              <Step1 inputs={[response1]} onResponse={(response) => setResponse2(response)} />
              <Step2 inputs={[response1, response2]} />
              <Destination />
            </Stack>
          </Stack>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!message}
          onClose={() => setMessage(undefined)}
          message={message}
          key="bottom-center"
          autoHideDuration={3000}
        />
      </div>
    </Headerify>
  );
}
