import GistsLogo from "assets/v2/gists-logo.svg";
import IconAccount from "assets/v2/icon-account.svg";
import IconAutomations from "assets/v2/icon-automations.svg";
import IconBilling from "assets/v2/icon-billing.svg";
import IconCharacters from "assets/v2/icon-characters.svg";
import IconClose from "assets/v2/icon-close.svg";
import IconCompany from "assets/v2/icon-company.svg";
import IconDashboard from "assets/v2/icon-dashboard.svg";
import IconDocs from "assets/v2/icon-docs.svg";
import IconDropdown from "assets/v2/icon-dropdown.svg";
import IconGear from "assets/v2/icon-gear.svg";
import IconHistory from "assets/v2/icon-history.svg";
// import IconAi from "assets/v2/icon-ai.svg";
// import IconJournal from "assets/v2/icon-journal.svg";
import IconLogout from "assets/v2/icon-logout.svg";
import IconManageTeam from "assets/v2/icon-manage-team.svg";
import IconNewPrompt from "assets/v2/icon-new-prompt.svg";
import IconPersonal from "assets/v2/icon-personal.svg";
// import IconPersonalInsights from "assets/v2/icon-personal-insights.svg";
import IconPromptFunction from "assets/v2/icon-prompt-function.svg";
import IconTeamInsights from "assets/v2/icon-team-insights.svg";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import RouteNames from "route_names";
import { AppLink } from "components/app_link";
import { AuthAccount, initializeAuth, logout, selectAuth, switchAccount } from "store/auth_reducer";
import { Box, Divider, Fade, Menu, MenuItem } from "@mui/material";
import { clearCharacters } from "store/characters_reducer";
import { clearCurrentPlan } from "store/plan_reducer";
import { clearCurrentUser, selectCurrentUser } from "store/current_user_reducer";
import { clearModels } from "store/models_reducer";
import { clearPrompts } from "store/prompts_reducer";
import { clearTemplates } from "store/templates_reducer";
import { contains, electronHeaderHeight } from "utils";
import { forwardRef, MutableRefObject, useRef, useState } from "react";
import { RBAC } from "lib/RBAC";
import { selectCurrentCompany } from "store/current_company_reducer";
import { useAppDispatch, useAppNavigate, useAppSelector, useIsElectron } from "hooks";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import "./menu.sass";

function NavItem({
  text,
  url,
  onClick,
  icon,
  endIcon,
  className,
  blank,
}: {
  icon: string;
  endIcon?: string | null | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  text: string;
  url?: string | null;
  className?: string | null;
  blank?: boolean;
}) {
  const location = useLocation();
  const selected = location.pathname === url;
  const button = (
    <ListItemButton
      className="MenuItem"
      sx={{
        justifyContent: "initial",
        pl: 1,
        pr: endIcon ? 0 : 1,
        py: 0.5,
        mx: 0,
        my: 0.25,
        borderRadius: "6px",
      }}
      selected={selected}
      onClick={onClick}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 1,
          justifyContent: "center",
        }}
      >
        <img className="Icon" src={icon} alt={text} />
      </ListItemIcon>
      <ListItemText primary={text} color="primary" />
      {endIcon && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: "center",
          }}
        >
          <img className="Icon" src={endIcon} alt={text} />
        </ListItemIcon>
      )}
    </ListItemButton>
  );

  return (
    <ListItem className={className ?? ""} key={text} disablePadding sx={{ display: "block" }}>
      {url ? (
        <AppLink to={url} target={blank ? "_blank" : undefined}>
          {button}
        </AppLink>
      ) : (
        button
      )}
    </ListItem>
  );
}

function initial(name: string): string {
  if (!name) {
    return "";
  }

  if (name.length === 1) {
    return name.toUpperCase();
  }

  if (name.length <= 6) {
    return name;
  }

  let names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

function NavMenu(
  props: { oldMenu?: boolean; mobile?: boolean; closeMenu?: () => void },
  ref: ((instance: HTMLUListElement | null) => void) | MutableRefObject<HTMLUListElement | null> | null
) {
  const isElectron = useIsElectron();
  const { initialized: initCurrentUser, currentUser } = useAppSelector(selectCurrentUser);
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const accountEl = useRef<HTMLElement | null>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const queryClient = useQueryClient();

  function clearAllSlices() {
    dispatch(clearPrompts());
    dispatch(clearModels());
    dispatch(clearCharacters());
    dispatch(clearCurrentPlan());
    dispatch(clearTemplates());
  }

  function handleLogout() {
    handleCloseAccountMenu();
    queryClient.invalidateQueries();
    dispatch(logout());
    dispatch(clearCurrentUser());

    clearAllSlices();

    dispatch(initializeAuth());
  }

  function handleCloseAccountMenu() {
    accountEl.current = null;
    setAccountMenuOpen(false);
  }

  function handleOpenAccountMenu(event: React.MouseEvent<HTMLElement>) {
    accountEl.current = event.currentTarget;
    setAccountMenuOpen(true);
  }

  function handleSwitchAccount(account: AuthAccount) {
    setAccountMenuOpen(false);
    dispatch(switchAccount(account));
    // refetch for the new user.
    queryClient.invalidateQueries();
    dispatch(clearCurrentUser());

    clearAllSlices();
  }

  const hasTeam = auth.currentAccount?.teamID;

  const teamManager =
    hasTeam &&
    initCurrentUser &&
    (contains(currentUser?.roles, RBAC.RoleTeamManager) ||
      contains(currentUser?.roles, RBAC.RoleAdmin) ||
      contains(currentUser?.roles, RBAC.RoleSuperAdmin));

  const accountName =
    initial(currentUser?.profile?.firstName || currentUser?.profile?.fullName || auth?.currentAccount?.name || "") ||
    "Account";

  const companyName = currentCompany?.name || auth?.currentAccount?.team || "Company";

  return (
    <List
      id="Menu"
      className="Menu"
      sx={{ py: 0, px: 2, alignItems: "stretch" }}
      style={{ top: props.mobile && isElectron ? electronHeaderHeight : 0, zIndex: 1000 }}
      ref={ref}
    >
      <Box sx={{ mt: 1 }} />
      <NavItem text={accountName} icon={IconAccount} endIcon={IconDropdown} onClick={handleOpenAccountMenu} />
      <Divider className="item-divider" />
      <NavItem text="Gists" url={RouteNames.Gists} icon={IconPromptFunction} />
      <NavItem text="Characters" url={RouteNames.Characters} icon={IconCharacters} />
      {/* <NavItem text="Datastores" url={RouteNames.Datastores} icon={IconCloud} /> */}
      {/* <NavItem text="Models" url={RouteNames.Models} icon={IconAi} /> */}
      <Divider className="item-divider" />
      <NavItem text="New Chat" url={RouteNames.NewPrompt} icon={IconNewPrompt} />
      <NavItem text="Chats" url={RouteNames.History} icon={IconHistory} />
      {props.oldMenu && <Divider className="item-divider" />}
      {/* {props.oldMenu && <NavItem text="Home" url={RouteNames.Wellbeing} icon={IconDashboard} />} */}
      {/* {props.oldMenu && <NavItem text="Insights" url={RouteNames.Insights} icon={IconPersonalInsights} />} */}
      {props.oldMenu && <NavItem text="Scheduled" url={RouteNames.ReminderSettings} icon={IconAutomations} />}
      {/* {props.oldMenu && <NavItem text="Journal" url={RouteNames.Journal} icon={IconJournal} />} */}
      {props.oldMenu &&
        (hasTeam ? (
          <>
            {teamManager ? (
              <>
                {props.oldMenu && <NavItem text="Team Insights" url={RouteNames.Team} icon={IconTeamInsights} />}
                <NavItem text="Manage Teams" url={RouteNames.TeamManager} icon={IconManageTeam} />
              </>
            ) : (
              <></>
            )}
            <NavItem text="Team Members" url={RouteNames.TeamMemberships} icon={IconCompany} />
          </>
        ) : (
          <NavItem text="Team" url={RouteNames.TeamInstall} icon={IconDashboard} />
        ))}
      {props.mobile && <NavItem text="Close" onClick={props.closeMenu} icon={IconClose} />}
      <Box style={{ flex: 1 }} />
      <Divider className="item-divider" />
      {!props.oldMenu && <NavItem text="Settings" url={RouteNames.Settings} icon={IconGear} />}
      {!props.oldMenu && <NavItem text="Subscription" url={RouteNames.Plan} icon={IconBilling} />}
      {!props.oldMenu && <NavItem text="Help & Docs" blank url="https://docs.gists.ai" icon={IconDocs} />}
      {/* <Box sx={{ m: 1 }} alignItems="center" justifyContent="center" display="flex">
        <UpgradeOption />
      </Box> */}
      <Box sx={{ p: 1 }}>
        <img src={GistsLogo} alt="Gists" style={{ height: 20 }} />
      </Box>
      <Menu
        elevation={4}
        sx={{ minWidth: 120 }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={accountEl.current}
        open={accountMenuOpen}
        onClose={handleCloseAccountMenu}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 1,
              justifyContent: "center",
            }}
          >
            <img className="Icon" src={companyName ? IconCompany : IconPersonal} alt="Current account" />
          </ListItemIcon>
          <ListItemText>
            {accountName ?? auth?.currentAccount?.email ?? "Guest"}
            {companyName ? ` - ${companyName}` : " - Personal"}
          </ListItemText>
        </MenuItem>
        {props.oldMenu && (
          <MenuItem
            onClick={() => {
              setAccountMenuOpen(false);
              // close the menu first.
              setTimeout(() => {
                navigate(RouteNames.Plan);
              }, 250);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 1,
                justifyContent: "center",
              }}
            >
              <img className="Icon" src={IconBilling} alt="Subscription" />
            </ListItemIcon>
            <ListItemText>Subscription</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 1,
              justifyContent: "center",
            }}
          >
            <img className="Icon" src={IconLogout} alt="Logout" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        {(auth?.accounts?.length ?? 0) > 1 && <Divider />}
        {(auth?.accounts?.length ?? 0) > 1 && (
          <Box>
            {(auth?.accounts ?? [])
              .filter((a) => !a?.isGuest && !isEqual(a, auth.currentAccount))
              .map((a) => (
                <MenuItem key={`${a.email}${a.team}${a.name}`} onClick={() => handleSwitchAccount(a)}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 1,
                      justifyContent: "center",
                    }}
                  >
                    <img className="Icon" src={a.teamID ? IconCompany : IconPersonal} alt="Current account" />
                  </ListItemIcon>
                  <ListItemText>
                    {a.name ? a.name : a.email ?? "Guest"}
                    {a.team ? ` - ${a.team}` : ""}
                  </ListItemText>
                </MenuItem>
              ))}
          </Box>
        )}
      </Menu>
    </List>
  );
}

function isEqual(obj1: any, obj2: any) {
  var props1 = Object.getOwnPropertyNames(obj1);
  var props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}

export default forwardRef(NavMenu);
