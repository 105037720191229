import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Layout from "components/layout/layout";
import RouteNames from "route_names";
import SelectCompany from "components/select-company";
import TeamFormModal from "./team_form_modal";
import TeamMembersIcon from "@mui/icons-material/PeopleOutlined";
import TeamMembersModal from "./team_members_modal";
import { Button, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Cell, DataGrid, Row } from "components/datagrid";
import { contains } from "utils";
import { GetTeamsQueryVariables, Team, useDeleteTeamMutation, useGetTeamsQuery } from "generated/graphql";
import { RBAC } from "lib/RBAC";
import { selectCurrentCompany } from "store/current_company_reducer";
import { selectCurrentUser } from "store/current_user_reducer";
import { useAppSelector } from "hooks";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.sass";

const TeamManager = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector(selectCurrentUser);
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const deleteTeam = useDeleteTeamMutation();
  const getTeams = useGetTeamsQuery(
    {
      companyId: currentCompany?.id ?? "",
    } as GetTeamsQueryVariables,
    {
      enabled: !!currentCompany?.id,
    }
  );

  const [currentTeam, setCurrentTeam] = useState<Team>();
  const [imSuperAdmin, setImSuperAdmin] = useState<boolean>(false);
  const [teamModalOpen, setTeamModalOpen] = useState<boolean>(false);
  const [teamMemberModalOpen, setTeamMemberModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      setImSuperAdmin(contains(currentUser.roles, RBAC.RoleSuperAdmin));
      if (
        !contains(currentUser.roles, RBAC.RoleTeamManager) &&
        !contains(currentUser.roles, RBAC.RoleAdmin) &&
        !imSuperAdmin
      )
        navigate(RouteNames.Home);
    }
  }, [navigate, currentUser, imSuperAdmin]);

  return (
    <Layout
      className="TeamPage"
      header={
        <div className="PageHeader">
          <Grid item sm={12} md={12} lg={4} className="PageTitleGrid">
            <Typography variant="h4" className="PageTitle">
              Team managment
            </Typography>
            <SelectCompany imSuperAdmin={imSuperAdmin}></SelectCompany>
          </Grid>
        </div>
      }
    >
      <TeamFormModal
        currentTeam={currentTeam}
        open={teamModalOpen}
        onClose={() => {
          setTeamModalOpen(false);
          setCurrentTeam(undefined);
        }}
        onConfirm={() => {
          setTeamModalOpen(false);
          setCurrentTeam(undefined);
          getTeams.refetch();
        }}
      />
      <TeamMembersModal
        currentTeam={currentTeam}
        open={teamMemberModalOpen}
        onClose={() => {
          setTeamMemberModalOpen(false);
          setCurrentTeam(undefined);
        }}
      />
      <Stack direction="row" spacing={1}>
        <Typography className="PageSubtitle">Managed Teams</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setTeamModalOpen(true);
            setCurrentTeam(undefined);
          }}
        >
          Add
        </Button>
      </Stack>
      <Stack>
        <DataGrid
          data={getTeams.data?.getTeams ?? []}
          actions={[
            {
              component: (cell: Cell, row: Row) => (
                <Tooltip key={`show-team-members-${row.cells[0].data}`} title="Show team members">
                  <div>
                    <IconButton
                      aria-label="Team Members"
                      color="info"
                      onClick={() => {
                        setCurrentTeam({
                          id: row.cells[0].data,
                          name: row.cells[1].data,
                          description: row.cells[2].data,
                        } as Team);
                        setTeamMemberModalOpen(true);
                      }}
                    >
                      <TeamMembersIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              ),
            },
            {
              component: (data: any, row: any) => (
                <Tooltip key={`edit-team-${row.cells[0].data}`} title="Edit Team">
                  <div>
                    <IconButton
                      aria-label="Edit"
                      color="info"
                      onClick={() => {
                        setCurrentTeam({
                          id: row.cells[0].data,
                          name: row.cells[1].data,
                          description: row.cells[2].data,
                        } as Team);
                        setTeamModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              ),
            },
            {
              component: (data: any, row: any) => (
                <Tooltip key={`delete-team-${row.cells[0].data}`} title="Delete Team">
                  <div>
                    <IconButton
                      aria-label="Delete"
                      color="error"
                      onClick={() => {
                        confirm({
                          title: "Are you sure you want to delete this team?",
                          description: `The team "${row.cells[1].data}" will be deleted permanently.`,
                          confirmationText: "Delete",
                          cancellationText: "Cancel",
                          confirmationButtonProps: { variant: "contained", color: "error" },
                        })
                          .then(async () => {
                            const result = await deleteTeam.mutateAsync({
                              teamId: row.cells[0].data,
                            });
                            if (result.deleteTeam) getTeams.refetch();
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              ),
            },
          ]}
          columns={[
            {
              name: "ID",
              hidden: true,
            },
            {
              name: "Name",
              width: "20vw",
              minWidth: "20vw",
            },
            {
              name: "Description",
              width: "80vw",
              minWidth: "20vw",
              sort: false,
            },
          ]}
          sort={true}
          autoWidth={false}
          search={true}
          pagination={{
            enabled: true,
            limit: 10,
          }}
        />
      </Stack>
    </Layout>
  );
};

export default TeamManager;
