import Box from "@mui/material/Box";
import Evaluators from "./evaluators";
import FunctionEvalCases from "./function_eval_cases";
// import FunctionOutput from "./function_output";
import FunctionVariables from "./function_variables";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { TabPanelProps } from "./bottom_tab";
import { Template, TemplateVariantFieldsInput } from "generated/graphql";
import { useState } from "react";

function TemplateTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Paper
      elevation={0}
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, flex: 1, height: "100%", overflow: "hidden" }}
      role="tabpanel"
      hidden={value !== index}
      id={`temlate-fields-tabpanel-${index}`}
      aria-labelledby={`temlate-fields-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Paper>
  );
}

function a11yProps(index: number) {
  return {
    id: `temlate-fields-tab-${index}`,
    "aria-controls": `temlate-fields-tabpanel-${index}`,
  };
}

export default function FunctionFieldTabs({
  template,
  runTemplate,
}: {
  template?: Template;
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    fields: TemplateVariantFieldsInput;
    values?: { [key: string]: string };
    evalCaseIDs?: string[];
  }) => void;
}) {
  const [value, setValue] = useState<number>(0);
  const onChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack alignItems="stretch" justifyContent="stretch" flex={1} style={{ height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={onChange} aria-label="basic tabs example">
          <Tab label="Tests" {...a11yProps(0)} />
          <Tab label="Inputs" {...a11yProps(1)} />
          {/* <Tab label="Output" {...a11yProps(2)} /> */}
          <Tab label="Evaluators" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TemplateTabPanel value={value} index={0}>
        <FunctionEvalCases template={template} runTemplate={runTemplate} />
      </TemplateTabPanel>
      <TemplateTabPanel value={value} index={1}>
        <FunctionVariables template={template} />
      </TemplateTabPanel>
      {/* <TemplateTabPanel value={value} index={2}>
        <FunctionOutput template={template} />
      </TemplateTabPanel> */}
      <TemplateTabPanel value={value} index={2}>
        <Evaluators template={template} />
      </TemplateTabPanel>
    </Stack>
  );
}
