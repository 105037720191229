import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { acceptInviteCode } from "api";
import Branding from "components/branding";
import { logError } from "logger";
import React, { useState } from "react";
import RouteNames from "route_names";

export default function AcceptInvite() {
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const code = params.get("code");
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [complete, setComplete] = useState<boolean>(false);

  async function acceptInvite() {
    if (!email || !code) {
      setError("Cannot accept invite. Please find the email and try again.");
      return;
    }

    setSaving(true);
    setError(null);

    try {
      const success = await acceptInviteCode(email, code);
      if (success) {
        setComplete(success);
      }
    } catch (e) {
      logError(e);
      setError("Error accepting invite. Please try again later.");
    } finally {
      setSaving(false);
    }
  }

  return (
    <Box sx={{ my: 8 }}>
      <Stack alignItems="center">
        <Branding />
        <Box className="SignIn">
          {complete ? (
            <Stack gap={2}>
              <Typography> You can sign in to see you team now</Typography>
              <Button variant="contained" href={RouteNames.SignIn}>
                Sign In
              </Button>
            </Stack>
          ) : (
            <Stack spacing={3} className="OAuth" style={{ width: 360 }}>
              <Typography variant="h4">Accept invite</Typography>
              <TextField
                required
                disabled
                label="Email"
                className="AcceptInvite_email"
                value={email}
                size="small"
                fullWidth
              />
              <TextField
                required
                disabled
                label="Invite code"
                className="AcceptInvite_code"
                value={code}
                size="small"
                fullWidth
              />
              <Stack gap={1}>
                <Button disabled={saving} variant="contained" onClick={acceptInvite}>
                  Accept Invite
                </Button>
                {/* <Button disabled={saving} color="error" size="small" onClick={acceptInvite}>
                  Delete
                </Button> */}
              </Stack>
              {!!error && (
                <FormHelperText error id="accept-error">
                  {error}
                </FormHelperText>
              )}
            </Stack>
          )}
        </Box>
        {!complete && (
          <Box className="Terms">
            By clicking on "Accept Invite", you agree to our <a href="https://www.gists.ai/terms">Terms</a>
            &nbsp;and&nbsp;
            <a href="https://www.gists.ai/privacy">Policy</a>.
          </Box>
        )}
      </Stack>
    </Box>
  );
}
