import AcceptInvite from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Logout";
import DenyInvite from "@mui/icons-material/HighlightOff";
import Layout from "components/layout/layout";
import { Cell, DataGrid, Row } from "components/datagrid";
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useConfirm } from "material-ui-confirm";
import "./index.sass";
import {
  TeamMemberStatus,
  useGetCurrentUserTeamMembershipsQuery,
  useUpdateTeamMemberStatusMutation,
  UpdateTeamMemberStatusMutationVariables,
} from "generated/graphql";

const TeamMemberships = () => {
  const confirm = useConfirm();
  const getCurrentUserTeamMemberships = useGetCurrentUserTeamMembershipsQuery();
  const updateTeamMemberStatusMutation = useUpdateTeamMemberStatusMutation();
  const DataGridMemo = memo(DataGrid);
  const data = useMemo(() => {
    if (
      getCurrentUserTeamMemberships.data &&
      getCurrentUserTeamMemberships.data.getCurrentUserTeamMemberships.length > 0
    )
      return getCurrentUserTeamMemberships.data?.getCurrentUserTeamMemberships;
    return [];
  }, [getCurrentUserTeamMemberships]);

  return (
    <Layout
      className="TeamPage"
      header={
        <div className="PageHeader">
          <Grid item sm={12} md={12} lg={4} className="PageTitleGrid">
            <Typography variant="h4" className="PageTitle">
              Team memberships
            </Typography>
          </Grid>
        </div>
      }
    >
      <Stack direction="row" spacing={1}>
        <Typography className="PageSubtitle">Your team memberships</Typography>
      </Stack>
      <Stack>
        <DataGridMemo
          data={data}
          actions={[
            {
              component: (cell: Cell, row: Row) =>
                row.cells[5].data === TeamMemberStatus.Invited && (
                  <Stack key={`invite-buttons-${row.cells[0].data}`} direction="row" spacing={1}>
                    <Tooltip title="Accept Team Invite">
                      <div>
                        <IconButton
                          disabled={
                            getCurrentUserTeamMemberships.isLoading || getCurrentUserTeamMemberships.isRefetching
                          }
                          aria-label="Accept team invitation"
                          color="success"
                          onClick={(e) => {
                            confirm({
                              title: "Accept team invitation",
                              description: `Do you want to accept the invitation of team "${row.cells[4].data}"?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "primary" },
                            })
                              .then(async () => {
                                const result = await updateTeamMemberStatusMutation.mutateAsync({
                                  teamMemberId: row.cells[0].data,
                                  status: TeamMemberStatus.Active,
                                } as UpdateTeamMemberStatusMutationVariables);
                                if (result.updateTeamMemberStatus) getCurrentUserTeamMemberships.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <AcceptInvite />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Remove Team Invite">
                      <div>
                        <IconButton
                          disabled={
                            getCurrentUserTeamMemberships.isLoading || getCurrentUserTeamMemberships.isRefetching
                          }
                          aria-label="Remove team invite"
                          color="error"
                          onClick={(e) => {
                            confirm({
                              title: "Remove team invite",
                              description: `Do you want to remove your invite to team "${row.cells[4].data}"?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "error" },
                            })
                              .then(async () => {
                                const result = await updateTeamMemberStatusMutation.mutateAsync({
                                  teamMemberId: row.cells[0].data,
                                  status: TeamMemberStatus.Deactivated,
                                } as UpdateTeamMemberStatusMutationVariables);
                                if (result.updateTeamMemberStatus) getCurrentUserTeamMemberships.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <DenyInvite />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Stack>
                ),
            },
            {
              component: (cell: Cell, row: Row) => {
                console.log(row.cells[5].data);
                return !row.cells[0].data ? (
                  <div key={`remove-button-${row.cells[0].data}`}></div>
                ) : (
                  row.cells[5].data === TeamMemberStatus.Active && (
                    <Tooltip key={`remove-button-${row.cells[0].data}`} title="Remove Team Membership">
                      <div>
                        <IconButton
                          aria-label="Remove Team Membership"
                          color="error"
                          disabled={
                            getCurrentUserTeamMemberships.isLoading || getCurrentUserTeamMemberships.isRefetching
                          }
                          onClick={() => {
                            confirm({
                              title: `Remove Team Membership`,
                              description: `Are you sure you want to leave the team: ${row.cells[4].data}?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "error" },
                            })
                              .then(async () => {
                                const result = await updateTeamMemberStatusMutation.mutateAsync({
                                  teamMemberId: row.cells[0].data,
                                  status: TeamMemberStatus.Deactivated,
                                } as UpdateTeamMemberStatusMutationVariables);
                                if (result.updateTeamMemberStatus) getCurrentUserTeamMemberships.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                );
              },
            },
          ]}
          columns={[
            {
              name: "ID",
              hidden: true,
            },
            {
              name: "teamId",
              hidden: true,
            },
            {
              name: "userId",
              hidden: true,
            },
            {
              name: "isTeamManager",
              hidden: true,
            },
            {
              name: "Team",
              width: "100%",
            },
            {
              id: "memberStatus",
              name: "Status",
              hidden: true,
              width: "20%",
            },
          ]}
          sort={true}
          autoWidth={false}
          search={true}
          pagination={{
            enabled: true,
            limit: 10,
          }}
        />
      </Stack>
    </Layout>
  );
};

export default TeamMemberships;
