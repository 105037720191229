import App from "./app";
import AppBuilder from "components/app_builder";
import AppHome, { GlobalKeyAppHome } from "components/app_home";
import AppHome2023 from "components/app_home_2023";
import BlinkRoom from "components/blink";
import BreathRoom from "components/breath/index";
import CharacterEditor from "components/characters/editor";
import Characters from "components/characters";
import createTheme from "@mui/material/styles/createTheme";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import EnergyModal from "components/journal/energy";
import FunctionEditor from "components/function/function";
import FunctionList from "components/function";
import Insights from "components/personal/insights";
import Journal from "components/personal/journal";
import ModelEditor from "components/ai_models/editor";
import Models from "components/ai_models";
import Onboarding from "components/onboarding";
import PromptEditorV1 from "components/prompt_editor";
import PromptEditorV2 from "components/prompt_editor_v2";
import React, { useEffect, useMemo } from "react";
import ReminderSettings from "components/settings/reminders";
import reportWebVitals from "./reportWebVitals";
import RouteNames from "./route_names";
import RoutineModal from "components/journal/routine";
import SettingsHome from "components/settings/home";
import SignIn from "./components/auth";
import SlackOAuthRedirect from "./components/auth/slack_oauth_redirect";
import SlackTokenRedirect from "components/auth/slack_token_redirect";
import StretchRoom from "components/stretch";
import Team from "components/team";
import TeamInstall from "components/team/install";
import TeamManager from "components/team/manager";
import TeamMemberships from "components/team/memberships";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AUTH_COOKIE_NAME, AuthState, initializeAuth, selectAuth } from "store/auth_reducer";
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { createRoot } from "react-dom/client";
import { Events, track } from "./analytics";
import { Provider } from "react-redux";
import { queryClient } from "api";
import { QueryClientProvider } from "@tanstack/react-query";
import { SkipElectron } from "components/electron";
import { store } from "./store/store";
import { useAppDispatch, useAppSelector, useIsElectron } from "hooks";
import "./index.sass";
import AcceptInvite from "components/auth/accept_invite";

const fallbackFontFamily = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  "Oxygen-Sans",
  "Ubuntu",
  "Cantarell",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const fontFamily = ["neue-haas-grotesk-text", ...fallbackFontFamily].join(",");

const accentFontFamily = ["neue-haas-grotesk-text", ...fallbackFontFamily].join(",");

const ExtensionMessageName = "__CUELY_GET_AUTH_MSG__";

declare global {
  interface Window {
    app: any;
    electron: any;
    versions: any;
  }
}

function Authed({ children }: { children: (auth: AuthState) => JSX.Element }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);

  useEffect(() => {
    if (!auth.initialized) {
      dispatch(initializeAuth());
    } else {
      // if the user is a guest and we're not authorizing them, we require login unless they're on the destination page
      if (
        auth.currentAccount?.isGuest &&
        window.location.pathname !== RouteNames.SlackTokenRedirect &&
        window.location.pathname !== RouteNames.SlackOAuthRedirect &&
        window.location.pathname !== RouteNames.SignIn
      ) {
        // enforce login if url doesn't match
        const currentURL = window.location.pathname + window.location.search;
        if (!auth?.currentAccount?.contentPath || auth.currentAccount?.contentPath !== currentURL) {
          navigate(RouteNames.SignIn);
        } else {
          track(Events.SharingPage.Landed, {});
        }
      }
    }
  }, [auth.currentAccount?.contentPath, auth.currentAccount?.isGuest, auth.initialized, dispatch, navigate]);

  return <div>{children(auth)}</div>;
}

function Root() {
  const isElectron = useIsElectron();
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#3d3b44",
            contrastText: "#fff",
          },
          secondary: {
            main: "#d6dce2",
          },
          background: {
            default: "#eee",
          },
          divider: "#9999aa44",
        },
        components: {
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
            styleOverrides: {
              root: {
                fontSize: 12,
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "0.15px",
              },
            },
          },
          MuiButton: {
            defaultProps: {
              disableElevation: true,
            },
            styleOverrides: {
              root: {
                fontSize: 12,
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "0.15px",

                "&.MuiButton-containedSecondary:hover": {
                  backgroundColor: "#c3c9ce",
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              colorSuccess: {
                color: "#3e6741",
                backgroundColor: "#f2fded",
                border: "1px solid #c6dec6",
              },
              colorInfo: {
                color: "#354e8c",
                backgroundColor: "#edf2fd",
                border: "1px solid #c6cfde",
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: "black",
                maxWidth: 240,
              },
              arrow: {
                color: "black",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: 6,
                backgroundColor: "#fff",
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                "&.Mui-expanded": {
                  margin: "0px",
                  borderRadius: "6px",
                },
                "&:before": {
                  display: "none",
                },
                borderRadius: "6px",
              },
            },
          },
          MuiAccordionSummary: {
            styleOverrides: {
              root: {
                "&.Mui-expanded": {
                  minHeight: "48px",
                },
              },
              content: {
                margin: "8px 0px",
                "&.Mui-expanded": {
                  minHeight: "16px",
                  margin: "8px 0px",
                },
              },
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor: "#f6f7f8",
                },
                // "&.Mui-selected a, .MuiTypography": {
                //   color: "white",
                // },
              },
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                ".MuiTableCell-root": {
                  textTransform: "uppercase",
                  fontSize: 10,
                  color: "#58585c",
                  backgroundColor: "#fff",
                },
              },
            },
          },
          MuiTableBody: {
            styleOverrides: {
              root: {
                ".MuiTableCell-root": {
                  fontSize: 12,
                  borderBottomColor: "#eaeaf4",
                },
                ".MuiTableHead-root .MuiTableCell-root": {
                  fontSize: 10,
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                minHeight: 0,
                minWidth: 0,
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                fontSize: 12,
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "0.15px",
                padding: "8px 16px",
                minHeight: 0,
                minWidth: 0,
              },
            },
          },
        },
        typography: {
          fontFamily,
          fontWeightRegular: 400,
          body1: {
            fontSize: 13,
          },
          body2: {
            fontSize: 13,
            fontFamily: accentFontFamily,
          },
          h1: {
            fontSize: 16,
            fontWeight: 600,
            fontFamily: accentFontFamily,
          },
          h2: {
            fontSize: 15,
            fontWeight: 600,
            fontFamily: accentFontFamily,
          },
          h3: {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: accentFontFamily,
          },
          h4: {
            fontSize: 13,
            fontWeight: 500,
            fontFamily: accentFontFamily,
          },
          h5: {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: accentFontFamily,
          },
          h6: {
            fontSize: 11,
            fontWeight: 500,
            fontFamily: accentFontFamily,
            textTransform: "uppercase",
          },
          subtitle1: {
            fontSize: 13.5,
            fontFamily: accentFontFamily,
          },
        },
      }),
    []
  );

  const Router = isElectron ? HashRouter : BrowserRouter;

  return (
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Authed>
              {(auth: AuthState) => (
                <ThemeProvider theme={theme}>
                  <ConfirmProvider>
                    <CssBaseline />
                    <Routes>
                      <Route element={<App />} path={RouteNames.Home}>
                        <Route element={<FunctionList />} path={RouteNames.Gists} />
                        <Route element={<FunctionEditor />} path={RouteNames.Gist} />
                        <Route element={<FunctionEditor />} path={RouteNames.GistVariant} />
                        <Route element={<Onboarding />} path={RouteNames.Onboarding} />
                        <Route element={<PromptEditorV2 />} path={RouteNames.NewPrompt} />
                        <Route element={<PromptEditorV2 />} path={RouteNames.PromptEditor} />
                        <Route
                          element={SkipElectron(() => import("components/settings/plan"))}
                          path={RouteNames.Plan}
                        />
                        <Route element={<PromptEditorV1 />} path={RouteNames.PromptEditorV1} />
                        <Route element={<AppBuilder />} path={RouteNames.Builder} />
                        <Route element={<Models />} path={RouteNames.Models} />
                        <Route element={<ModelEditor />} path={RouteNames.ModelEditor} />
                        <Route element={<Characters />} path={RouteNames.Characters} />
                        <Route element={<CharacterEditor />} path={RouteNames.CharacterEditor} />
                        <Route element={<Models />} path={RouteNames.Datastores} />
                        <Route element={<SettingsHome />} path={RouteNames.Settings} />

                        <Route element={<AppHome2023 />} path="/" key={GlobalKeyAppHome}>
                          <Route element={<EnergyModal />} path={RouteNames.EnergyModal} />
                          <Route element={<RoutineModal />} path={RouteNames.RoutineModal} />
                        </Route>
                        <Route element={<AppHome2023 />} path={RouteNames.History} />
                        <Route element={<AppHome />} path={RouteNames.Wellbeing} />
                        <Route element={<BreathRoom />} path={RouteNames.Breath} />
                        <Route element={<BlinkRoom />} path={RouteNames.Eyebreak} />
                        <Route element={<StretchRoom />} path={RouteNames.Stretch} />
                        <Route element={<Insights />} path={RouteNames.Insights} />
                        <Route element={<Journal />} path={RouteNames.Journal} />
                        <Route element={<Team />} path={RouteNames.Team} />
                        <Route element={<TeamManager />} path={RouteNames.TeamManager} />
                        <Route element={<TeamInstall />} path={RouteNames.TeamInstall} />
                        <Route element={<TeamMemberships />} path={RouteNames.TeamMemberships} />
                        <Route element={<ReminderSettings />} path={RouteNames.ReminderSettings} />
                      </Route>
                      <Route element={<PromptEditorV2 />} path={RouteNames.NewPrompt} />
                      <Route element={<SignIn />} path={RouteNames.SignIn} />
                      <Route element={<AcceptInvite />} path={RouteNames.Invite} />
                      <Route
                        element={SkipElectron(() => import("components/electron/google_sign_in"))}
                        path={RouteNames.DesktopGoogleSignIn}
                      />
                      <Route element={<SlackOAuthRedirect />} path={RouteNames.SlackOAuthRedirect} />
                      <Route element={<SlackTokenRedirect />} path={RouteNames.SlackTokenRedirect} />
                    </Routes>
                  </ConfirmProvider>
                </ThemeProvider>
              )}
            </Authed>
          </QueryClientProvider>
        </Provider>
      </Router>
    </React.StrictMode>
  );
}

const ORIGIN_EXTENSION_PROTOCOLS = ["chrome-extension://", "extension://"];
const ORIGIN_EXTENSION_IDS = ["nidgemnjgfbiefcpdbahpgjghfidpnao"];
const ORIGIN_EXTENSION_WHITELIST = new Set<string>(
  (() => {
    const result = [];
    for (let p of ORIGIN_EXTENSION_PROTOCOLS) {
      for (let id of ORIGIN_EXTENSION_IDS) {
        result.push(p + id);
      }
    }
    return result;
  })()
);

async function bootstrapApplication() {
  // handle extension
  window.addEventListener("message", (event: any) => {
    if (ORIGIN_EXTENSION_WHITELIST.has(event.origin) && event.data === ExtensionMessageName) {
      const auth = localStorage.getItem(AUTH_COOKIE_NAME);
      event.source.postMessage(auth, event.origin);
    }
  });

  const root = createRoot(document.getElementById("root")!);
  root.render(<Root />);
}

bootstrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
