import IconVariant from "assets/v2/icon-template-variant.svg";
import TextField from "@mui/material/TextField/TextField";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  Template,
  TemplateVariant,
  useDoCreateTemplateVariantMutation,
  useDoUpdateTemplateVariantMutation,
} from "generated/graphql";
import { generatePath } from "react-router-dom";
import RouteNames from "route_names";
import { refreshTemplate, variantChanged } from "store/eval_reducer";
import { invalidateTemplateQuery } from "./function";
import { useAppDispatch, useAppNavigate } from "hooks";

export default function CreateFunctionVariantModal({
  template,
  variant,
  open,
  onClose,
  onSucceed,
}: {
  template: Template;
  variant?: TemplateVariant;
  open: boolean;
  onClose: () => void;
  onSucceed?: (variant?: TemplateVariant) => void;
}) {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>(variant?.name ?? "New gist variant");
  const [description, setDescription] = useState<string>(variant?.description ?? "");
  const [slug, setSlug] = useState<string>(variant?.slug ?? "");
  const [saving, setSaving] = useState<boolean>(false);

  const createTemplateVariantMutation = useDoCreateTemplateVariantMutation();
  const updateTemplateVariantMutation = useDoUpdateTemplateVariantMutation();

  function close() {
    onClose();
  }

  function validate(): boolean {
    return name.trim() !== "";
  }

  function formatSlug(slug: string): string {
    return slug
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  async function updateVariant(variant: TemplateVariant) {
    dispatch(refreshTemplate());
    await invalidateTemplateQuery(template.id);
    navigate(generatePath(RouteNames.GistVariant, { id: template.id, variantId: variant.id }));
    dispatch(variantChanged(variant));
  }

  async function saveAndClose() {
    if (validate() && !saving) {
      setSaving(true);

      try {
        if (variant?.id) {
          const response = await updateTemplateVariantMutation.mutateAsync({
            input: {
              id: variant.id,
              templateID: template.id,
              name,
              slug: formatSlug(slug),
              description,
            },
          });

          if (response.updateTemplateVariant) {
            onSucceed?.(response.updateTemplateVariant);
            updateVariant(response.updateTemplateVariant);
          }
        } else {
          const response = await createTemplateVariantMutation.mutateAsync({
            input: {
              description,
              name,
              templateID: template.id,
            },
          });

          if (response.createTemplateVariant) {
            onSucceed?.(response.createTemplateVariant);
            updateVariant(response.createTemplateVariant);
          }
        }

        close();
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="CreateTemplateVariantModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconVariant} alt="Create new function variant" />
          <Typography variant="h4">{!!variant?.id ? "Edit variant" : "New variant"}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2} sx={{ py: 1 }}>
          <Box>
            <TextField
              required
              disabled={saving}
              label="Name of the variant"
              className="CreateTemplateVariantModal__name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Choose a name for your function variant"
              value={name}
              size="small"
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              disabled={saving}
              className="CreateTemplateVariantModal__description"
              label="Description of the variant"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe what your variant is about"
              value={description ?? ""}
              size="small"
              fullWidth
            />
          </Box>
          {!!variant?.id && (
            <Box>
              <TextField
                disabled={saving}
                className="CreateTemplateVariantModal__slug"
                label="Slug of the variant"
                onChange={(e) => setSlug(formatSlug(e.target.value))}
                placeholder="An easy to remember slug for your variant"
                value={slug ?? ""}
                size="small"
                fullWidth
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={!validate() || saving}
              onClick={() => {
                saveAndClose();
              }}
              variant="contained"
            >
              {!!variant ? "Update" : "Create"}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
