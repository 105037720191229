import ActiveEmployees from "./dashboard/active_employees";
import EnergySummary from "./dashboard/energy_summary";
import EnergyTrend from "./dashboard/energy_trend";
import Layout from "components/layout/layout";
import NegativeContributingFactors from "./dashboard/negative_contributing_factors";
import PositiveContributingFactors from "./dashboard/positive_contributing_factors";
import React, { useEffect } from "react";
import RouteNames from "route_names";
import SelectCompany from "components/select-company";
import SelectTeam from "components/select-team";
import TotalBreaks from "./dashboard/total_breaks";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Divider, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { contains, endOfDay, endOfToday, goBackWeeksFromNow, startOfDay } from "utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RBAC } from "lib/RBAC";
import { selectAuth } from "store/auth_reducer";
import { selectCurrentCompany } from "store/current_company_reducer";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import "./index.sass";
import { selectCurrentUser } from "store/current_user_reducer";
import { selectCurrentTeam } from "store/current_team_reducer";

const Team = () => {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const { initialized: initCurrentUser, currentUser } = useAppSelector(selectCurrentUser);
  const { initialized: initCurrentCompany, currentCompany } = useAppSelector(selectCurrentCompany);
  const { initialized: initCurrentTeam, currentTeam } = useAppSelector(selectCurrentTeam);
  const [imSuperAdmin, setImSuperAdmin] = React.useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<string>();
  const [selectedTeamId, setSelectedTeamId] = React.useState<string>();
  const [startAt, setStartAt] = React.useState<Date>(goBackWeeksFromNow(8));
  const [endAt, setEndAt] = React.useState<Date>(endOfToday);

  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (auth.initialized && !auth.currentAccount?.teamID) {
      navigate(RouteNames.Home);
    }
  }, [auth.currentAccount?.teamID, auth.initialized, navigate]);

  useEffect(() => {
    if (initCurrentUser && currentUser?.roles) setImSuperAdmin(contains(currentUser?.roles, RBAC.RoleSuperAdmin));
    if (initCurrentCompany && currentCompany) setSelectedCompanyId(currentCompany.id);
    if (initCurrentTeam && currentTeam) setSelectedTeamId(currentTeam.id === "-" ? undefined : currentTeam.id);
  }, [initCurrentUser, currentUser, initCurrentCompany, currentCompany, initCurrentTeam, currentTeam]);

  const dashboardFilters: {
    companyIds?: string | string[] | undefined | null;
    teamIds?: string | string[] | undefined | null;
  } = {
    companyIds: imSuperAdmin && selectedCompanyId ? [selectedCompanyId] : [],
    teamIds: selectedTeamId ? [selectedTeamId] : [],
  };

  if (!auth.currentAccount?.teamID) {
    return <></>;
  }

  return (
    <Layout
      className="TeamPage"
      header={
        <div className="PageHeader">
          <Grid container className="PageHeaderContainer" alignItems="center" spacing={1}>
            <Grid item sm={12} md={6} lg={4} className="PageTitleGrid">
              <Typography variant="h4" className="PageTitle">
                Team insights
              </Typography>
              <SelectCompany imSuperAdmin={imSuperAdmin}></SelectCompany>
              <SelectTeam imSuperAdmin={imSuperAdmin}></SelectTeam>
            </Grid>
            <Grid item container sm={12} md={6} lg={8}>
              <Stack
                direction="row"
                justifyContent={narrow ? "flex-start" : "flex-end"}
                className="DatePickerContainer"
              >
                <Grid item className="DatePickerTextField">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startAt}
                      onChange={(newValue: any) => {
                        setStartAt(startOfDay(newValue));
                      }}
                      renderInput={(params: any) => <TextField size="small" {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Divider className="DatePickerSeparator">
                  <Typography variant="body2">to</Typography>
                </Divider>
                <Grid item className="DatePickerTextField">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={endAt}
                      onChange={(newValue: any) => {
                        setEndAt(endOfDay(newValue));
                      }}
                      renderInput={(params: any) => (
                        <TextField className="DatePickerTextField" size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Stack gap={2}>
        <Typography variant="h2" className="PageSubtitle" sx={{ mb: 2 }}>
          How is your team feeling?
        </Typography>

        <EnergySummary
          hidden={true}
          companyIds={dashboardFilters.companyIds}
          teamIds={dashboardFilters.teamIds}
          startAt={startAt}
          endAt={endAt}
        />

        <Grid container spacing={2} columns={12} alignItems="stretch">
          <EnergyTrend
            companyIds={dashboardFilters.companyIds}
            teamIds={dashboardFilters.teamIds}
            startAt={startAt}
            endAt={endAt}
          />
          <PositiveContributingFactors
            companyIds={dashboardFilters.companyIds}
            teamIds={dashboardFilters.teamIds}
            startAt={startAt}
            endAt={endAt}
          />
          <NegativeContributingFactors
            companyIds={dashboardFilters.companyIds}
            teamIds={dashboardFilters.teamIds}
            startAt={startAt}
            endAt={endAt}
          />
        </Grid>

        <Typography variant="h6" className="PageSubtitle" sx={{ mt: 4, mb: 2 }}>
          How active is your team?
        </Typography>

        <Grid container spacing={2} columns={12} alignItems="stretch">
          <TotalBreaks
            companyIds={dashboardFilters.companyIds}
            teamIds={dashboardFilters.teamIds}
            startAt={startAt}
            endAt={endAt}
          />
          <ActiveEmployees
            companyIds={dashboardFilters.companyIds}
            teamIds={dashboardFilters.teamIds}
            startAt={startAt}
            endAt={endAt}
          />
        </Grid>
      </Stack>
    </Layout>
  );
};

export default Team;
