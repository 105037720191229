import { getEnergyIcon, replaceEmoji } from "utils";
import { DateToSimpleString, parseISODate } from "./calendar-helper";

export interface JournalInfo {
  [key: string]: JournalEntry;
}

export interface JournalEntryInfo {
  createdAt: string;
  description: string;
  type: string;
  energy: number;
}

export class JournalEntry {
  entries: JournalEntryInfo[] = [];
  day: string | undefined;
  getDailyEnergy = (): string => {
    const dailyEnergies = this.entries.filter((e) => e.energy > 0);
    return (
      dailyEnergies.length > 0 ? dailyEnergies.reduce((a, b) => a + b.energy, 0) / dailyEnergies.length : 0.0
    ).toPrecision(2);
  };
}

export function getJournalEntryDescription(journalData: any[] | undefined | null): JournalInfo {
  const journalInfo: JournalInfo = {} as JournalInfo;
  if (journalData) {
    journalData.forEach(function (value: any, key: number) {
      const docObj = JSON.parse(value.doc);
      let description: string = "";
      const day = value.createdAt.slice(0, 10);
      if (!journalInfo[day]) journalInfo[day] = new JournalEntry();
      // TODO: add the rest of the activities
      switch (value.type) {
        case "eye-break":
          description = ":eye: Eye break";
          break;
        case "water":
          description = ":potable_water: Water break";
          break;
        case "breath":
          description = ":deciduous_tree: Breathe";
          break;
        case "gratitude":
          description = `:heart: ${docObj.text}`;
          break;
        case "priority":
          description = `:dart: ${docObj.text}`;
          break;
        case "lunch":
          description = ":burrito: Lunch";
          break;
        case "exercise":
          description = ":raising_hand: Stretch";
          break;
        case "walk":
          description = ":walking: Walk";
          break;
        case "energy-level":
          description = `:zap: Feeling ${getEnergyIcon(docObj.value)}`;
          // console.log(`${getEnergyIcon(docObj.value)} ${value.type}`);
          break;
        case "mindfulness-area":
        case "boundary":
          return;
        default:
          description = `:question: ${value.type}`;
          console.log(docObj);
      }
      journalInfo[day].day = DateToSimpleString(parseISODate(day));
      journalInfo[day].entries.push({
        type: value.type,
        createdAt: value.createdAt,
        description: replaceEmoji(description),
        energy: docObj.value ? docObj.value : 0,
      });
    });
  }
  return journalInfo;
}
