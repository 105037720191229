import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { PaginatedQuery, useGetTemplateLogsQuery } from "generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { addLogs, selectEval } from "store/eval_reducer";
import { OutputRow } from "./evaluation_suite";

function Logs() {
  const dispatch = useAppDispatch();
  const { variant, logs, logsCursor, logsLoaded, logsLimit } = useAppSelector(selectEval);
  const templateID = variant?.templateID;
  const [query, setQuery] = useState<PaginatedQuery>({ id: templateID, query: variant?.id, limit: logsLimit });
  const templateLogsQuery = useGetTemplateLogsQuery({ query }, { enabled: !!templateID && !!variant?.id });

  useEffect(() => {
    if (templateLogsQuery.isFetched) {
      dispatch(addLogs(templateLogsQuery?.data?.templateLogs));
    }
  }, [dispatch, templateLogsQuery]);

  const loadMore = useCallback(() => {
    if (!logsLoaded) {
      if (logsCursor) {
        // load more page
        setQuery({ ...query, cursor: logsCursor });
      }
    }
  }, [logsCursor, logsLoaded, query]);

  return (
    <Box flex={1} style={{ background: "#f6f7f8", height: "100%", overflow: "hidden" }}>
      <TableContainer style={{ height: "100%" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Logs</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((row) => (
              <OutputRow
                key={`log-result-${row.id}`}
                templateID={variant?.templateID}
                row={row}
                setMessage={() => {}}
              />
            ))}

            {!logsLoaded && !templateLogsQuery.isLoading && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Button onClick={loadMore} size="small">
                    Load more...
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Logs;
