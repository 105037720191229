import MultipleChoice, { MultipleChoiceOptions } from "./multiple_choice";
import styles from "./onboarding.module.sass";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { OnboardingProgress } from ".";
import Stack from "@mui/system/Stack";
import IconBenefit from "assets/v2/icon-benefit.svg";
import { OnboardingQuestionInput, useDoSaveOnboardingQuestionMutation } from "generated/graphql";

const channelOptions = {
  options: [
    { value: "none", label: "None" },
    { value: "once", label: "Once a week" },
    { value: "twice", label: "2-3 times a week" },
    { value: "everyday", label: "Pretty much every day" },
  ],
} as MultipleChoiceOptions<string>;

function getConfirmation(selected: string[]): string | undefined {
  if (!selected || selected.length === 0) {
    return;
  }
  switch (selected[0]) {
    case "none":
      return "We're glad to help you start integrating AI at work!";
    case "once":
      return "Great! We're glad to help you better integrate AI at work";
    case "twice":
    case "everyday":
      return "Awesome! We're glad to help you unlock the full power of AI at work";
    default:
      return "Thank you";
  }
}

export default function Frequency({
  progress,
  setProgress,
}: {
  progress: OnboardingProgress;
  setProgress: React.Dispatch<React.SetStateAction<OnboardingProgress>>;
}) {
  const [selected, setSelected] = useState<string[]>([]);
  const [pending, setPending] = useState<boolean>(false);

  const saveOnboardingQuestionMutation = useDoSaveOnboardingQuestionMutation();

  async function submit() {
    setPending(true);
    try {
      if (selected.length > 0) {
        saveOnboardingQuestionMutation.mutateAsync({
          input: {
            version: "20230319",
            questionKey: "chatgpt-frequency",
            value: selected,
          } as OnboardingQuestionInput,
        });
      }

      const confirmation = getConfirmation(selected);
      setProgress({ ...progress, frequency: true, confirmation });
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
    }
  }

  function onOptionUpdate<T>(values: T[]) {
    setSelected(values as string[]);
  }

  return (
    <div className={styles.Step}>
      <Stack className={styles.Content} gap={3}>
        <Typography className={styles.TopTitle} variant="h1">
          How often did you use chatGPT, GPT4, or other LLMs
          <br />
          at work in the last few weeks?
        </Typography>
        <MultipleChoice vertical left options={channelOptions} callback={onOptionUpdate} />
      </Stack>
      <div className={styles.Bottom}>
        <Button
          variant="contained"
          disabled={pending}
          onClick={submit}
          className={`${selected.length === 0 ? styles.Disabled : ""}`}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export function FrequencyDemo() {
  return (
    <div className={styles.Demo}>
      <Stack className={styles.Content} gap={2} sx={{ mb: 8 }}>
        <Typography variant="h1">Ship LLM apps faster</Typography>
        <Stack gap={1} sx={{ mb: 4 }}>
          <Stack direction="row" gap={1} alignItems="center">
            <img src={IconBenefit} alt="benefit" />
            <Typography>Build AI prototypes with NoCode</Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <img src={IconBenefit} alt="benefit" />
            <Typography>Evaluate and benchmark models and prompts</Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <img src={IconBenefit} alt="benefit" />
            <Typography>Faster prompt engineering with smart assistant</Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <img src={IconBenefit} alt="benefit" />
            <Typography>Unified workflow for product, engineering, and QA</Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <img src={IconBenefit} alt="benefit" />
            <Typography>Monitor LLM performance in production</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4">
            To customize your experience, we'd like
            <br /> to ask a few simple questions.
          </Typography>
        </Stack>
        <Typography>Step 1 / 4</Typography>
      </Stack>
    </div>
  );
}
