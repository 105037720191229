import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Layout from "components/layout/layout";
import Calendar, { CalendarDayHeader } from "./calendar";
import { useGetPersonalJournalQuery, GetPersonalJournalQueryVariables } from "generated/graphql";
import { getJournalEntryDescription } from "./journal-helper";
import { format, getDaysInMonth } from "date-fns";
import { useState } from "react";
import "./index.sass";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const Journal = () => {
  const currentDate = new Date();
  const [yearAndMonth, setYearAndMonth] = useState([currentDate.getFullYear(), currentDate.getMonth() + 1]);
  const [filterAlignment, setFilterAlignment] = React.useState("all");

  const cursorFormat = (param: string) => `olderThan|${param}-01T00:00:00.000Z`;
  // graphql data call
  const personalJournal = useGetPersonalJournalQuery({
    startAt: `${yearAndMonth[0]}-${yearAndMonth[1].toString().padStart(2, "0")}-01T00:00:00.000Z`,
    endAt: `${yearAndMonth[0]}-${yearAndMonth[1].toString().padStart(2, "0")}-${getDaysInMonth(
      new Date(yearAndMonth[0], yearAndMonth[1] - 1, 1)
    )}T23:59:59.999Z`,
    cursor: btoa(unescape(encodeURIComponent(cursorFormat(`${yearAndMonth[0]}-${yearAndMonth[1]}`)))),
    limit: 1000,
  } as GetPersonalJournalQueryVariables);

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setFilterAlignment(newAlignment);
  };

  const handlePagerClick = (event: React.MouseEvent<HTMLElement>, page: string) => {
    const [year, month] = yearAndMonth;

    if (page === "prev") {
      let nextYear = year;
      let nextMonth = month - 1;

      if (nextMonth === 0) {
        nextMonth = 12;
        nextYear = year - 1;
      }

      setYearAndMonth([nextYear, nextMonth]);
    } else {
      let nextYear = year;
      let nextMonth = month + 1;

      if (nextMonth === 13) {
        nextMonth = 1;
        nextYear = year + 1;
      }

      setYearAndMonth([nextYear, nextMonth]);
    }
  };

  const content =
    !personalJournal.isLoading && personalJournal.isSuccess
      ? getJournalEntryDescription(personalJournal.data.personalJournal?.personalJournalEntries.entries)
      : undefined;

  if (content && filterAlignment !== null && filterAlignment !== "all") {
    Object.keys(content).forEach((k) => {
      if (content && content[k].entries) {
        content[k].entries = content[k].entries?.filter((entry) => entry.type === filterAlignment);
        content && content[k].entries.length < 1 && delete content[k];
      }
    });
  }

  return (
    <Layout
      className="JournalPage"
      header={
        <div className="PageHeader">
          <Grid container className="PageHeaderContainer" alignItems="center" spacing={1}>
            <Grid item md={12} lg={4}>
              <Typography variant="h4" className="PageTitle">
                Personal Journal
              </Typography>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Stack gap={2}>
        <Paper className="JournalInfo" sx={{ p: 2 }} elevation={0}>
          🔒 All your personal data is only visible to you
        </Paper>

        <Paper className="" sx={{ p: 3, borderRadius: 6 }} elevation={0}>
          <Grid container className="" alignItems="center" spacing={2}>
            <Grid item md={12} lg={4}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <Typography variant="h5" className="JournalDate">
                  {`${format(new Date(yearAndMonth[0], yearAndMonth[1] - 1, 1), "LLLL")} ${yearAndMonth[0]}`}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& > *": {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
                    <Button size="small" onClick={(e) => handlePagerClick(e, "prev")}>
                      <NavigateBefore fontSize="small" />
                    </Button>
                    <Button size="small" onClick={(e) => handlePagerClick(e, "next")}>
                      <NavigateNext fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Stack>
            </Grid>

            <Grid item container md={12} lg={8}>
              <Stack direction="row" justifyContent="flex-end" className="JournalFilterContainer">
                <Typography variant="h2" className="JournalFilterLabel">
                  View:
                </Typography>

                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={filterAlignment}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="energy-level">🙂 Energy</ToggleButton>
                  <ToggleButton value="gratitude">❤️ Appreciation</ToggleButton>
                  <ToggleButton value="priority">🎯 Priority</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Calendar
                yearAndMonth={yearAndMonth}
                onYearAndMonthChange={setYearAndMonth}
                content={content}
                renderDay={(calendarDayObject: any) => (
                  <>
                    <CalendarDayHeader calendarDayObject={calendarDayObject} />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Layout>
  );
};

export default Journal;
