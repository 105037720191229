import Box from "@mui/material/Box";
import IconClose from "assets/v2/icon-close.svg";
import IconDashboard from "assets/v2/icon-dashboard.svg";
import RouteNames from "route_names";
import Stack from "@mui/material/Stack";
import styles from "./index.module.sass";
import Typography from "@mui/material/Typography";
import useCopy from "@react-hook/copy";
import { addModels } from "store/models_reducer";
import { createPrompt } from "store/prompts_reducer";
import { Divider, IconButton, Slide, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { EditorForm } from "./editor_form";
import { electronHeaderHeight, userfacingError } from "utils";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";
import { Headerify } from "components/layout/layout";
import { Messages } from "./messages";
import { PromptBox } from "./prompt_box";
import { selectAuth } from "store/auth_reducer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { usePromptEditorModel } from "./model";
import {
  PaginatedQuery,
  PromptType,
  useDoGenerateMutation,
  useGetPromptsQuery,
  Prompt,
  PromptField,
  Maybe,
  GptParamsInput,
  useGetModelsQuery,
  AiModelType,
  Character,
  useGetCharactersQuery,
  UserFacingError,
} from "generated/graphql";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useCachedAiModel,
  useIsElectron,
  useVisualHeight,
} from "hooks";

const NEW_PROMPT_CACHE_KEY = "new-prompt-cache-key";

function isRaw(fields?: Maybe<PromptField[]> | PromptField[] | undefined): boolean {
  return (
    !fields ||
    fields.length === 0 ||
    !fields.find((f) => {
      return !!(f.values ?? [])[0] || !!(f.intValues ?? [])[0] || !!(f.floatValues ?? [])[0];
    })
  );
}

export function appendResult(responses: Prompt[], newResponses: Prompt[]): Prompt[] {
  const set = new Set(responses.map((r) => r.id));
  const filtered = newResponses.filter((r) => !set.has(r.id));
  return [...filtered, ...responses];
}

export enum PromptEditorMode {
  guided = "guided",
  raw = "raw",
}

function NavHeader() {
  return (
    <Stack direction="row" className="PageHeader" alignItems="center">
      <Link to={RouteNames.Home}>
        <IconButton>
          <img src={IconDashboard} alt="home" />
        </IconButton>
      </Link>
      <Divider flexItem orientation="vertical" sx={{ ml: 1, mr: 2 }} />
      <Typography variant="h5">Chat</Typography>
    </Stack>
  );
}

const limit = 10;

function PromptEditor() {
  const { pathname, state } = useLocation();
  const locationState = state ?? {};

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { id } = useParams();

  const { currentAccount } = useAppSelector(selectAuth);
  const isGuest = !currentAccount || (currentAccount.isGuest ?? false);

  const isNew = pathname === RouteNames.NewPrompt;
  const [mode, setMode] = useState<PromptEditorMode>(currentAccount ? PromptEditorMode.raw : PromptEditorMode.guided);

  const [editingPrompt, setEditingPrompt] = useState<Prompt | undefined>();

  const model = usePromptEditorModel(editingPrompt);

  const {
    setGoal,
    setContent,
    setAudience,
    setPerspective,
    setContext,
    setFormat,
    setTone,
    rawPrompt,
    setRawPrompt,
    buildPrompt,
    toFields,
    toMap,
  } = model;
  const isElectron = useIsElectron();
  const editorOpen = mode === PromptEditorMode.guided;
  const prompt = editorOpen ? buildPrompt()?.trim() : rawPrompt;

  const [query, setQuery] = useState<PaginatedQuery>({ id, limit });
  const [cursor, setCursor] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(isNew ? true : false);

  const [busyPrompt, setBusyPrompt] = useState<string>();
  const generateMutation = useDoGenerateMutation();

  const [defaultModel, customModel] = useCachedAiModel();

  const promptQuery = useGetPromptsQuery({ query }, { enabled: !!id && !isNew });
  const [responses, setResponses] = useState<Prompt[]>([]);
  const previewRef = useRef<HTMLDivElement>();

  const [character, setCharacter] = useState<Character>();

  const modelsQuery = { limit: 100 } as PaginatedQuery;
  const modelsQueryResult = useGetModelsQuery({ query: modelsQuery });

  const [hasJumped, setHasJumped] = useState<boolean>(false);
  const { copied, copy, reset: resetCopied } = useCopy(prompt ?? "");
  const [message, setMessage] = useState<string>();
  const [promptHeight, setPromptHeight] = useState<number>();

  const headerRef = useRef<HTMLDivElement>();
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const _visualHeight = useVisualHeight();
  const visualHeight = isElectron ? _visualHeight - electronHeaderHeight : _visualHeight;

  const characterQuery = useGetCharactersQuery(
    { query: { id: locationState.characterId, limit: 1 } },
    { enabled: !!locationState.characterId }
  );

  useEffect(() => {
    if (!character && characterQuery.data?.characters?.characters?.length) {
      setCharacter(characterQuery.data.characters.characters[0]);
    }
  }, [character, characterQuery.data?.characters?.characters]);

  const [mobileShowLogin, setMobileShowResponse] = useState<boolean>(false);

  const jumpToBottom = useCallback((duration?: number) => {
    setTimeout(() => {
      if (previewRef.current) {
        const height = Math.ceil(previewRef.current.getBoundingClientRect().height);
        if (height < previewRef.current.scrollHeight) {
          previewRef.current.scrollTo(0, previewRef.current.scrollHeight);
        } else {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    }, duration ?? 1000);
  }, []);

  const toggleMode = useCallback(
    (specificMode?: PromptEditorMode) => {
      let finalMode = specificMode ?? PromptEditorMode.raw;
      if (mode === PromptEditorMode.guided) {
        finalMode = PromptEditorMode.raw;
      } else {
        finalMode = PromptEditorMode.guided;
      }

      if (finalMode === PromptEditorMode.raw) {
        // if raw prompt is null, copy value over
        if (!rawPrompt || rawPrompt === "") {
          const builtPrompt = buildPrompt()?.trim();
          if (builtPrompt) {
            setRawPrompt(builtPrompt ?? "");
          }
        }
      }

      setMode(finalMode);
      jumpToBottom(400);
    },
    [buildPrompt, jumpToBottom, mode, rawPrompt, setRawPrompt]
  );

  useHotkeys("ctrl+1", () => toggleMode(), { enabled: !mobile, enableOnFormTags: true }, [toggleMode]);

  useEffect(() => {
    if (modelsQueryResult.isFetched) {
      dispatch(addModels(modelsQueryResult?.data?.models));
    }
  }, [dispatch, modelsQueryResult?.data?.models, modelsQueryResult.isFetched]);

  useEffect(() => {
    if (hasJumped) {
      return;
    }
    if (responses.length > 0 && previewRef.current) {
      jumpToBottom();
      setHasJumped(true);
    }
  }, [hasJumped, jumpToBottom, responses.length]);

  // listen to header size change
  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries[0] && entries[0].target) {
        const headerRect = entries[0].target.getBoundingClientRect();
        setHeaderHeight(headerRect.height ?? 0);
      }
    });

    resizeObserver.observe(headerRef.current);
    const headerRect = headerRef?.current?.getBoundingClientRect();
    setHeaderHeight(headerRect?.height ?? 0);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    // react-query still returns cached result even if it's disabled. so we filter it here.
    if (isNew) {
      return;
    }

    if (promptQuery.isFetched) {
      const newCursor = promptQuery.data?.prompts?.cursor;
      const prompts = [...(promptQuery.data?.prompts?.prompts ?? [])];
      if (newCursor && newCursor !== cursor && prompts.length > 0) {
        // we loaded a new page. let's push the result
        const oldToNew = prompts.reverse();
        setResponses(appendResult(responses, oldToNew));
        // now it won't load again
        setCursor(newCursor);
      }

      if (prompts.length < limit) {
        setLoaded(true);
      }
    }
  }, [
    cursor,
    isNew,
    promptQuery.data?.prompts?.cursor,
    promptQuery.data?.prompts?.prompts,
    promptQuery.isFetched,
    responses,
  ]);

  // save prompts when users are not logged in.
  useEffect(() => {
    if (isNew && isGuest) {
      localStorage.setItem(NEW_PROMPT_CACHE_KEY, JSON.stringify(toMap()));
    }
  }, [isGuest, isNew, toMap]);

  useEffect(() => {
    if (!isGuest) {
      const item = localStorage.getItem(NEW_PROMPT_CACHE_KEY);
      if (item) {
        try {
          const fields = JSON.parse(item);
          setRawPrompt(fields.rawPrompt ?? "");
          setGoal(fields.goal ?? "");
          setContent(fields.content ?? "");
          setPerspective(fields.perspective ?? "");
          setAudience(fields.audience ?? "");
          setContext(fields.context ?? "");
          setTone(fields.tone ?? "");
          setFormat(fields.format ?? "");
        } catch (error) {
        } finally {
          localStorage.removeItem(NEW_PROMPT_CACHE_KEY);
        }
      }
    }
  }, [isGuest, setAudience, setContent, setContext, setFormat, setGoal, setPerspective, setRawPrompt, setTone]);

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setQuery({ ...query, cursor });
      }
    }
  }, [cursor, loaded, query]);

  const copyPrompt = useCallback(() => {
    if (!prompt) {
      return;
    }

    copy();
    setTimeout(() => {
      resetCopied();
    }, 3000);
  }, [copy, prompt, resetCopied]);

  const responsesToMessages = useCallback((): { text: string; role: "user" | "system" | "assistant" }[] => {
    const currentCharacter = character?.id;

    const messages = [...responses].reverse().reduce((array, r) => {
      if (!!r.texts && r.texts.length > 0) {
        if (!r.character?.id || r.character?.id === currentCharacter) {
          array.push({ text: r.texts[0], role: "assistant" });
        } else {
          // this result is generated as another character, we treat it as user for context, so each character is consistent
          array.push({ text: `${r.character?.name} said: ${r.texts[0]}`, role: "user" });
        }
        array.push({ text: r.userPrompt, role: "user" });
      }
      return array;
    }, [] as { text: string; role: "user" | "system" | "assistant" }[]);
    return messages.reverse();
  }, [character, responses]);

  const editPrompt = useCallback(
    (prompt: Prompt) => {
      setEditingPrompt(prompt);
      setRawPrompt(prompt.userPrompt);

      // it's awkward to edit prompt in guided mode
      if (isRaw(prompt.fields) && editorOpen) {
        toggleMode(PromptEditorMode.raw);
      }
    },
    [editorOpen, setRawPrompt, toggleMode]
  );

  const generate = useCallback(
    async (presetPrompt?: string) => {
      const finalPrompt = presetPrompt ?? prompt;

      if (!!busyPrompt || !finalPrompt || finalPrompt === "") {
        return;
      }

      if (isGuest) {
        setMessage("Please sign in to run the prompt chatGPT");
        return;
      }

      // on mobile, upon submission, we switch to message mode for response
      if (mobile && mode !== PromptEditorMode.raw) {
        toggleMode(PromptEditorMode.raw);
      }

      try {
        jumpToBottom();
        setRawPrompt("");
        setBusyPrompt(finalPrompt);

        const result = await generateMutation.mutateAsync({
          input: {
            id,
            type: PromptType.Text,
            model: !!customModel ? customModel.aiModelType : (defaultModel as AiModelType),
            modelID: !!customModel ? customModel.id : undefined,
            prompt: finalPrompt,
            fields: mode === PromptEditorMode.raw ? [] : toFields().fields,
            gpt: { messages: responsesToMessages() } as GptParamsInput,
            characterID: character?.id,
            visitLinks: true,
          },
        });

        // clear
        localStorage.removeItem(NEW_PROMPT_CACHE_KEY);

        if (!id && result?.generate?.prompt?.id) {
          setQuery({ ...query, id: result.generate.prompt.id });
          // add a new parent prompt to the redux store
          const promptObj = { ...result.generate.prompt, messageCount: 1 };
          dispatch(createPrompt(promptObj));
          navigate(
            generatePath(RouteNames.PromptEditor, { id: result.generate.prompt.id, characterId: character?.id })
          );
        }

        if (result && result.generate.prompt?.texts) {
          responses.push(result.generate.prompt);
        }
      } catch (error) {
        if (error instanceof Error) {
          setMessage(`Failed to generate prompt. ${userfacingError(error.message as UserFacingError)}`);
        }
        // put the input back
        setRawPrompt(finalPrompt);
      } finally {
        setBusyPrompt(undefined);
        jumpToBottom();
      }
    },
    [
      busyPrompt,
      character,
      customModel,
      defaultModel,
      dispatch,
      generateMutation,
      id,
      isGuest,
      jumpToBottom,
      mobile,
      mode,
      navigate,
      prompt,
      query,
      responses,
      responsesToMessages,
      setRawPrompt,
      toFields,
      toggleMode,
    ]
  );

  const onRawPromptChange = useCallback(
    (value: string) => {
      setRawPrompt(value);
    },
    [setRawPrompt]
  );

  function deletePrompt(promptId: string) {
    const newResponses = responses.filter((r) => r.id !== promptId);
    setResponses(newResponses);
  }

  const promptBox = useMemo(
    () => (
      <PromptBox
        key="RawPromptBox"
        prompt={prompt}
        editorOpen={editorOpen}
        isRaw={true}
        editingPrompt={editingPrompt}
        rawPrompt={rawPrompt}
        toggleMode={toggleMode}
        copyPrompt={copyPrompt}
        copied={copied}
        busy={!!busyPrompt}
        generate={generate}
        onRawPromptChange={onRawPromptChange}
        isGuest={isGuest}
        mobile={mobile}
        hasResponse={responses.length > 0}
        setMobileShowResponse={() => setMobileShowResponse(true)}
        onHeightChange={setPromptHeight}
        character={character}
        setCharacter={setCharacter}
      />
    ),
    [
      busyPrompt,
      character,
      copied,
      copyPrompt,
      editingPrompt,
      editorOpen,
      generate,
      isGuest,
      mobile,
      onRawPromptChange,
      prompt,
      rawPrompt,
      responses.length,
      toggleMode,
    ]
  );

  return (
    <Headerify>
      <div className={`AppFrame ${isElectron ? "Electron" : ""}`}>
        <Box
          className={`${styles.PromptEditor} ${mobile ? styles.Mobile : ""}  ${
            editorOpen ? styles.GuideMode : styles.RawMode
          } ${mobileShowLogin ? styles.MobileShowResponse : ""}`}
        >
          <Stack
            direction="row"
            className={styles.SectionHeader}
            alignItems="center"
            justifyContent="space-between"
            ref={headerRef}
          >
            <NavHeader />
            <Stack direction="row" alignItems="center" gap={2}>
              {!mobile && <Typography variant="h5">Responses</Typography>}
            </Stack>
          </Stack>

          <Stack
            className={styles.ColumnWrapper}
            position="relative"
            direction="row"
            alignItems="flex-start"
            justifySelf="stretch"
          >
            <Slide direction="right" in={editorOpen} mountOnEnter unmountOnExit>
              <Stack
                className={`${styles.Editor} Editor`}
                style={{ paddingTop: mobileShowLogin ? 0 : headerHeight, height: mobile ? "undefined" : visualHeight }}
              >
                <EditorForm model={model} character={character} setCharacter={setCharacter} />

                {editorOpen && (
                  <PromptBox
                    key="EditorPromptBox"
                    prompt={prompt}
                    editorOpen={editorOpen}
                    isRaw={false}
                    editingPrompt={editingPrompt}
                    rawPrompt={rawPrompt}
                    toggleMode={toggleMode}
                    copyPrompt={copyPrompt}
                    copied={copied}
                    busy={!!busyPrompt}
                    generate={generate}
                    onRawPromptChange={onRawPromptChange}
                    isGuest={isGuest}
                    mobile={mobile}
                    hasResponse={responses.length > 0}
                    setMobileShowResponse={() => setMobileShowResponse(true)}
                    onHeightChange={setPromptHeight}
                    character={character}
                    setCharacter={setCharacter}
                  />
                )}
              </Stack>
            </Slide>

            <Stack
              className={`${styles.Preview} Preview`}
              style={{
                width: "100%",
                overflow: !mobile && editorOpen ? "auto" : undefined,
                height: !mobile && editorOpen ? visualHeight : undefined,
              }}
              ref={previewRef}
            >
              {mobileShowLogin && (
                <IconButton sx={{ position: "fixed", top: 16, right: 16 }} onClick={() => setMobileShowResponse(false)}>
                  <img src={IconClose} alt="close" />
                </IconButton>
              )}

              {!(mobile && editorOpen) && (
                <Messages
                  style={{
                    paddingTop: mobileShowLogin || editorOpen ? 0 : headerHeight,
                    paddingBottom: mobileShowLogin || editorOpen ? 0 : promptHeight ?? 0,
                  }}
                  mode={mode}
                  busyPrompt={busyPrompt}
                  responses={responses}
                  editPrompt={editPrompt}
                  loaded={loaded}
                  loadMore={loadMore}
                  isNew={isNew}
                  isGuest={isGuest}
                  deletePrompt={deletePrompt}
                  mobile={mobile}
                />
              )}

              {promptBox}
            </Stack>
          </Stack>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!!message}
            onClose={() => setMessage(undefined)}
            message={message}
            key="bottom-center"
            autoHideDuration={3000}
          />
        </Box>
      </div>
    </Headerify>
  );
}

export default PromptEditor;
