const DEFAULT_SERVER_HOST = "https://api.gists.ai";
const DEFAULT_WEB_HOST = "https://app.gists.ai";
const DEFAULT_SLACK_CLIENT_ID = "1468056276434.1802823318660";
const DEFAULT_SLACK_APP_ID = "A01PLQ79CKE";
const DEFAULT_GOOGLE_CLIENT_ID = "314625834481-l1evpobkc1usjqb0ok9377vmfho48d13.apps.googleusercontent.com";

const variables = {
  SERVER_HOST: process.env.REACT_APP_SERVER_HOST ?? DEFAULT_SERVER_HOST,
  WEB_HOST:
    process.env.REACT_APP_WEB_HOST ??
    (window.location.protocol === "file:" ? DEFAULT_WEB_HOST : window.location.protocol + "//" + window.location.host),
  SLACK_CLIENT_ID: process.env.REACT_APP_SLACK_CLIENT_ID ?? DEFAULT_SLACK_CLIENT_ID,
  SLACK_APP_ID: process.env.REACT_APP_SLACK_APP_ID ?? DEFAULT_SLACK_APP_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? DEFAULT_GOOGLE_CLIENT_ID,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default variables;
