import Button from "@mui/material/Button";
import IconBenefit from "assets/v2/icon-benefit.svg";
import Stack from "@mui/material/Stack";
import { selectModels } from "store/models_reducer";
import { useAppSelector } from "hooks";

export function ModelOptions({ model, onChange }: { model: string; onChange: (model: string) => void }) {
  const { modelLabels } = useAppSelector(selectModels);
  return (
    <Stack sx={{ p: 2 }}>
      {Object.keys(modelLabels).map((m) => (
        <Button sx={{ justifyContent: "flex-start" }} onClick={() => onChange(m)} key={m}>
          {`${modelLabels[m]}`}&nbsp;{model === m && <img src={IconBenefit} alt="Selected" height={20} />}
        </Button>
      ))}
    </Stack>
  );
}

export function CharacterOptions() {
  return (
    <Stack direction="row" sx={{ p: 2 }}>
      hello
    </Stack>
  );
}
