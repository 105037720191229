import React from "react";
import gistsLogo from "assets/v2/gists-logo.svg";

function Branding({ white }: { white?: boolean }) {
  return (
    <div className="Branding">
      {white ? (
        <img className="logo" src={gistsLogo} alt="logo" style={{ filter: "invert()" }} />
      ) : (
        <img className="logo" src={gistsLogo} alt="logo" />
      )}
    </div>
  );
}
export default Branding;
