import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { EditorModel } from "./model";
import styles from "./index.module.sass";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppSelector, useCachedAiModel } from "hooks";
import MenuItem from "@mui/material/MenuItem";
import { selectModels } from "store/models_reducer";
import { CharacterPicker } from "./character_picker";
import { Character } from "generated/graphql";

export function EditorForm({
  model,
  character,
  setCharacter,
}: {
  model: EditorModel;
  character?: Character | null;
  setCharacter: (character: Character) => void;
}) {
  const { modelLabels } = useAppSelector(selectModels);
  const [defaultModel, customModel, setDefaultModel] = useCachedAiModel();

  function handleChange(event: SelectChangeEvent<string | null | "">) {
    try {
      if (event.target.value === "") {
        setDefaultModel("");
        return;
      }

      setDefaultModel(event.target.value ?? "");
    } catch {
      setDefaultModel("");
    }
  }

  const safeModelLabels = { [defaultModel]: "Loading custom model...", ...modelLabels };
  const isCustomModel = !!customModel;

  return (
    <Stack className={styles.EditorForm} gap={3} sx={{ overflowY: "auto", px: 3, pt: 1, pb: 3 }} flex={1}>
      <Stack gap={2} sx={{ mt: 2 }}>
        <Typography variant="h6">Basics</Typography>
        <Select size="small" labelId="model-picker-select-label" value={defaultModel} onChange={handleChange} required>
          <MenuItem disabled value="">
            Model (required)
          </MenuItem>
          {Object.keys(safeModelLabels).map((a: string) => (
            <MenuItem selected={a === defaultModel} key={a} value={a}>
              {modelLabels[a]}
            </MenuItem>
          ))}
        </Select>

        {!isCustomModel && <CharacterPicker character={character} onChange={setCharacter} />}

        <TextField
          required
          id="goal"
          label="What do you want to accomplish?"
          onChange={model.onGoalChange}
          defaultValue={model.goal}
          size="small"
          autoFocus
          autoComplete="off"
        />
        <TextField
          id="content"
          label="Is there an outline?"
          onChange={model.onContentChange}
          defaultValue={model.content}
          size="small"
          multiline
          minRows={2}
          autoComplete="off"
        />
      </Stack>

      <Stack gap={2}>
        <Typography variant="h6">Modifier - situation</Typography>
        <Stack gap={1}>
          <TextField
            id="audience"
            label="Who is the audience?"
            onChange={model.onAudienceChange}
            defaultValue={model.audience}
            size="small"
            autoComplete="off"
          />
        </Stack>
        <Stack gap={1}>
          <TextField
            id="perspective"
            label="Whose perspective should the result be from?"
            onChange={model.onPerspectiveChange}
            defaultValue={model.perspective}
            size="medium"
            autoComplete="off"
          />
        </Stack>

        <TextField
          id="context"
          label="Any prior context?"
          onChange={model.onContextChange}
          defaultValue={model.context}
          size="small"
          multiline
          minRows={2}
          autoComplete="off"
        />
      </Stack>

      <Stack gap={2}>
        <Typography variant="h6">Modifier - output</Typography>
        <Stack gap={1}>
          <TextField
            id="tone"
            label="What tones and styles would you like to use?"
            onChange={model.onToneChange}
            defaultValue={model.tone}
            size="small"
            autoComplete="off"
          />
        </Stack>
        <TextField
          id="format"
          label="What should the output format be?"
          onChange={model.onFormatChange}
          defaultValue={model.format}
          size="small"
          multiline
          minRows={2}
          autoComplete="off"
        />
      </Stack>
    </Stack>
  );
}
