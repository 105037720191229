import { Box, Button, Chip, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import IconAi from "assets/v2/icon-ai.svg";
import IconSolidOkay from "assets/v2/icon-solid-ok.svg";
import { AiModel, PaginatedQuery, useDoDeleteModelsMutation, useGetModelsQuery } from "generated/graphql";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "react-spinners/ScaleLoader";
import { selectCurrentUser } from "store/current_user_reducer";
import { addModels, removeModels, selectModels } from "store/models_reducer";
import IconMore from "assets/v2/icon-more.svg";
import { generatePath, Link } from "react-router-dom";
import "./index.sass";
import CreateModelModal from "./create_model_modal";
import RouteNames from "route_names";

function ModelCardPlaceholder() {
  return (
    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
      <Box className="ModelCard" sx={{ p: 2 }} style={{ borderRadius: 6 }}>
        <Typography variant="h1">
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
      </Box>
    </Grid>
  );
}

function ModelCard({ model }: { model: AiModel }) {
  const dispatch = useAppDispatch();
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const deleteMutation = useDoDeleteModelsMutation();

  async function deleteModel() {
    dispatch(removeModels([model.id]));
    try {
      await deleteMutation.mutateAsync({
        ids: [model.id],
      });
    } catch (error) {}
  }

  return (
    <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
      <Box className="ModelCard" sx={{ p: 2 }} style={{ borderRadius: 6 }}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ height: "100%" }}>
          <Link to={generatePath(RouteNames.ModelEditor, { id: model.id })}>
            <Stack
              sx={{ px: 1.5, py: 1, height: "100%" }}
              alignItems="flex-start"
              justifyContent="space-between"
              gap={1}
            >
              <Box flex={1}>{model.name}</Box>
              <Chip
                icon={model.isReady ? <img src={IconSolidOkay} alt="Ready" style={{ height: 16 }} /> : undefined}
                color={model.isReady ? "success" : "default"}
                label={model.isReady ? "Ready to use" : "Draft"}
              />
            </Stack>
          </Link>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
              <img src={IconMore} alt="Card options" style={{ height: 16 }} />
            </IconButton>
            <Menu
              elevation={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={showCardMenu}
              anchorEl={el.current}
              onClose={() => setShowCardMenu(false)}
            >
              {/* <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                }}
              >
                <span>Share</span>
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                  setShowConfirmDeleteMenu(true);
                }}
              >
                <span>Delete</span>
              </MenuItem>
            </Menu>
            <Menu
              elevation={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={showConfirmDeleteMenu}
              anchorEl={el.current}
              onClose={() => setShowConfirmDeleteMenu(false)}
            >
              <MenuItem
                onClick={() => {
                  deleteModel();
                  setShowConfirmDeleteMenu(false);
                }}
              >
                <span>Yes, delete!</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowConfirmDeleteMenu(false);
                }}
              >
                <span>No, cancel</span>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}

function NewModel({ open }: { open: () => void }) {
  return (
    <Stack alignItems="center" flex={1} gap={4} sx={{ mt: 4 }}>
      <img src="https://staticgists.com/astronaut-train-v1.png" alt="New model" style={{ width: 240 }} />
      <Typography textAlign="center">
        Get 2x better results by training GPT-3 with your own data and content.
      </Typography>
      <Typography textAlign="center">
        No models has been created yet.
        <br />
        Create a new model and it'll show up here.
      </Typography>
      <Button onClick={open} variant="outlined" size="medium">
        <img src={IconAi} alt="New model" height={20} />
        &nbsp;New Model
      </Button>
    </Stack>
  );
}

export default function Models() {
  const dispatch = useAppDispatch();
  const { models: responses, cursor, loaded, limit } = useAppSelector(selectModels);
  const [query, setQuery] = useState<PaginatedQuery>({ limit });
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const modelsQuery = useGetModelsQuery({ query });
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (modelsQuery.isFetched) {
      dispatch(addModels(modelsQuery?.data?.models));
    }
  }, [dispatch, modelsQuery?.data?.models, modelsQuery.isFetched]);

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setQuery({ ...query, cursor });
      }
    }
  }, [cursor, loaded, query]);

  return (
    <Layout
      className="Models"
      workspace
      header={
        <Stack direction="row" className="PageHeader" justifyContent="space-between">
          <Typography variant="h4" className="PageTitle">
            Models
          </Typography>
          <Button onClick={() => setCreateModalOpen(true)} variant="outlined" size="medium">
            <img src={IconAi} alt="New model" height={14} />
            &nbsp;&nbsp;New Model
          </Button>
        </Stack>
      }
      key={`models-${currentUser?.currentUser?.id}`}
    >
      <Stack alignItems="center">
        <Grid
          spacing={1.5}
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          justifyContent="stretch"
          alignItems="stretch"
          sx={{ position: "relative" }}
        >
          {modelsQuery.isLoading && responses.length === 0 ? (
            Array(12)
              .fill(0)
              .map((_, i) => <ModelCardPlaceholder key={i} />)
          ) : responses.length > 0 ? (
            responses.map((r: AiModel) => <ModelCard key={r.id} model={r} />)
          ) : (
            <NewModel
              open={() => {
                setCreateModalOpen(true);
              }}
            />
          )}
        </Grid>

        {!loaded && !modelsQuery.isLoading && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Button onClick={loadMore} size="small">
              Load more...
            </Button>
          </Stack>
        )}

        {loaded && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {responses.length} Models
          </Stack>
        )}

        {modelsQuery.isLoading && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        <CreateModelModal
          open={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
        />
      </Stack>
    </Layout>
  );
}
