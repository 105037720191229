import { Grid, Tooltip } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import { Link, Outlet } from "react-router-dom";
import { EnterRoomContext } from "app";
import { ActivityType, HomeActivityCard, ProfilePicture } from "generated/graphql";
import { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { selectUI } from "store/ui_reducer";
import "./app_home.sass";
import DailyCalendar from "./daily_calendar";
import DailySummary from "./daily_summary";
import {
  formatSocialProof,
  getBadge,
  getButtonText,
  getCardLink,
  getDefaultTitle,
  getDuration,
  getGradient,
} from "./home_card_helpers";
import Layout from "./layout/layout";
import ProfilePictures from "./profile_pictures";
import { useAppSelector } from "hooks";

export const GlobalKeyAppHome = "app-home";

function ActivityCard({
  link,
  header,
  title,
  badge,
  subtitle,
  button,
  images,
  socialProof,
  loading,
  gradient,
  duration,
}: {
  link: string;
  header?: string | null;
  title: string;
  badge: string;
  subtitle?: string | null;
  button: string;
  images?: ProfilePicture[] | null;
  socialProof: string;
  loading: boolean;
  gradient: string;
  duration?: string;
}) {
  let backgroundImage = `url(${header})`;
  if (gradient !== "") {
    backgroundImage = `${gradient}, url(${header})`;
  }

  return (
    <Link to={link} className="Room">
      <Card className="RoomCard" style={loading ? { background: "#c7ccd6" } : { backgroundImage }}>
        {loading ? (
          <Skeleton containerClassName="RoomCard__badge" width="30%" height={40} borderRadius="20px" />
        ) : (
          <img className="RoomCard__badge" src={badge} alt={title} />
        )}
        <CardContent className="RoomCard__bottom" sx={{ p: 2.5, pt: 2 }}>
          {(images?.length || loading) && (
            <Tooltip placement="right" title={socialProof}>
              <div className="RoomCard__socialProof">
                {images ? <ProfilePictures profilePictures={images} /> : <Skeleton circle width={32} height={32} />}
              </div>
            </Tooltip>
          )}
          <Typography gutterBottom variant="body2" sx={{ color: "white" }}>
            {loading ? <Skeleton count={1} /> : duration}
          </Typography>
          {!loading && subtitle && (
            <Typography gutterBottom variant="subtitle2">
              {subtitle}
            </Typography>
          )}
          {loading ? (
            <Skeleton height={44} borderRadius={24} />
          ) : (
            <Button size="large" variant="contained" className="RoomCard__cta" sx={{ mt: 2 }} fullWidth>
              {button}
            </Button>
          )}
        </CardContent>
      </Card>
    </Link>
  );
}

function AppHome() {
  const ui = useAppSelector(selectUI);
  const [width, setWidth] = useState<number>(300);
  const calendarColumnRef = useRef<HTMLDivElement | null>(null);
  const playEnterRoom = useContext(EnterRoomContext);
  const loading = !ui.homeActivitCards;

  const defaultCards = ["DailyClass", "Breath", "EyeBreak"].map((i) => {
    return { action: i as ActivityType, title: i, header: i };
  }) as HomeActivityCard[];

  // make sure the fixed column width always matches parent
  useEffect(() => {
    function handleResize() {
      const width = calendarColumnRef?.current?.getBoundingClientRect().width ?? 300;
      setWidth(width);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  return (
    <Layout className="AppHome" oldMenu>
      <Grid container justifyContent="stretch" alignItems="stretch" sx={{ position: "relative" }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3} ref={calendarColumnRef}>
          <DailyCalendar width={width} />
        </Grid>
        <Grid item xs={3} sm={6} md={8} lg={9} xl={9} sx={{ p: 4 }}>
          <DailySummary />
          <h3 className="PageSubtitle">Take a healthy break now</h3>
          <Grid container className="Rooms" spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            {(ui.homeActivitCards ?? defaultCards).map((card) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                key={card.action}
                onClick={playEnterRoom?.play}
                alignContent="stretch"
                justifyContent="stretch"
                sx={{ display: "flex" }}
              >
                <ActivityCard
                  loading={loading}
                  link={getCardLink(card.action)}
                  header={card.header}
                  title={getDefaultTitle(card.action)}
                  badge={getBadge(card.action)}
                  button={getButtonText(card.action)}
                  subtitle={card?.subtitle}
                  images={card.socialProof?.profilePictures ?? []}
                  socialProof={formatSocialProof(card.action, card.socialProof?.profilePictures?.length)}
                  gradient={getGradient(card.action)}
                  duration={getDuration(card.action)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Outlet />
    </Layout>
  );
}

export default AppHome;
