// export const RBACPermissionFormat = "%s:%s";

export const RBAC = {
  // Roles
  RoleSuperAdmin: "super-admin",
  RoleAdmin: "admin",
  RoleManager: "manager",
  RoleTeamManager: "team-manager",
  RoleUser: "user",
  // Scopes
  Scope_SuperAdmin: "super-admin",
  Scope_Dashboard: "dashboard",
  Scope_Account: "account",
  Scope_Challenges: "challenges",
  Scope_TeamMembers: "team-members",
  Scope_Invoices: "invoices",
  Scope_Cards: "cards",
  Scope_Teams: "teams",
  // Actions
  Permission_View: "view",
  Permission_Create: "create",
  Permission_Read: "read",
  Permission_Update: "update",
  Permission_Delete: "delete",
  Permission_List: "list",
  Permission_Upgrade: "upgrade",
  Permission_Downgrade: "downgrade",
  Permission_Assign: "assign",
  Permission_Remove: "remove",
  Permission_Team: "team",
  Permission_Self: "self",
  Permission_Launch: "launch",
  Permission_Results: "results",
};

export function buildPermissionFormat(scope: string, action: string): string {
  return `${scope}:${action}`;
}
