import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/system/Box/Box";
import { useNavigate } from "react-router-dom";
import Layout from "components/layout/layout";
import React, { useEffect } from "react";
import SlackIcon from "assets/slack-icon.svg";

import RouteNames from "route_names";
import { selectAuth } from "store/auth_reducer";
import "./index.sass";
import { useAppSelector } from "hooks";

const TeamInstall = () => {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);

  useEffect(() => {
    if (auth.currentAccount?.teamID) {
      navigate(RouteNames.Team);
    }
  }, [auth.currentAccount?.teamID, navigate]);

  return (
    <Layout
      className="TeamPage"
      header={
        <div className="PageHeader">
          <Grid container className="PageHeaderContainer" alignItems="center" spacing={1}>
            <Grid item md={12} lg={4}>
              <Typography variant="h4" className="PageTitle">
                Cuely works better with teams
              </Typography>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Box sx={{ mb: 2 }}>Is your team on Slack? Install our Slack integration so you can use GPT3 within Slack.</Box>
      <Button
        href="https://cuelyteam.slack.com/apps/A01PLQ79CKE-cuely?tab=more_info"
        variant="contained"
        size="large"
        target="_blank"
        startIcon={<img src={SlackIcon} alt="Go to Slack app store" style={{ height: 18 }} />}
      >
        <span>Install Cuely Slack app</span>
      </Button>
    </Layout>
  );
};

export default TeamInstall;
