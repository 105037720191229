import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentCompany } from "generated/graphql";
import { RootState } from "./store";

export interface CurrentCompanyState {
  initialized: boolean;
  currentCompany?: CurrentCompany;
}

const initialState: CurrentCompanyState = { initialized: false };

export const currentCompanySlice = createSlice({
  name: "currentCompany",
  initialState,
  reducers: {
    initialized: (state, action: PayloadAction<CurrentCompany>) => {
      state.initialized = true;
      state.currentCompany = action.payload;
      return state;
    },
    clearCurrentCompany: (state) => {
      state.initialized = false;
      state.currentCompany = undefined;
      return state;
    },
    updateCurrentCompany: (state, action: PayloadAction<CurrentCompany>) => {
      state.initialized = true;
      state.currentCompany = action.payload;
      return state;
    },
    companyNameUpdated: (state, action: PayloadAction<string>) => {
      if (state.currentCompany) {
        state.currentCompany.name = action.payload;
      }
      return state;
    },
  },
});

export const { initialized, updateCurrentCompany, clearCurrentCompany, companyNameUpdated } =
  currentCompanySlice.actions;

export const selectCurrentCompany = (state: RootState) => state.currentCompany;

export default currentCompanySlice.reducer;
