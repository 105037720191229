import PremiumMetricChart from "components/chart/premium_chart";
import { Box, Grid } from "@mui/material";
import { chartBreakdownByParticipantsColors, chartBreakdownByTypeColors } from "components/chart/colors";
import { chartBreakdownByParticipantsLabels, chartBreakdownByTypeLabels } from "components/chart/labels";
import { DoughnutTooltipCallbacks } from "components/chart/helpers";
import { factoryDefaultData } from "components/chart/types";
import { PeriodLimit, periodRange, useCanAccessPersonalDashboard } from "utils";
import { useState } from "react";
import {
  GetPersonalMeetingBreakdownQueryVariables,
  TimePeriod,
  useGetPersonalMeetingBreakdownQuery,
} from "generated/graphql";

const chartBreakdownByParticipans = "chart-breakdown-by-participans",
  chartBreakdownByType = "chart-breakdown-by-type";

const PersonalMeetingBreakdown = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  const tooltipTitlePrefix = "Type: ",
    tooltipLabelPrefix = "Hours: ";
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);
  const [activeChart, SetActiveChart] = useState<string | undefined>(chartBreakdownByParticipans);

  const canAccessPersonalDashboard = useCanAccessPersonalDashboard();

  // GraphQL data call
  const personalEnergyBreakdown = useGetPersonalMeetingBreakdownQuery(
    {
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
      frequency,
    } as GetPersonalMeetingBreakdownQueryVariables,
    { enabled: canAccessPersonalDashboard }
  );

  // Chart control handlers

  // Chart props and variables
  const type = "doughnut";
  let title = "Meeting Breakdown",
    subtitleBreakdownByParticipants: string | null | undefined = "Meeting type breakdown",
    subtitleBreakdownByType: string | null | undefined = "Meeting type breakdown",
    chartDataBreakdownByParticipants = factoryDefaultData(),
    chartDataBreakdownByType = factoryDefaultData();

  if (!personalEnergyBreakdown.isLoading && personalEnergyBreakdown.isSuccess) {
    const rawDataByParticipants = personalEnergyBreakdown.data?.personalDashboard?.meetingBreakdown?.stats[0];
    const rawDataByTypes = personalEnergyBreakdown.data?.personalDashboard?.meetingBreakdown?.stats[1];
    if (rawDataByParticipants?.values.length && rawDataByTypes?.values.length) {
      subtitleBreakdownByParticipants = rawDataByParticipants?.name;
      chartDataBreakdownByParticipants = {
        labels: rawDataByParticipants?.values?.map((f) => chartBreakdownByParticipantsLabels[f.label]),
        datasets: [
          {
            label: rawDataByParticipants?.name ?? title,
            data: rawDataByParticipants?.values?.map((f) => f.value),
            backgroundColor: rawDataByParticipants?.values?.map((f) => chartBreakdownByParticipantsColors[f.label]),
          },
        ],
      };
      subtitleBreakdownByType = rawDataByTypes?.name;
      chartDataBreakdownByType = {
        labels: rawDataByTypes?.values?.map((f) => chartBreakdownByTypeLabels[f.label]),
        datasets: [
          {
            label: rawDataByTypes?.name ?? title,
            data: rawDataByTypes?.values?.map((f) => f.value),
            backgroundColor: rawDataByTypes?.values?.map((f) => chartBreakdownByTypeColors[f.label]),
          },
        ],
      };
    }
  }
  const customActionsButtonHandlers = [
    {
      value: chartBreakdownByParticipans,
      label: "Number of participants",
      handler: (event: React.MouseEvent<HTMLElement>, value: any) => {
        SetActiveChart(chartBreakdownByParticipans);
      },
    },
    {
      value: chartBreakdownByType,
      label: "Recurring Type",
      handler: (event: React.MouseEvent<HTMLElement>, value: any) => {
        SetActiveChart(chartBreakdownByType);
      },
    },
  ];
  return (
    <Grid item xs={12} md={6} lg={6}>
      <Box className={`${activeChart === chartBreakdownByType && "HiddenChart"}`}>
        <PremiumMetricChart
          canAccess={canAccessPersonalDashboard}
          type={type}
          title={title}
          subtitle={subtitleBreakdownByParticipants}
          dataSuccess={personalEnergyBreakdown.isSuccess}
          dataLoading={personalEnergyBreakdown.isLoading}
          hasEnoughData={personalEnergyBreakdown.isSuccess} // for personal, always should have enough data
          data={chartDataBreakdownByParticipants}
          options={{
            plugins: {
              tooltip: {
                callbacks: DoughnutTooltipCallbacks(tooltipTitlePrefix, tooltipLabelPrefix),
              },
            },
          }}
          actionToBeTakenText="Turn some 1:1 meetings into a walking meeting to increase your energy and creativity"
          selectedCustomAction={activeChart}
          customActionsButtonHandlers={customActionsButtonHandlers}
        />
      </Box>
      <Box className={`${activeChart === chartBreakdownByParticipans && "HiddenChart"}`}>
        <PremiumMetricChart
          canAccess={canAccessPersonalDashboard}
          type={type}
          title={title}
          subtitle={subtitleBreakdownByType}
          dataSuccess={personalEnergyBreakdown.isSuccess}
          dataLoading={personalEnergyBreakdown.isLoading}
          hasEnoughData={personalEnergyBreakdown.isSuccess} // for personal, always should have enough data
          data={chartDataBreakdownByType}
          options={{
            // disabling animation on this chart, to avoid "growing" effect when changing chart type
            animation: false,
            plugins: {
              tooltip: {
                callbacks: DoughnutTooltipCallbacks(tooltipTitlePrefix, tooltipLabelPrefix),
              },
            },
          }}
          actionToBeTakenText="Do a time audit and see which recurring meetings may no longer be valuable to keep"
          selectedCustomAction={activeChart}
          customActionsButtonHandlers={customActionsButtonHandlers}
        />
      </Box>
    </Grid>
  );
};

export default PersonalMeetingBreakdown;
