import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectCurrentCompany, updateCurrentCompany } from "store/current_company_reducer";
import { Company, CurrentCompany, useGetCompaniesQuery } from "generated/graphql";
import "./index.sass";

const SelectCompany = ({ imSuperAdmin = false }: { imSuperAdmin: boolean }) => {
  const dispatch = useAppDispatch();
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const [companies, setCompanies] = React.useState<Company[]>([]);

  const gqlCompanies = useGetCompaniesQuery(undefined, { enabled: imSuperAdmin });
  if (imSuperAdmin && companies?.length === 0) {
    if (gqlCompanies.isSuccess) {
      setCompanies(gqlCompanies.data?.companies);
    }
  }

  if (!imSuperAdmin || companies?.length === 0 || currentCompany === undefined) {
    return <></>;
  }

  return (
    <Select
      value={currentCompany?.id}
      label="Company"
      className="CompanyPicker"
      onChange={(e) => {
        if (companies.length > 0) {
          const company = companies.find((c) => c.id === e.target.value);
          if (company)
            dispatch(
              updateCurrentCompany({
                ...company,
                ...({} as CurrentCompany),
              } as CurrentCompany)
            );
        }
      }}
    >
      {companies?.map((company) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectCompany;
