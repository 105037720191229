import { GoogleLoginResponse } from "models/io";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { AuthAccount } from "store/auth_reducer";
import { DefaultTimezone } from "utils";
import variables from "variables";

const queryCache = new QueryCache({
  onError: (error) => {
    console.error(error);
  },
  onSuccess: (data) => {},
});

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: { queries: { staleTime: 10 * 60 * 1000, refetchOnWindowFocus: false } },
});

export async function api(path: string, data?: any): Promise<Response> {
  const token = localStorage.getItem("token");
  if (!token) {
    return Promise.reject(new Error("Not authorized"));
  }
  const res = await fetch(`${variables.SERVER_HOST}${path}`, {
    method: data ? "post" : "get",
    headers: {
      "x-cuely-token": token,
      "x-gists-token": token,
    },
    body: data ? JSON.stringify(data) : undefined,
  });
  return res;
}

export async function sendLoginCode(email: string): Promise<string | undefined> {
  const timezone = DefaultTimezone;
  const res = await fetch(`${variables.SERVER_HOST}/auth/email/login`, {
    method: "post",
    body: JSON.stringify({ email, timezone }),
  });
  const json = await res.json();
  if (json.ok) {
    return json.requestID;
  }
}

export async function verifyLoginCode(email: string, requestID: string, code: string): Promise<AuthAccount[]> {
  const res = await fetch(`${variables.SERVER_HOST}/auth/email/verifyCode`, {
    method: "post",
    body: JSON.stringify({ email, requestID, code }),
  });
  const json = (await res.json()) as AuthAccount[];
  return json;
}

export async function acceptInviteCode(email: string, code: string): Promise<boolean> {
  try {
    const res = await fetch(`${variables.SERVER_HOST}/invite/accept`, {
      method: "post",
      body: JSON.stringify({ email, code }),
    });
    return res.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function verifyGoogleOAuthCode(payload: GoogleLoginResponse): Promise<AuthAccount[]> {
  const timezone = DefaultTimezone;
  payload.timezone = timezone;
  const res = await fetch(`${variables.SERVER_HOST}/google/oauth/verifyToken`, {
    method: "post",
    body: JSON.stringify(payload),
  });
  const json = (await res.json()) as AuthAccount[];
  return json;
}
