import { Button } from "@mui/material";
import Box from "@mui/system/Box";
import Stack from "@mui/system/Stack";
import { useIsWindows } from "hooks";
import IconClose from "assets/v2/icon-close.svg";
import IconMinimize from "assets/v2/icon-minimize.svg";
import IconFullScreen from "assets/v2/icon-fullscreen.svg";
import IconMainMenu from "assets/v2/icon-main-menu.svg";
import styles from "./layout/electron.module.sass";

declare global {
  interface Window {
    windowManagement: {
      openMenu: () => void;
      minimizeWindow: () => void;
      maximizeWindow: () => void;
      closeWindow: () => void;
    };
  }
}

export default function DraggableHeader({ className }: { className: string }) {
  const isWin = useIsWindows();
  return (
    <Stack direction="row" gap={1} className={`${className} ${isWin ? "" : styles.DragHeader}`}>
      {isWin && (
        <Button
          onClick={() => {
            window.windowManagement?.openMenu();
          }}
        >
          <img src={IconMainMenu} alt="Gists.ai" style={{ height: 16 }} />
        </Button>
      )}
      {isWin && <Box flex={1} className={styles.DragHeader}></Box>}
      {isWin && (
        <Stack direction="row">
          <Button
            onClick={() => {
              window.windowManagement?.minimizeWindow();
            }}
          >
            <img src={IconMinimize} alt="Gists.ai" style={{ height: 16 }} />
          </Button>
          <Button
            onClick={() => {
              window.windowManagement?.maximizeWindow();
            }}
          >
            <img src={IconFullScreen} alt="Gists.ai" style={{ height: 16 }} />
          </Button>
          <Button
            className={styles.CloseButton}
            onClick={() => {
              window.windowManagement?.closeWindow();
            }}
          >
            <img src={IconClose} alt="Gists.ai" style={{ height: 16 }} />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
