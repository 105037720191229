import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { invalidateTemplateQuery } from "./function";
import { Evaluator, Template, useDoUpdateTemplateMutation } from "generated/graphql";
import debounce from "lodash.debounce";
import { useAppDispatch } from "hooks";
import { DefaultEvalutors, evaluatorsChanged } from "store/eval_reducer";

function EvaluatorRow({
  row,
  onEvaluatorChanged,
  onSaveEvaluator,
}: {
  row: Evaluator;
  onEvaluatorChanged: (evaluator: Evaluator) => void;
  onSaveEvaluator: () => void;
}) {
  return (
    <TableRow sx={{ background: "white" }}>
      <TableCell scope="row" style={{ textTransform: "capitalize" }}>
        {row.type}
      </TableCell>
      <TableCell style={{ width: 90 }} align="right">
        <Checkbox
          size="small"
          checked={row.active}
          onChange={() => onEvaluatorChanged({ ...row, active: !row.active })}
          onBlur={() => onSaveEvaluator()}
        />
      </TableCell>
      {/* <TableCell style={{ width: "30%" }} align="right">
        <Checkbox
          size="small"
          checked={row.production}
          onChange={() => onEvaluatorChanged({ ...row, production: !row.production })}
          onBlur={() => onSaveEvaluator()}
        />
      </TableCell> */}
    </TableRow>
  );
}

export default function Evaluators({ template }: { template?: Template }) {
  const updateTemplateMutation = useDoUpdateTemplateMutation();
  const dispatch = useAppDispatch();

  // build a map of active evaluators
  const activeEvalutors = (template?.fields?.evaluators ?? []).reduce((acc, curr) => {
    if (!curr.templateID) {
      acc[curr.type] = curr;
    }
    return acc;
  }, {} as { [key: string]: Evaluator });

  const [defaultEvaluators, setDefaultEvaluators] = useState<Evaluator[]>(
    DefaultEvalutors.map((e) => activeEvalutors[e.type] ?? e)
  );

  const [customEvaluators, setCustomEvaluators] = useState<Evaluator[]>(
    (template?.fields?.evaluators ?? []).filter((e) => !!e.templateID)
  );

  // add custom evaluators (with template id) to the list
  const evaluators = [...defaultEvaluators, ...customEvaluators];

  function onEvaluatorChanged(evaluator: Evaluator) {
    if (evaluator.templateID) {
      const e = customEvaluators.map((e) => {
        if (e.templateID === evaluator.templateID) {
          return evaluator;
        }
        return e;
      });
      setCustomEvaluators(e);
      dispatch(evaluatorsChanged([...defaultEvaluators, ...e]));
    } else {
      const e = defaultEvaluators.map((e) => {
        if (e.type === evaluator.type) {
          return evaluator;
        }
        return e;
      });
      setDefaultEvaluators(e);
      dispatch(evaluatorsChanged([...e, ...customEvaluators]));
    }
  }

  const debounceOnSaveEvaluator = useMemo(
    () =>
      debounce(async (evaluators: Evaluator[]) => {
        if (!template) {
          return;
        }

        try {
          const result = await updateTemplateMutation.mutateAsync({
            input: {
              id: template.id,
              fields: {
                evaluators,
              },
            },
          });

          if (result?.updateTemplate) {
            await invalidateTemplateQuery(template.id);
          }
        } catch (error) {}
      }, 500),
    [template, updateTemplateMutation]
  );

  async function onSaveEvaluator() {
    debounceOnSaveEvaluator(evaluators);
  }

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        background: "#f6f7f8",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        overflow: "hidden",
      }}
    >
      <TableContainer style={{ height: "100%" }}>
        <Table size="small" stickyHeader sx={{ "& td": { px: 1 }, "& th": { px: 1 } }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell style={{ width: 90 }} align="right">
                Enabled
              </TableCell>
              {/* <TableCell style={{ width: "30%" }} align="right">
                Prod
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluators.map((e) => (
              <EvaluatorRow
                key={`evaluator-${e.type}${e.templateID ? e.templateID : ""}`}
                row={e}
                onEvaluatorChanged={onEvaluatorChanged}
                onSaveEvaluator={onSaveEvaluator}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
