import Box from "@mui/material/Box";
import IconClipboard from "assets/v2/icon-clipboard.svg";
import IconClose from "assets/v2/icon-close.svg";
import IconDashboard from "assets/v2/icon-dashboard.svg";
import IconGuideMode from "assets/v2/icon-guide-mode.svg";
import IconMenu from "assets/v2/icon-menu.svg";
import IconRawMode from "assets/v2/icon-raw-mode.svg";
import IconSend from "assets/v2/icon-send.svg";
import RouteNames from "route_names";
import Stack from "@mui/material/Stack";
import styles from "./index.module.sass";
import TextareaAutosize from "react-textarea-autosize";
import Typography from "@mui/material/Typography";
import useCopy from "@react-hook/copy";
import { createPrompt } from "store/prompts_reducer";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { Divider, IconButton, Snackbar, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";
import { Headerify } from "components/layout/layout";
import { Messages } from "./messages";
import { selectAuth } from "store/auth_reducer";
import { useAppDispatch, useAppNavigate, useAppSelector, useIsElectron } from "hooks";
import { usePromptEditorModel } from "./model";
import {
  PaginatedQuery,
  PromptType,
  useDoGenerateMutation,
  useGetPromptsQuery,
  Prompt,
  PromptField,
  Maybe,
} from "generated/graphql";

const NEW_PROMPT_CACHE_KEY = "new-prompt-cache-key";

function isRaw(fields?: Maybe<PromptField[]> | PromptField[] | undefined): boolean {
  return (
    !fields ||
    fields.length === 0 ||
    !fields.find((f) => {
      return !!(f.values ?? [])[0] || !!(f.intValues ?? [])[0] || !!(f.floatValues ?? [])[0];
    })
  );
}

export function appendResult(responses: Prompt[], newResponses: Prompt[]): Prompt[] {
  const set = new Set(responses.map((r) => r.id));
  const filtered = newResponses.filter((r) => !set.has(r.id));
  return [...filtered, ...responses];
}

export enum PromptEditorMode {
  guided = "guided",
  raw = "raw",
}

function NavHeader() {
  return (
    <Stack direction="row" className="PageHeader" alignItems="center">
      <Link to={RouteNames.Home}>
        <IconButton>
          <img src={IconDashboard} alt="home" />
        </IconButton>
      </Link>
      <Divider flexItem orientation="vertical" sx={{ ml: 1, mr: 2 }} />
      <Typography variant="h5">Prompt Editor for chatGPT</Typography>
    </Stack>
  );
}

function PromptBox({
  prompt,
  mode,
  toggleMode,
  copyPrompt,
  copied,
  busy,
  generate,
  onRawPromptChange,
  isGuest,
  setMobileShowResponse,
  onHeightChange,
}: {
  prompt: string;
  mode: PromptEditorMode;
  rawPrompt: string;
  editingPrompt?: Prompt | null;
  toggleMode: () => void;
  copyPrompt: () => void;
  copied: boolean;
  busy: boolean;
  generate: () => void;
  onRawPromptChange: (value: string) => void;
  isGuest: boolean;
  setMobileShowResponse: () => void;
  onHeightChange: (height: number) => void;
}) {
  const inputRef = createRef<HTMLTextAreaElement>();
  const boxRef = useRef<HTMLDivElement>();

  // const fallbackToRawPrompt = !!editingPrompt && prompt === "";
  // const displayPrompt = fallbackToRawPrompt ? rawPrompt : prompt;

  useEffect(() => {
    // initialze the prompt
    if (inputRef.current && inputRef.current?.value !== prompt) {
      inputRef.current.value = prompt;
    }
  }, [inputRef, prompt]);

  function onPromptChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (event.target.value) {
      onRawPromptChange(event.target.value);
    }
  }

  function onKey(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.shiftKey && (event.key === "Enter" || event.key === "Return")) {
      generate();
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      event.preventDefault();
      return false;
    }
  }

  return (
    <Box className={styles.PromptBoxWrapper} ref={boxRef}>
      <Stack className={styles.PromptBox} direction="row" gap={1} alignItems="center" justifySelf="flex-end">
        {isGuest ? (
          <IconButton onClick={setMobileShowResponse}>
            <img src={IconMenu} alt="Adjust mode" />
          </IconButton>
        ) : (
          <IconButton onClick={toggleMode}>
            <Tooltip
              placement="bottom"
              title={mode === PromptEditorMode.guided ? "Change to message mode" : "Change to guided mode"}
            >
              <img src={mode === PromptEditorMode.guided ? IconRawMode : IconGuideMode} alt="Adjust mode" />
            </Tooltip>
          </IconButton>
        )}
        {mode === PromptEditorMode.guided ? (
          <Box flex={1} className={styles.PromptPreview} style={{ whiteSpace: "pre-wrap" }}>
            {prompt === "" ? "Guided mode, use the input boxes above" : prompt}
          </Box>
        ) : (
          <TextareaAutosize
            cacheMeasurements
            ref={inputRef}
            className={styles.PromptPreview}
            onChange={onPromptChange}
            maxRows={12}
            onKeyDown={onKey}
            autoFocus
            placeholder="Start typing prompts. E.g. write an email..."
          />
        )}
        <IconButton disabled={!prompt || prompt === ""} onClick={copyPrompt}>
          <Tooltip placement="bottom" title={copied ? "Copied!" : "Copy to clipboard"}>
            <img src={IconClipboard} alt="clipboard" />
          </Tooltip>
        </IconButton>
        <IconButton disabled={!prompt || prompt === "" || busy} onClick={generate}>
          <Tooltip placement="bottom" title="Generate">
            <img src={IconSend} alt="Send" />
          </Tooltip>
        </IconButton>
      </Stack>
    </Box>
  );
}

const limit = 10;

function PromptEditor() {
  const location = useLocation();
  const isElectron = useIsElectron();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { id } = useParams();

  const { currentAccount } = useAppSelector(selectAuth);
  const isGuest = !currentAccount || (currentAccount.isGuest ?? false);

  const isNew = location.pathname === RouteNames.NewPrompt;
  const [mode, setMode] = useState<PromptEditorMode>(isNew ? PromptEditorMode.guided : PromptEditorMode.raw);

  const [editingPrompt, setEditingPrompt] = useState<Prompt | undefined>();

  const model = usePromptEditorModel(editingPrompt);
  const prompt = mode === PromptEditorMode.raw ? model.rawPrompt : model.buildPrompt()?.trim();

  const [query, setQuery] = useState<PaginatedQuery>({ id, limit });
  const [cursor, setCursor] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(isNew ? true : false);

  const [busy, setBusy] = useState<boolean>(false);
  const generateMutation = useDoGenerateMutation();

  const promptQuery = useGetPromptsQuery({ query }, { enabled: !!id && !isNew });
  const [responses, setResponses] = useState<Prompt[]>([]);
  const messagesRef = useRef<HTMLDivElement>();

  const [hasJumped, setHasJumped] = useState<boolean>(false);
  const { copied, copy, reset: resetCopied } = useCopy(prompt ?? "");
  const [message, setMessage] = useState<string>();
  const [promptHeight, setPromptHeight] = useState<number>();

  const [mobileShowLogin, setMobileShowResponse] = useState<boolean>(false);

  const jumpToBottom = useCallback(() => {
    setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }, 200);
  }, []);

  useEffect(() => {
    if (hasJumped) {
      return;
    }
    if (responses.length > 0) {
      jumpToBottom();
      setHasJumped(true);
    }
  }, [hasJumped, jumpToBottom, responses.length]);

  useEffect(() => {
    // react-query still returns cached result even if it's disabled. so we filter it here.
    if (isNew) {
      return;
    }

    if (promptQuery.isFetched) {
      const newCursor = promptQuery.data?.prompts?.cursor;
      const prompts = [...(promptQuery.data?.prompts?.prompts ?? [])];
      if (newCursor && newCursor !== cursor && prompts.length > 0) {
        // we loaded a new page. let's push the result
        const oldToNew = prompts.reverse();
        setResponses(appendResult(responses, oldToNew));
        // now it won't load again
        setCursor(newCursor);
      }

      if (prompts.length < limit) {
        setLoaded(true);
      }
    }
  }, [
    cursor,
    isNew,
    promptQuery.data?.prompts?.cursor,
    promptQuery.data?.prompts?.prompts,
    promptQuery.isFetched,
    responses,
  ]);

  // save prompts when users are not logged in.
  useEffect(() => {
    if (isNew && isGuest) {
      localStorage.setItem(NEW_PROMPT_CACHE_KEY, JSON.stringify(model.toMap()));
    }
  }, [isGuest, isNew, model]);

  useEffect(() => {
    if (!isGuest) {
      const item = localStorage.getItem(NEW_PROMPT_CACHE_KEY);
      if (item) {
        try {
          const fields = JSON.parse(item);
          model.setRawPrompt(fields.rawPrompt ?? "");
          model.setGoal(fields.goal ?? "");
          model.setContent(fields.content ?? "");
          model.setPerspective(fields.perspective ?? "");
          model.setAudience(fields.audience ?? "");
          model.setContext(fields.context ?? "");
          model.setTone(fields.tone ?? "");
          model.setFormat(fields.format ?? "");
        } catch (error) {
        } finally {
          localStorage.removeItem(NEW_PROMPT_CACHE_KEY);
        }
      }
    }
  }, [isGuest, model]);

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setQuery({ ...query, cursor });
      }
    }
  }, [cursor, loaded, query]);

  function copyPrompt() {
    if (!prompt) {
      return;
    }

    copy();
    setTimeout(() => {
      resetCopied();
    }, 3000);
  }

  function toggleMode() {
    if (mode === PromptEditorMode.guided) {
      // if raw prompt is null, copy value over
      if (!model.rawPrompt || model.rawPrompt === "") {
        model.setRawPrompt(prompt);
      }

      setMode(PromptEditorMode.raw);
    } else {
      setMode(PromptEditorMode.guided);
    }
  }

  function editPrompt(prompt: Prompt) {
    setEditingPrompt(prompt);
    model.setRawPrompt(prompt.userPrompt);

    // it's awkward to edit prompt in guided mode
    if (isRaw(prompt.fields)) {
      setMode(PromptEditorMode.raw);
    }
  }

  async function generate() {
    if (busy || !prompt || prompt === "") {
      return;
    }

    if (isGuest) {
      setMessage("Please sign in to run the prompt chatGPT");
      return;
    }

    // on mobile, upon submission, we switch to message mode for response
    if (mobile && mode !== PromptEditorMode.raw) {
      setMode(PromptEditorMode.raw);
    }

    try {
      jumpToBottom();
      model.setRawPrompt("");
      setBusy(true);
      const result = await generateMutation.mutateAsync({
        input: {
          id,
          type: PromptType.Text,
          prompt: prompt,
          fields: mode === PromptEditorMode.raw ? [] : model.toFields().fields,
        },
      });

      // clear
      localStorage.removeItem(NEW_PROMPT_CACHE_KEY);

      if (!id && result?.generate?.prompt?.id) {
        setQuery({ ...query, id: result.generate.prompt.id });
        // add a new parent prompt to the redux store
        const prompt = { ...result.generate.prompt, messageCount: 1 };
        dispatch(createPrompt(prompt));
        navigate(generatePath(RouteNames.PromptEditor, { id: result.generate.prompt.id }));
      }

      if (result && result.generate.prompt?.texts) {
        responses.push(result.generate.prompt);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
      jumpToBottom();
    }
  }

  function onRawPromptChange(value: string) {
    model.setRawPrompt(value);
  }

  return (
    <Headerify>
      <div className={`AppFrame ${isElectron ? "Electron" : ""}`}>
        <Stack
          direction={mobile ? "column" : "row"}
          className={`${styles.PromptEditor} ${mobile ? styles.Mobile : ""}  ${
            mode === PromptEditorMode.raw ? styles.RawMode : styles.GuideMode
          } ${mobileShowLogin ? styles.MobileShowResponse : ""}`}
        >
          {mode === PromptEditorMode.guided && (
            <Stack
              className={`${styles.Editor} Editor`}
              style={{ paddingBottom: mode === PromptEditorMode.guided ? promptHeight : 0 }}
            >
              <NavHeader />
              <Stack sx={{ mt: 2, mb: 4, px: 1.5 }} gap={3} flex={1}>
                <Stack gap={2}>
                  <Typography variant="h6">Basics</Typography>
                  <TextField
                    required
                    id="goal"
                    label="What do you want to accomplish?"
                    onChange={model.onGoalChange}
                    value={model.goal}
                    size="medium"
                    autoFocus
                    autoComplete="off"
                  />

                  <TextField
                    id="content"
                    label="Is there an outline?"
                    onChange={model.onContentChange}
                    value={model.content}
                    size="medium"
                    multiline
                    minRows={3}
                    autoComplete="off"
                  />
                </Stack>

                <Stack gap={2}>
                  <Typography variant="h6">Modifier - situation</Typography>
                  <Stack gap={1} sx={{ mb: 1 }}>
                    <TextField
                      id="audience"
                      label="Who is the audience?"
                      onChange={model.onAudienceChange}
                      value={model.audience}
                      size="medium"
                      autoComplete="off"
                    />
                  </Stack>
                  <Stack gap={1} sx={{ mb: 1 }}>
                    <TextField
                      id="perspective"
                      label="Whose perspective should the result be from?"
                      onChange={model.onPerspectiveChange}
                      value={model.perspective}
                      size="medium"
                      autoComplete="off"
                    />
                  </Stack>

                  <TextField
                    id="context"
                    label="Any prior context?"
                    onChange={model.onContextChange}
                    value={model.context}
                    size="medium"
                    multiline
                    minRows={3}
                    autoComplete="off"
                  />
                </Stack>

                <Stack gap={2}>
                  <Typography variant="h6">Modifier - output</Typography>
                  <Stack gap={1} sx={{ mb: 1 }}>
                    <TextField
                      id="tone"
                      label="What tones and styles would you like to use?"
                      onChange={model.onToneChange}
                      value={model.tone}
                      size="medium"
                      autoComplete="off"
                    />
                  </Stack>

                  <TextField
                    id="format"
                    label="What should the output format be?"
                    onChange={model.onFormatChange}
                    value={model.format}
                    size="medium"
                    multiline
                    minRows={3}
                    autoComplete="off"
                  />
                </Stack>
              </Stack>

              {mode === PromptEditorMode.guided && (
                <PromptBox
                  prompt={prompt}
                  mode={mode}
                  rawPrompt={model.rawPrompt}
                  editingPrompt={editingPrompt}
                  toggleMode={toggleMode}
                  copyPrompt={copyPrompt}
                  copied={copied}
                  busy={busy}
                  generate={generate}
                  onRawPromptChange={onRawPromptChange}
                  isGuest={isGuest}
                  setMobileShowResponse={() => setMobileShowResponse(true)}
                  onHeightChange={setPromptHeight}
                />
              )}

              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={!!message}
                onClose={() => setMessage(undefined)}
                message={message}
                key="bottom-center"
                autoHideDuration={3000}
              />
            </Stack>
          )}

          <Stack className={`${styles.Preview} Preview`} style={{ paddingBottom: promptHeight }}>
            {!isGuest && (
              <Stack
                direction="row"
                className={styles.SectionHeader}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                {mode === PromptEditorMode.raw && <NavHeader />}
                {!mobile && <Typography variant="h5">Responses</Typography>}
              </Stack>
            )}

            {mobileShowLogin && (
              <IconButton sx={{ position: "fixed", top: 16, right: 16 }} onClick={() => setMobileShowResponse(false)}>
                <img src={IconClose} alt="close" />
              </IconButton>
            )}

            <Messages
              mode={mode}
              busy={busy}
              responses={responses}
              editPrompt={editPrompt}
              ref={messagesRef}
              loaded={loaded}
              loadMore={loadMore}
              isNew={isNew}
              isGuest={isGuest}
            />

            {mode === PromptEditorMode.raw && (
              <PromptBox
                prompt={prompt}
                mode={mode}
                editingPrompt={editingPrompt}
                rawPrompt={model.rawPrompt}
                toggleMode={toggleMode}
                copyPrompt={copyPrompt}
                copied={copied}
                busy={busy}
                generate={generate}
                onRawPromptChange={onRawPromptChange}
                isGuest={isGuest}
                setMobileShowResponse={() => setMobileShowResponse(true)}
                onHeightChange={setPromptHeight}
              />
            )}
          </Stack>
        </Stack>
      </div>
    </Headerify>
  );
}

export default PromptEditor;
