import Stack from "@mui/material/Stack/Stack";
import { Button, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  MemberRole,
  PaginatedQuery,
  useDoAddMemberMutation,
  useDoUpdateMemberMutation,
  useGetCurrentMembersQuery,
} from "generated/graphql";
import { useEffect, useRef, useState } from "react";
import "./index.sass";

const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 1,
    sortable: false,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    sortable: false,
    editable: false,
  },
  {
    field: "role",
    headerName: "Role",
    type: "singleSelect",
    valueOptions: [
      // { value: MemberRole.Admin, label: "Admin" },
      { value: MemberRole.Editor, label: "Editor" },
      { value: MemberRole.User, label: "User" },
    ],
    sortable: false,
    editable: true,
  },
];

interface MemberRow {
  id: string;
  fullName: string;
  email: string;
  role: string;
}

const MembersPageSize = 10;

export function MembersTabPanel({
  setStatusMessage,
}: {
  setStatusMessage: (message: string | null | undefined) => void;
}) {
  const [query, setQuery] = useState<PaginatedQuery>({ limit: MembersPageSize });
  const [page, setPage] = useState<number>(0);
  const mapPageToNextCursor = useRef<{ [page: number]: string }>({});
  const getCurrentMembersQuery = useGetCurrentMembersQuery({ query });
  const [cursor, setCursor] = useState<string | null>();
  const [rows, setRows] = useState<MemberRow[]>([]);

  const [email, setEmail] = useState<string>();
  const [changed, setChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const inviteMemberMutation = useDoAddMemberMutation();
  const updateMemberMutation = useDoUpdateMemberMutation();

  useEffect(() => {
    const queryCursor = getCurrentMembersQuery.data?.currentCompany?.members?.cursor;
    if (!cursor || cursor !== queryCursor) {
      setCursor(queryCursor);
      setRows(
        (getCurrentMembersQuery.data?.currentCompany?.members?.members ?? []).map((member) => ({
          id: member.id,
          fullName: member.fullName ?? "",
          email: member.email,
          role: member.role,
        }))
      );
    }
    if (queryCursor) {
      mapPageToNextCursor.current[page] = queryCursor;
    }
  }, [
    getCurrentMembersQuery.data?.currentCompany?.members?.cursor,
    getCurrentMembersQuery.data?.currentCompany?.members?.members,
    cursor,
    page,
  ]);

  async function updateUserRole(id: string, role: string) {
    if (saving) {
      return;
    }

    setSaving(true);
    try {
      // local cache update
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, role };
        }
        return row;
      });
      setRows(newRows);

      const resp = await updateMemberMutation.mutateAsync({
        input: {
          id,
          role,
        },
      });
      if (resp.updateMember) {
        setStatusMessage("Member role updated");
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setStatusMessage(e.message);
      }
    } finally {
      setSaving(false);
    }
  }

  async function onInvite() {
    if (saving || email === "") {
      return;
    }

    setSaving(true);

    try {
      const resp = await inviteMemberMutation.mutateAsync({
        input: {
          email,
          role: MemberRole.Invited,
        },
      });
      if (resp.addMember) {
        setEmail("");
        setChanged(false);
        // todo: figure out how to refresh the table
        setStatusMessage("New member invited!");
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setStatusMessage(e.message);
      }
    } finally {
      setSaving(false);
    }
  }

  return (
    <Stack sx={{ p: 6 }} gap={3} alignItems="stretch" flex={1}>
      <Stack direction="row" gap={2} alignItems="center" flex={1}>
        <TextField
          fullWidth
          id="new-member-email"
          type="email"
          size="small"
          disabled={saving}
          className="New_Member__email"
          onChange={(e) => {
            setChanged(true);
            setEmail(e.target.value);
          }}
          placeholder="New member email"
          label="New member email"
          value={email ?? ""}
        />
        <Button variant="contained" disabled={!changed || saving} onClick={onInvite}>
          Invite
        </Button>
      </Stack>

      <DataGrid
        loading={getCurrentMembersQuery.isLoading}
        rows={rows}
        columns={columns}
        rowCount={getCurrentMembersQuery.data?.currentCompany?.totalUsers ?? 0}
        pagination
        paginationMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: query.limit ?? MembersPageSize,
              page,
            },
          },
        }}
        onPaginationModelChange={(model) => {
          if (model.page === 0 || mapPageToNextCursor.current[model.page - 1]) {
            setPage(model.page);
            setQuery({ ...query, cursor: mapPageToNextCursor.current[model.page - 1] });
          }
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          if (updatedRow.role !== originalRow.role) {
            updateUserRole(updatedRow.id, updatedRow.role);
          }
          return updatedRow;
        }}
        pageSizeOptions={[query.limit ?? MembersPageSize]}
        hideFooterSelectedRowCount={true}
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        disableEval
      />
    </Stack>
  );
}
