import MultipleChoice, { MultipleChoiceOptions } from "./multiple_choice";
import styles from "./onboarding.module.sass";
import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { OnboardingProgress } from ".";
import { OnboardingQuestionInput, useDoSaveOnboardingQuestionMutation } from "generated/graphql";

const goalsOptions = {
  options: [
    { value: "content-generation", label: "Content and document generation" },
    { value: "idea-brainstorm", label: "Idea brainstorm" },
    { value: "text-summarization", label: "Text summarization" },
    { value: "code-completion", label: "Code completion and refactor" },
    { value: "communication-drafting", label: "Email and reply drafting" },
    { value: "information-search", label: "Information look-up" },
    { value: "translation", label: "Translation" },
    { value: "data-analysis", label: "Data analysis" },
    { value: "decision-making", label: "Decision making" },
    { value: "classification", label: "Classification" },
  ],
} as MultipleChoiceOptions<string>;

function getConfirmation(selected: string[]): string | undefined {
  if (!selected || selected.length === 0) {
    return;
  }
  switch (selected[0]) {
    default:
      return "Thanks for sharing. Those are some great use cases of AI!";
  }
}

export default function Goals({
  progress,
  setProgress,
}: {
  progress: OnboardingProgress;
  setProgress: React.Dispatch<React.SetStateAction<OnboardingProgress>>;
}) {
  const [selected, setSelected] = useState<string[]>([]);
  const [pending, setPending] = useState<boolean>(false);

  const saveOnboardingQuestionMutation = useDoSaveOnboardingQuestionMutation();

  async function submit() {
    setPending(true);
    try {
      if (selected.length > 0) {
        saveOnboardingQuestionMutation.mutateAsync({
          input: {
            version: "20230319",
            questionKey: "chatgpt-goals",
            value: selected,
          } as OnboardingQuestionInput,
        });
      }

      const confirmation = getConfirmation(selected);
      setProgress({ ...progress, goals: true, confirmation });
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
    }
  }

  function onOptionUpdate<T>(values: T[]) {
    setSelected(values as string[]);
  }

  return (
    <div className={styles.Step}>
      <Stack className={styles.Content} gap={3}>
        <Stack>
          <Typography className={styles.TopTitle} variant="h1">
            What did you use LLMs for?
          </Typography>
          <Typography className={styles.Explanation} variant="body1">
            Choose as many options as you want
          </Typography>
        </Stack>
        <MultipleChoice left multiple options={goalsOptions} callback={onOptionUpdate} />
      </Stack>
      <div className={styles.Bottom}>
        <Button
          variant="contained"
          disabled={pending}
          onClick={submit}
          className={`${selected.length === 0 ? styles.Disabled : ""}`}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export function GoalsDemo({ progress }: { progress: OnboardingProgress }) {
  return (
    <div className={styles.Demo}>
      <Stack className={styles.Content} gap={2} sx={{ mb: 8 }}>
        {progress?.confirmation ? (
          <Typography variant="h1">{progress.confirmation}</Typography>
        ) : (
          <Typography variant="h1">Ship LLM apps faster</Typography>
        )}
        <Typography>Step 2 / 4</Typography>
      </Stack>
    </div>
  );
}
