import classNames from "classnames";
import { useAppDispatch } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { setVolume } from "store/ui_reducer";
import "./audio.sass";
import ReactHowler from "./howler";

export interface AudioState {
  muted: boolean;
  volume: number;
  inProgress: boolean;
  started: boolean;
  completed: boolean;
  key: number;
}

export function cloneState(state: AudioState): AudioState {
  return {
    ...state,
  } as AudioState;
}

export interface AudioTrack {
  sources: string[];
  maxVolume: number;
  type: AudioType;
}

export enum AudioType {
  Sound,
  Music,
  VoiceOver,
  Greeting,
  Celebrate,
  Feedback,
}

export function defaultAudioState(volume: number): AudioState {
  // debug
  // return {
  //   muted: false,
  //   volume: volume,
  //   inProgress: false,
  //   started: true,
  //   completed: true,
  //   key: new Date().valueOf(),
  // };
  const state: AudioState = {
    muted: false,
    volume: volume,
    inProgress: false,
    started: false,
    completed: false,
    key: new Date().valueOf(),
  };
  return state;
}

interface AudioProps {
  audios?: AudioTrack[];
  voiceOver?: AudioTrack;
  state: AudioState;
  onTimeupdate: (time: number) => void;
  onAdjust: (playing: boolean, volume: number) => void;
}

type ReactHowlerOrNull = ReactHowler | null;

function AudioControl({ audios, voiceOver, state, onTimeupdate, onAdjust }: AudioProps) {
  const dispatch = useAppDispatch();
  const [playingVoice, setPlayingVoice] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [clicked, setClicked] = useState(false);
  const refs = useRef<ReactHowlerOrNull[]>([]);
  const voiceOverRef = useRef<ReactHowlerOrNull>(null);

  useEffect(() => {
    let newPlaying: boolean = playing;
    if (state.muted && playing) {
      newPlaying = false;
    } else if ((clicked || state.started) && !state.muted && !playing) {
      newPlaying = true;
    }
    if (newPlaying !== playing) {
      setPlaying(newPlaying);
    }

    let newPlayingVoice: boolean = playingVoice;
    if ((state.muted || !state.inProgress) && playingVoice) {
      newPlayingVoice = false;
    } else if ((clicked || state.started) && !state.muted && state.inProgress && !playingVoice) {
      newPlayingVoice = true;
    }
    if (newPlayingVoice !== playingVoice) {
      try {
        voiceOverRef.current?.seek(0);
        setPlayingVoice(newPlayingVoice);
      } catch (error) {}
    }
  }, [state, state.volume, state.muted, playing, playingVoice, clicked]);

  function toggle() {
    if (!clicked) {
      setClicked(true);
    }
    const newPlaying = !playing;
    if (newPlaying && state.volume === 0) {
      dispatch(setVolume(0.4));
    }
    // notify the parent then rely on useEffect to adjust it
    const muted = !newPlaying;
    onAdjust(muted, state.volume);
  }

  function adjustVolume(e: React.MouseEvent<HTMLDivElement>) {
    if (!clicked) {
      setClicked(true);
    }
    const rects = e.currentTarget.getClientRects();
    if (rects && rects.length > 0) {
      const width = rects[0].width;
      const left = rects[0].left;
      const x = e.nativeEvent.pageX - left;
      // round to nearest 10%
      if (width !== 0) {
        const percent = Math.round((x / width) * 10) * 10;
        const volume = percent / 100;
        if (percent > 0) {
          dispatch(setVolume(volume));
          const muted = false;
          onAdjust(muted, volume);
        } else {
          const muted = true;
          onAdjust(muted, volume);
        }
      }
    }
  }

  return (
    <div className="Audios">
      {voiceOver && (
        <ReactHowler
          ref={voiceOverRef}
          preload
          key="audio-voice-over"
          src={voiceOver.sources}
          playing={playingVoice}
          volume={state.volume * voiceOver.maxVolume}
          loop
        />
      )}
      {audios?.map((a, i) => {
        return (
          <ReactHowler
            preload
            key={`audio-${i}`}
            src={a.sources}
            playing={playing}
            volume={state.volume * a.maxVolume}
            loop
            ref={(r) => refs.current.push(r)}
          />
        );
      })}
      <div className={classNames("pane", { on: playing })}>
        <div className="icon" onClick={toggle}></div>
        <div className="track" onClick={adjustVolume}>
          <div className="cursor" style={{ left: `${playing ? state.volume * 100 : 0}%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default AudioControl;
