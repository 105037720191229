import IconMore from "assets/v2/icon-more.svg";
import IconNewFunction from "assets/v2/icon-prompt-function.svg";
import Layout from "../layout/layout";
import Loader from "react-spinners/ScaleLoader";
import RouteNames from "route_names";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { generatePath, Link } from "react-router-dom";
import {
  PaginatedQuery,
  Template,
  useDoDeleteTemplatesMutation,
  useGetPromptTemplateStatsQuery,
  useGetPromptTemplatesQuery,
} from "generated/graphql";
import { selectCurrentUser } from "store/current_user_reducer";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import "./index.sass";
import "react-loading-skeleton/dist/skeleton.css";
import { addTemplates, removeTemplates, selectTemplates } from "store/templates_reducer";
import CreateFunctionModal from "./create_function_modal";
import { queryClient } from "api";
import { selectCurrentCompany } from "store/current_company_reducer";

function FunctionCard({
  template,
  calls,
  editFunction,
}: {
  template: Template;
  calls?: number;
  editFunction?: (template: Template) => void;
}) {
  const dispatch = useAppDispatch();
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const deleteMutation = useDoDeleteTemplatesMutation();

  async function deleteTemplates() {
    dispatch(removeTemplates([template.id]));
    try {
      await deleteMutation.mutateAsync({
        ids: [template.id],
      });
    } catch (error) {}
  }

  return (
    <TableRow key={template.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Link to={generatePath(RouteNames.Gist, { id: template.id })} style={{ textDecoration: "none" }}>
          <Typography>{template.name}</Typography>
        </Link>
      </TableCell>
      <TableCell align="right">{calls}</TableCell>
      <TableCell align="right">
        <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
          <img src={IconMore} alt="Card options" style={{ height: 16 }} />
        </IconButton>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={showCardMenu}
          anchorEl={el.current}
          onClose={() => setShowCardMenu(false)}
        >
          <MenuItem
            onClick={() => {
              setShowCardMenu(false);
              if (editFunction) {
                editFunction(template);
              }
            }}
          >
            <span>Edit</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowCardMenu(false);
              setShowConfirmDeleteMenu(true);
            }}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={showConfirmDeleteMenu}
          anchorEl={el.current}
          onClose={() => setShowConfirmDeleteMenu(false)}
        >
          <MenuItem
            onClick={() => {
              deleteTemplates();
              setShowConfirmDeleteMenu(false);
            }}
          >
            <span>Yes, delete!</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowConfirmDeleteMenu(false);
            }}
          >
            <span>No, cancel</span>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

function TemplateCardPlaceholder() {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row" width="40%">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
    </TableRow>
  );
}

function NewTemplate({ onAddNewTemplate }: { onAddNewTemplate: () => void }) {
  return (
    <Stack alignItems="center" flex={1} gap={4} sx={{ mt: 0, mb: 4 }}>
      <img src="https://staticgists.com/astronaut-template-v1.png" alt="New Function" style={{ width: 240 }} />
      <Typography textAlign="center">
        No gists has been created yet.
        <br />
        Create a new gist and it'll show up here.
      </Typography>
      <Button onClick={onAddNewTemplate} variant="outlined" size="medium">
        <img src={IconNewFunction} alt="New Gist" height={20} />
        &nbsp;New Gist
      </Button>
    </Stack>
  );
}

function FunctionList() {
  const dispatch = useAppDispatch();
  const { templates: responses, cursor, loaded, limit } = useAppSelector(selectTemplates);
  const { currentUser } = useAppSelector(selectCurrentUser);
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editingTemplate, setEditingTemplate] = useState<Template>();
  const [query, setQuery] = useState<PaginatedQuery>({ id: currentCompany?.id, limit });
  const promptTemplateQuery = useGetPromptTemplatesQuery({ query }, { enabled: !!currentCompany?.id });
  const promptTemplateStatsQuery = useGetPromptTemplateStatsQuery({ query }, { enabled: !!currentCompany?.id });

  useEffect(() => {
    if (promptTemplateQuery.isFetched) {
      dispatch(addTemplates(promptTemplateQuery?.data?.templates));
    }
  }, [dispatch, promptTemplateQuery?.data?.templates, promptTemplateQuery.isFetched]);

  useEffect(() => {
    if (currentCompany?.id && !query.id) {
      setQuery({ ...query, id: currentCompany?.id });
    }
  }, [currentCompany?.id, query]);

  const templateCalls =
    promptTemplateStatsQuery?.data?.templateStats?.stats?.reduce((acc, cur) => {
      acc[cur.templateId] = cur.callCount ?? 0;
      return acc;
    }, {} as { [key: string]: number }) ?? {};

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setQuery({ ...query, cursor });
      }
    }
  }, [cursor, loaded, query]);

  return (
    <Layout
      className="TemplateList"
      workspace
      header={
        <Stack direction="row" className="PageHeader" justifyContent="space-between">
          <Typography variant="h4" className="PageTitle">
            Gists
          </Typography>
          <Button onClick={() => setCreateModalOpen(true)} variant="outlined" size="medium">
            <img src={IconNewFunction} alt="New Function" height={14} />
            &nbsp;&nbsp;New Gist
          </Button>
        </Stack>
      }
      key={`apphome-${currentUser?.id}`}
    >
      <Stack alignItems="stretch" style={{ maxWidth: "100%" }} gap={1}>
        <TableContainer component={Paper} elevation={0} style={{ borderRadius: 6 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Gist</TableCell>
                <TableCell align="right">Calls</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(promptTemplateQuery.isLoading || promptTemplateStatsQuery.isLoading) && responses.length === 0 ? (
                Array(6)
                  .fill(0)
                  .map((_, i) => <TemplateCardPlaceholder key={`template-placeholder-${i}`} />)
              ) : responses.length > 0 ? (
                responses.map((r: Template) => (
                  <FunctionCard
                    key={r.id}
                    template={r}
                    calls={templateCalls[r.id]}
                    editFunction={(template) => {
                      setEditingTemplate(template);
                    }}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <NewTemplate onAddNewTemplate={() => setCreateModalOpen(true)} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loaded && !promptTemplateQuery.isLoading && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Button onClick={loadMore} size="small">
              Load more...
            </Button>
          </Stack>
        )}

        {loaded && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {responses.length} Gists
          </Stack>
        )}

        {promptTemplateQuery.isLoading && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        <CreateFunctionModal
          key={editingTemplate ? `editTemplate-${editingTemplate.id}` : "createTemplate"}
          template={editingTemplate}
          open={createModalOpen || !!editingTemplate}
          onClose={() => {
            setCreateModalOpen(false);
            setEditingTemplate(undefined);
          }}
          onSucceed={() => {
            setCreateModalOpen(false);
            if (editingTemplate) {
              queryClient.invalidateQueries(["getPromptTemplates", { query: { limit } }]);
            }
            setEditingTemplate(undefined);
          }}
        />
      </Stack>
    </Layout>
  );
}

export default FunctionList;
