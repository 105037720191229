import RouteNames from "route_names";
import variables from "variables";
import { IPC_EVENT_OPEN_URL } from "values";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { NavigateOptions, useNavigate } from "react-router-dom";
import type { AppDispatch, RootState } from "./store/store";
import { useCallback, useEffect, useState } from "react";
import { AiModel, AiModelType, PaginatedQuery, useGetModelsQuery } from "generated/graphql";
import { CURRENT_AI_MODEL_LOCAL_STORAGE_KEY, addModels, selectModels, switchModel } from "store/models_reducer";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useIsElectron() {
  return typeof window.electron !== "undefined";
}

export function useIsWindows() {
  return useIsElectron() && typeof window.versions !== "undefined" && window.versions.isWindows;
}

export function useIsMac() {
  return useIsElectron() && typeof window.versions !== "undefined" && window.versions.isMac;
}

export function useIPCSend() {
  const isElectron = useIsElectron();
  return (event: string, data: any) => {
    if (isElectron) {
      window.electron.ipcRenderer.openURL(data);
    }
  };
}

export function useOnAutoUpdate() {
  const isElectron = useIsElectron();
  useEffect(() => {
    if (isElectron) {
      const unsub = window.electron.ipcRenderer.onAutoUpdate((data: any) => {
        console.log("auto update", data);
      });
      return () => unsub();
    }
  }, [isElectron]);
}

export function useOnDeepLink() {
  const isElectron = useIsElectron();
  const [url, setUrl] = useState<string | undefined | null>(undefined);
  useEffect(() => {
    if (isElectron) {
      const unsub = window.electron.ipcRenderer.onDeeplink((url?: string | null) => {
        // console.log("deeplink", url!);
        setUrl(url);
      });
      return () => unsub();
    }
  }, [isElectron]);

  return url;
}

const MissingElectronRoutes = {
  [RouteNames.Plan]: true,
  [RouteNames.DesktopGoogleSignIn]: true,
  [RouteNames.DesktopSlackSignIn]: true,
};

export function electronRouteWrapper(route: string) {
  if (MissingElectronRoutes[route]) {
    return `${variables.WEB_HOST}${route}`;
  }
  return route;
}

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const isElectron = useIsElectron();
  const ipcSend = useIPCSend();

  return (route: string, options?: NavigateOptions) => {
    if (isElectron && MissingElectronRoutes[route]) {
      ipcSend(IPC_EVENT_OPEN_URL, electronRouteWrapper(route));
      return;
    }

    navigate(route, options);
  };
};

export function useVisualHeight() {
  const [visualHeight, setVisualHeight] = useState<number>(window.visualViewport?.height ?? window.innerHeight);

  useEffect(() => {
    function resize() {
      setVisualHeight(window.visualViewport?.height ?? window.innerHeight);
    }
    window.visualViewport?.addEventListener("resize", resize);
    return () => window.visualViewport?.removeEventListener("resize", resize);
  }, []);

  return visualHeight;
}

export function useCachedAiModel(): [string, AiModel | undefined, (model: string) => void, string] {
  const { modelLabels, models, currentModel } = useAppSelector(selectModels);
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      const model = localStorage.getItem(CURRENT_AI_MODEL_LOCAL_STORAGE_KEY) ?? AiModelType.Chatgpt_3_5Turbo;
      dispatch(switchModel(model));
    }
  }, [loaded, dispatch, modelLabels]);

  const saveDefaultModel = useCallback(
    (model: string) => {
      dispatch(switchModel(model));
    },
    [dispatch]
  );

  const customModel = models.find((m) => m.id === currentModel);

  return [currentModel ?? AiModelType.Chatgpt_3_5Turbo, customModel, saveDefaultModel, modelLabels[currentModel]];
}

export function useSafeModelName(
  type: AiModelType | undefined,
  currentModel: string
): [string | undefined, AiModel | undefined] {
  const { modelLabels, models } = useAppSelector(selectModels);
  const modelsQuery = { limit: 100 } as PaginatedQuery;
  const modelsQueryResult = useGetModelsQuery({ query: modelsQuery });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modelsQueryResult.isFetched) {
      dispatch(addModels(modelsQueryResult?.data?.models));
    }
  }, [dispatch, modelsQueryResult?.data?.models, modelsQueryResult.isFetched]);

  const customModel = models.find((m) => m.id === currentModel);

  return [modelLabels[type ?? ""] ?? type, customModel];
}
