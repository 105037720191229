import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import Box from "@mui/material/Box";
import css from "react-syntax-highlighter/dist/cjs/languages/prism/css";
import IconAi from "assets/v2/icon-ai.svg";
import IconPersonal from "assets/v2/icon-personal.svg";
import IconClipboard from "assets/v2/icon-clipboard.svg";
import IconMore from "assets/v2/icon-more.svg";
import javascript from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json";
import Loader from "react-spinners/BeatLoader";
import markdown from "react-syntax-highlighter/dist/cjs/languages/prism/markdown";
import rangeParser from "parse-numeric-range";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ScaleLoader from "react-spinners/ScaleLoader";
import scss from "react-syntax-highlighter/dist/cjs/languages/prism/scss";
import SignInButtons from "components/auth/sign_in_buttons";
import Stack from "@mui/material/Stack";
import styles from "./index.module.sass";
import swift from "react-syntax-highlighter/dist/cjs/languages/prism/swift";
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";
import Typography from "@mui/material/Typography";
import useCopy from "@react-hook/copy";
import { Button, Chip, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { CSSProperties, forwardRef, MutableRefObject, useMemo, useRef, useState } from "react";
import { oneLight } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { PluggableList } from "react-markdown/lib/react-markdown";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { Prompt, useDoDeletePromptsMutation } from "generated/graphql";
import { PromptEditorMode } from ".";
import { removePrompts } from "store/prompts_reducer";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectModels } from "store/models_reducer";

SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("scss", scss);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("markdown", markdown);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("css", css);
SyntaxHighlighter.registerLanguage("swift", swift);

function MarkdownMessage({ prompt }: { prompt: Prompt; size?: DOMRectReadOnly }) {
  const components = useMemo(() => {
    return {
      code({ node, inline, className, ...props }: { node: any; inline: boolean; className: string } & any) {
        const hasLang = /language-(\w+)/.exec(className || "");
        const hasMeta = node?.data?.meta;

        const applyHighlights: object = (applyHighlights: number) => {
          if (hasMeta) {
            const RE = /{([\d,-]+)}/;
            const metadata = node.data.meta?.replace(/\s/g, "");
            const strlineNumbers = RE?.test(metadata) ? RE?.exec(metadata)![1] : "0";
            const highlightLines = rangeParser(strlineNumbers);
            const highlight = highlightLines;
            const data = highlight.includes(applyHighlights) ? "highlight" : null;
            return { data };
          } else {
            return {};
          }
        };

        return hasLang ? (
          <SyntaxHighlighter
            style={oneLight}
            language={hasLang[1]}
            PreTag="div"
            className="codeStyle"
            showLineNumbers
            wrapLines={hasMeta}
            useInlineStyles
            lineProps={applyHighlights}
          >
            {props.children}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props} />
        );
      },
    };
  }, []);

  const plugins: PluggableList = useMemo(() => {
    return [[remarkGfm, { singleTilde: false }]];
  }, []);

  return (
    <div
      className={styles.MessageMarkdown}
      style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
    >
      <ReactMarkdown key={`react-markdown-${prompt.id}`} components={components} remarkPlugins={plugins}>
        {prompt!.texts![0].trim()}
      </ReactMarkdown>
    </div>
  );
}

export function AIMessage({
  prompt,
  deletePrompt,
  mobile,
}: {
  prompt: Prompt;
  mobile: boolean;
  deletePrompt: (promptId: string) => void;
}) {
  const { copied, copy, reset: resetCopied } = useCopy(prompt!.texts![0] ?? "");
  const { modelLabels } = useAppSelector(selectModels);

  function copyResponse() {
    copy();
    setTimeout(() => {
      resetCopied();
    }, 3000);
  }

  return (
    <Stack gap={1} direction="row" alignItems="flex-start">
      <Stack className={styles.MessageWrapper} alignItems="flex-start" gap={1}>
        <Box className={styles.Message} flex={1}>
          {mobile ? prompt!.texts![0].trim() : <MarkdownMessage prompt={prompt} />}
        </Box>
        <Stack direction="row" gap={1}>
          <Chip
            size="small"
            icon={<img src={IconAi} alt="model" style={{ paddingLeft: 4, height: 16 }} />}
            label={modelLabels[prompt.customModelID] ?? modelLabels[prompt.aiModelType]}
            style={{ fontSize: 11 }}
          />
          {prompt.character && (
            <Chip
              size="small"
              icon={<img src={IconPersonal} alt="character" style={{ paddingLeft: 4, height: 16 }} />}
              label={prompt.character?.name ?? "AI"}
              style={{ fontSize: 11 }}
            />
          )}
        </Stack>
      </Stack>

      <IconButton onClick={copyResponse}>
        <Tooltip open={copied} placement="bottom" title={copied ? "Copied" : "Copy this response"}>
          <img src={IconClipboard} alt="Copy" width={16} />
        </Tooltip>
      </IconButton>
    </Stack>
  );
}

export function UserMessage({
  prompt,
  editPrompt,
  deletePrompt,
}: {
  prompt: Prompt;
  editPrompt: (prompt: Prompt) => void;
  deletePrompt: (promptId: string) => void;
}) {
  const { copied, copy, reset: resetCopied } = useCopy(prompt.userPrompt ?? "");
  const [open, setOpen] = useState<boolean>(false);
  const el = useRef<HTMLAnchorElement | null>(null);
  const dispatch = useAppDispatch();
  const deleteMutation = useDoDeletePromptsMutation();

  function copyPrompt() {
    copy();
    setOpen(false);

    setTimeout(() => {
      resetCopied();
    }, 3000);
  }

  function showMenu(event: React.MouseEvent) {
    setOpen(true);
    event.preventDefault();
    return false;
  }

  async function onDeletePrompt() {
    dispatch(removePrompts([prompt.id]));
    try {
      await deleteMutation.mutateAsync({
        ids: [prompt.id],
      });
      deletePrompt(prompt.id);
    } catch (error) {}
  }

  return (
    <Stack
      gap={1}
      sx={{ alignSelf: "flex-end", justifyContent: "flex-end" }}
      direction="row"
      key={`prompt-${prompt.id}`}
      alignItems="flex-start"
    >
      <IconButton href={""} onClick={showMenu} ref={el}>
        <Tooltip placement="bottom" title={copied ? "Copied" : ""}>
          <img src={IconMore} alt="More" width={16} />
        </Tooltip>
      </IconButton>
      <Stack className={styles.MessageWrapper}>
        <Box className={styles.UserMessage}>{prompt.userPrompt}</Box>
      </Stack>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={el.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={copyPrompt}>
          <span>Copy</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            editPrompt(prompt);
            setOpen(false);
          }}
        >
          <span>Edit</span>
        </MenuItem>
        <MenuItem onClick={onDeletePrompt}>
          <span>Delete</span>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export const Messages = forwardRef(
  (
    {
      isNew,
      responses,
      editPrompt,
      busyPrompt,
      loaded,
      loadMore,
      mode,
      isGuest,
      style,
      deletePrompt,
      mobile,
    }: {
      isNew: boolean;
      mode: PromptEditorMode;
      loaded: boolean;
      loadMore: () => void;
      responses: Prompt[];
      busyPrompt: string | undefined;
      editPrompt: (prompt: Prompt) => void;
      isGuest: boolean;
      style: CSSProperties;
      deletePrompt: (promptId: string) => void;
      mobile: boolean;
    },
    ref?:
      | ((instance: HTMLDivElement | undefined | null) => void)
      | MutableRefObject<HTMLDivElement | undefined | null>
      | null
  ) => {
    const busyRef = useRef<HTMLDivElement>();

    const rows: { user: boolean; prompt: Prompt }[] = responses.reduce((array, r) => {
      if (!!r.texts && r.texts.length > 0) {
        array.push({ prompt: r, user: true });
        array.push({ prompt: r, user: false });
      }
      return array;
    }, [] as { user: boolean; prompt: Prompt }[]);

    const loading = !loaded && !isNew && responses.length === 0;

    return (
      <Box className={styles.MessagesWrapper} style={{ ...style }} ref={ref}>
        <Stack className={styles.Messages}>
          <Stack flex={1} alignItems="center" justifyContent="center" sx={{ mx: 8, pb: 4, textAlign: "center" }}>
            {!loading && (
              <Stack className={styles.Illustration}>
                <Box>
                  <img
                    style={{ width: 240 }}
                    src={
                      mode === PromptEditorMode.raw
                        ? "https://staticgists.com/astronaut-working-v1.png"
                        : "https://staticgists.com/astronaut-reading-v1.png"
                    }
                    alt="illustration"
                  />
                </Box>
                <Typography variant="h5">
                  You are in the {mode === PromptEditorMode.raw ? "message" : "guided"} mode
                </Typography>
                <Typography sx={{ mb: 4 }}>
                  {mode === PromptEditorMode.raw
                    ? "Use this mode if you are experienced with prompt-engineering."
                    : "Use this mode if you are new to prompt-engineering."}
                </Typography>

                {responses.length === 0 && !isGuest && (
                  <Typography>
                    {mode === PromptEditorMode.raw
                      ? "Start typing in the input box below."
                      : "Follow the guided questions on the left side."}
                  </Typography>
                )}

                {responses.length === 0 && !isGuest && (
                  <Typography>
                    {mode === PromptEditorMode.raw
                      ? "Click send or press Shift + Enter to generate responses."
                      : "Click on send to generate responses."}
                  </Typography>
                )}
              </Stack>
            )}

            {isGuest && (
              <Box sx={{ mt: 6 }}>
                <SignInButtons showBranding={false} singleColumn />
              </Box>
            )}
          </Stack>

          {!loaded && !isNew && responses.length > 0 && (
            <Stack sx={{ mb: 2 }} alignItems="center">
              <Button onClick={loadMore} size="small">
                Load more...
              </Button>
            </Stack>
          )}

          {loading && (
            <Stack sx={{ mb: 2 }} alignItems="center">
              <ScaleLoader color="#ccc" />
            </Stack>
          )}

          <Stack gap={2} alignItems="flex-start">
            {rows.map((r) =>
              r.user ? (
                <UserMessage
                  key={`userprompt-${r.prompt.id}`}
                  prompt={r.prompt}
                  editPrompt={editPrompt}
                  deletePrompt={deletePrompt}
                />
              ) : (
                <AIMessage
                  mobile={mobile}
                  key={`response-${r.prompt.id}`}
                  prompt={r.prompt}
                  deletePrompt={deletePrompt}
                />
              )
            )}

            {!!busyPrompt && (
              <UserMessage
                key={`userprompt-pending-${busyPrompt}`}
                prompt={{ userPrompt: busyPrompt, id: busyPrompt } as Prompt}
                editPrompt={() => {}}
                deletePrompt={() => {}}
              />
            )}

            {!!busyPrompt && (
              <Box className={styles.Message} ref={busyRef}>
                <Loader speedMultiplier={0.5} size={6} color="#cccccc" style={{ margin: 2 }} />
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }
);
