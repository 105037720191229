import { Button, Grid } from "@mui/material";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./choice_buttons.sass";

function ChoiceButton({
  choice,
  onToggle,
}: {
  choice: { label: string; value: string };
  onToggle: (choice: { label: string; value: string }, on: boolean) => void;
}) {
  const [on, setOn] = useState<boolean>(false);
  function toggle() {
    const newResult = !on;
    setOn(newResult);
    onToggle(choice, newResult);
  }
  return (
    <Button
      className="MultipleChoice"
      onClick={toggle}
      variant="outlined"
      style={{ borderColor: on ? "#3d3b44" : "#3d3b4422", backgroundColor: on ? "#ECEAF2" : "transparent" }}
    >
      <div className={`MultipleChoice__Icon ${on ? "MultipleChoice__Icon_on" : ""}`}>
        {on ? <CheckIcon style={{ color: "white", fontSize: 16 }} /> : null}
      </div>
      <span>{choice.label}</span>
    </Button>
  );
}

export default function ChoiceButtons({
  choices,
  onChange,
}: {
  choices: { label: string; value: string }[];
  onChange: (values: string[]) => void;
}) {
  const [values, setValues] = useState<Set<string>>(new Set());

  return (
    <Grid container columns={3}>
      {choices.map((c) => (
        <Grid item m={1} key={c.value}>
          <ChoiceButton
            choice={c}
            onToggle={(choice, on) => {
              if (on) {
                values.add(choice.value);
              } else {
                values.delete(choice.value);
              }

              setValues(values);
              onChange(Array.from(values.values()));
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
