import { Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import TwitterIcon from "assets/twitter-icon.svg";
import React from "react";
import "./sharing_info.sass";

export const GuestTokenSource_Share = "guest_token_source_share";
export const GuestTokenSource_Twitter = "guest_token_source_twitter";

export default function SharingInfo({ source, name }: { source: string; name: string }) {
  return (
    <div className="SharingInfo">
      {source === GuestTokenSource_Share && (
        <Box>
          <Typography variant="body1" className="SharingInfo__source">
            {name} took a healthy break and wanted to share it with you!
          </Typography>
        </Box>
      )}
      {source === GuestTokenSource_Twitter && (
        <Box>
          <Typography variant="h4" className="SharingInfo__source">
            Hey, let's take a healthy break!
          </Typography>
          <img src={TwitterIcon} alt="Twitter logo" />
        </Box>
      )}
      <div className="SharingInfo__powered">
        <Typography variant="body2" className="SharingInfo__source">
          Content provided by&nbsp;
          <a href="https://cuely.ai" target="_blank" rel="noreferrer">
            Cuely
          </a>
        </Typography>
      </div>
    </div>
  );
}
