import MultipleChoice, { MultipleChoiceOptions } from "./multiple_choice";
import styles from "./onboarding.module.sass";
import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { OnboardingProgress } from ".";
import { OnboardingQuestionInput, useDoSaveOnboardingQuestionMutation } from "generated/graphql";
import { queryClient } from "api";

const companyOptions = {
  options: [
    { value: "1", label: "Just me" },
    { value: "2-10", label: "2 - 10" },
    { value: "11-50", label: "11 - 50" },
    { value: "51-200", label: "51 - 200" },
    { value: "201-500", label: "201 - 500" },
    { value: "501-1000", label: "501 - 1000" },
    { value: "1001-5000", label: "1001 - 5000" },
    { value: "5001-10000", label: "5001 - 10000" },
    { value: "10000+", label: "10000+" },
  ],
} as MultipleChoiceOptions<string>;

export default function Company({
  progress,
  setProgress,
}: {
  progress: OnboardingProgress;
  setProgress: React.Dispatch<React.SetStateAction<OnboardingProgress>>;
}) {
  const [selected, setSelected] = useState<string[]>([]);
  const [pending, setPending] = useState<boolean>(false);

  const saveOnboardingQuestionMutation = useDoSaveOnboardingQuestionMutation();

  async function submit() {
    setPending(true);
    try {
      if (selected.length > 0) {
        await saveOnboardingQuestionMutation.mutateAsync({
          input: {
            version: "20230319",
            questionKey: "chatgpt-company-size",
            value: selected,
            completed: true,
          } as OnboardingQuestionInput,
        });

        await queryClient.invalidateQueries(["getCurrentUser"]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProgress({ ...progress, companySize: true, confirmation: undefined });
      setPending(false);
    }
  }

  function onOptionUpdate<T>(values: T[]) {
    setSelected(values as string[]);
  }

  return (
    <div className={styles.Step}>
      <Stack className={styles.Content} gap={3}>
        <Stack>
          <Typography className={styles.TopTitle} variant="h1">
            How big is your company?
          </Typography>
        </Stack>
        <MultipleChoice vertical options={companyOptions} callback={onOptionUpdate} />
      </Stack>
      <div className={styles.Bottom}>
        <Button
          variant="contained"
          disabled={pending}
          onClick={submit}
          className={`${selected.length === 0 ? styles.Disabled : ""}`}
        >
          Complete
        </Button>
      </div>
    </div>
  );
}

export function CompanyDemo({ progress }: { progress: OnboardingProgress }) {
  return (
    <div className={styles.Demo}>
      <Stack className={styles.Content} gap={2} sx={{ mb: 8 }}>
        {progress?.confirmation ? (
          <Typography variant="h1">{progress.confirmation}</Typography>
        ) : (
          <Typography variant="h1">Ship LLM apps faster</Typography>
        )}
        <Typography>Step 4 / 4</Typography>
      </Stack>
    </div>
  );
}
