import { _, Grid as GridJS } from "gridjs-react";
import { Cell, Row } from "gridjs";
import { Stack } from "@mui/material";
import { UserConfig } from "gridjs/dist/src/config";
import "./index.sass";

export declare type DataGridActions = {
  /**
   * The component here receives the cell and the row data
   * to make the proper use of the information allocated in the grid
   */
  component: (cell: any, row: any) => any;
};

export { Row, Cell };

export function DataGrid(props: UserConfig & { name?: string; actions?: DataGridActions[] }) {
  // console.log(`Datagrid name: ${props.name}`);
  return (
    <div>
      <GridJS
        {...props}
        columns={[
          ...(props.columns ?? []),
          props.actions
            ? {
                name: "Actions",
                sort: false,
                formatter: (cell: Cell, tRow: Row) =>
                  _(
                    <Stack direction={"row"}>
                      {props.actions && props.actions.map((action) => action.component(cell, tRow))}
                    </Stack>
                  ),
              }
            : {},
        ]}
      />
    </div>
  );
}
