import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import IconAbility from "assets/v2/icon-ability.svg";
import { SourcePurpose, SourceType, useDoCreateSourceMutation, useDoCreateUploadUrlMutation } from "generated/graphql";
import { useDropzone } from "react-dropzone";
import { LoadingButton } from "@mui/lab";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";

export default function SelectAbilityPurposesModal({
  id,
  open,
  onClose,
  onSucceed,
}: {
  id?: string;
  open: boolean;
  onClose: () => void;
  onSucceed?: (purposes: string[]) => void;
}) {
  return (
    <Dialog className="SelectAbililtyPurposes" open={open} onClose={onClose}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconAbility} alt="Select ability" />
          <Typography variant="h4">What types of ability would you like to add</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2}></Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={() => (onSucceed ? onSucceed([]) : null)}>
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

// export function AddSourceModal({
//   characterId,
//   open,
//   onClose,
//   onSucceed,
// }: {
//   characterId?: string;
//   open: boolean;
//   onClose: () => void;
//   onSucceed?: (purposes: string[]) => void;
// }) {
//   return (
//     <Dialog className="SelectSourceType" open={open} onClose={onClose}>
//       <DialogTitle sx={{ mt: 2 }}>
//         <Stack direction="row" alignItems="center" gap={1}>
//           <img src={IconAbility} alt="Select ability" />
//           <Typography variant="h4">Add new ability</Typography>
//         </Stack>
//       </DialogTitle>
//       <DialogContent sx={{ minWidth: 560 }}>
//         <Stack gap={2}></Stack>
//       </DialogContent>
//       <DialogActions>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
//           <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
//             <Button onClick={onClose}>Cancel</Button>
//             <Button variant="contained" onClick={() => (onSucceed ? onSucceed([]) : null)}>
//               Next
//             </Button>
//           </Stack>
//         </Stack>
//       </DialogActions>
//     </Dialog>
//   );
// }

export function CreateAbilityModal({
  characterId,
  open,
  onClose,
  onSucceed,
}: {
  characterId?: string;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
}) {
  const [error, setError] = useState<string>();
  const [busy, setBusy] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const { acceptedFiles, inputRef, getRootProps, getInputProps } = useDropzone({});

  const createUploadURLMutation = useDoCreateUploadUrlMutation();
  const createSourceMutation = useDoCreateSourceMutation();

  function close(event: React.MouseEvent<HTMLElement>, reason?: string | null) {
    if (reason && reason === "backdropClick") {
      return;
    }
    resetUI();
    onClose();
  }

  function resetUI() {
    setError(undefined);
    setBusy(false);
    setProgress(0);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
  }

  async function upload() {
    if (busy || !characterId || !acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    setError(undefined);
    setBusy(true);
    try {
      // create url
      const urlResp = await createUploadURLMutation.mutateAsync({
        input: {
          filename: acceptedFiles[0].name,
        },
      });

      if (!urlResp.createUploadURL.url || !urlResp.createUploadURL.fileKey || !urlResp.createUploadURL.filename) {
        setError("An error occurred while uploading the document. Please try again later.");
        return;
      }

      // upload file
      const f = acceptedFiles[0];
      const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total && progressEvent.total > 0) {
            setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        },
      } as AxiosRequestConfig<File>;
      setProgress(0);
      const result = await axios.put(urlResp.createUploadURL.url, f, config);
      if (result.status !== 200) {
        setError("An error occurred while uploading the document. Please try again later.");
        return;
      }

      // create source
      const source = await createSourceMutation.mutateAsync({
        input: {
          id: characterId,
          fileKey: urlResp.createUploadURL.fileKey,
          name: urlResp.createUploadURL.filename,
          type: SourceType.Documents,
          purposes: [SourcePurpose.InformationRetrieval],
        },
      });

      if (source.createSource?.id && onSucceed) {
        resetUI();
        onSucceed();
      }
    } catch (error) {
      setError(`${error}`);
      setBusy(false);
    }
  }

  return (
    <Dialog className="CreateAbilityModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconAbility} alt="Add ability" />
          <Typography variant="h4">Add Ability</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2}>
          {busy && <LinearProgress variant="determinate" value={progress} />}
          <Box {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} disabled={busy} />
            {acceptedFiles.length > 0 ? (
              <div>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {acceptedFiles[0]?.name}
                </Typography>
                <Typography>Drag & drop or click to upload another document</Typography>
              </div>
            ) : (
              <div>
                <Typography sx={{ mb: 2 }}>Drag & drop or click to upload a new document</Typography>
              </div>
            )}
          </Box>
          <Typography color="error">{error ? error : ""}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <LoadingButton onClick={(event) => close(event, undefined)} disabled={busy}>
              Cancel
            </LoadingButton>
            <LoadingButton disabled={acceptedFiles.length === 0 || busy} variant="contained" onClick={upload}>
              Upload
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
