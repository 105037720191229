import { Grid } from "@mui/material";
import MetricChart from "components/chart";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import {
  GetTeamActiveEmployeesCountQueryVariables,
  TimePeriod,
  useGetTeamActiveEmployeesCountQuery,
} from "generated/graphql";
import { periodRange, PeriodLimit, addDays, endOfWeek } from "utils";
import { useState } from "react";

const ActiveEmployees = ({
  companyIds,
  teamIds,
  startAt,
  endAt,
}: {
  companyIds?: string | string[] | undefined | null;
  teamIds?: string | string[] | undefined | null;
  startAt: Date;
  endAt: Date;
}) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  // GraphQL data call
  const activeEmployees = useGetTeamActiveEmployeesCountQuery({
    companyIds,
    teamIds,
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetTeamActiveEmployeesCountQueryVariables);

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    description = `active users for the selected time period`,
    actionToBeTakenText = `Launch a fun team challenge 👫 to increase the team's engagement`,
    // actionToBeTakenOnClick = () => {
    //   console.log("Setup team challenge!");
    // },
    dateFmt = "MMM do";
  let title = "Active Employees",
    subtitle = undefined, //To allow chart component to show a circle progress when loading ;)
    data = factoryDefaultData();

  if (!activeEmployees.isLoading && activeEmployees.isSuccess) {
    title = activeEmployees.data?.teamDashboard?.activeEmployeesCount.name ?? title;
    subtitle = activeEmployees.data?.teamDashboard?.activeEmployeesCount?.total;
    const rawData = activeEmployees.data?.teamDashboard?.activeEmployeesCount?.values;
    data = {
      labels: rawData?.map((ae) => {
        const start = format(parseISO(ae.date), dateFmt);
        const end = format(addDays(endOfWeek(parseISO(ae.date)), 1), dateFmt);

        return `${start}-${end}`;
      }),
      datasets: [
        {
          label: title,
          data: rawData?.map((ae) => ae.value),
          backgroundColor: rawData?.map((ae) => "#F584DE"),
        },
      ],
    };
  }

  return (
    <Grid item container xs={12} md={6} lg={6}>
      <MetricChart
        requireUpgrade={false}
        type={type}
        title={title}
        subtitle={subtitle}
        description={description}
        actionToBeTakenText={actionToBeTakenText}
        // actionToBeTakenOnClick={actionToBeTakenOnClick}
        dataSuccess={activeEmployees.isSuccess}
        dataLoading={activeEmployees.isLoading}
        hasEnoughData={activeEmployees.data?.teamDashboard?.hasEnoughData} // hard code because we always show total active employees
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                callback: function (value: any, index: any, ticks: any) {
                  const startDate = format(
                    parseISO(activeEmployees?.data?.teamDashboard?.activeEmployeesCount?.values[index].date),
                    "MMM do"
                  );
                  return startDate;
                },
              },
            },
          },
        }}
      />
    </Grid>
  );
};

export default ActiveEmployees;
