import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Icon from "@mui/material/Icon/Icon";
import IconBoundary from "assets/icon-boundary.svg";
import IconBreath from "assets/icon-breath.svg";
import IconCheckIn from "assets/icon-energy-checkin.svg";
import IconEyeBreak from "assets/icon-eye-break.svg";
import IconGratitude from "assets/icon-gratitude.svg";
import IconHydrate from "assets/icon-hydrate.svg";
import IconJournal from "assets/icon-journal.svg";
import IconLunch from "assets/icon-lunch.svg";
import IconMeeting from "assets/icon-meeting.svg";
import IconPriority from "assets/icon-priority.svg";
import IconStretch from "assets/icon-stretch.svg";
import IconWalk from "assets/icon-walk.svg";
import { ActivityType, PersonalScheduleItem } from "generated/graphql";

import { imageURL } from "lib/util";

function getImgIcon(icon: string, alt: string, className?: string): JSX.Element {
  return <img src={imageURL(icon)} alt={alt} className={className} style={{ height: 24 }} />;
}

export function getReminderTypeIcon(item: PersonalScheduleItem, className?: string): JSX.Element {
  const action = item.action;
  const title = item.title;
  switch (action) {
    case ActivityType.JoinMeeting:
      return getImgIcon(IconMeeting, action ?? title, className);
    case ActivityType.Breath:
      return getImgIcon(IconBreath, action ?? title, className);
    case ActivityType.EyeBreak:
      return getImgIcon(IconEyeBreak, action ?? title, className);
    case ActivityType.Stretch:
      return getImgIcon(IconStretch, action ?? title, className);
    case ActivityType.Walk:
      return getImgIcon(IconWalk, action ?? title, className);
    case ActivityType.Lunch:
      return getImgIcon(IconLunch, action ?? title, className);
    case ActivityType.Water:
      return getImgIcon(IconHydrate, action ?? title, className);
    case ActivityType.Journal:
      return getImgIcon(IconCheckIn, action ?? title, className);
    case ActivityType.Boundary:
      return getImgIcon(IconBoundary, action ?? title, className);
    case ActivityType.Gratitude:
      return getImgIcon(IconGratitude, action ?? title, className);
    case ActivityType.Priority:
      return getImgIcon(IconPriority, action ?? title, className);
    case ActivityType.Routine:
      return getImgIcon(IconJournal, action ?? title, className);
    case ActivityType.CustomReminder:
      return (
        <Icon className={className}>
          <NotificationsActiveIcon titleAccess={action ?? title} />
        </Icon>
      );
  }
}
