import { Prompt } from "generated/graphql";
import { useEffect, useState } from "react";
import mustache from "mustache";

function useValueChange(setter: React.Dispatch<React.SetStateAction<string>>) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value ?? "");
  };
}

export interface EditorModel {
  goal: string;
  content: string;
  perspective: string;
  audience: string;
  context: string;
  tone: string;
  format: string;
}

export function usePromptEditorModel(editingPrompt?: Prompt) {
  const promptFieldMap: { [key: string]: string | number | null | undefined } =
    editingPrompt?.fields?.reduce((map, el) => {
      if (el.name) {
        if ((el.values?.length ?? 0) > 0) {
          map[el.name] = el.values![0];
        } else if ((el.intValues?.length ?? 0) > 0) {
          map[el.name] = el.intValues![0];
        } else if ((el.floatValues?.length ?? 0) > 0) {
          map[el.name] = el.floatValues![0];
        }
      }
      return map;
    }, {} as { [key: string]: string | number | null | undefined }) ?? {};

  const [goal, setGoal] = useState<string>(`${promptFieldMap.goal ?? ""}`);
  const [content, setContent] = useState<string>(`${promptFieldMap.content ?? ""}`);
  const [perspective, setPerspective] = useState<string>(`${promptFieldMap.perspective ?? ""}`);
  const [audience, setAudience] = useState<string>(`${promptFieldMap.audience ?? ""}`);
  const [context, setContext] = useState<string>(`${promptFieldMap.context ?? ""}`);
  const [tone, setTone] = useState<string>(`${promptFieldMap.tone ?? ""}`);
  const [format, setFormat] = useState<string>(`${promptFieldMap.format ?? ""}`);
  const [rawPrompt, setRawPrompt] = useState<string>("");

  const template =
    (promptFieldMap.template as string) ??
    `{{#goal}}The purpose of this prompt is to {{{goal}}}.\n{{/goal}} {{#content}}{{{content}}}{{/content}}
{{#perspective}}Pretend you are a {{{perspective}}}{{/perspective}}
{{#audience}}The output is intended for {{{audience}}}{{/audience}}
{{#context}}Here is the context of the situation {{{context}}}{{/context}}
{{#tone}}The tone should be {{{tone}}}{{/tone}}
{{#format}}Output with the following format:\n{{{format}}}{{/format}}`;

  // todo: fix later
  useEffect(() => {
    setGoal(`${promptFieldMap.goal ?? ""}`);
    setContent(`${promptFieldMap.content ?? ""}`);
    setPerspective(`${promptFieldMap.perspective ?? ""}`);
    setAudience(`${promptFieldMap.audience ?? ""}`);
    setContext(`${promptFieldMap.context ?? ""}`);
    setTone(`${promptFieldMap.tone ?? ""}`);
    setFormat(`${promptFieldMap.format ?? ""}`);
  }, [
    promptFieldMap.audience,
    promptFieldMap.content,
    promptFieldMap.context,
    promptFieldMap.format,
    promptFieldMap.goal,
    promptFieldMap.perspective,
    promptFieldMap.tone,
  ]);

  function buildPrompt() {
    return mustache
      .render(template, { goal, content, perspective, audience, context, tone, format })
      .replaceAll(/\n{3,}/g, "\n\n");
  }

  return {
    template,

    goal,
    setGoal,
    onGoalChange: useValueChange(setGoal),

    content,
    setContent,
    onContentChange: useValueChange(setContent),

    audience,
    setAudience,
    onAudienceChange: useValueChange(setAudience),

    perspective,
    setPerspective,
    onPerspectiveChange: useValueChange(setPerspective),

    context,
    setContext,
    onContextChange: useValueChange(setContext),

    format,
    setFormat,
    onFormatChange: useValueChange(setFormat),

    tone,
    setTone,
    onToneChange: useValueChange(setTone),

    rawPrompt,
    setRawPrompt,

    buildPrompt,

    toFields: () => {
      return {
        userPrompt: rawPrompt,
        fields: [
          { name: "goal", values: [goal] },
          { name: "content", values: [content] },
          { name: "perspective", values: [perspective] },
          { name: "audience", values: [audience] },
          { name: "context", values: [context] },
          { name: "tone", values: [tone] },
          { name: "format", values: [format] },
          { name: "template", values: [template] },
        ],
      };
    },

    toMap: () => {
      return {
        rawPrompt,
        goal,
        content,
        perspective,
        audience,
        context,
        tone,
        format,
      };
    },
  };
}
