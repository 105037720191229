import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { api } from "api";
import {
  Plan,
  PlanOption,
  PaymentIntent,
  SetupIntent,
  Invoice,
  StripeSubscription,
  CurrentCustomer,
} from "generated/graphql";

export interface PendingPaymentIntent {
  paymentIntent: PaymentIntent;
  invoice?: Invoice | null;
  subscription?: StripeSubscription | null;
  planOption: PlanOption;
  plan?: Plan;
}

export interface PlanState {
  initialized: boolean;
  currentCustomer?: CurrentCustomer;
  plans: Plan[];
  options?: PlanOption[];
  error?: string;
  pendingPaymentIntent?: PendingPaymentIntent;
  pendingSetupIntent?: SetupIntent;
  showModal: boolean;
}

export const PlanStatusCanceled = "canceled";
export const PlanStatusActive = "active";
export const PlanStatusPastDue = "past_due";
export const PlanStatusTrialing = "trialing";
export const PlanStatusUnpaid = "unpaid";

export function isPlanActive(status?: string | null): boolean {
  return (
    status === PlanStatusActive ||
    status === PlanStatusPastDue ||
    status === PlanStatusTrialing ||
    status === PlanStatusUnpaid
  );
}

const initialState: PlanState = { initialized: false, plans: [], showModal: false };

export const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    plansLoaded: (state, action: PayloadAction<Plan[]>) => {
      state.plans = action.payload ?? [];
      state.initialized = true;
      return state;
    },
    customerLoaded: (state, action: PayloadAction<CurrentCustomer>) => {
      state.currentCustomer = action.payload;
      state.initialized = true;
      return state;
    },
    showModal: (state, action: PayloadAction<void>) => {
      state.showModal = true;
      return state;
    },
    closeModal: (state, action: PayloadAction<void>) => {
      state.showModal = false;
      return state;
    },
    optionsLoaded: (state, action: PayloadAction<PlanOption[]>) => {
      state.options = action.payload;
      return state;
    },
    paymentIntentSucceeded: (state) => {
      state.pendingPaymentIntent = undefined;
      return state;
    },
    paymentIntentPending: (state, action: PayloadAction<PendingPaymentIntent>) => {
      state.pendingPaymentIntent = action.payload;
      return state;
    },
    cancelPaymentIntent: (state) => {
      state.pendingPaymentIntent = undefined;
      return state;
    },
    setupIntentSucceeded: (state) => {
      state.pendingSetupIntent = undefined;
      return state;
    },
    setupIntentPending: (state, action: PayloadAction<SetupIntent>) => {
      state.pendingSetupIntent = action.payload;
      return state;
    },
    cancelSetupIntent: (state) => {
      state.pendingSetupIntent = undefined;
      return state;
    },
    errorOccured: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      return state;
    },
    clearCurrentPlan: (state) => {
      return initialState;
    },
  },
});

export const {
  plansLoaded,
  customerLoaded,
  optionsLoaded,
  paymentIntentPending,
  paymentIntentSucceeded,
  cancelPaymentIntent,
  setupIntentPending,
  setupIntentSucceeded,
  cancelSetupIntent,
  errorOccured,
  showModal,
  closeModal,
  clearCurrentPlan,
} = planSlice.actions;

export const setupPlan =
  (token: string) => async (dispatch: Dispatch<PayloadAction<PaymentIntent | SetupIntent | string>>) => {
    try {
      const { clientSecret } = await api("/plan/setup", {}).then((response) => {
        return response.json();
      });

      if (clientSecret) {
        // dispatch(setupIntentPending({ ClientSecret: clientSecret }));
      } else {
        dispatch(errorOccured("Failed to setup plan."));
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(errorOccured(`${error}`));
      }
    }
  };

export const trialPlan =
  (token: string, quantity: number) => async (dispatch: Dispatch<PayloadAction<Plan[] | string>>) => {
    try {
      const plan = await api("/plan/trial", {
        // monthly.
        priceId: "price_1Ks9hyJV5rc7NTkh2SyQoQbI",
        quantity,
      }).then((response) => {
        return response.json();
      });

      if (plan) {
        dispatch(plansLoaded([plan]));
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(errorOccured(`${error}`));
      }
    }
  };

export const addPlan =
  (token: string, quantity: number) => async (dispatch: Dispatch<PayloadAction<PaymentIntent | string>>) => {
    try {
      const { subscription, clientSecret } = await api("/plan/add", {
        // monthly.
        priceId: "price_1Ks9hyJV5rc7NTkh2SyQoQbI",
        quantity,
      }).then((response) => {
        return response.json();
      });

      if (subscription && clientSecret) {
        // dispatch(paymentIntentPending({ subscription, clientSecret } as PendingPaymentIntent));
      } else {
        dispatch(errorOccured("Failed to create new subscription."));
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(errorOccured(`${error}`));
      }
    }
  };

export const cancelPlan =
  (token: string, subscriptionId: string) => async (dispatch: Dispatch<PayloadAction<Plan[]>>) => {
    try {
      const plan = await api("/plan/cancel", { subscriptionId }).then((r) => r.json());
      if (plan) {
        dispatch(plansLoaded([plan]));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const selectPlan = (state: RootState) => state.plan;

export default planSlice.reducer;
