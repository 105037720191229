import React, { useState } from "react";
import styles from "./multiple_choice.module.sass";

export interface MultipleChoiceOption<T> {
  value: T;
  label: string;
  emoji: string;
}

export interface MultipleChoiceOptions<T> {
  options: MultipleChoiceOption<T>[];
}

export type MultipleChoiceOptionsCallback = <S>(values: S[]) => void;

function MultipleChoice<T>({
  options,
  callback,
  initialSelection,
  multiple,
  vertical,
  emojiOnly,
  left,
}: {
  options: MultipleChoiceOptions<T>;
  callback: MultipleChoiceOptionsCallback;
  initialSelection?: T[];
  multiple?: boolean;
  vertical?: boolean;
  left?: boolean;
  emojiOnly?: boolean;
}) {
  const [selected, setSelected] = useState<T[]>(initialSelection ?? []);
  const selectedSet = new Set<T>(selected);

  function toggle(value: T) {
    let newValues = [];
    if (!multiple) {
      // replace
      newValues = [value];
    } else {
      if (selectedSet.has(value)) {
        newValues = selected.filter((v) => v !== value);
      } else {
        newValues = [...selected, value];
      }
    }

    setSelected(newValues);
    callback(newValues);
  }

  return (
    <div className={`${styles.MultipleChoices} ${vertical ? styles.Vertical : ""} ${left ? styles.Left : ""}`}>
      {options.options.map((o) => (
        <div
          className={`${styles.MultipleChoice} ${selectedSet.has(o.value) ? styles.selected : ""}`}
          key={`multiple-choice-${o.value}`}
          onClick={() => toggle(o.value)}
        >
          {o.emoji} <span>{emojiOnly ? "" : o.label}</span>
        </div>
      ))}
    </div>
  );
}

export default MultipleChoice;
