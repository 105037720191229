import Box from "@mui/material/Box/Box";
import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import parseISO from "date-fns/parseISO";
import Skeleton from "react-loading-skeleton";
import { ActivityType, PersonalScheduleItem, ScheduleItemType, useDoLogActivityMutation } from "generated/graphql";
import { Button, Link, Menu, MenuItem, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { formatHour12Hour } from "utils";
import { getReminderTypeConfig } from "common/reminder_type_config";
import { getReminderTypeIcon } from "reminder_type_icon";
import { queryClient } from "api";
import { selectCurrentUser } from "store/current_user_reducer";
import { useAppSelector } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.sass";

function BreakPopover({ item, close }: { item: PersonalScheduleItem; close: () => void }) {
  const startAt = parseISO(item.startAt);
  const navigate = useNavigate();
  const now = new Date();
  const config = getReminderTypeConfig(item);
  const icon = getReminderTypeIcon(item, "BreakInfoPopover__icon");
  const [gif, setGif] = useState<string | null>(null);

  const logActivityMutation = useDoLogActivityMutation();

  async function actionHandler() {
    if (config?.link) {
      navigate(config.link);
      close();
      return;
    }

    switch (item.action) {
      // links
      case ActivityType.JoinMeeting:
        break;
      case ActivityType.Breath:
        break;
      case ActivityType.EyeBreak:
        break;
      case ActivityType.Stretch:
        break;
      case ActivityType.CustomReminder:
        break;

      // i did it
      case ActivityType.Walk:
      case ActivityType.Lunch:
      case ActivityType.Water:
      case ActivityType.Boundary:
        const result = await logActivityMutation.mutateAsync({
          input: {
            action: item.action,
            from: "web",
            reminderID: item.id,
          },
        });

        queryClient.invalidateQueries(["getCurrentUser"]);

        if (!!result?.logActivity?.gif) {
          setGif(result.logActivity.gif);
          setTimeout(() => close(), 5000);
          return;
        }

        break;

      // reflections
      case ActivityType.Journal:
        break;
      case ActivityType.Routine:
        break;
      case ActivityType.Gratitude:
        break;
      case ActivityType.Priority:
        break;
    }

    close();
  }

  return (
    <Stack sx={{ p: 2 }} className="BreakInfoPopover">
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }} style={{ color: config?.color ?? "#333" }}>
        {icon}
        <Typography variant="h6" className="BreakInfoPopover__title">
          {item.title}
        </Typography>
      </Stack>
      <Typography variant="body2">
        {item.done || gif
          ? "You did it, nice job!"
          : startAt > now
          ? `We'll remind you at ${format(startAt, "h:mm a")}`
          : `Due ${formatDistanceToNowStrict(startAt)} ago`}
      </Typography>
      {!item.done && !gif && config?.button && (startAt.getHours() === now.getHours() || startAt < now) && (
        <Button
          onClick={actionHandler}
          sx={{ mt: 2 }}
          variant="contained"
          style={{ background: config?.color ?? "#e8e8e8", color: "white" }}
          disabled={logActivityMutation.isLoading}
        >
          {config.button}
        </Button>
      )}
      {!!gif && (
        <Box sx={{ maxWidth: 360, mt: 1 }}>
          <img style={{ width: "100%" }} src={gif} alt="Nice job!" />
        </Box>
      )}
    </Stack>
  );
}

function MeetingPopover({ item, close }: { item: PersonalScheduleItem; close: () => void }) {
  const [openOtherLinks, setOpenOtherLinks] = useState<boolean>(false);
  const otherLinkEl = useRef<HTMLElement | null>(null);

  const startAt = parseISO(item.startAt);
  const endAt = parseISO(item.endAt);

  const urls = item.urls ?? [];
  const joinableLinks = urls.length === 1 ? [urls[0]] : urls.filter((url) => url.canJoin);
  const otherLinks = urls.length === 1 ? [] : urls.filter((url) => !url.canJoin);

  function handleOpenOtherLinks(event: React.MouseEvent<HTMLElement>) {
    otherLinkEl.current = event.currentTarget;
    setOpenOtherLinks(true);
  }

  function handleCloseOtherLinks() {
    otherLinkEl.current = null;
    setOpenOtherLinks(false);
  }

  return (
    <Stack sx={{ p: 2 }} className="MeetingInfoPopover">
      <Typography variant="body2">
        {format(startAt, "h:mm a")} - {format(endAt, "hh:mm a")}
      </Typography>
      <Typography variant="h6">{item.title}</Typography>
      {joinableLinks.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {joinableLinks.map((url) => {
            const host = new URL(url.url).host;
            return (
              <Tooltip title={url.url} placement="right" key={`joinable-link-${url}`}>
                <Button
                  onClick={() => close()}
                  key={url.url}
                  href={url.url}
                  target="_blank"
                  color="primary"
                  variant="contained"
                  startIcon={
                    url.icon && url.icon !== "" ? (
                      <img className="MeetingInfoPopover__MeetingIcon" src={url.icon} alt={host} />
                    ) : (
                      <LaunchIcon />
                    )
                  }
                >
                  {host}
                </Button>
              </Tooltip>
            );
          })}
        </Box>
      )}
      {otherLinks.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Button startIcon={<LinkIcon />} onClick={handleOpenOtherLinks}>
            Other Links
          </Button>
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={openOtherLinks}
            anchorEl={otherLinkEl.current}
            onClose={handleCloseOtherLinks}
          >
            {otherLinks.map((url) => {
              const host = new URL(url.url).host;
              return (
                <Tooltip title={url.url} placement="right" key={`other-link-${url}`}>
                  <MenuItem>
                    <Link href={url.url} target="_blank" sx={{ textDecoration: "none" }}>
                      {host}
                    </Link>
                  </MenuItem>
                </Tooltip>
              );
            })}
          </Menu>
        </Box>
      )}
    </Stack>
  );
}

export default function DailyCalendar({ width }: { width: number }) {
  const { currentUser } = useAppSelector(selectCurrentUser);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const currentHourEl = useRef<HTMLElement>(null);
  const hoursEl = useRef<HTMLElement>(null);
  const calendarItemEl = useRef<HTMLElement | null>(null);
  const [calendarItem, setCalendarItem] = useState<PersonalScheduleItem | null>(null);

  let startHour: number = -1;
  let endHour: number = -1;

  const hourlyItems =
    currentUser?.personalSchedule?.items?.reduce((map: { [key: number]: [PersonalScheduleItem] }, i) => {
      try {
        const hours = parseISO(i.startAt).getHours();

        // side effect, update startHour and endHour
        if (startHour < 0 || hours < startHour) {
          startHour = Math.max(0, hours - 1);
        }

        if (endHour < 0 || hours > endHour) {
          endHour = Math.min(24, hours + 2);
        }

        if (map[hours]) {
          map[hours].push(i);
        } else {
          map[hours] = [i];
        }

        // sort
        map[hours].sort((a, b) => {
          if (a.startAt === b.startAt) {
            if (a.itemType === ScheduleItemType.Meeting && a.itemType !== b.itemType) {
              return -1;
            }
            if (b.itemType === ScheduleItemType.Meeting && a.itemType !== b.itemType) {
              return 1;
            }
          }
          return a.startAt < b.startAt ? -1 : 1;
        });
      } catch (error) {}
      return map;
    }, {} as { [key: number]: [PersonalScheduleItem] }) ?? {};

  const weekday = format(new Date(), "EEEE");
  const date = format(new Date(), "MMM dd");
  const year = format(new Date(), "yyyy");

  const [scrolled, setScrolled] = useState<boolean>(false);

  // make sure we include current hour
  startHour = Math.min(new Date().getHours(), startHour < 0 ? 8 : startHour);
  endHour = endHour < 0 ? 20 : endHour;

  useEffect(() => {
    if (currentHourEl && !scrolled && hoursEl?.current) {
      const height = hoursEl.current.getBoundingClientRect().height;
      // only scroll if needed.
      if (hoursEl.current.scrollHeight > height) {
        currentHourEl.current?.scrollIntoView();
      }
      setScrolled(true);
    }
  }, [scrolled]);

  function toggleDetail(event: React.MouseEvent<HTMLElement>, item: PersonalScheduleItem) {
    if (detailOpen) {
      setDetailOpen(false);
      setCalendarItem(null);
      calendarItemEl.current = null;
    } else {
      setDetailOpen(true);
      setCalendarItem(item);
      calendarItemEl.current = event.currentTarget.closest(".DailyCalendar__hour");
    }
  }

  function handleClose() {
    setDetailOpen(false);
    calendarItemEl.current = null;
  }

  const currentHour = new Date().getHours();

  return (
    <Box className="DailyCalendar" sx={{ width }}>
      <Box className="DailyCalendar__title" display="flex" alignItems="space-between">
        <Typography>{weekday}</Typography>
        <Typography>
          <b>{date}</b>, {year}
        </Typography>
      </Box>
      <Stack className="DailyCalendar__hours" ref={hoursEl}>
        {Array.from({ length: Math.max(0, endHour - startHour + 1) }, (_, key) => key + startHour).map((i) => {
          // generate hours from first event to last event of the day
          return (
            <Box
              className={`DailyCalendar__hour ${
                currentUser?.personalSchedule && (i < 8 || i > 18 || i < currentHour) ? "DailyCalendar__hour_off" : ""
              }`}
              key={`daily-calendar-hour-${i}`}
              ref={currentHour === i ? currentHourEl : undefined}
            >
              {currentHour === i && (
                <div
                  className="DailyCalendar__currentMarker"
                  style={{ top: (new Date().getMinutes() / 60) * 100 + "%" }}
                />
              )}
              {currentUser?.personalSchedule ? (
                <Box display="flex">
                  <span className="DailyCalendar__label">{formatHour12Hour(i)}</span>
                  <Stack className="DailyCalendar__items" spacing={0.5}>
                    {(hourlyItems[i] ?? []).map((item: PersonalScheduleItem) => {
                      const config = getReminderTypeConfig(item);
                      const icon = getReminderTypeIcon(item, "DailyCalendar__itemIcon");
                      const isMeeting = item.itemType === ScheduleItemType.Meeting;
                      return (
                        <Stack
                          alignItems="center"
                          direction="row"
                          key={`${item.startAt}-${item.title}`}
                          className={`DailyCalendar__item ${item.done ? "DailyCalendar__item_done" : ""} ${
                            isMeeting ? "DailyCalendar__item_meeting" : "DailyCalendar__item_break"
                          }`}
                          onClick={(event) => toggleDetail(event, item)}
                          style={{
                            backgroundColor: config?.background ?? "#e8e8e8",
                            color: config?.color ?? "#333",
                          }}
                        >
                          {icon}
                          <Typography variant="body2" style={{ color: config?.color ?? "#333" }}>
                            {item.title}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Box>
              ) : (
                <Skeleton />
              )}
            </Box>
          );
        })}
      </Stack>
      <Popover
        open={detailOpen && !!calendarItem}
        anchorEl={calendarItemEl.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        {calendarItem &&
          (calendarItem?.itemType === ScheduleItemType.Meeting ? (
            <MeetingPopover item={calendarItem} close={handleClose} />
          ) : (
            <BreakPopover item={calendarItem} close={handleClose} />
          ))}
      </Popover>
    </Box>
  );
}
