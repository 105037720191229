import MetricChart from "components/chart";
import { addDays, endOfWeek, PeriodLimit, periodRange } from "utils";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { GetPersonalEnergyTrendQueryVariables, TimePeriod, useGetPersonalEnergyTrendQuery } from "generated/graphql";
import { Grid } from "@mui/material";
import { useState } from "react";
// import RouteNames from "route_names";

const PersonalEnergyTrend = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  // GraphQL data call
  const personalEnergyTrend = useGetPersonalEnergyTrendQuery({
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetPersonalEnergyTrendQueryVariables);

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "MMM do",
    description = `average energy for selected time period`,
    actionToBeTakenText = `Set up an "energy reflection" reminder on a team channel to understand what's impacting your team's energy`; /*,
    actionToBeTakenOnClick = () => {
      navigate(RouteNames.ReminderSettings);
    }*/
  let title = "Energy Trend",
    subtitle = undefined, // To allow chart component to show a circle progress when loading ;)
    hoverCardTitle = "Energy Level",
    chartData = factoryDefaultData();

  if (!personalEnergyTrend.isLoading && personalEnergyTrend.isSuccess) {
    title = personalEnergyTrend.data?.personalDashboard?.energyTrendLevels?.name ?? title;
    subtitle = personalEnergyTrend.data?.personalDashboard?.energyTrendLevels?.average;
    const rawData = personalEnergyTrend.data.personalDashboard?.energyTrendLevels.values;
    chartData = {
      labels: rawData?.map((et) => {
        const start = format(parseISO(et.period), dateFmt);
        const end = format(addDays(endOfWeek(parseISO(et.period)), 1), dateFmt);

        return `${start}-${end}`;
      }),
      datasets: [
        {
          label: hoverCardTitle,
          data: rawData?.map((et) => et.avgAllEnergy),
          backgroundColor: rawData?.map((et) => "#F584DE"),
        },
      ],
    };
  }
  const hasEnoughData = personalEnergyTrend.data?.personalDashboard?.energyTrendLevels.values
    ? personalEnergyTrend.data?.personalDashboard?.energyTrendLevels.values.length > 0
    : false;
  return (
    <Grid item xs={12} md={12} lg={12}>
      <MetricChart
        requireUpgrade={false}
        type={type}
        title={title}
        subtitle={subtitle}
        description={description}
        actionToBeTakenText={actionToBeTakenText}
        dataSuccess={personalEnergyTrend.isSuccess}
        dataLoading={personalEnergyTrend.isLoading}
        hasEnoughData={hasEnoughData}
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                callback: function (value: any, index: any, ticks: any) {
                  const startDate = format(
                    parseISO(personalEnergyTrend?.data?.personalDashboard?.energyTrendLevels?.values[index].period),
                    "MMM do"
                  );
                  return startDate;
                },
              },
            },
          },
        }}
      />
    </Grid>
  );
};

export default PersonalEnergyTrend;
