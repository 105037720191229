import MetricChart, { ChartParams } from ".";
import RouteNames from "route_names";
import { Events, track } from "analytics";
import { useAppNavigate } from "hooks";

export default function PremiumMetricChart(params: ChartParams) {
  const navigate = useAppNavigate();

  function showPlans() {
    navigate(RouteNames.Plan);
    if (params.showPlans) {
      params.showPlans();
    }
    track(Events.ProtectedFeature.Clicked, { type: params.type, title: params.title, subtitle: params.subtitle });
  }

  return <MetricChart {...params} showPlans={showPlans} requireUpgrade={true} />;
}
