import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Stack, Typography } from "@mui/material";
import { queryClient } from "api";
import FeelingMap from "feelings";
import { MindfulnessAreaEntry, MindfulnessFeelingEntry, useDoLogMindfulnessMutation } from "generated/graphql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchReminderID } from "utils";
import ChoiceButtons from "./choice-bar";
import EnergyButtons, { enermoji } from "./energy-buttons";

enum EnergyModalStep {
  Rate,
  Feeling,
  Area,
}

function getRatingAcknowledgement(energy: number): string {
  switch (energy) {
    case 1:
      return `Sorry to hear you're feeling ${enermoji(energy)}`;
    case 2:
      return `Sorry to hear you're feeling ${enermoji(energy)}`;
    case 4:
      return `Glad to hear you're feeling ${enermoji(energy)}.`;
    case 5:
      return `Glad to hear you're feeling ${enermoji(energy)}.`;
    default:
      return `You're feeling ${enermoji(energy)}`;
  }
}

function getFeelingChoices(energy: number): { label: string; value: string }[] {
  switch (energy) {
    case 1:
      return FeelingMap[1];
    case 2:
      return FeelingMap[2];
    case 4:
      return FeelingMap[4];
    case 5:
      return FeelingMap[5];
    default:
      return FeelingMap[3];
  }
}

function getAreasChoices(): { label: string; value: string }[] {
  return [
    { value: "area-wellness", label: "Wellness" },
    { value: "area-sleep", label: "Sleep Quality" },
    { value: "area-physical", label: "Physical Health" },
    { value: "area-food", label: "Food" },
    { value: "area-personal", label: "Personal Life" },
    { value: "area-work", label: "Workload & Productivity" },
    { value: "area-colleague", label: "Team & Colleagues" },
    { value: "area-company", label: "Company Culture & Direction" },
    { value: "area-other", label: "Other" },
  ];
}

export default function EnergyModal() {
  const navigate = useNavigate();
  const [step, setStep] = useState<EnergyModalStep>(EnergyModalStep.Rate);
  const [energy, setEnergy] = useState<number>(0);
  const [areas, setAreas] = useState<string[]>([]);
  const [feelings, setFeelings] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const reminderID = useSearchReminderID();
  const logMindfulnessMutation = useDoLogMindfulnessMutation();

  function close() {
    navigate("/");
  }

  function validate(): boolean {
    return true;
  }

  async function saveAndClose() {
    if (validate()) {
      setSaving(true);

      const feelingsEntries = feelings.map((f) => ({ feeling: f, energy } as MindfulnessFeelingEntry));
      const areasEntries = areas.map((a) => ({ area: a, energy } as MindfulnessAreaEntry));

      try {
        await Promise.all([
          logMindfulnessMutation.mutateAsync({
            input: {
              energy,
              reminderID,
              feelings: feelingsEntries,
              areas: areasEntries,
            },
          }),
        ]);

        close();
        queryClient.invalidateQueries(["getCurrentUser"]);
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="EnergyModal" open={true} onClose={close}>
      <DialogTitle>Energy reflection</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        {step === EnergyModalStep.Rate && (
          <Stack>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              What's your energy level now?
            </Typography>
            <EnergyButtons
              disabled={saving}
              onChange={(val) => {
                setEnergy(val);
                setStep(EnergyModalStep.Feeling);
              }}
            />
          </Stack>
        )}
        {step === EnergyModalStep.Feeling && (
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Stack>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {getRatingAcknowledgement(energy)}
              </Typography>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                How would you describe how you're feeling?
              </Typography>
              <ChoiceButtons choices={getFeelingChoices(energy)} onChange={(values) => setFeelings(values)} />
            </Stack>
          </Slide>
        )}
        {step === EnergyModalStep.Area && (
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Stack>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                What factors would you attribute to these feelings?
              </Typography>
              <ChoiceButtons choices={getAreasChoices()} onChange={(values) => setAreas(values)} />
            </Stack>
          </Slide>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ p: 1 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close}>Cancel</Button>
            {step !== EnergyModalStep.Rate && (
              <Button
                onClick={() => {
                  if (step === EnergyModalStep.Area) {
                    setStep(EnergyModalStep.Feeling);
                  } else if (step === EnergyModalStep.Feeling) {
                    setStep(EnergyModalStep.Rate);
                  }
                }}
                variant="contained"
              >
                Back
              </Button>
            )}
            {step === EnergyModalStep.Feeling && (
              <Button
                onClick={() => {
                  setStep(EnergyModalStep.Area);
                }}
                variant="contained"
              >
                Next
              </Button>
            )}
            {step === EnergyModalStep.Area && (
              <Button
                disabled={!validate()}
                onClick={() => {
                  saveAndClose();
                }}
                variant="contained"
              >
                Save
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
