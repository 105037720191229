import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconAdjustMode from "assets/v2/icon-adjust-layout.svg";
import IconAi from "assets/v2/icon-ai.svg";
import IconButton from "@mui/material/IconButton";
import IconClipboard from "assets/v2/icon-clipboard.svg";
import IconMenu from "assets/v2/icon-menu.svg";
import IconSend from "assets/v2/icon-send.svg";
import IconShortcut from "assets/v2/icon-shortcut.svg";
import Popover from "@mui/material/Popover";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import styles from "./index.module.sass";
import TextareaAutosize from "react-textarea-autosize";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import usePickerPopover from "components/pickers/picker_popover_hook";
import { BehaviorSubject, debounceTime, distinctUntilChanged } from "rxjs";
import { Character, Prompt } from "generated/graphql";
import { CharacterPickerPopover } from "./character_picker";
import { createRef, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { ModelOptions } from "./model_options";
import { useCachedAiModel } from "hooks";

const onHeightChange$ = new BehaviorSubject<number>(0);

export function ModelOptionsPopover({
  open,
  anchorEl,
  model,
  dropup,
  onClose,
  onChange,
}: {
  open: boolean;
  dropup?: boolean;
  anchorEl?: HTMLDivElement;
  model: string;
  onClose: () => void;
  onChange: (model: string) => void;
}) {
  return (
    <Popover
      className="ModelOptionsPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: dropup ? "bottom" : "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: dropup ? "top" : "bottom",
        horizontal: "left",
      }}
      onClose={onClose}
      elevation={20}
    >
      <ModelOptions
        model={model}
        onChange={(model) => {
          onChange(model);
          onClose();
        }}
      />
    </Popover>
  );
}

export const PromptBox = forwardRef(
  (
    {
      prompt,
      isRaw,
      toggleMode,
      copyPrompt,
      copied,
      busy,
      generate,
      onRawPromptChange,
      isGuest,
      setMobileShowResponse,
      onHeightChange,
      mobile,
      hasResponse,
      editorOpen,
      character,
      setCharacter,
    }: {
      prompt: string;
      isRaw: boolean;
      rawPrompt: string;
      editingPrompt?: Prompt | null;
      toggleMode: () => void;
      copyPrompt: () => void;
      copied: boolean;
      busy: boolean;
      generate: (presetPrompt?: string) => void;
      onRawPromptChange: (value: string) => void;
      isGuest: boolean;
      setMobileShowResponse: () => void;
      onHeightChange: (height: number) => void;
      mobile: boolean;
      hasResponse: boolean;
      editorOpen: boolean;
      character?: Character | null;
      setCharacter: (characters: Character) => void;
    },
    ref
  ) => {
    const [shortcutOpen, setShortcutOpen] = useState<boolean>(true);
    const inputRef = createRef<HTMLTextAreaElement>();
    const boxRef = useRef<HTMLDivElement>();
    const shortcutRef = useRef<HTMLDivElement>();
    const [modelOptionsOpen, setModelOptionsOpen] = useState<boolean>(false);
    const [defaultModel, customModel, setDefaultModel, defaultModelLabel] = useCachedAiModel();
    const isCustomModel = !!customModel;

    const [characterPickerAnchor, , openCharacterPicker, , closeCharacterPicker] = usePickerPopover<
      Character | null | undefined
    >();

    const getBoxHeight = useCallback(() => {
      const boxRect = boxRef.current?.getBoundingClientRect();
      return mobile || !editorOpen ? (window.visualViewport?.height ?? window.innerHeight) - (boxRect?.top ?? 0) : 0;
    }, [editorOpen, mobile]);

    useEffect(() => {
      const changeSubscription = onHeightChange$
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(500))
        .subscribe(() => {
          onHeightChange(getBoxHeight());
        });

      return () => {
        changeSubscription.unsubscribe();
      };
    }, [getBoxHeight, onHeightChange]);

    useEffect(() => {
      // initialze the prompt
      if (inputRef.current && inputRef.current?.value !== prompt) {
        inputRef.current.value = prompt;
      }
    }, [inputRef, prompt]);

    async function onPromptChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      if (event.target) {
        onRawPromptChange(event.target.value);
      }
    }

    function onKey(event: React.KeyboardEvent<HTMLTextAreaElement>) {
      if (!event.shiftKey && (event.key === "Enter" || event.key === "Return")) {
        onRawPromptChange(inputRef.current?.value ?? "");
        generate(inputRef.current?.value ?? "");

        if (inputRef.current) {
          inputRef.current.value = "";
        }

        onHeightChange(getBoxHeight());
        event.preventDefault();
        return false;
      }
    }

    function toggleShortcut() {
      setShortcutOpen(!shortcutOpen);
    }

    const leftIcon = isGuest ? (
      <IconButton onClick={setMobileShowResponse}>
        <img src={IconMenu} alt="Adjust mode" />
      </IconButton>
    ) : (
      <IconButton onClick={() => toggleMode()}>
        <Tooltip placement="bottom" title={isRaw ? "Open guided editor (ctrl+1)" : "Close guided editor (ctrl+1)"}>
          <img src={IconAdjustMode} alt="Adjust mode" style={{ transform: isRaw ? "scaleX(-1)" : "none" }} />
        </Tooltip>
      </IconButton>
    );

    const copyIcon = (
      <IconButton disabled={!prompt || prompt === ""} onClick={copyPrompt}>
        <Tooltip open={copied} placement="bottom" title={copied ? "Copied!" : "Copy to clipboard"}>
          <img src={IconClipboard} alt="clipboard" />
        </Tooltip>
      </IconButton>
    );

    const sendIcon = (
      <IconButton disabled={!prompt || prompt === "" || busy} onClick={() => generate()}>
        <Tooltip placement="bottom" title="Generate">
          <img src={IconSend} alt="Send" />
        </Tooltip>
      </IconButton>
    );

    const shortcutIcon = (
      <IconButton onClick={toggleShortcut}>
        <img src={IconShortcut} alt="Open shortcut" />
      </IconButton>
    );

    const shareRef = (el: HTMLDivElement) => {
      boxRef.current = el;
      if (typeof ref === "function") {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    };

    return !isRaw ? (
      <Stack sx={{ pt: 2, px: 3, pb: 2 }} gap={1} className={styles.PromptBox} flex="0 0 auto" ref={shareRef}>
        <Typography variant="h6">Prompt preview</Typography>
        <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-end">
          <Box
            flex={1}
            className={styles.PromptPreview}
            style={{ whiteSpace: "pre-wrap", minHeight: mobile ? "5em" : "6em", position: "relative" }}
          >
            {prompt === "" ? "Empty prompt" : prompt}
            {mobile || prompt === "" ? null : (
              <Box style={{ position: "absolute", bottom: 6, right: 6 }}>{copyIcon}</Box>
            )}
          </Box>
          {mobile ? (
            <Stack>
              {leftIcon}
              {sendIcon}
            </Stack>
          ) : (
            <Stack>
              {leftIcon}
              {sendIcon}
            </Stack>
          )}
        </Stack>
      </Stack>
    ) : (
      <Box className={styles.PromptBoxWrapper} ref={boxRef}>
        <Slide direction="up" in={!editorOpen} mountOnEnter unmountOnExit>
          <Stack ref={ref}>
            {shortcutOpen && (
              <Stack
                ref={shortcutRef}
                className={styles.Shortcut}
                direction="row"
                gap={1}
                alignItems="flex-end"
                justifyContent="space-between"
                sx={{ pt: 1, mb: 2 }}
              >
                <Stack className={styles.Roles} alignItems="center" direction="row" gap={1}>
                  <Chip
                    sx={{ backdropFilter: "blur(10px)" }}
                    onClick={() => setModelOptionsOpen(true)}
                    icon={<img src={IconAi} alt="settings" style={{ paddingLeft: 4 }} />}
                    label={defaultModelLabel}
                  />
                  {!isCustomModel &&
                    (character ? (
                      <Chip
                        sx={{ backdropFilter: "blur(10px)" }}
                        onClick={(event) =>
                          openCharacterPicker(character, event.currentTarget, (val) => {
                            if (val) {
                              setCharacter(val);
                            }
                          })
                        }
                        avatar={<Avatar alt={character.name} src={character.picture ?? ""} />}
                        label={character.name}
                      />
                    ) : (
                      <Chip
                        sx={{ backdropFilter: "blur(10px)" }}
                        onClick={(event) =>
                          openCharacterPicker(character, event.currentTarget, (val) => {
                            if (val) {
                              setCharacter(val);
                            }
                          })
                        }
                        avatar={<Avatar alt="AI Language Model" />}
                        label="AI Language Model"
                      />
                    ))}
                </Stack>
                {hasResponse && !isCustomModel && (
                  <Stack className={styles.Actions} direction="row" gap={1}>
                    <Button
                      className={styles.Button}
                      variant="contained"
                      size="small"
                      sx={{ borderRadius: 50 }}
                      onClick={() => generate("Continue writing")}
                    >
                      Tell me more
                    </Button>
                    <Button
                      className={styles.Button}
                      variant="contained"
                      size="small"
                      sx={{ borderRadius: 50 }}
                      onClick={() => generate("Generate the last prompt again")}
                    >
                      Regenerate
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}

            <Stack className={styles.PromptBox} direction="row" gap={1} alignItems="center" justifySelf="flex-end">
              {mobile ? null : leftIcon}
              <TextareaAutosize
                key="promot-textarea"
                cacheMeasurements
                ref={inputRef}
                defaultValue={prompt}
                className={styles.PromptPreview}
                onChange={onPromptChange}
                minRows={mobile ? 3 : 1}
                onKeyDown={onKey}
                autoFocus
                placeholder={
                  mobile ? "Start typing prompts..." : "Start typing prompts. Shift + Enter to create a new line."
                }
              />
              {mobile ? null : shortcutIcon}
              {mobile ? (
                <Stack>
                  {leftIcon}
                  {sendIcon}
                </Stack>
              ) : (
                sendIcon
              )}
            </Stack>

            <ModelOptionsPopover
              model={defaultModel}
              onChange={setDefaultModel}
              anchorEl={shortcutRef.current}
              open={modelOptionsOpen}
              onClose={() => setModelOptionsOpen(false)}
            />

            <CharacterPickerPopover
              open={!!characterPickerAnchor}
              onClose={closeCharacterPicker}
              character={character}
              setCharacter={setCharacter}
              anchorEl={characterPickerAnchor}
            />
          </Stack>
        </Slide>
      </Box>
    );
  }
);
