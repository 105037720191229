export const DoughnutTooltipCallbacks = (
  titlePrefix: string = "Factor: ",
  labelPrefix: string = "Count: ",
  footerPrefix: string = "Percentage: "
) => ({
  title: (ctx: any) => (ctx && ctx?.length && ctx[0].parsed !== null ? `${titlePrefix}${ctx[0].label}` : ""),
  label: (ctx: any) => `${labelPrefix}${ctx?.parsed}`,
  footer: (ctx: any) =>
    `${footerPrefix}${Math.floor(
      (100 * ctx[0]?.dataset?.data[ctx[0].dataIndex]) /
        ctx[0]?.dataset?.data.reduce((acc: number, c: number) => (acc += c), 0)
    )}%`,
});

export const LineTooltipCallbacks = (
  metaData: any,
  titlePrefix: string = " ",
  labelPrefix: string = "Value: ",
  footerPrefix: string = " "
) => ({
  title: (ctx: any) => (ctx && ctx?.length && ctx[0].parsed !== null ? `${titlePrefix}${ctx[0].label}` : ""),
  label: (ctx: any) => `${labelPrefix}${ctx?.raw}`,
  footer: (ctx: any) => `${footerPrefix}${metaData[ctx[0].dataIndex]}`,
});

export const SkippedPoints = (ctx: any, value: any) => (ctx.p0.skip || ctx.p1.skip ? value : undefined);
export const UpTrend = (ctx: any, value: any) => (ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined);
export const DownTrend = (ctx: any, value: any) => (ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined);
