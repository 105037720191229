import appVariables from "variables";

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit["headers"]
): (() => Promise<TData>) => {
  return async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return Promise.reject(new Error("Not authorized"));
    }
    const res = await fetch(`${appVariables.SERVER_HOST}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-cuely-token": token,
        "x-gists-token": token,
        ...(options ?? {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    // debug
    // const json = await new Promise(resolve => setTimeout(resolve, 10000000)).then(() => res.json());

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || "Error..";
      throw new Error(message);
    }

    return json.data;
  };
};
