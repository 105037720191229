import { AuthState } from "store/auth_reducer";

declare global {
  interface Window {
    chrome: any,
  }
}

const ExtensionDivID = "__CUELY_EXTENSION_ID__";

export default function notifyAuthChangeToExtension(auth: AuthState) {
  const extensionIdDiv = document.getElementById(ExtensionDivID);
  if (extensionIdDiv) {
    const extensionId = extensionIdDiv.getAttribute("data-extension-id");
    if (extensionId && window?.chrome?.runtime?.sendMessage) {
      try {
        window.chrome.runtime.sendMessage(extensionId, { auth }, function () { return true; });
      } catch (error) {
        console.log(error);
      }
    }
  }
}
