import CreateDatasetModal from "./create_dataset_modal";
import CreateModelModal from "./create_model_modal";
import IconEdit from "assets/v2/icon-edit.svg";
import IconRawMode from "assets/v2/icon-raw-mode.svg";
import IconSolidOkay from "assets/v2/icon-solid-ok.svg";
import IconTrainingData from "assets/v2/icon-training-data.svg";
import Layout from "components/layout/layout";
import Loader from "react-spinners/ScaleLoader";
import parseISO from "date-fns/parseISO";
import RingLoader from "react-spinners/RingLoader";
import RouteNames from "route_names";
import { AiModelDataset, GptDatesetStatus, useGetModelDatasetsQuery, useGetModelsQuery } from "generated/graphql";
import { Box, Breadcrumbs, Button, Chip, IconButton, List, Skeleton, Stack, Typography } from "@mui/material";
import { formatDate, formatTime } from "lib/util";
import { Link, useParams } from "react-router-dom";
import { queryClient } from "api";
import { selectCurrentUser } from "store/current_user_reducer";
import { switchModel } from "store/models_reducer";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import "./index.sass";

function ModelCardPlaceholder() {
  return (
    <Box className="DatasetRow">
      <Typography variant="h1">
        <Skeleton />
      </Typography>
    </Box>
  );
}

function DatasetCard({ dataset }: { dataset: AiModelDataset }) {
  const isReady = dataset.status === GptDatesetStatus.Succeeded;
  const isTraining = dataset.status === GptDatesetStatus.Pending || dataset.status === GptDatesetStatus.Running;

  return (
    <Box className="DatasetRow" sx={{ py: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: "100%" }}>
        <Stack gap={1} direction="row" alignItems="center" flex={1}>
          Dateset
          <Chip size="small" variant="outlined" label={formatDate(parseISO(dataset.createdAt))} />
          <Chip size="small" variant="outlined" label={formatTime(parseISO(dataset.createdAt))} />
        </Stack>
        <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
          <Chip variant="filled" label={`${dataset.items} items`} />
          <Chip
            icon={
              isReady ? (
                <img src={IconSolidOkay} alt="trained" />
              ) : (
                <Box sx={{ pl: 0.5 }}>
                  <RingLoader color="#449" size={16} />
                </Box>
              )
            }
            color={isReady ? "success" : isTraining ? "info" : "default"}
            label={isReady ? "Trained" : "Training"}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default function ModelEditor() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const modelsQuery = useGetModelsQuery({ query: { id, limit: 1 } });
  const datasetsQuery = useGetModelDatasetsQuery({ query: { id, limit: 100 } });
  const currentUser = useAppSelector(selectCurrentUser);
  const _datasets = datasetsQuery?.data?.modelDatasets?.datasets;
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);

  const models = modelsQuery?.data?.models?.models ?? [];
  const modelName = models.length > 0 ? `${models[0].name ?? ""}` : "Model";

  const [datasets, setDatasets] = useState<AiModelDataset[]>();

  useEffect(() => {
    if (datasetsQuery.isFetched && !datasets) {
      setDatasets(_datasets ?? []);
    }
  }, [_datasets, datasets, datasetsQuery.isFetched]);

  function addDataset(data: AiModelDataset) {
    if (data) {
      if (datasets) {
        setDatasets([...datasets, data]);
      } else {
        setDatasets([data]);
      }
    }
  }

  function tryModel() {
    dispatch(switchModel(models[0].id));
    navigate(RouteNames.NewPrompt);
  }

  function invalidateModelQuery() {
    queryClient.invalidateQueries(["getModels", { query: { id, limit: 1 } }]);
  }

  const isLoading = modelsQuery.isLoading || datasetsQuery.isLoading;
  const totalItems = datasets?.reduce((acc, cur) => acc + cur.items, 0);

  return (
    <Layout
      className="Models"
      workspace
      header={
        <Stack className="PageHeader" direction="row" alignItems="flex-start" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link to={RouteNames.Models}>Models</Link>
              <Typography>{isLoading ? <Skeleton width={60} /> : modelName}</Typography>
            </Breadcrumbs>

            <IconButton onClick={() => setUpdateModalOpen(true)}>
              <img src={IconEdit} alt="Edit" height={16} />
            </IconButton>
            <Chip
              size="small"
              icon={models[0]?.isReady ? <img src={IconSolidOkay} alt="Ready" style={{ height: 16 }} /> : undefined}
              color={models[0]?.isReady ? "success" : "default"}
              label={models[0]?.isReady ? "Ready to use" : isLoading ? "..." : "Draft"}
            />
            {models[0]?.isTraining && (
              <Chip
                size="small"
                icon={
                  <Box sx={{ pl: 0.5 }}>
                    <RingLoader color="#449" size={16} />
                  </Box>
                }
                color="info"
                label={"Training"}
              />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            {models[0]?.isReady && (
              <Button variant="outlined" size="medium" onClick={tryModel}>
                <img src={IconRawMode} alt="Try model" />
                &nbsp;Try model
              </Button>
            )}
            <Button variant="outlined" size="medium" onClick={() => setCreateModalOpen(true)}>
              <img src={IconTrainingData} alt="Add training data" height={14} />
              &nbsp;Add Training Data
            </Button>
          </Stack>
        </Stack>
      }
      key={`dataset-${currentUser?.currentUser?.id}`}
    >
      <Stack alignItems="stretch" gap={1}>
        {isLoading ? (
          <Skeleton width={200} />
        ) : models.length > 0 && models[0].description ? (
          <Typography>{models[0].description}</Typography>
        ) : null}

        <List sx={{ position: "relative" }}>
          {isLoading && (datasets ?? []).length === 0
            ? Array(3)
                .fill(0)
                .map((_, i) => <ModelCardPlaceholder key={i} />)
            : (datasets ?? []).length > 0
            ? (datasets ?? []).map((r: AiModelDataset) => <DatasetCard key={r.id} dataset={r} />)
            : null}
        </List>

        {(datasets ?? []).length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {totalItems} Training items from {(datasets ?? []).length} datasets
          </Stack>
        )}

        {isLoading && (datasets ?? []).length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        <CreateDatasetModal
          id={id}
          open={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
          onSucceed={invalidateModelQuery}
          addDataset={addDataset}
        />

        <CreateModelModal
          key={`update-model-modal-${models[0]?.id ?? 0}`}
          model={models[0]}
          open={updateModalOpen}
          onSucceed={invalidateModelQuery}
          onClose={() => {
            setUpdateModalOpen(false);
          }}
        />
      </Stack>
    </Layout>
  );
}
