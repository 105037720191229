import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Divider from "@mui/material/Divider/Divider";
import FormControl from "@mui/material/FormControl/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import IconButton from "@mui/material/IconButton/IconButton";
import Stack from "@mui/material/Stack/Stack";
import useTheme from "@mui/material/styles/useTheme";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { sendLoginCode, verifyGoogleOAuthCode, verifyLoginCode } from "api";
import SlackIcon from "assets/slack-icon.svg";
import GoogleIcon from "assets/google-icon.svg";
import Branding from "components/branding";
import {
  electronRouteWrapper,
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useIsElectron,
  useOnDeepLink,
} from "hooks";
import { logError } from "logger";
import { GoogleLoginResponse } from "models/io";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RouteNames from "route_names";
import {
  addAccounts,
  authorizeWithSlackOauth,
  selectAuth,
  slackOauthDesktopRedirectURI,
  slackSignInURL,
  SLACK_OAUTH_STATE_KEY,
} from "store/auth_reducer";
import { v4 as uuidv4 } from "uuid";
import { DEEP_LINK_PATH_GOOGLE_SIGN_IN, DEEP_LINK_PATH_SLACK_SIGN_IN } from "values";
import variables from "variables";
import "./sign_in_buttons.sass";

type GoogleLoginCallback = (response: GoogleLoginResponse) => void;

type GoogleClient = {
  accounts: {
    id: {
      initialize: (params: { client_id: string; callback: GoogleLoginCallback }) => void;
      renderButton: (el: HTMLDivElement, theme: { [key: string]: any }) => void;
    };
  };
};

declare global {
  var google: GoogleClient;
}

enum SignInWithEmailStep {
  Initial,
  VerifyCode,
}

const Margin = 12;

const slackEnabled = false;

function SignInButtons({ showBranding, singleColumn }: { showBranding: boolean; singleColumn?: boolean }) {
  const auth = useAppSelector(selectAuth);
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const isElectron = useIsElectron();
  const theme = useTheme();
  const [currentFlow, setCurrentFlow] = useState<SignInWithEmailStep>(SignInWithEmailStep.Initial);
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [requestID, setRequestID] = useState<string | null>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const oauthState = useMemo(() => uuidv4(), []);
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const narrow = useMediaQuery(theme.breakpoints.down("sm")) || singleColumn;

  const url = useOnDeepLink();

  // handle deep link
  useEffect(() => {
    if (url) {
      const parsed = new URL(url);
      const params = new URLSearchParams(parsed.search);
      const pathname = parsed.pathname.replace(/\/+/g, "");
      if (pathname === DEEP_LINK_PATH_GOOGLE_SIGN_IN) {
        verifyGoogleOAuthCode({
          credential: params.get("credential"),
          clientId: params.get("clientId"),
        } as GoogleLoginResponse).then((authTokens) => {
          if (authTokens) {
            dispatch(addAccounts(authTokens));
          } else {
            setError("Error logging in with Google. Please try again later.");
          }
        });
      } else if (pathname === DEEP_LINK_PATH_SLACK_SIGN_IN) {
        const code = params.get("code");
        const state = params.get("state");
        const existingState = localStorage.getItem(SLACK_OAUTH_STATE_KEY);
        if (!code || state === "" || state !== existingState) {
          setError("Invalid state. Please sign in again");
          return;
        }
        dispatch(authorizeWithSlackOauth(code, slackOauthDesktopRedirectURI()));
      }
    }
  }, [code, dispatch, url]);

  useEffect(() => {
    if (auth.currentAccount) {
      navigate(RouteNames.Home);
      return;
    }
  }, [auth.currentAccount, navigate]);

  const MaxWidth = narrow ? 240 : 320;

  const onGoogleSignInResponse = useCallback(
    async function (response: GoogleLoginResponse) {
      if (response && response.credential) {
        const authTokens = await verifyGoogleOAuthCode(response);
        if (authTokens) {
          dispatch(addAccounts(authTokens));
        } else {
          setError("Error logging in with Google. Please try again later.");
        }
      } else {
        setError("Error logging in with Google. Please try again later.");
      }
    },
    [dispatch]
  );

  const initializeGoogleSignInLibrary = useCallback(() => {
    if (isElectron || !google || !variables.GOOGLE_CLIENT_ID || !googleButtonRef.current) {
      return;
    }

    google.accounts.id.initialize({
      client_id: variables.GOOGLE_CLIENT_ID,
      callback: onGoogleSignInResponse,
    });

    google.accounts.id.renderButton(googleButtonRef.current, {
      class: "g_id_signin",
      type: "standard",
      theme: "outline",
      size: "large",
      text: "signup_with",
      shape: "circle",
      logo_alignment: "left",
      width: Math.min(MaxWidth, window.innerWidth - Margin * 2),
    });
  }, [MaxWidth, isElectron, onGoogleSignInResponse]);

  useEffect(() => {
    if (currentFlow !== SignInWithEmailStep.Initial) {
      return;
    }

    if (typeof google !== "undefined") {
      initializeGoogleSignInLibrary();
    } else {
      window.addEventListener("load", initializeGoogleSignInLibrary);
      return () => document.removeEventListener("load", initializeGoogleSignInLibrary);
    }
  }, [currentFlow, initializeGoogleSignInLibrary, onGoogleSignInResponse]);

  function onEmailChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    setError(null);
  }

  function onCodeChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value);
    setError(null);
  }

  function onSlackSignInClicked() {
    // set the key in local storage then follow the url
    localStorage.setItem(SLACK_OAUTH_STATE_KEY, oauthState);
  }

  async function onSignInClicked(event: React.MouseEvent<HTMLButtonElement>) {
    if (email === "") {
      setError("Please enter an email address.");
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const requestID = await sendLoginCode(email);
      if (requestID) {
        setRequestID(requestID);
        setCurrentFlow(SignInWithEmailStep.VerifyCode);
      } else {
        setError("Error logging in. Please try again later.");
      }
    } catch (e) {
      logError(e);
    } finally {
      setLoading(false);
    }
  }

  async function onVerifyClicked(event: React.MouseEvent<HTMLButtonElement>) {
    if (!code || code.length !== 6) {
      setError("Please enter a valid code.");
      return;
    }

    if (!requestID) {
      setError("Please refresh the page and try again.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const authTokens = await verifyLoginCode(email, requestID, code);
      if (authTokens) {
        dispatch(addAccounts(authTokens));
      } else {
        setError("Login code is invalid. Please try again.");
      }
    } catch (e) {
      logError(e);
    } finally {
      setLoading(false);
    }
  }

  function onBackClicked(event: React.MouseEvent<HTMLButtonElement>) {
    setRequestID(null);
    setError(null);
    setCode("");
    setCurrentFlow(SignInWithEmailStep.Initial);
  }

  return (
    <div className="SignInButtons">
      {currentFlow === SignInWithEmailStep.Initial ? (
        <Stack style={{ margin: Margin }}>
          {showBranding && (
            <div style={narrow ? { width: MaxWidth, margin: "12px auto" } : {}}>
              <Branding white />
            </div>
          )}
          <Box className={`SignIn ${narrow ? "Narrow" : ""}`}>
            <Stack spacing={2} className="OAuth" style={{ width: MaxWidth }}>
              <Typography variant="h5">Quick sign-up</Typography>
              {isElectron ? (
                <div className="SignInButton">
                  <Button
                    href={electronRouteWrapper(RouteNames.DesktopGoogleSignIn)}
                    variant="outlined"
                    size="large"
                    startIcon={<img src={GoogleIcon} alt="Sign in with Google" style={{ height: 18 }} />}
                    style={{ width: "100%" }}
                  >
                    <span className="Label">Sign in with Google</span>
                  </Button>
                </div>
              ) : (
                <div className="Google" ref={googleButtonRef}></div>
              )}
              {slackEnabled && (
                <div className="SignInButton">
                  <Button
                    onClick={onSlackSignInClicked}
                    href={
                      isElectron
                        ? slackSignInURL(oauthState, slackOauthDesktopRedirectURI())
                        : slackSignInURL(oauthState)
                    }
                    variant="outlined"
                    size="large"
                    startIcon={<img src={SlackIcon} alt="Sign in with Slack" style={{ height: 18 }} />}
                    style={{ width: "100%" }}
                  >
                    <span className="Label">Sign up with Slack</span>
                  </Button>
                </div>
              )}
            </Stack>
            <Divider sx={{ my: 3 }} orientation="horizontal" flexItem>
              OR
            </Divider>
            <Stack spacing={2} className="Email" style={{ width: MaxWidth }}>
              <Typography variant="h5">Sign up with email</Typography>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  required
                  id="email"
                  type="email"
                  label="Email address"
                  onChange={onEmailChanged}
                  value={email}
                  size="small"
                />
              </FormControl>
              {loading ? (
                <LoadingButton loading variant="outlined" style={{ width: "100%" }}>
                  Please wait...
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  style={{ width: "100%" }}
                  onClick={onSignInClicked}
                >
                  Sign up
                </Button>
              )}
              {!!error && (
                <FormHelperText error id="email-error">
                  {error}
                </FormHelperText>
              )}
            </Stack>
          </Box>
          <Box className="Terms" style={narrow ? { width: MaxWidth, margin: "24px auto" } : {}}>
            By clicking on "Sign up", "Sign up with Slack", or "Sign up with Google", you agree to our{" "}
            <a href="https://www.gists.ai/terms">Terms</a>&nbsp;and&nbsp;
            <a href="https://www.gists.ai/privacy">Policy</a>.
          </Box>
        </Stack>
      ) : (
        <Stack className="SignIn" style={narrow ? { maxWidth: MaxWidth, marginLeft: Margin, marginRight: Margin } : {}}>
          {showBranding && (
            <div style={narrow ? { width: MaxWidth } : {}}>
              <Branding white />
            </div>
          )}
          <div className="Email">
            <IconButton aria-label="back" style={{ marginLeft: -Margin, marginBottom: Margin }} onClick={onBackClicked}>
              <ArrowBackIcon />
            </IconButton>
            <Stack spacing={2} style={narrow ? { width: MaxWidth } : {}}>
              <h3>Login code sent to {email}</h3>
              <p>Please enter the code below. Make sure you check your spam folder as well.</p>
              <FormControl style={{ width: "100%" }}>
                <TextField required id="code" label="Login code" onChange={onCodeChanged} value={code} size="small" />
              </FormControl>
              {loading ? (
                <LoadingButton loading variant="outlined" style={{ width: "100%" }}>
                  Please wait...
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  style={{ width: "100%" }}
                  onClick={onVerifyClicked}
                >
                  Sign in
                </Button>
              )}
              {!!error && (
                <FormHelperText error id="code-error">
                  {error}
                </FormHelperText>
              )}
            </Stack>
          </div>
        </Stack>
      )}
    </div>
  );
}

export default SignInButtons;
