const RouteNames = {
  // auth
  SignIn: "/sign_in",
  DesktopGoogleSignIn: "/desktop/google_sign_in/",
  DesktopSlackSignIn: "/desktop/google_sign_in/",
  SlackOAuthRedirect: "/slack/oauth/redirect",
  SlackTokenRedirect: "/slack/token/redirect",

  Home: "/",
  Onboarding: "/start",

  // settings
  Plan: "/settings/plan",
  Profile: "/settings/profile",
  Settings: "/settings",

  History: "/history",
  NewPrompt: "/prompt/new",
  PromptEditor: "/prompt/edit/:id",
  PromptEditorV1: "/prompt/v1/edit/:id",

  Characters: "/characters",
  CharacterEditor: "/character/edit/:id",

  Models: "/models",
  ModelEditor: "/model/edit/:id",
  Gists: "/gists",
  Gist: "/gist/:id",
  GistVariant: "/gist/:id/variant/:variantId",

  Invite: "/invite",

  // not implemented
  Datastores: "/datastores",
  Workflow: "/workflow",
  Builder: "/build",

  // old
  ReminderSettings: "/settings/reminders",
  Wellbeing: "/well-being",
  Breath: "/room/breath",
  Eyebreak: "/room/eyebreak",
  Stretch: "/room/stretch",
  Daily: "/room/daily",
  Team: "/team",
  TeamManager: "/team/manager",
  TeamMemberships: "/team/memberships",
  TeamInstall: "/team/install",
  Insights: "/insights",
  Journal: "/journal",
  EnergyModal: "/journal/energy",
  RoutineModal: "/journal/routine",
};

export default RouteNames;
