import LaunchIcon from "@mui/icons-material/Launch";
import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import Box from "@mui/system/Box/Box";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { ScheduleItemType } from "generated/graphql";
import { useAppSelector } from "hooks";
import Skeleton from "react-loading-skeleton";

import { selectCurrentUser } from "store/current_user_reducer";
import "./daily_summary.sass";

export default function DailySummary() {
  const { currentUser } = useAppSelector(selectCurrentUser);
  const nextMeeting = currentUser?.personalSchedule?.items?.find((i) => {
    try {
      const endAt = parseISO(i.endAt);
      return i.itemType === ScheduleItemType.Meeting && endAt > new Date();
    } catch (error) {
      return false;
    }
  });

  const urls = nextMeeting?.urls ?? [];
  const joinableLinks = urls.length === 1 ? [urls[0]] : urls.filter((url) => url.canJoin);

  const startAt = nextMeeting ? parseISO(nextMeeting.startAt) : undefined;
  let startText = "";
  if (startAt && startAt < new Date()) {
    startText = `, started ${formatDistanceToNowStrict(startAt)} ago.`;
  } else if (startAt) {
    startText = `, starting in ${formatDistanceToNowStrict(startAt)}.`;
  }

  return (
    <div className="PageHeader PersonalInsight">
      <Typography variant="h4" sx={{ pb: 2 }}>
        Today at a glance
      </Typography>
      <Grid
        container
        spacing={2}
        className="PersonalInsight__items"
        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
      >
        {currentUser?.personalSchedule?.insights &&
          (nextMeeting ? (
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={`next-meeting-${nextMeeting.title}`}
              item
              sx={{ display: "flex", flex: 1 }}
            >
              <Paper className="InsightItem" sx={{ p: 2 }} elevation={0}>
                <Box display="flex" alignItems="flex-start">
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    Your next meeting is <b>{nextMeeting.title.trim()}</b>
                    <span>{startText}</span>
                  </Typography>
                  {(joinableLinks ?? []).map((url) => {
                    const host = new URL(url.url).host;
                    return (
                      <Tooltip title={url.url} placement="right" key={`joinable-link-${url}`}>
                        <IconButton key={url.url} href={url.url} target="_blank" style={{ width: 40, height: 40 }}>
                          {url.icon && url.icon !== "" ? (
                            <img className="MeetingInfoPopover__MeetingIcon" src={url.icon} alt={host} />
                          ) : (
                            <LaunchIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    );
                  })}
                </Box>
              </Paper>
            </Grid>
          ) : (
            <Grid xs={12} sm={12} md={6} lg={4} xl={3} key={`no-meeting`} item sx={{ display: "flex", flex: 1 }}>
              <Paper className="InsightItem" sx={{ p: 2 }} elevation={0}>
                <Typography variant="body2">No meetings for the rest of the day. Yay!</Typography>
              </Paper>
            </Grid>
          ))}
        {(currentUser?.personalSchedule?.insights ?? ["", "", ""]).map((insight, i) => {
          return (
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={`insight-${i}-${insight}`}
              item
              sx={{ display: "flex", flex: 1 }}
            >
              <Paper className="InsightItem" sx={{ p: 2 }} elevation={0}>
                <Typography variant="body2">{insight === "" ? <Skeleton count={2} /> : insight}</Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
