import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentTeam } from "generated/graphql";
import { RootState } from "./store";

export interface CurrentTeamState {
  initialized: boolean;
  currentTeam?: CurrentTeam;
}

const initialState: CurrentTeamState = {
  initialized: false,
  currentTeam: {
    id: "-",
    name: "All teams",
  } as CurrentTeam,
};

export const currentTeamSlice = createSlice({
  name: "currentTeam",
  initialState,
  reducers: {
    initialized: (state, action: PayloadAction<CurrentTeam>) => {
      state.initialized = true;
      state.currentTeam = action.payload;
      return state;
    },
    clearCurrentTeam: (state) => {
      state.initialized = false;
      state.currentTeam = undefined;
      return state;
    },
    updateCurrentTeam: (state, action: PayloadAction<CurrentTeam>) => {
      state.initialized = true;
      state.currentTeam = action.payload;
      return state;
    },
  },
});

export const { initialized, updateCurrentTeam, clearCurrentTeam } = currentTeamSlice.actions;

export const selectCurrentTeam = (state: RootState) => state.currentTeam;

export default currentTeamSlice.reducer;
