import Button from "@mui/material/Button/Button";
import { Events, track } from "analytics";
import React from "react";
import "./sharing_button.sass";

export default function SharingButton({ askForName }: { askForName: () => void }) {
  function clicked() {
    askForName();
    track(Events.SharingButton.Clicked, {});
  }
  return (
    <div className="SharingButton">
      <Button onClick={clicked} variant="outlined">
        Share this break
      </Button>
    </div>
  );
}
