import { Prompt } from "generated/graphql";
import { useCallback, useEffect, useState, useMemo } from "react";
import mustache from "mustache";

function useValueChange(setter: React.Dispatch<React.SetStateAction<string>>) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value ?? "");
  };
}

export function usePromptEditorModel(editingPrompt?: Prompt) {
  const promptFieldMap: { [key: string]: string | number | null | undefined } =
    editingPrompt?.fields?.reduce((map, el) => {
      if (el.name) {
        if ((el.values?.length ?? 0) > 0) {
          map[el.name] = el.values![0];
        } else if ((el.intValues?.length ?? 0) > 0) {
          map[el.name] = el.intValues![0];
        } else if ((el.floatValues?.length ?? 0) > 0) {
          map[el.name] = el.floatValues![0];
        }
      }
      return map;
    }, {} as { [key: string]: string | number | null | undefined }) ?? {};

  const [goal, setGoal] = useState<string>(`${promptFieldMap.goal ?? ""}`);
  const [content, setContent] = useState<string>(`${promptFieldMap.content ?? ""}`);
  const [perspective, setPerspective] = useState<string>(`${promptFieldMap.perspective ?? ""}`);
  const [audience, setAudience] = useState<string>(`${promptFieldMap.audience ?? ""}`);
  const [context, setContext] = useState<string>(`${promptFieldMap.context ?? ""}`);
  const [tone, setTone] = useState<string>(`${promptFieldMap.tone ?? ""}`);
  const [format, setFormat] = useState<string>(`${promptFieldMap.format ?? ""}`);
  const [rawPrompt, setRawPrompt] = useState<string>("");

  const template =
    (promptFieldMap.template as string) ??
    `{{#goal}}The purpose of this prompt is to {{{goal}}}.\n{{/goal}}{{#content}}{{{content}}}{{/content}}
{{#perspective}}Pretend you are a {{{perspective}}}{{/perspective}}
{{#audience}}The output is intended for {{{audience}}}{{/audience}}
{{#context}}Here is the context of the situation {{{context}}}{{/context}}
{{#tone}}The tone should be {{{tone}}}{{/tone}}
{{#format}}Output with the following format:\n{{{format}}}{{/format}}`;

  // todo: fix later
  useEffect(() => {
    setGoal(`${promptFieldMap.goal ?? ""}`);
    setContent(`${promptFieldMap.content ?? ""}`);
    setPerspective(`${promptFieldMap.perspective ?? ""}`);
    setAudience(`${promptFieldMap.audience ?? ""}`);
    setContext(`${promptFieldMap.context ?? ""}`);
    setTone(`${promptFieldMap.tone ?? ""}`);
    setFormat(`${promptFieldMap.format ?? ""}`);
  }, [
    promptFieldMap.audience,
    promptFieldMap.content,
    promptFieldMap.context,
    promptFieldMap.format,
    promptFieldMap.goal,
    promptFieldMap.perspective,
    promptFieldMap.tone,
  ]);

  const buildPrompt = useCallback(() => {
    return mustache
      .render(template, { goal, content, perspective, audience, context, tone, format })
      .replaceAll(/\n{3,}/g, "\n\n");
  }, [audience, content, context, format, goal, perspective, template, tone]);

  const toFields = useCallback(() => {
    return {
      userPrompt: rawPrompt,
      fields: [
        { name: "goal", values: [goal] },
        { name: "content", values: [content] },
        { name: "perspective", values: [perspective] },
        { name: "audience", values: [audience] },
        { name: "context", values: [context] },
        { name: "tone", values: [tone] },
        { name: "format", values: [format] },
        { name: "template", values: [template] },
      ],
    };
  }, [audience, content, context, format, goal, perspective, rawPrompt, template, tone]);

  const toMap = useCallback(() => {
    return {
      rawPrompt,
      goal,
      content,
      perspective,
      audience,
      context,
      tone,
      format,
    };
  }, [audience, content, context, format, goal, perspective, rawPrompt, tone]);

  const onGoalChange = useValueChange(setGoal);
  const onContentChange = useValueChange(setContent);
  const onAudienceChange = useValueChange(setAudience);
  const onPerspectiveChange = useValueChange(setPerspective);
  const onContextChange = useValueChange(setContext);
  const onFormatChange = useValueChange(setFormat);
  const onToneChange = useValueChange(setTone);

  const object = useMemo(
    () => ({
      template,

      goal,
      setGoal,
      onGoalChange,

      content,
      setContent,
      onContentChange,

      audience,
      setAudience,
      onAudienceChange,

      perspective,
      setPerspective,
      onPerspectiveChange,

      context,
      setContext,
      onContextChange,

      format,
      setFormat,
      onFormatChange,

      tone,
      setTone,
      onToneChange,

      rawPrompt,
      setRawPrompt,

      buildPrompt,

      toFields,

      toMap,
    }),
    [
      audience,
      buildPrompt,
      content,
      context,
      format,
      goal,
      onAudienceChange,
      onContentChange,
      onContextChange,
      onFormatChange,
      onGoalChange,
      onPerspectiveChange,
      onToneChange,
      perspective,
      rawPrompt,
      template,
      toFields,
      toMap,
      tone,
    ]
  );

  return object;
}

export type EditorModel = ReturnType<typeof usePromptEditorModel>;
