import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import debounce from "lodash.debounce";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Character, PromptField, useGetCharactersQuery } from "generated/graphql";
import { selectCurrentUser } from "store/current_user_reducer";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";

const limit = 20;

const defaultSystemMessage = `You are a language model who is factual and honest.
You will answer the prompts factually and if you don't know the answers, say "I don't know" or "I need {missing information} to answer the question".
`;

function appendIfNotExist<T extends { id: string }>(array: T[], items: T[]): T[] {
  const set = new Set(array.map((t) => t.id));
  const result = [...array];
  for (const item of items) {
    if (!set.has(item.id)) {
      result.push(item);
    }
  }
  return result;
}

const DefaultOption: Character = {
  id: "default",
  name: "AI Language Model",
  fragment: {
    type: "system",
    name: "system",
    id: "system",
    fields: [{ name: "system", values: [defaultSystemMessage] } as PromptField],
  },
} as Character;

export function CharacterPicker({
  character,
  onChange,
}: {
  character?: Character | null;
  onChange: (character: Character) => void;
}) {
  const currentUser = useAppSelector(selectCurrentUser);
  const [userID, setUserID] = useState<string | undefined | null>(currentUser?.currentUser?.id);
  const [keyword, setKeyword] = useState<string | null>();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Character[]>([DefaultOption, ...(character ? [character] : [])]);
  const charactersQuery = useGetCharactersQuery({ query: { limit, id: userID, query: keyword } }, { enabled: open });
  const loading = open && charactersQuery.isLoading;

  const defaultOption = character ?? DefaultOption;
  const [selectedCharacter, setSelectedCharacter] = useState<Character>(defaultOption);

  function onSelect(value: Character | null) {
    if (!value) {
      setSelectedCharacter(DefaultOption);
      onChange(DefaultOption);
      setKeyword(undefined);
      return;
    }

    setSelectedCharacter(value);
    onChange(value);
    setKeyword(undefined);
  }

  useEffect(() => {
    if (!userID && currentUser?.currentUser?.id) {
      setUserID(currentUser?.currentUser?.id);
    }
  }, [currentUser?.currentUser?.id, userID]);

  useEffect(() => {
    if (!character) {
      return;
    }

    if (character?.id !== "default") {
      setOptions(appendIfNotExist(options, [character!]));
    }

    if (selectedCharacter?.id === "default" && character?.id !== "default") {
      setSelectedCharacter(character!);
    }
  }, [character, options, selectedCharacter?.id]);

  useEffect(() => {
    if (charactersQuery.isFetched) {
      let newOptions = [] as Character[];
      newOptions = appendIfNotExist(newOptions, [DefaultOption]);
      newOptions = appendIfNotExist(newOptions, selectedCharacter ? [selectedCharacter] : []);
      newOptions = appendIfNotExist(newOptions, charactersQuery.data?.characters?.characters ?? []);
      setOptions(newOptions);
    }
  }, [charactersQuery.data?.characters, charactersQuery.isFetched, open, selectedCharacter]);

  const debounceSetQuery = debounce((keyword: string) => setKeyword(keyword?.trim() ? keyword : undefined), 1000);

  return (
    <Autocomplete
      defaultValue={defaultOption}
      filterOptions={(x) => x}
      onChange={(_, option) => onSelect(option)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedCharacter}
      onInputChange={(e, value, reason) => {
        if (reason !== "reset") {
          debounceSetQuery(value);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Characters" InputLabelProps={{ shrink: false }} size="small" />
      )}
    />
  );
}

export function CharacterPickerPopover({
  open,
  character,
  setCharacter,
  onClose,
  anchorEl,
}: {
  open: boolean;
  character?: Character | null;
  setCharacter: (character: Character) => void;
  onClose: () => void;
  anchorEl: Element | null;
}) {
  return (
    <Popover
      className="CharactersPickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={onClose}
      elevation={20}
    >
      <Stack alignItems="stretch" sx={{ p: 2, minWidth: 320, maxWidth: 480 }} gap={1}>
        <CharacterPicker character={character} onChange={setCharacter} />
      </Stack>
    </Popover>
  );
}

export function MultipleCharacterPicker({
  characters,
  onChange,
}: {
  characters: Character[];
  onChange: (characters: Character[]) => void;
}) {
  const currentUser = useAppSelector(selectCurrentUser);
  const [userID, setUserID] = useState<string | undefined | null>(currentUser?.currentUser?.id);
  const [keyword, setKeyword] = useState<string | null>();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Character[]>([DefaultOption, ...characters]);
  const charactersQuery = useGetCharactersQuery({ query: { limit, id: userID, query: keyword } }, { enabled: open });
  const loading = open && charactersQuery.isLoading;

  const defaultOption = characters.length === 0 ? [DefaultOption] : characters;
  const [selectedCharacters, setSelectedCharacters] = useState<Character[]>(defaultOption);

  function onSelect(value: Character[]) {
    // filter out the default option
    const withoutDefault = value.filter((v) => v.id !== DefaultOption.id);

    if (!withoutDefault || withoutDefault.length === 0) {
      setSelectedCharacters([DefaultOption]);
      onChange([DefaultOption]);
      setKeyword(undefined);
      return;
    }

    setSelectedCharacters(withoutDefault);
    onChange(withoutDefault);
    setKeyword(undefined);
  }

  useEffect(() => {
    if (!userID && currentUser?.currentUser?.id) {
      setUserID(currentUser?.currentUser?.id);
    }
  }, [currentUser?.currentUser?.id, userID]);

  useEffect(() => {
    if (charactersQuery.isFetched) {
      let newOptions = [] as Character[];
      newOptions = appendIfNotExist(newOptions, [DefaultOption]);
      newOptions = appendIfNotExist(newOptions, selectedCharacters);
      newOptions = appendIfNotExist(newOptions, charactersQuery.data?.characters?.characters ?? []);
      setOptions(newOptions);
    }
  }, [charactersQuery.data?.characters, charactersQuery.isFetched, open, selectedCharacters]);

  const debounceSetQuery = debounce((keyword: string) => setKeyword(keyword?.trim() ? keyword : undefined), 1000);

  return (
    <Autocomplete
      multiple
      defaultValue={defaultOption}
      filterOptions={(x) => x}
      onChange={(_, option) => onSelect(option)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedCharacters}
      onInputChange={(e, value, reason) => {
        if (reason !== "reset") {
          debounceSetQuery(value);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Characters" InputLabelProps={{ shrink: false }} size="small" />
      )}
    />
  );
}

export function MultipleCharacterPickerPopover({
  open,
  characters,
  setCharacters,
  onClose,
  anchorEl,
}: {
  open: boolean;
  characters: Character[];
  setCharacters: (characters: Character[]) => void;
  onClose: () => void;
  anchorEl: Element | null;
}) {
  return (
    <Popover
      className="CharactersPickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onClose={onClose}
      elevation={20}
    >
      <Stack sx={{ p: 2, minWidth: 320, maxWidth: 480 }} gap={1}>
        <MultipleCharacterPicker characters={characters} onChange={setCharacters} />
      </Stack>
    </Popover>
  );
}
