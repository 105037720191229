import { Box, Button, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import IconCharacters from "assets/v2/icon-characters.svg";
import { Character, PaginatedQuery, useDoDeleteCharactersMutation, useGetCharactersQuery } from "generated/graphql";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "react-spinners/ScaleLoader";
import { selectCurrentUser } from "store/current_user_reducer";
import { addCharacters, removeCharacters, selectCharacters } from "store/characters_reducer";
import IconMore from "assets/v2/icon-more.svg";
import { generatePath, Link } from "react-router-dom";
import "./index.sass";
import RouteNames from "route_names";
import CreateCharacterModal from "./create_character_modal";
import { selectCurrentCompany } from "store/current_company_reducer";

function CharacterCardPlaceholder() {
  return (
    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
      <Box className="CharacterCard" sx={{ p: 2 }} style={{ borderRadius: 6 }}>
        <Typography variant="h1">
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
      </Box>
    </Grid>
  );
}

function CharacterCard({ character }: { character: any }) {
  const dispatch = useAppDispatch();
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const deleteMutation = useDoDeleteCharactersMutation();

  async function deleteCharacter() {
    dispatch(removeCharacters([character.id]));
    try {
      await deleteMutation.mutateAsync({
        ids: [character.id],
      });
    } catch (error) {}
  }

  return (
    <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
      <Box className="CharacterCard" sx={{ p: 2 }} style={{ borderRadius: 6 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: "100%" }}>
          <Link to={generatePath(RouteNames.CharacterEditor, { id: character.id })}>
            <Stack
              direction="row"
              sx={{ px: 1.5, py: 1, height: "100%" }}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              {character.name}
            </Stack>
          </Link>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
              <img src={IconMore} alt="Card options" style={{ height: 16 }} />
            </IconButton>
            <Menu
              elevation={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={showCardMenu}
              anchorEl={el.current}
              onClose={() => setShowCardMenu(false)}
            >
              {/* <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                }}
              >
                <span>Share</span>
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                  setShowConfirmDeleteMenu(true);
                }}
              >
                <span>Delete</span>
              </MenuItem>
            </Menu>
            <Menu
              elevation={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={showConfirmDeleteMenu}
              anchorEl={el.current}
              onClose={() => setShowConfirmDeleteMenu(false)}
            >
              <MenuItem
                onClick={() => {
                  deleteCharacter();
                  setShowConfirmDeleteMenu(false);
                }}
              >
                <span>Yes, delete!</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowConfirmDeleteMenu(false);
                }}
              >
                <span>No, cancel</span>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}

function NewCharacter({ open }: { open: () => void }) {
  return (
    <Stack alignItems="center" flex={1} gap={4} sx={{ mt: 4 }}>
      <img src="https://staticgists.com/astronaut-business-v1.png" alt="New character" style={{ width: 240 }} />
      <Typography textAlign="center">Create your own AI characters who can help with particular tasks.</Typography>
      <Typography textAlign="center">
        No character has been created yet.
        <br />
        Create a new character and it'll show up here.
      </Typography>
      <Button onClick={open} variant="outlined" size="medium">
        <img src={IconCharacters} alt="New character" height={20} />
        &nbsp;New Character
      </Button>
    </Stack>
  );
}

export default function Characters() {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectCurrentUser);
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const { characters, cursor, loaded, limit } = useAppSelector(selectCharacters);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const [companyQuery, setCompanyQuery] = useState<PaginatedQuery>({ id: currentCompany?.id, limit });
  const companyCharactersQuery = useGetCharactersQuery({ query: companyQuery }, { enabled: !!currentCompany?.id });

  useEffect(() => {
    if (companyCharactersQuery.isFetched) {
      const characters = companyCharactersQuery?.data?.characters;
      dispatch(addCharacters(characters));
    }
  }, [companyCharactersQuery, dispatch]);

  useEffect(() => {
    if (currentCompany?.id && !companyQuery.id) {
      setCompanyQuery({ ...companyQuery, id: currentCompany?.id });
    }
  }, [companyQuery, currentCompany?.id]);

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setCompanyQuery({ ...companyQuery, cursor });
      }
    }
  }, [cursor, companyQuery, loaded]);

  return (
    <Layout
      className="Characters"
      workspace
      header={
        <Stack direction="row" className="PageHeader" justifyContent="space-between">
          <Typography variant="h4" className="PageTitle">
            Characters{" "}
          </Typography>
          <Button onClick={() => setCreateModalOpen(true)} variant="outlined" size="medium">
            <img src={IconCharacters} alt="New character" height={14} />
            &nbsp;&nbsp;New Character
          </Button>
        </Stack>
      }
      key={`characters-${currentUser?.id}`}
    >
      <Stack alignItems="center">
        <Grid
          spacing={1.5}
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          justifyContent="stretch"
          alignItems="stretch"
          sx={{ position: "relative" }}
        >
          {companyCharactersQuery.isLoading && characters.length === 0 ? (
            Array(12)
              .fill(0)
              .map((_, i) => <CharacterCardPlaceholder key={i} />)
          ) : characters.length > 0 ? (
            characters.map((r: Character) => <CharacterCard key={r.id} character={r} />)
          ) : (
            <NewCharacter
              open={() => {
                setCreateModalOpen(true);
              }}
            />
          )}
        </Grid>

        {!loaded && !companyCharactersQuery.isLoading && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Button onClick={loadMore} size="small">
              Load more...
            </Button>
          </Stack>
        )}

        {loaded && characters.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {characters.length} Characters
          </Stack>
        )}

        {companyCharactersQuery.isLoading && characters.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        <CreateCharacterModal
          open={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
        />
      </Stack>
    </Layout>
  );
}
