import { lazy, Suspense } from "react";

export function SkipElectron(callback: () => Promise<{ default: React.ComponentType<any> }>): React.ReactNode | null {
  if (typeof window.electron !== "undefined") {
    return null;
  }

  const Component = lazy(callback);
  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
}
