import ToggleButton from "@mui/material/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";
import "./energy_buttons.sass";

export function enermoji(energy: number | string): string {
  switch (energy) {
    case 1:
    case "1":
      return "😫";
    case 2:
    case "2":
      return "😔";
    case 4:
    case "4":
      return "😊";
    case 5:
    case "5":
      return "😁";
    default:
      return "🙂";
  }
}

export default function EnergyButtons({ disabled, onChange }: { disabled: boolean; onChange: (val: number) => void }) {
  const [value, setValue] = useState<string>("0");

  function handleButtonGroup(evt: React.MouseEvent<HTMLElement>, newVal: string) {
    onChange(parseInt(newVal, 10));
    setValue(newVal);
  }

  return (
    <ToggleButtonGroup
      disabled={disabled}
      color="primary"
      className="EnergyButtons"
      value={value}
      exclusive
      onChange={handleButtonGroup}
      aria-label="energy values"
    >
      <ToggleButton value="1" aria-label="1">
        😫
      </ToggleButton>
      <ToggleButton value="2" aria-label="2">
        😔
      </ToggleButton>
      <ToggleButton value="3" aria-label="3">
        🙂
      </ToggleButton>
      <ToggleButton value="4" aria-label="4">
        😊
      </ToggleButton>
      <ToggleButton value="5" aria-label="5">
        😁
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
