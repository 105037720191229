import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { Character, Characters, CharacterType } from "generated/graphql";
import { RootState } from "./store";
import { Character, Characters } from "generated/graphql";

export interface CharacterState {
  initialized: boolean;
  characters: Character[];
  cursor?: string | null;
  loaded: boolean;
  limit: number;
  characterLabels: { [id: string]: string };
  currentCharacter: string;
}

export interface CharacterDatasetState {
  cursor?: string | null;
  loaded: boolean;
}

export function appendResult<T extends { id: string }>(responses: T[], newResponses: T[]): T[] {
  const existingIds = new Set(responses.map((r) => r.id));
  const filtered = newResponses.filter((r) => !existingIds.has(r.id));
  return [...responses, ...filtered];
}

export const CURRENT_CHARACTER_LOCAL_STORAGE_KEY = "cuely-ai-character-key";

const initialState: CharacterState = {
  initialized: false,
  characters: [],
  cursor: null,
  loaded: false,
  limit: 24,
  characterLabels: {},
  currentCharacter: localStorage.getItem(CURRENT_CHARACTER_LOCAL_STORAGE_KEY) ?? "",
};

export const charactersSlice = createSlice({
  name: "characters",
  initialState,
  reducers: {
    addCharacters: (state, action: PayloadAction<Characters | null | undefined>) => {
      state.initialized = true;
      const newCursor = action.payload?.cursor;
      const newToOld = [...(action.payload?.characters ?? [])];
      if (newCursor && newCursor !== state.cursor && newToOld.length > 0) {
        // we loaded a new page. let's push the result
        state.characters = appendResult(state.characters, newToOld);
        // now it won't load again
        state.cursor = newCursor;
      }

      if (newToOld.length < state.limit) {
        state.loaded = true;
      }

      action.payload?.characters?.forEach((m) => {
        state.characterLabels[m.id] = m.name;
      });

      return state;
    },
    // add a single character, it doesn't change the cursor
    createCharacter: (state, action: PayloadAction<Character>) => {
      if (action.payload) {
        state.characters = [action.payload, ...state.characters];
      }
      state.characterLabels[action.payload.id] = action.payload.name;
      return state;
    },
    updateCharacter: (state, action: PayloadAction<Character>) => {
      const newResponses = state.characters.map((m) => (m.id === action.payload.id ? action.payload : m));
      state.characters = newResponses;
      state.characterLabels[action.payload.id] = action.payload.name;
      return state;
    },
    removeCharacters: (state, action: PayloadAction<string[]>) => {
      const toBeDeleted = new Set<string>(action.payload ?? []);
      state.characters = state.characters.filter((p) => !toBeDeleted.has(p.id));
      action.payload.forEach((id) => {
        delete state.characterLabels[id];
      });
      return state;
    },
    clearCharacters: (state) => {
      state.initialized = false;
      state.characters = [];
      state.loaded = false;
      state.cursor = null;
      state.characterLabels = {};
      return state;
    },
  },
});

export const { addCharacters, createCharacter, updateCharacter, removeCharacters, clearCharacters } =
  charactersSlice.actions;

export const selectCharacters = (state: RootState) => state.characters;

export default charactersSlice.reducer;
