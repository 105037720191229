import { AudioTrack } from "components/audio";
import { selectAuth } from "store/auth_reducer";
import { selectPlan } from "store/plan_reducer";
import { TimePeriod, UserFacingError } from "generated/graphql";
import { useAppSelector } from "hooks";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

export const electronHeaderHeight = 28;
export const DefaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getStaticFile(path: string, postfix: string = "") {
  return `https://staticgists.com${path}${postfix}`;
  // return process.env.PUBLIC_URL + path + postfix;
}

export interface Scene {
  audios: AudioTrack[];
  bg?: string;
  videos?: string[];
  firstBlobColor?: string;
  secondBlobColor?: string;
}

export function formatNumber(val: number): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(val);
}

export function formatPrice(amount: number, currency: string): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  return formatter.format(amount);
}

export function useLoggedIn() {
  const auth = useAppSelector(selectAuth);
  return auth.initialized && !!auth.currentAccount && auth.loggedIn;
}

export function useHasSlack() {
  const auth = useAppSelector(selectAuth);
  return !!auth.currentAccount?.teamID;
}

export function useCanConnectCalendar() {
  const plan = useAppSelector(selectPlan);
  if (!plan.initialized) {
    return false;
  }
  return true;
}

export function useCanAccessPersonalDashboard() {
  const plan = useAppSelector(selectPlan);
  if (!plan.initialized) {
    return false;
  }
  return true;
}

export function useCanAccessTeamDashboard() {
  const plan = useAppSelector(selectPlan);
  if (!plan.initialized) {
    return false;
  }
  return true;
}

type Emojis = {
  [key: string]: string;
};

const emojis: Emojis = {
  slightly_smiling_face: "😊",
  blush: "😊",
  grin: "😁",
  arrow_up: "⬆️",
  arrow_down: "⬇️",
  sweat_smile: "😅",
  smile: "😊",
  crossed_swords: "⚔️",
  dragon: "🐉",
  fire: "🔥",
  runner: "🏃",
  wave: "👋",
  thumbsup: "👍",
  thumbsdown: "👎",
  tophat: "🎩",
  point_up: "👆",
  point_down: "👇",
  point_left: "👈",
  point_right: "👉",
  raised_hands: "🙌",
  pray: "🙏",
  clap: "👏",
  muscle: "💪",
  people_holding_hands: "🤝",
  person_doing_cartwheel: "🤸",
  person_in_lotus_position: "🧘",
  raising_hand: "🙋",
  earth_americas: "🌎",
  earth_africa: "🌍",
  earth_asia: "🌏",
  potable_water: "🚰",
  battery: "🔋",
  brain: "🧠",
  gear: "⚙️",
  wrench: "🔧",
  tada: "🎉",
  one: "1️⃣",
  heart: "❤️",
  memo: "📝",
  lock: "🔒",
  sparkles: "✨",
  wink: "😉",
  white_check_mark: "✅",
  dog: "🐶",
  standing_person: "🧍",
  headphones: "🎧",
  sunny: "☀️",
  rainbow: "🌈",
  alarm_clock: "⏰",
  zzz: "💤",
  pencil2: "✏️",
  date: "📅",
  spiral_calendar_pad: "🗓",
  shushing_face: "🤫",
  eye: "👁",
  deciduous_tree: "🌳",
  walking: "🚶",
  burrito: "🌯",
  house_with_garden: "🏡",
  tv: "📺",
  no_bell: "🔕",
  zap: "⚡️",
  thought_balloon: "💭",
  tired_face: "😫",
  baloon: "🎈",
  bellhop_bell: "🛎",
  pensive: "😔",
  musical_note: "🎵",
  yawning_face: "🥱",
  partying_face: "🥳",
  woozy_face: "🥴",
  wrestlers: "🤼",
  "100": "💯",
  face_with_raised_eyebrow: "🤨",
  stopwatch: "⏱",
  small_red_triangle_down: "🔻",
  small_red_triangle_up: "🔺",
  dart: "🎯",
};

export const replaceEmoji = (text: string | undefined) => {
  if (!text) {
    return "";
  }
  return text.replace(/:([a-zA-Z0-9_\-+]+):/g, (match, name) => {
    const emoji = emojis[name];
    if (emoji) return emoji;
    return match;
  });
};

export function formatText(input: string): string {
  return input
    .replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<b>$1</b>")
    .replace(/(?:_)([^_<\n]+)(?:_)/g, "<i>$1</i>")
    .replace(/(?:~)([^~<\n]+)(?:~)/g, "<s>$1</s>")
    .replace(/(?:`)([^`<\n]+)(?:`)/g, "<tt>$1</tt>");
}

export function replaceCharAtIndex(input: string, index: number, val: string): string {
  return input.substring(0, index) + val + input.substring(index + val.length);
}

export function formatTimezone(timezone: string): string {
  try {
    return new Date()
      .toLocaleTimeString(undefined, {
        timeZone: timezone,
        timeZoneName: "short",
      })
      .split(" ")[2];
  } catch (error) {
    return formatTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
}

export function formatTimezoneLong(timezone: string): string {
  try {
    return new Date()
      .toLocaleTimeString(undefined, {
        timeZone: timezone,
        timeZoneName: "long",
      })
      .split(" ")[2];
  } catch {
    return formatTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
}

export function formatHour12Hour(i: number): string {
  return `${i % 12 === 0 ? "12" : i % 12}${i >= 12 && i !== 24 ? "pm" : "am"}`;
}

export function formatMin12Hour(i: number): string {
  const hour = (i / 60) | 0;
  const min = i % 60;
  return `${hour % 12 === 0 ? "12" : hour % 12}${min < 10 ? `:0${min}` : `:${min}`}${
    hour >= 12 && i !== 24 ? "pm" : "am"
  }`;
}

export function formatPercentage(value: number): string {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export function formatPercentageStr(text: string): number {
  return parseFloat(text.replaceAll(/[^0-9\\.]/g, "")) / 100.0;
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export enum PeriodLimit {
  START = "start",
  END = "end",
}

export function periodRange(periodDate: Date, period: TimePeriod, periodLimit: PeriodLimit): Date {
  let resultDate = periodDate;

  switch (period) {
    case "week":
      resultDate =
        periodLimit === PeriodLimit.START ? addDays(startOfWeek(resultDate), 1) : addDays(endOfWeek(resultDate), 1);
      break;
    case "month":
      resultDate = periodLimit === PeriodLimit.START ? startOfMonth(resultDate) : endOfMonth(resultDate);
      break;
    case "year":
      resultDate = periodLimit === PeriodLimit.START ? startOfYear(resultDate) : endOfYear(resultDate);
      break;
  }

  return resultDate;
}

export const goBackDays = (from: Date, days: number) => addDays(from, -days);
export const goBackWeeks = (from: Date, weeks: number) => addWeeks(from, -weeks);
export const goBackMonths = (from: Date, months: number) => addMonths(from, -months);
export const goBackYears = (from: Date, years: number) => addYears(from, -years);

export const goBackDaysFromNow = (days: number) => goBackDays(new Date(), days);
export const goBackWeeksFromNow = (weeks: number) => goBackWeeks(new Date(), weeks);
export const goBackMonthsFromNow = (months: number) => goBackMonths(new Date(), months);
export const goBackYearsFromNow = (years: number) => goBackYears(new Date(), years);

export {
  addDays,
  startOfDay,
  endOfToday,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
};

export function useSearchReminderID(): string | undefined | null {
  return new URLSearchParams(window.location.search).get("reminderId");
}

export function getEnergyIcon(energy: number): string {
  switch (energy) {
    case 1:
      return ":tired_face:";
    case 2:
      return ":pensive:";
    case 3:
      return ":slightly_smiling_face:";
    case 4:
      return ":blush:";
    case 5:
      return ":grin:";
  }
  return ":face_with_raised_eyebrow:";
}

export const contains = <T>(arr: T[] | undefined | null, item: T): boolean => {
  return !!arr && arr.findIndex((i) => i === item) !== -1;
};

export function extractMustacheVariables(text: string): string[] {
  const variables = text.match(/{{([#/]?[a-z][a-z0-9_]+?)}}/g)?.map((v) => v.replace(/{{[#/]?|}}/g, "")) || [];
  return Array.from(new Set<string>(variables));
}

export function maskApiKey(apiKey: string): string {
  if (apiKey.length <= 8) {
    return apiKey.replaceAll(/./g, "*");
  }
  return apiKey
    .split("")
    .map((c, i) => {
      if (i < 4 || i > apiKey.length - 5) {
        return c;
      }
      return "*";
    })
    .join("");
}

export function userfacingError(code: UserFacingError): string {
  switch (code) {
    case UserFacingError.OpenaiKeyRequired:
      return "OpenAI key is required. Please add it in the settings.";
    case UserFacingError.BillingPaidPlanRequired:
      return "Please upgrade to a paid plan to continue using Gists.";
  }
  return code;
}
