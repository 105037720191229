import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUser, UserProfile } from "generated/graphql";
import { RootState } from "./store";

export interface CurrentUserState {
  initialized: boolean;
  currentUser?: CurrentUser;
}

const initialState: CurrentUserState = { initialized: false };

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    currentUserInitialized: (state, action: PayloadAction<CurrentUser>) => {
      state.initialized = true;
      state.currentUser = action.payload;
      return state;
    },
    clearCurrentUser: (state) => {
      state.initialized = false;
      state.currentUser = undefined;
      return state;
    },
    userProfileUpdated: (state, action: PayloadAction<UserProfile>) => {
      if (state.currentUser) {
        state.currentUser.profile = { ...(state.currentUser.profile ?? {}), ...action.payload };
      }
      return state;
    },
  },
});

export const { currentUserInitialized, clearCurrentUser, userProfileUpdated } = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) => state.currentUser;

export default currentUserSlice.reducer;
