import React, { useEffect, useRef, useState } from "react";
import "./audio.sass";
import "./timer.sass";
import { getStaticFile } from "utils";
import { AudioState } from "./audio";
import classnames from "classnames";
import { intervalToDuration } from "date-fns";
import ReactHowler from "./howler";

interface TimerProps {
  clicked?: boolean;
  state: AudioState;
  durations?: DurationOption[];
  defaultDuration: number;
  onStart: (duration: number) => void;
  onPause: () => void;
  onResume: () => void;
  onComplete: () => void;
}

export interface DurationOption {
  duration: number;
  label: string;
  default: boolean;
}

// States:
// waiting to start
// timing
// paused
// finished
function Timer({ state, onStart, onPause, onComplete, onResume, clicked, durations, defaultDuration }: TimerProps) {
  const [timeLeft, setTimeLeft] = useState<number | undefined>();
  const [timing, setTiming] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const ref = useRef<ReactHowler | null>(null);

  useEffect(() => {
    if (!state.inProgress) {
      return;
    }
    let timer: NodeJS.Timeout;
    if (typeof timeLeft !== "undefined") {
      timer = setInterval(() => {
        if (timeLeft <= 0) {
          clearInterval(timer);

          setTimeLeft(undefined);
          ref.current?.seek(0);
          setPlaying(true);

          onComplete();
        } else if (timing) {
          setTimeLeft(timeLeft - 1);
        } else {
          // ignore
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [onComplete, state.inProgress, timeLeft, timing]);

  const started = state.started;
  const finished = started && state.completed;
  const paused = started && !finished && !state.inProgress;

  function start(totalTime: number) {
    setTimeLeft(totalTime);
    setTiming(true);
    setPlaying(false);
    onStart(totalTime);
  }

  function pause() {
    setTiming(false);
    setPlaying(false);
    onPause();
  }

  function resume() {
    setTiming(true);
    setPlaying(false);
    onResume();
  }

  const timerClass = classnames("Timer", { inProgress: state.started });
  const completeSources = [
    getStaticFile("/s/b4f106be-9750-4911-b1fb-b069efa2da0c", "-complete.m4a"),
    getStaticFile("/s/b4f106be-9750-4911-b1fb-b069efa2da0c", "-complete.ogg"),
  ];

  const startWrapperClass = classnames("startWrapper", { startWrapperSingle: !durations || durations.length === 0 });

  const timeLeftDuration = intervalToDuration({ start: 0, end: (timeLeft ?? 0) * 1000 });
  const timeLeftInSeconds = `${timeLeftDuration.minutes}:${(timeLeftDuration.seconds ?? 0) < 10 ? "0" : ""}${
    timeLeftDuration.seconds
  }`;

  return (
    <div className={timerClass}>
      {clicked && (
        <ReactHowler
          key="audio-enter-room"
          src={completeSources}
          ref={ref}
          playing={playing}
          onEnd={() => setPlaying(false)}
        />
      )}
      <div className="pane">
        {!started && !finished && (
          <div className={startWrapperClass}>
            <div onClick={() => start(defaultDuration)}>Start</div>
            {durations && durations.length > 0 && (
              <div className="startTimer">
                {durations.map((d, i) => {
                  const durationClass = classnames("start", { default: d.default, last: i === durations.length - 1 });
                  return (
                    <div key={d.label} className={durationClass} onClick={() => start(d.duration)}>
                      {d.label}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {finished && (
          <div>
            <div className={startWrapperClass}>
              <div onClick={() => start(defaultDuration)}>Restart</div>
              {durations && durations.length > 0 && (
                <div className="startTimer">
                  {durations.map((d, i) => {
                    const durationClass = classnames("start", { default: d.default, last: i === durations.length - 1 });
                    return (
                      <div key={d.label} className={durationClass} onClick={() => start(d.duration)}>
                        {d.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {paused && <div onClick={resume}>Paused</div>}
        {!finished && timing && <div onClick={pause}>{timeLeftInSeconds}</div>}
      </div>
      {!started && !finished && <div className="startTip">Turn on audio, click 'Start'.</div>}
    </div>
  );
}

export default Timer;
