import PremiumMetricChart from "components/chart/premium_chart";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { Grid } from "@mui/material";
import { LineTooltipCallbacks } from "components/chart/helpers";
import { PeriodLimit, periodRange, useCanAccessPersonalDashboard } from "utils";
import { useState } from "react";
import {
  GetPersonalEnergyPatternByHourOfTheDayQueryVariables,
  TimePeriod,
  useGetPersonalEnergyPatternByHourOfTheDayQuery,
} from "generated/graphql";
// import RouteNames from "route_names";

const PersonalEnergyPatternByHOD = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const canAccessPersonalDashboard = useCanAccessPersonalDashboard();

  // GraphQL data call
  const energyPatternDOW = useGetPersonalEnergyPatternByHourOfTheDayQuery(
    {
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
    } as GetPersonalEnergyPatternByHourOfTheDayQueryVariables,
    { enabled: canAccessPersonalDashboard }
  );

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "h aaa";
  let title = "Personal Energy Pattern by Hour of the Day",
    subtitle = "Hourly energy trend", //To allow chart component to show a circle progress when loading ;)
    data = factoryDefaultData();

  const checkinCount: any[] = [];
  if (!energyPatternDOW.isLoading && energyPatternDOW.isSuccess) {
    title = energyPatternDOW.data?.personalDashboard?.energyPatternByHours.name ?? title;
    const rawData = energyPatternDOW.data?.personalDashboard?.energyPatternByHours?.values;
    // Find the highest and lowest point of energy
    if (rawData) checkinCount.push(...rawData?.map((tb) => tb?.label));
    const highestValue = rawData?.find(
      (tb) => tb.value === Math.max(...rawData?.filter((d) => d.value >= 1).map((tb) => parseFloat(tb.value)))
    );
    const lowestValue = rawData?.find(
      (tb) => tb.value === Math.min(...rawData?.filter((d) => d.value >= 1).map((tb) => parseFloat(tb.value)))
    );
    if (
      lowestValue &&
      highestValue &&
      lowestValue?.value >= 1 &&
      highestValue?.value >= 1 &&
      (highestValue?.value * 100) / lowestValue?.value >= 103
    ) {
      subtitle = `Highest around ${format(parseISO(highestValue?.date), dateFmt)} and lowest around ${format(
        parseISO(lowestValue?.date),
        dateFmt
      )}`;
    }
    data = {
      labels: rawData?.map((tb) => format(parseISO(tb.date), dateFmt)),
      datasets: [
        {
          label: "Average",
          data: rawData?.map((tb) => tb.value),
          backgroundColor: rawData?.map((tb) => "#F584DE"),
        },
      ],
    };
  }
  const hasEnoughData = energyPatternDOW.data?.personalDashboard?.energyPatternByHours?.values
    ? energyPatternDOW.data?.personalDashboard?.energyPatternByHours?.values.length > 0
    : false;

  return (
    <Grid item container xs={12} md={6} lg={6}>
      <PremiumMetricChart
        canAccess={canAccessPersonalDashboard}
        type={type}
        title={title}
        subtitle={subtitle}
        dataSuccess={energyPatternDOW.isSuccess}
        dataLoading={energyPatternDOW.isLoading}
        hasEnoughData={hasEnoughData}
        data={data}
        options={{
          plugins: {
            tooltip: {
              callbacks: LineTooltipCallbacks(checkinCount, "Time: ", "Average: ", "Count: "),
            },
          },
        }}
        actionToBeTakenText="Block time during your high energy hours to focus on important tasks."
      />
    </Grid>
  );
};

export default PersonalEnergyPatternByHOD;
