import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import IconTrainingData from "assets/v2/icon-training-data.svg";
import { AiModelDataset, DataSetType, useDoTrainModelMutation } from "generated/graphql";
import { useDropzone } from "react-dropzone";
import { LoadingButton } from "@mui/lab";

function InstructionGPT() {
  return (
    <Stack alignItems="flex-start" flex={1} gap={2}>
      <Typography variant="h4">Dataset format</Typography>
      <Typography sx={{ maxWidth: 640 }}>
        To create a custom GPT model, please prepare your data in .jsonl format, and upload the file here.
      </Typography>
      <Box style={{ whiteSpace: "pre-wrap" }}>
        {`{"messages": [{"role": "system", "content": "Marv is a factual chatbot that is also sarcastic."}, {"role": "user", "content": "What's the capital of France?"}, {"role": "assistant", "content": "Paris, as if everyone doesn't know that already."}]}
{"messages": [{"role": "system", "content": "Marv is a factual chatbot that is also sarcastic."}, {"role": "user", "content": "Who wrote 'Romeo and Juliet'?"}, {"role": "assistant", "content": "Oh, just some guy named William Shakespeare. Ever heard of him?"}]}
{"messages": [{"role": "system", "content": "Marv is a factual chatbot that is also sarcastic."}, {"role": "user", "content": "How far is the Moon from Earth?"}, {"role": "assistant", "content": "Around 384,400 kilometers. Give or take a few, like that really matters."}]}`}
      </Box>
    </Stack>
  );
}

export default function CreateDatasetModal({
  id,
  open,
  onClose,
  onSucceed,
  addDataset,
}: {
  id?: string;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
  addDataset: (dataset: AiModelDataset) => void;
}) {
  const [error, setError] = useState<string>();
  const [busy, setBusy] = useState<boolean>(false);

  const trainModelMutation = useDoTrainModelMutation();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".jsonl"],
    },
  });

  function close(event: React.MouseEvent<HTMLElement>, reason?: string | null) {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  }

  function upload() {
    if (busy || !id || !acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    console.log(acceptedFiles[0].size);
    if (acceptedFiles[0].size > 10000000) {
      setError("File size is too large. Please upload a file less than 10MB.");
      return;
    }

    setError(undefined);
    setBusy(true);
    try {
      const reader = new FileReader();
      reader.onloadend = async (event) => {
        if (event.loaded && reader.result && typeof reader.result === "string") {
          try {
            const dataset = await trainModelMutation.mutateAsync({
              input: {
                id,
                type: DataSetType.Jsonl,
                data: reader.result,
              },
            });

            if (dataset?.trainModel) {
              addDataset(dataset.trainModel);
              onSucceed?.();
            }

            onClose();
          } catch (error) {
            if (error instanceof Error && error.message === "model.cannot_train_while_training") {
              setError("Only one training job can be running at a time. Please try again later.");
            } else {
              setError("An error occurred while uploading the dataset. Please try again later.");
            }
          } finally {
            setBusy(false);
          }
        }
      };
      reader.readAsText(acceptedFiles[0]);
    } catch (error) {
      setError(`${error}`);
      setBusy(false);
    }
  }

  return (
    <Dialog className="CreateDatasetModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconTrainingData} alt="Create new dataset" />
          <Typography variant="h4">Add training data</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2}>
          <Box {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} disabled={busy} />
            {acceptedFiles.length > 0 ? (
              <div>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {acceptedFiles[0]?.name}
                </Typography>
                <Typography>Drag & drop or click to upload another dataset file</Typography>
              </div>
            ) : (
              <div>
                <Typography sx={{ mb: 2 }}>Drag & drop or click to select your dataset file</Typography>
                <Typography>(Only .jsonl files will be accepted)</Typography>
              </div>
            )}
          </Box>
          <Typography color="error">{error ? error : ""}</Typography>
          <InstructionGPT />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <LoadingButton onClick={(event) => close(event, undefined)} disabled={busy}>
              Cancel
            </LoadingButton>
            <LoadingButton disabled={acceptedFiles.length === 0 || busy} variant="contained" onClick={upload}>
              Upload
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
