import CreateCharacterModal from "./create_character_modal";
import IconCharacters from "assets/v2/icon-characters.svg";
import IconEdit from "assets/v2/icon-edit.svg";
import IconMore from "assets/v2/icon-more.svg";
import IconSolidOkay from "assets/v2/icon-solid-ok.svg";
import Layout from "components/layout/layout";
import Loader from "react-spinners/ScaleLoader";
import RouteNames from "route_names";
import { Link, useParams } from "react-router-dom";
import { queryClient } from "api";
import { selectCurrentUser } from "store/current_user_reducer";
import { switchModel } from "store/models_reducer";
import { CreateAbilityModal } from "./create_ability_modal";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks";
import { useRef, useState } from "react";
import "./index.sass";
import {
  AiModelType,
  DataSource,
  SourceStatus,
  useDoUnlinkCharacterSourcesMutation,
  useGetCharacterSourcesQuery,
  useGetCharactersQuery,
} from "generated/graphql";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

function AbilityCard({ characterId, source }: { characterId: string; source: DataSource }) {
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const unlinkAbilityMutation = useDoUnlinkCharacterSourcesMutation();

  async function unlinkAbility() {
    try {
      await unlinkAbilityMutation.mutateAsync({
        characterId: characterId,
        ids: [source.id],
      });
      queryClient.invalidateQueries(["getCharacterSources", { query: { id: characterId } }]);
    } catch (error) {}
  }

  return (
    <Box className="AbilityCard" sx={{ p: 2 }} style={{ maxWidth: "100%" }}>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ height: "100%" }}>
        <Stack gap={1}>
          <Box sx={{ m: 1, wordBreak: "break-word", textOverflow: "ellipsis", overflow: "hidden" }}>{source.name}</Box>
          <Chip
            sx={{ textTransform: "capitalize" }}
            icon={
              source.status === SourceStatus.Finished ? (
                <img src={IconSolidOkay} alt="Ready" style={{ height: 16 }} />
              ) : undefined
            }
            color={source.status === SourceStatus.Finished ? "success" : "default"}
            label={source.status}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
            <img src={IconMore} alt="Card options" style={{ height: 16 }} />
          </IconButton>
          <Menu
            elevation={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={showCardMenu}
            anchorEl={el.current}
            onClose={() => setShowCardMenu(false)}
          >
            {/* <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                }}
              >
                <span>Share</span>
              </MenuItem> */}
            <MenuItem
              onClick={() => {
                setShowCardMenu(false);
                setShowConfirmDeleteMenu(true);
              }}
            >
              <span>Remove ability</span>
            </MenuItem>
          </Menu>
          <Menu
            elevation={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={showConfirmDeleteMenu}
            anchorEl={el.current}
            onClose={() => setShowConfirmDeleteMenu(false)}
          >
            <MenuItem
              onClick={() => {
                unlinkAbility();
                setShowConfirmDeleteMenu(false);
              }}
            >
              <span>Yes, delete!</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowConfirmDeleteMenu(false);
              }}
            >
              <span>No, cancel</span>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
}

function Abilities({ id }: { id: string }) {
  const abilitiesQuery = useGetCharacterSourcesQuery({ query: { id } });
  const abilities = (abilitiesQuery.data?.characters?.characters ?? [])[0]?.abilities || [];

  const [addAbilityModalOpen, setAddAbilityModalOpen] = useState<boolean>(false);

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Abilities</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setAddAbilityModalOpen(true);
          }}
        >
          Add
        </Button>
      </Stack>
      <Grid
        spacing={1.5}
        container
        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        justifyContent="stretch"
        alignItems="stretch"
        sx={{ position: "relative" }}
      >
        {abilities.map((ability) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={ability.id}>
            <AbilityCard characterId={id} source={ability} />
          </Grid>
        ))}
      </Grid>

      <CreateAbilityModal
        characterId={id}
        open={addAbilityModalOpen}
        onClose={() => setAddAbilityModalOpen(false)}
        onSucceed={() => {
          setAddAbilityModalOpen(false);
          queryClient.invalidateQueries(["getCharacterSources", { query: { id } }]);
        }}
      />
    </Stack>
  );
}

export default function CharacterEditor() {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { id } = useParams();
  const charactersQuery = useGetCharactersQuery({ query: { id, limit: 1 } });
  const currentUser = useAppSelector(selectCurrentUser);

  const characters = charactersQuery?.data?.characters?.characters ?? [];
  const characterName = characters.length > 0 ? `${characters[0].name ?? ""}` : "Character";

  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);

  function invalidateCharacterQuery() {
    queryClient.invalidateQueries(["getCharacters", { query: { id, limit: 1 } }]);
  }

  const isLoading = charactersQuery.isLoading;
  const character = characters[0];
  const fields = character?.fragment?.fields ?? [];
  const values = fields[0]?.values ?? [];

  function startChat() {
    // make sure the model is a chat model, because characters are not supported in custom models
    dispatch(switchModel(AiModelType.Chatgpt_3_5Turbo));
    navigate(RouteNames.NewPrompt, { state: { characterId: id } });
  }

  return (
    <Layout
      className="Characters"
      workspace
      header={
        <Stack className="PageHeader" direction="row" alignItems="flex-start" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link to={RouteNames.Characters}>Characters</Link>
              <Typography>{isLoading ? <Skeleton width={60} /> : characterName}</Typography>
            </Breadcrumbs>

            <IconButton onClick={() => setUpdateModalOpen(true)}>
              <img src={IconEdit} alt="Edit" height={16} />
            </IconButton>
          </Stack>

          <Button variant="outlined" size="medium" onClick={startChat} style={{ whiteSpace: "nowrap" }}>
            <img src={IconCharacters} alt="Start Chat" height={14} />
            &nbsp;&nbsp;Start Chat
          </Button>
        </Stack>
      }
      key={`character-${currentUser?.currentUser?.id}`}
    >
      <Stack alignItems="stretch" gap={1}>
        {isLoading ? (
          <Skeleton width={200} />
        ) : characters.length > 0 && characters[0].description ? (
          <Typography>{characters[0].description}</Typography>
        ) : null}

        {!!values && <Box>{values[0]}</Box>}

        {isLoading && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}

        {id && <Abilities id={id} />}
        {/* {!isLoading && id && <UploadNewDocument characterId={id} />} */}
      </Stack>

      <CreateCharacterModal
        key={`update-character-modal-${characters[0]?.id ?? 0}`}
        character={characters[0]}
        open={updateModalOpen}
        onSucceed={invalidateCharacterQuery}
        onClose={() => {
          setUpdateModalOpen(false);
        }}
      />
    </Layout>
  );
}
