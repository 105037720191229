import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Icon from "@mui/material/Icon/Icon";
import BadgeBreath from "assets/badge-breath.svg";
import BadgeEyeBreak from "assets/badge-eyebreak.svg";
import IconBoundary from "assets/icon-boundary.svg";
import IconBreath from "assets/icon-breath.svg";
import IconCheckIn from "assets/icon-energy-checkin.svg";
import IconEyeBreak from "assets/icon-eye-break.svg";
import IconGratitude from "assets/icon-gratitude.svg";
import IconHydrate from "assets/icon-hydrate.svg";
import IconJournal from "assets/icon-journal.svg";
import IconLunch from "assets/icon-lunch.svg";
import IconMeeting from "assets/icon-meeting.svg";
import IconPriority from "assets/icon-priority.svg";
import IconStretch from "assets/icon-stretch.svg";
import IconWalk from "assets/icon-walk.svg";
import Color from "color";
import { ActivityType } from "generated/graphql";
import RouteNames from "route_names";

export function formatSocialProof(action: string, count?: number | null): string {
  if (!count) {
    return "";
  }

  switch (action) {
    case ActivityType.Breath:
      return `${count} of your teammates took a breath break in the last 24 hours`;
    case ActivityType.EyeBreak:
      return `${count} of your teammates took an eye break in the last 24 hours`;
  }
  return "";
}

export function getCardLink(action: string): string {
  switch (action) {
    case ActivityType.Breath:
      return RouteNames.Breath;
    case ActivityType.EyeBreak:
      return RouteNames.Eyebreak;
  }
  return "";
}

export function getDefaultTitle(action: string): string {
  switch (action) {
    case ActivityType.Breath:
      return "Breath";
    case ActivityType.EyeBreak:
      return "Eye Break";
    case ActivityType.Stretch:
      return "Stretch";
    case ActivityType.Walk:
      return "Short walk";
    case ActivityType.Routine:
      return "Daily check-in";
    case ActivityType.Water:
      return "Drink water";
    case ActivityType.Lunch:
      return "Lunch";
    case ActivityType.Priority:
      return "Daily Intention";
    case ActivityType.Gratitude:
      return "Gratitude";
    case ActivityType.Boundary:
      return "Wrap up";
    case ActivityType.Journal:
      return "Energy Reflection";
    case ActivityType.CustomReminder:
      return "Custom Reminder";
  }
  return "";
}

export function getButtonText(action: string): string {
  switch (action) {
    case ActivityType.Breath:
      return "Calm Down";
    case ActivityType.EyeBreak:
      return "Relax Eyes";
  }
  return "";
}

export function getBadge(action: string): string {
  switch (action) {
    case ActivityType.Breath:
      return BadgeBreath;
    case ActivityType.EyeBreak:
      return BadgeEyeBreak;
  }
  return "";
}

export function getTint(action: string): string {
  switch (action) {
    case ActivityType.Breath:
      return "#24574A";
    case ActivityType.EyeBreak:
      return "#392878";
  }
  return "";
}

export function getGradient(action: string, top?: boolean): string {
  const tint = getTint(action);
  if (tint === "") {
    return "";
  }
  try {
    const gradient = Color(tint);
    return `linear-gradient(to ${top ? "top" : "bottom"}, transparent, ${gradient
      .alpha(0)
      .hexa()}, ${gradient.hexa()})`;
  } catch (e) {
    return "";
  }
}

export function getDuration(action: string, top?: boolean): string | undefined {
  switch (action) {
    case ActivityType.Breath:
      return "Takes 1 minute";
    case ActivityType.EyeBreak:
      return "Takes 1 minute";
  }
}

export function getActivityIcon(action: ActivityType, title?: string, className?: string): JSX.Element | undefined {
  switch (action) {
    case ActivityType.JoinMeeting:
      return <img src={IconMeeting} alt={action ?? title} className={className} />;
    case ActivityType.Breath:
      return <img src={IconBreath} alt={action ?? title} className={className} />;
    case ActivityType.EyeBreak:
      return <img src={IconEyeBreak} alt={action ?? title} className={className} />;
    case ActivityType.Stretch:
      return <img src={IconStretch} alt={action ?? title} className={className} />;
    case ActivityType.Walk:
      return <img src={IconWalk} alt={action ?? title} className={className} />;
    case ActivityType.Lunch:
      return <img src={IconLunch} alt={action ?? title} className={className} />;
    case ActivityType.Water:
      return <img src={IconHydrate} alt={action ?? title} className={className} />;
    case ActivityType.Journal:
      return <img src={IconCheckIn} alt={action ?? title} className={className} />;
    case ActivityType.Boundary:
      return <img src={IconBoundary} alt={action ?? title} className={className} />;
    case ActivityType.Gratitude:
      return <img src={IconGratitude} alt={action ?? title} className={className} />;
    case ActivityType.Priority:
      return <img src={IconPriority} alt={action ?? title} className={className} />;
    case ActivityType.Routine:
      return <img src={IconJournal} alt={action ?? title} className={className} />;
    case ActivityType.CustomReminder:
      return (
        <Icon className={className}>
          <NotificationsActiveIcon titleAccess={action ?? title} />
        </Icon>
      );
  }
}
