import { formatDistanceToNow, parseISO } from "date-fns";
import classnames from "classnames";
import React from "react";
import "./recent_users.sass";
import { ProfilePicture } from "generated/graphql";

function RecentUsers({ profilePictures }: { profilePictures: ProfilePicture[] }) {
  // const excludeSelf = profilePictures.filter((p) => {
  //   // only show current user's session if older than 1 minute.
  //   return !p.isCurrentUser || (p.lastSeen && parseISO(p.lastSeen) < subMinutes(new Date(), 1));
  // });
  let limit = 6;
  let extra = profilePictures.length > limit ? profilePictures.length - limit : 0;
  if (extra === 1) {
    limit += 1;
    extra = 0;
  }

  const avatarClass = classnames("avatar");

  return (
    <div className="RecentUsers">
      {profilePictures.map((val: ProfilePicture) => {
        const lastSeen = val.lastSeen && parseISO(val.lastSeen);
        return (
          <div key={val.url} className="RecentUser">
            <div className={avatarClass}>
              <img src={val.url} alt="User profile" />
            </div>
            <div className="info">
              <div>
                <b>{val.name}</b> did it
              </div>
              <div>{lastSeen && formatDistanceToNow(parseISO(val.lastSeen), { addSuffix: true })}</div>
            </div>
          </div>
        );
      })}
      {extra > 0 && <span>and {extra} others</span>}
    </div>
  );
}

export default RecentUsers;
