import PremiumMetricChart from "components/chart/premium_chart";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { Grid } from "@mui/material";
import { LineTooltipCallbacks } from "components/chart/helpers";
import { PeriodLimit, periodRange, useCanAccessPersonalDashboard } from "utils";
import { useState } from "react";
import {
  GetPersonalEnergyPatternByDayOfTheWeekQueryVariables,
  TimePeriod,
  useGetPersonalEnergyPatternByDayOfTheWeekQuery,
} from "generated/graphql";
// import RouteNames from "route_names";

const PersonalEnergyPatternByDOW = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const canAccessPersonalDashboard = useCanAccessPersonalDashboard();

  // GraphQL data call
  const energyPatternDOW = useGetPersonalEnergyPatternByDayOfTheWeekQuery(
    {
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
    } as GetPersonalEnergyPatternByDayOfTheWeekQueryVariables,
    { enabled: canAccessPersonalDashboard }
  );

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "EEEE";
  let title = "Personal Energy Pattern by Day of the Week",
    subtitle = "Weekly energy trend", //To allow chart component to show a circle progress when loading ;)
    data = factoryDefaultData();
  const checkinCount: any[] = [];
  if (!energyPatternDOW.isLoading && energyPatternDOW.isSuccess) {
    title = energyPatternDOW.data?.personalDashboard?.energyPatternByDays.name ?? title;
    const rawData = energyPatternDOW.data?.personalDashboard?.energyPatternByDays?.values;

    data = {
      labels: rawData?.map((tb) => format(parseISO(tb.date), dateFmt)),
      datasets: [
        {
          label: "Average",
          data: rawData?.map((tb) => tb.value),
          backgroundColor: rawData?.map((tb) => "#F584DE"),
        },
      ],
    };

    if (rawData) checkinCount.push(...rawData?.map((tb) => tb?.label));
    const cleanData: any[] | undefined = rawData?.filter((d) => d.value !== null);
    if (cleanData) {
      const highestValue = cleanData?.find((tb) => tb.value === Math.max(...cleanData.map((tb) => tb.value)));
      const lowestValue = cleanData?.find((tb) => tb.value === Math.min(...cleanData.map((tb) => tb.value)));
      if (
        lowestValue &&
        highestValue &&
        lowestValue?.value >= 1 &&
        highestValue?.value >= 1 &&
        (highestValue?.value * 100) / lowestValue?.value >= 103
      ) {
        subtitle = `Highest on ${format(parseISO(highestValue?.date), dateFmt)} and lowest on ${format(
          parseISO(lowestValue?.date),
          dateFmt
        )}`;
      }
    }
  }
  const hasEnoughData = energyPatternDOW.data?.personalDashboard?.energyPatternByDays?.values
    ? energyPatternDOW.data?.personalDashboard?.energyPatternByDays?.values.length > 0
    : false;

  return (
    <Grid item container xs={12} md={6} lg={6}>
      <PremiumMetricChart
        canAccess={canAccessPersonalDashboard}
        type={type}
        title={title}
        subtitle={subtitle}
        dataSuccess={energyPatternDOW.isSuccess}
        dataLoading={energyPatternDOW.isLoading}
        hasEnoughData={hasEnoughData}
        data={data}
        options={{
          plugins: {
            tooltip: {
              callbacks: LineTooltipCallbacks(checkinCount, "", "Average: ", "Count: "),
            },
          },
        }}
        actionToBeTakenText="Protect your “maker's schedule” and tackle the most difficult problems on days with higher energy."
        actionToBeTakenOnClick={() => {
          window.open(
            "https://www.fastcompany.com/3054571/the-better-time-management-strategy-this-googler-taught-his-coworkers",
            "_blank"
          );
        }}
      />
    </Grid>
  );
};

export default PersonalEnergyPatternByDOW;
