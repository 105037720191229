import Typography from "@mui/material/Typography/Typography";
import VideoRoom from "components/video";
import { ActivityType } from "generated/graphql";

function StretchRoom() {
  return (
    <div className="StretchRoom">
      <VideoRoom
        videoURL={"https://vimeo.com/703472112/0b4102b469"}
        action={ActivityType.Stretch}
        keepProgress={true}
        roomName="Stretch"
        contentID={0}
      >
        <Typography variant="h2">Stretch</Typography>
        <p>Stretch your body</p>
      </VideoRoom>
    </div>
  );
}

export default StretchRoom;
