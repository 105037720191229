import AddIcon from "@mui/icons-material/PersonAdd";
import AddManagerIcon from "@mui/icons-material/AddModerator";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveManagerIcon from "@mui/icons-material/RemoveModerator";
import { _ } from "gridjs-react";
import { Cell, DataGrid, Row } from "components/datagrid";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { memo, useMemo } from "react";
import { selectCurrentUser } from "store/current_user_reducer";
import { useAppSelector } from "hooks";
import { useConfirm } from "material-ui-confirm";
import "./index.sass";
import {
  useGetTeamMembersQuery,
  useAssignTeamMemberMutation,
  useRemoveTeamMemberMutation,
  useUpdateTeamManagerValueMutation,
  GetTeamMembersQueryVariables,
  Team,
  UpdateTeamManagerValueMutationVariables,
  TeamMemberStatus,
} from "generated/graphql";

export default function TeamMemberManager({
  open,
  currentTeam,
  onClose,
}: {
  open: boolean;
  currentTeam?: Team;
  onClose?: () => void;
}) {
  const confirm = useConfirm();
  const { currentUser } = useAppSelector(selectCurrentUser);
  const promoteToTeamManager = useUpdateTeamManagerValueMutation();
  const assignTeamMember = useAssignTeamMemberMutation();
  const removeTeamMember = useRemoveTeamMemberMutation();
  const getTeamMembers = useGetTeamMembersQuery({
    teamId: currentTeam?.id ?? "",
  } as GetTeamMembersQueryVariables);
  const DataGridMemo = memo(DataGrid);

  const data = useMemo(() => {
    if (getTeamMembers.data && getTeamMembers.data.getTeamMembers.length > 0)
      return getTeamMembers.data?.getTeamMembers;
    return [];
  }, [getTeamMembers]);

  return (
    <Dialog
      className="TeamMemberDialog"
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <DialogTitle sx={{ minWidth: 400, maxWidth: 920 }}>{`Team "${currentTeam ? currentTeam.name : ""}"`}</DialogTitle>
      <DialogContent sx={{ maxWidth: 720, minHeight: 420 }}>
        <DataGridMemo
          data={data}
          actions={[
            {
              component: (cell: Cell, row: Row) =>
                row.cells[0].data === null || row.cells[2].data === currentUser?.gid ? (
                  <div key={`manager-empty-button-${row.cells[1].data}-${row.cells[2].data}`}></div>
                ) : (
                  row.cells[4].data !== TeamMemberStatus.Inactive &&
                  row.cells[4].data !== TeamMemberStatus.Deactivated &&
                  (!!row.cells[3].data ? (
                    <Tooltip
                      key={`remove-manager-button-${row.cells[1].data}-${row.cells[2].data}`}
                      title="Remove Team Manager"
                    >
                      <div>
                        <IconButton
                          disabled={getTeamMembers.isLoading || getTeamMembers.isRefetching}
                          aria-label="Remove team manager"
                          color="secondary"
                          onClick={(e) => {
                            confirm({
                              title: "Team Manager removal",
                              description: `Do you want to remove ${row.cells[6].data} as team manager?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "secondary" },
                            })
                              .then(async () => {
                                const result = await promoteToTeamManager.mutateAsync({
                                  teamMemberId: row.cells[0].data,
                                  manager: false,
                                } as UpdateTeamManagerValueMutationVariables);
                                if (result.updateTeamManagerValue) getTeamMembers.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <RemoveManagerIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      key={`add-manager-button-${row.cells[1].data}-${row.cells[2].data}`}
                      title="Promote to Team Manager"
                    >
                      <div>
                        <IconButton
                          disabled={getTeamMembers.isLoading || getTeamMembers.isRefetching}
                          aria-label="Promote as team manager"
                          color="secondary"
                          onClick={(e) => {
                            confirm({
                              title: "Team Manager promotion",
                              description: `Do you want to promote ${row.cells[6].data} as team manager?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "secondary" },
                            })
                              .then(async () => {
                                const result = await promoteToTeamManager.mutateAsync({
                                  teamMemberId: row.cells[0].data,
                                  manager: true,
                                } as UpdateTeamManagerValueMutationVariables);
                                if (result.updateTeamManagerValue) getTeamMembers.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <AddManagerIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ))
                ),
            },
            {
              component: (cell: Cell, row: Row) =>
                row.cells[0].data !== null &&
                row.cells[4].data?.toString() !== TeamMemberStatus.Inactive &&
                row.cells[4].data?.toString() !== TeamMemberStatus.Deactivated ? (
                  <div key={`add-button-${row.cells[1].data}-${row.cells[2].data}`}></div>
                ) : (
                  row.cells[4].data?.toString() !== TeamMemberStatus.Active && (
                    <Tooltip key={`add-button-${row.cells[1].data}-${row.cells[2].data}`} title="Assign Team Member">
                      <div>
                        <IconButton
                          disabled={getTeamMembers.isLoading || getTeamMembers.isRefetching}
                          aria-label="Assign team member"
                          color="info"
                          onClick={(e) => {
                            confirm({
                              title: "Assign Team Member",
                              description: `Do you want to assign  ${row.cells[6].data} to the team?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "success" },
                            })
                              .then(async () => {
                                const result = await assignTeamMember.mutateAsync({
                                  input: {
                                    teamId: currentTeam?.id ?? "",
                                    userId: row.cells[2].data?.toString() ?? "",
                                  },
                                });
                                if (result.assignTeamMember) getTeamMembers.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                ),
            },
            {
              component: (cell: Cell, row: Row) =>
                row.cells[0].data === null ||
                row.cells[2].data?.toString() === currentUser?.gid ||
                (row.cells[4].data?.toString() !== TeamMemberStatus.Active &&
                  row.cells[4].data !== TeamMemberStatus.Invited) ? (
                  <div key={`remove-button-${row.cells[2].data}`}></div>
                ) : (
                  row.cells[4].data !== TeamMemberStatus.Inactive &&
                  row.cells[4].data !== TeamMemberStatus.Deactivated && (
                    <Tooltip key={`remove-button-${row.cells[0].data}`} title="Remove Team Member">
                      <div>
                        <IconButton
                          aria-label="Remove team member"
                          color="error"
                          disabled={getTeamMembers.isLoading || getTeamMembers.isRefetching}
                          onClick={() => {
                            confirm({
                              title: `Remove Team Member`,
                              description: `Are you sure you want to remove ${row.cells[6].data}'s team assignment?`,
                              confirmationText: "Yes",
                              cancellationText: "No",
                              confirmationButtonProps: { variant: "contained", color: "error" },
                            })
                              .then(async () => {
                                const result = await removeTeamMember.mutateAsync({
                                  teamMemberId: row.cells[0].data?.toString() ?? "",
                                });
                                if (result.removeTeamMember) getTeamMembers.refetch();
                              })
                              .catch((err) => console.error(err));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                ),
            },
          ]}
          columns={[
            {
              name: "ID",
              hidden: true,
            },
            {
              name: "teamId",
              hidden: true,
            },
            {
              name: "userId",
              hidden: true,
            },
            {
              name: "isTeamManager",
              hidden: true,
            },
            {
              id: "memberStatus",
              name: "Status",
              hidden: true,
              width: 10,
            },
            {
              id: "profilePicture",
              sort: false,
              formatter: (cell: any, row: any) =>
                _(
                  <img
                    className={
                      row.cells[4].data
                        ? row.cells[4].data === TeamMemberStatus.Active
                          ? "team-member-active"
                          : row.cells[4].data === TeamMemberStatus.Invited
                          ? "team-member-invited"
                          : row.cells[4].data === TeamMemberStatus.Deactivated
                          ? "team-member-deactivated"
                          : ""
                        : "team-member-inactive"
                    }
                    src={row.cells[5].data}
                    key={`avatar-${row.cells[0].data}`}
                    alt={`${row.cells[6].data}`}
                    width={50}
                    height={50}
                  />
                ),
              width: 100,
            },
            {
              name: "Name",
              width: "25%",
            },
            {
              name: "Email",
              width: "25%",
            },
          ]}
          sort={true}
          autoWidth={false}
          search={true}
          pagination={{
            enabled: true,
            limit: 10,
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
