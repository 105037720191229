import { Box, Button, IconButton, List, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import { generatePath, Link, Outlet } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "./app_home_2023.sass";
import Layout from "./layout/layout";
import IconMore from "assets/v2/icon-more.svg";
import IconRawMode from "assets/v2/icon-raw-mode.svg";
import { Prompt, PaginatedQuery, useDoDeletePromptsMutation, useGetPromptsQuery } from "generated/graphql";
import { useCallback, useEffect, useRef, useState } from "react";
import RouteNames from "route_names";
import IconNewPrompt from "assets/v2/icon-new-prompt.svg";
import Loader from "react-spinners/ScaleLoader";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks";
import { selectCurrentUser } from "store/current_user_reducer";
import { addPrompts, removePrompts, selectPrompts } from "store/prompts_reducer";

export const GlobalKeyAppHome = "app-home";

function PromptCard({ prompt }: { prompt: Prompt }) {
  const dispatch = useAppDispatch();
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState<boolean>(false);

  const deleteMutation = useDoDeletePromptsMutation();

  async function deletePrompts() {
    dispatch(removePrompts([prompt.id]));
    try {
      await deleteMutation.mutateAsync({
        ids: [prompt.id],
      });
    } catch (error) {}
  }

  return (
    <Box className="PromptCard" style={{ maxWidth: "100%" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: "100%" }}>
        <Box sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{prompt.name}</Box>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Link to={generatePath(RouteNames.PromptEditor, { id: prompt.id })} style={{ textDecoration: "none" }}>
            <Button startIcon={<img src={IconRawMode} alt={`${prompt.messageCount} prompts`} style={{ height: 20 }} />}>
              {prompt.messageCount}
            </Button>
          </Link>
          <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
            <img src={IconMore} alt="Card options" style={{ height: 16 }} />
          </IconButton>
          <Menu
            elevation={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={showCardMenu}
            anchorEl={el.current}
            onClose={() => setShowCardMenu(false)}
          >
            {/* <MenuItem
                onClick={() => {
                  setShowCardMenu(false);
                }}
              >
                <span>Share</span>
              </MenuItem> */}
            <MenuItem
              onClick={() => {
                setShowCardMenu(false);
                setShowConfirmDeleteMenu(true);
              }}
            >
              <span>Delete</span>
            </MenuItem>
          </Menu>
          <Menu
            elevation={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={showConfirmDeleteMenu}
            anchorEl={el.current}
            onClose={() => setShowConfirmDeleteMenu(false)}
          >
            <MenuItem
              onClick={() => {
                deletePrompts();
                setShowConfirmDeleteMenu(false);
              }}
            >
              <span>Yes, delete!</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowConfirmDeleteMenu(false);
              }}
            >
              <span>No, cancel</span>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
}

function PromptCardPlaceholder() {
  return (
    <Box className="PromptCard" style={{ width: "100%" }}>
      <Typography variant="h1">
        <Skeleton />
      </Typography>
    </Box>
  );
}

function NewPrompt() {
  const navigate = useAppNavigate();
  function goToNewPrompt() {
    navigate(RouteNames.NewPrompt);
  }
  return (
    <Stack alignItems="center" flex={1} gap={4} sx={{ mt: 4 }}>
      <img src="https://staticgists.com/astronaut-start-v1.png" alt="New Chat" style={{ width: 240 }} />
      <Typography textAlign="center">
        No chat has been created yet.
        <br />
        Create a new chat and it'll show up here.
      </Typography>
      <Button onClick={goToNewPrompt} variant="outlined" size="medium">
        <img src={IconNewPrompt} alt="New Chat" height={20} />
        &nbsp;New Chat
      </Button>
    </Stack>
  );
}

function AppHome2023() {
  const dispatch = useAppDispatch();
  const { prompts: responses, cursor, loaded, limit } = useAppSelector(selectPrompts);
  const [query, setQuery] = useState<PaginatedQuery>({ limit });
  const promptQuery = useGetPromptsQuery({ query });
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (promptQuery.isFetched) {
      dispatch(addPrompts(promptQuery?.data?.prompts));
    }
  }, [dispatch, promptQuery?.data?.prompts, promptQuery.isFetched]);

  const loadMore = useCallback(() => {
    if (!loaded) {
      if (cursor) {
        // load more page
        setQuery({ ...query, cursor });
      }
    }
  }, [cursor, loaded, query]);

  return (
    <Layout
      className="AppHome2023"
      header={
        <div className="PageHeader">
          <Typography variant="h4" className="PageTitle">
            Chat history
          </Typography>
        </div>
      }
      key={`apphome-${currentUser?.currentUser?.id}`}
    >
      <Stack alignItems="center" style={{ maxWidth: "100%" }}>
        <List sx={{ position: "relative", alignItems: "stretch", width: "100%" }}>
          {promptQuery.isLoading && responses.length === 0 ? (
            Array(20)
              .fill(0)
              .map((_, i) => <PromptCardPlaceholder key={i} />)
          ) : responses.length > 0 ? (
            responses.map((r: Prompt) => <PromptCard key={r.id} prompt={r} />)
          ) : (
            <NewPrompt />
          )}
        </List>

        {!loaded && !promptQuery.isLoading && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Button onClick={loadMore} size="small">
              Load more...
            </Button>
          </Stack>
        )}

        {loaded && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            {responses.length} Prompts
          </Stack>
        )}

        {promptQuery.isLoading && responses.length > 0 && (
          <Stack sx={{ mt: 4, mb: 6 }} alignItems="center">
            <Loader color="#ccc" />
          </Stack>
        )}
      </Stack>
      <Outlet />
    </Layout>
  );
}

export default AppHome2023;
