import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

export function NumberPicker({
  min,
  max,
  step,
  value,
  int,
  scaleFunction,
  onChange,
}: {
  value: number;
  min: number | 0;
  max: number | 1;
  step: number | 0.01;
  int?: boolean;
  scaleFunction?: (x: number) => number;
  onChange: (value: number) => void;
}) {
  const [currentNumber, setCurrentNumber] = useState<number>(value);

  return (
    <Slider
      track={false}
      sx={{ width: 200 }}
      step={step}
      min={min}
      max={max}
      scale={scaleFunction}
      value={currentNumber}
      // marks={marks}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => `${int ? value | 0 : value.toFixed(2)}`}
      onChange={(_, val) => {
        const newNumber = Array.isArray(val) ? val[0] : val;
        onChange(int ? newNumber | 0 : newNumber);
        setCurrentNumber(int ? newNumber | 0 : newNumber);
      }}
    />
  );
}

export function NumberPickerPopover({
  open,
  className,
  value,
  onClose,
  anchorEl,
  onConfirm,
  min,
  max,
  step,
  minLabel,
  maxLabel,
  int,
  scaleFunction,
}: {
  open: boolean;
  className: string;
  value: number;
  onClose: () => void;
  onConfirm: (newNumber: number) => void;
  anchorEl: Element | null;
  min: number | 0;
  max: number | 1;
  step: number | 0.01;
  minLabel: string;
  maxLabel: string;
  int?: boolean;
  scaleFunction?: (x: number) => number;
}) {
  const [newNumber, setNewNumber] = useState<number>(value ?? 1);

  useEffect(() => {
    setNewNumber(value ?? 1);
  }, [value]);

  return (
    <Popover
      className="NumberPickerPopover"
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      elevation={0}
    >
      <Stack alignItems="center" direction="row" sx={{ p: 2 }}>
        <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 2, ml: 2, mr: 2, mb: 2 }}>
          <Typography>{minLabel}</Typography>
          <NumberPicker
            value={newNumber}
            min={min}
            max={max}
            step={step}
            int={int}
            scaleFunction={scaleFunction}
            onChange={(t) => setNewNumber(t)}
          />
          <Typography>{maxLabel}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <IconButton
            onClick={() => {
              onConfirm(newNumber);
              onClose();
            }}
            color="success"
          >
            <CheckIcon />
          </IconButton>
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Popover>
  );
}
