import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppSelector } from "hooks";
import { Link } from "react-router-dom";

import RouteNames from "route_names";
import { selectAuth } from "store/auth_reducer";
import Branding from "../branding";
import SharingInfo from "../sharing/sharing_info";
import "./nav.sass";

function Nav({ currentRoom, teamName }: { currentRoom?: string; teamName?: string | null }) {
  const { currentAccount } = useAppSelector(selectAuth);

  // hide nav if it's guest
  if (currentAccount?.isGuest && currentAccount?.source && currentAccount?.sourceUserName) {
    return <SharingInfo source={currentAccount?.source} name={currentAccount?.sourceUserName} />;
  }

  return (
    <div className="Nav">
      {currentRoom ? (
        <div className="NavItems">
          <Link to={RouteNames.Home}>
            <ArrowBackIcon className="Icon" />
          </Link>
          <div className="CurrentRoom">{currentRoom}</div>
        </div>
      ) : (
        <Branding />
      )}
    </div>
  );
}

export default Nav;
