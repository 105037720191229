import MetricChart from "components/chart";
import { chartActivityLabels } from "components/chart/labels";
import { chartWellnessAreaColors } from "components/chart/colors";
import { DoughnutTooltipCallbacks } from "components/chart/helpers";
import { factoryDefaultData } from "components/chart/types";
import { Grid } from "@mui/material";
import { PeriodLimit, periodRange } from "utils";
import { useState } from "react";
import {
  GetPersonalActivitySummaryQueryVariables,
  TimePeriod,
  useGetPersonalActivitySummaryQuery,
} from "generated/graphql";

// const defaultPeriod = "this week";

const PersonalActivitySummary = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  const tooltipTitlePrefix = "Activity: ";
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  // GraphQL data call
  const activitySummary = useGetPersonalActivitySummaryQuery({
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetPersonalActivitySummaryQueryVariables);

  // Chart props and variables
  const type = "doughnut",
    title = "Activity Summary",
    subtitle = "Activity Breakdown";

  let chartData = factoryDefaultData();

  if (!activitySummary.isLoading && activitySummary.isSuccess) {
    const rawData = activitySummary.data?.personalDashboard?.activitySummary?.values;

    if (rawData?.length)
      chartData = {
        labels: rawData?.map((f) => chartActivityLabels[f.label]),
        datasets: [
          {
            label: title,
            data: rawData?.map((f) => f.value),
            backgroundColor: rawData?.map((f) => chartWellnessAreaColors[f.label]),
          },
        ],
      };
  }
  return (
    <Grid item xs={12} md={6} lg={6}>
      <MetricChart
        requireUpgrade={false}
        type={type}
        title={title}
        subtitle={subtitle}
        dataSuccess={activitySummary.isSuccess}
        dataLoading={activitySummary.isLoading}
        hasEnoughData={activitySummary.isSuccess} // for personal, always should have enough data
        data={chartData}
        options={{
          plugins: {
            tooltip: {
              callbacks: DoughnutTooltipCallbacks(tooltipTitlePrefix),
            },
          },
        }}
      />
    </Grid>
  );
};

export default PersonalActivitySummary;
