export const chartWellnessLabels: { [area: string]: string } = {
  "area-wellness": "Wellness",
  "area-sleep": "Sleep Quality",
  "area-physical": "Physical Health",
  "area-food": "Food",
  "area-personal": "Personal Life",
  "area-work": "Workload & Productivity",
  "area-colleague": "Team & Colleagues",
  "area-company": "Company Culture & Direction",
  "area-other": "Other",
};

export const chartActivityLabels: { [area: string]: string } = {
  "energy-level": "Energy check-in",
  breath: "Breathing",
  "live-class": "Live class",
  water: "Water",
  "eye-break": "Eye break",
  lunch: "Lunch",
  priority: "Priority",
  gratitude: "Gratitude",
  walk: "Walking",
  exercise: "Exercising",
  boundary: "Boundary",
};

export const chartBreakdownByParticipantsLabels: { [area: string]: string } = {
  "1:1": "One-on-One",
  "+3": "3+ participants",
  "blocker": "Time blocker",
};

export const chartBreakdownByTypeLabels: { [area: string]: string } = {
  recurring: "Recurring",
  "non-recurring": "Not Recurring",
};
