import Box from "@mui/system/Box/Box";
import Chart from "chart.js/auto";
import IconIdea from "assets/icon-idea.svg";
import IconLock from "assets/icon-lock.svg";
import Skeleton from "react-loading-skeleton";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { Chart as ReactChart } from "react-chartjs-2";
import { ChartData, ChartOptions, ChartType, ChartTypeRegistry, DefaultDataPoint, LinearScale, Plugin } from "chart.js";
import { factoryDefaultData } from "./types";
import { pluginEmptyDoughnut } from "./plugins";
import { SkippedPoints } from "./helpers";
import { useState } from "react";
import "./index.sass";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

Chart.register(LinearScale);

export interface ChartParams {
  title: string | undefined | null;
  subtitle: string | undefined | null;
  description?: string | undefined | null;
  hidden?: boolean;
  type?: keyof ChartTypeRegistry;
  data: ChartData<ChartType, DefaultDataPoint<ChartType>, unknown> | any;
  /** Indicates data loading success (to remove progress indicator) */
  dataSuccess: boolean;
  dataLoading: boolean;
  hasEnoughData?: boolean | null;
  /** Custom control button handlers */
  selectedCustomAction?: string | undefined;
  customActionsButtonHandlers?: {
    value: string;
    label?: string;
    handler?: (event: React.MouseEvent<HTMLElement>, value: any, toggleButtonGroupOnChangeCallback: Function) => void;
  }[];
  /**
   * Chart options
   * @see https://www.chartjs.org/docs/latest/general/options.html
   */
  options?: ChartOptions;
  /**
   * (Optional) Text to display in the action to be taken button
   * @default "Action to be taken"
   * @type string
   * @memberof Chart
   * @property {string} actionToBeTakenText
   * @instance
   */
  actionToBeTakenText?: string | undefined;
  actionToBeTakenOnClick?: () => void;
  overrideBaseHeight?: number;
  requireUpgrade?: boolean;
  canAccess?: boolean;
  showPlans?: () => void;
}

export default function MetricChart({
  title,
  subtitle,
  description,
  dataSuccess,
  dataLoading,
  hasEnoughData,
  data,
  hidden = false,
  type = "line",
  selectedCustomAction,
  customActionsButtonHandlers = [],
  actionToBeTakenText,
  actionToBeTakenOnClick,
  options,
  overrideBaseHeight,
  requireUpgrade = false,
  canAccess = true,
  showPlans,
}: ChartParams) {
  const hasData = !!(
    hasEnoughData &&
    data &&
    data?.datasets &&
    data?.datasets?.find &&
    data?.datasets?.find((d: any) => d?.data?.length > 0)
  );

  // If any of the handlers are set, show the control section
  const enableCustomActionsSection = customActionsButtonHandlers?.length > 0;
  let chartHeight = 80;
  if (overrideBaseHeight && overrideBaseHeight > 0) {
    chartHeight = overrideBaseHeight;
  }
  // take on account the height of the tip text
  if (hasData && !actionToBeTakenText) chartHeight += 41;
  // and also if there's dynamic controls
  if (!enableCustomActionsSection) chartHeight += 86;
  // Local state
  const [selectedAction, setSelectedAction] = useState<string | undefined>(
    selectedCustomAction || enableCustomActionsSection ? customActionsButtonHandlers[0].value : undefined
  );
  // In case the selected is not the initialized selectedCustomAction, update the selected action
  if (selectedCustomAction && selectedCustomAction !== selectedAction) {
    setSelectedAction(selectedCustomAction ?? selectedAction);
  }
  // Chart control handler callback to be exposed for the dynamic controls if needed
  const callbackToggleChangeHandler = (event: React.MouseEvent<HTMLElement>, newRange: string | undefined) => {
    setSelectedAction(newRange);
  };

  const defaultFontOptions: any = {
      family: "neue-haas-grotesk-text",
      size: 14,
      align: "right",
      weight: "normal",
    },
    defaultFontColor = "#3d3b44";

  const defaultTooltipFormat = {
    displayColors: false,
    padding: 10,
    backgroundColor: "#eee",
    bodyColor: defaultFontColor,
    titleColor: defaultFontColor,
    footerColor: defaultFontColor,
    titleFont: defaultFontOptions,
    bodyFont: {
      ...defaultFontOptions,
    },
    footerFont: defaultFontOptions,
    yAlign: "bottom",
    xAlign: "center",
  };
  const defaultOptions = {
      title: {
        display: true,
        text: title,
      },
      responsive: true,
      maintainAspectRatio: false,
      scaleShowVerticalLines: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...defaultTooltipFormat,
          ...options?.plugins?.tooltip,
        },
      },
    } as any, // to allow to add plugins in the default options, such as emptyDoughnut
    plugins: Plugin[] = [];

  switch (type) {
    case "doughnut":
      (defaultOptions as any).plugins = {
        ...defaultOptions?.plugins,
        emptyDoughnut: {
          color: "#3d3b44",
          width: 2,
          radiusDecrease: 10,
        },
        legend: {
          ...options?.plugins?.legend,
          position: "right",
        },
      };
      plugins.push(pluginEmptyDoughnut);
      if (options?.plugins) options.plugins = defaultOptions.plugins;
      break;
    case "line":
      defaultOptions.scales = {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
          min: 1,
        },
        x: {
          grid: {
            display: false,
          },
        },
      };
      defaultOptions.interaction = {
        intersect: false,
        mode: "index",
      };
      defaultOptions.lineTension = 0.3;
      defaultOptions.borderWidth = 2;
      defaultOptions.borderColor = "#3d3b44aa";
      defaultOptions.pointHitRadius = 20;
      defaultOptions.pointRadius = 4;
      defaultOptions.pointHoverRadius = 5;
      defaultOptions.pointBorderWidth = 0;
      defaultOptions.pointHoverBorderWidth = 0;
      if (options?.plugins)
        options.plugins = {
          ...defaultOptions.plugins,
          ...options.plugins,
          tooltip: {
            ...defaultTooltipFormat,
            ...options?.plugins?.tooltip,
          },
        };
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].segment = {
          // for trending colors red on downward trend, green on upward trend, not needed now but fun effect to have on the sleeve
          // borderColor: (ctx: any) => SkippedPoints(ctx, "rgb(0,0,0,0.2)") || DownTrend(ctx, "rgb(192,75,75)") || UpTrend(ctx, "rgb(75,192,75)"),
          borderColor: (ctx: any) => SkippedPoints(ctx, "rgb(0,0,0,0.2)"),
          borderDash: (ctx: any) => SkippedPoints(ctx, [7, 7]),
        };
        data.datasets[i].spanGaps = true;
      }
  }
  const mergedOptions = { ...defaultOptions, ...options };

  return (
    <Paper
      className={`ChartContainer ${hidden ? "HiddenChart" : "ChartContainer"}`}
      sx={{ p: 3, borderRadius: 6 }}
      elevation={0}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography className="chartTitle">{title ?? <Skeleton />}</Typography>
        {requireUpgrade && <Chip className="UpgradeBadge" label="Pro" />}
      </Stack>
      <Typography className="chartSubtitle" sx={{ my: 1 }}>
        {hasData === true ? subtitle : dataLoading ? <Skeleton /> : "-"}
      </Typography>
      {hasData === true && (
        <Typography className="chartDescription">{dataSuccess ? description : <Skeleton />}</Typography>
      )}
      <Box className="ChartCanvas" sx={{ my: 2 }}>
        {dataLoading ? (
          <CircularProgress className="chartProgress" />
        ) : hasData === true ? (
          <ReactChart
            data={data ?? factoryDefaultData()}
            type={type}
            options={mergedOptions}
            plugins={plugins}
            height={chartHeight}
          />
        ) : (
          <Stack gap={2} direction="row" alignItems="center" className="NotEnoughData" sx={{ p: 3 }}>
            {requireUpgrade && !canAccess && <img src={IconLock} alt="Unlock" />}
            <Typography variant="body1">
              {requireUpgrade && !canAccess ? `Upgrade your plan to unlock ${subtitle}` : "Not enough data"}
            </Typography>
            {requireUpgrade && !canAccess && showPlans && (
              <Button onClick={showPlans} sx={{ whiteSpace: "nowrap", borderRadius: 30 }} variant="contained">
                See Plans
              </Button>
            )}
          </Stack>
        )}
      </Box>
      {hasData === true && enableCustomActionsSection && (
        <Grid item xs={12} className="chartCustomActions">
          <ToggleButtonGroup
            value={selectedAction}
            exclusive
            onChange={callbackToggleChangeHandler}
            className="chartCustomActionsButtonGroup"
          >
            {customActionsButtonHandlers?.map((action, index) => (
              <ToggleButton
                className="chartCustomActionsButton"
                key={index}
                onClick={(event: React.MouseEvent<HTMLElement>, value: any) => {
                  action?.handler?.(event, value, callbackToggleChangeHandler);
                }}
                value={action.value}
              >
                {action.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      )}
      {hasData && actionToBeTakenText && (
        <>
          <Divider className="chartDivider" />
          <Stack direction="row" spacing={2} className="chartActionToBeTaken" alignItems="center" sx={{ mt: 2 }}>
            <img src={IconIdea} alt="Action to be taken" />
            <Typography flex={1}>{actionToBeTakenText}</Typography>
            {actionToBeTakenOnClick && (
              <Box alignSelf="flex-end">
                <IconButton color="primary" aria-label="action-to-be-taken" onClick={actionToBeTakenOnClick}>
                  <ArrowForwardOutlined />
                </IconButton>
              </Box>
            )}
          </Stack>
        </>
      )}
    </Paper>
  );
}
