import { useNavigate } from "react-router-dom";
import Branding from "components/branding";
import { ActivityType } from "generated/graphql";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import RouteNames from "route_names";
import { SlackActions } from "slack_actions";
import { authorizeWithAuthToken, selectAuth } from "store/auth_reducer";
import { useAppDispatch, useAppSelector } from "hooks";

function useAuthorize(): Error | undefined {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { token: authToken, action, path } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [tokenUsed, setTokenUsed] = useState<boolean>(false);

  const redirect = useCallback(() => {
    if (action === ActivityType.Breath || action === SlackActions.SlackAction_StartBreath) {
      navigate(RouteNames.Breath);
    } else if (action === ActivityType.EyeBreak || action === SlackActions.SlackAction_StartEyeBreak) {
      navigate(RouteNames.Eyebreak);
    } else if (action === ActivityType.Stretch || action === SlackActions.SlackAction_StartExercise) {
      // todo: navigate to stretch
      navigate(RouteNames.Home);
    } else if (path && typeof path === "string") {
      navigate(path);
    } else {
      navigate(RouteNames.Home);
    }
  }, [action, path, navigate]);

  const authorize = useCallback(async () => {
    dispatch(authorizeWithAuthToken(`${authToken}`));
  }, [authToken, dispatch]);

  useEffect(() => {
    if (auth.initialized) {
      if (!tokenUsed) {
        setTokenUsed(true);
        authorize();
        return;
      }

      if (auth.currentAccount) {
        redirect();
        return;
      }
    }
  }, [auth.currentAccount, auth.initialized, authorize, redirect, tokenUsed]);

  return auth.error;
}

function SlackTokenRedirect() {
  const error = useAuthorize();
  return (
    <div className="Loading">
      {error && (
        <div>
          <div>Oops...it's our fault. Please try again.</div>
          <div>
            <a href="mailto:team@cuely.ai">Contact support</a>
          </div>
        </div>
      )}
      {!error && "One sec..."}
      <Branding />
    </div>
  );
}

export default SlackTokenRedirect;
