import Box from "@mui/material/Box";
import CodeEditor from "@uiw/react-textarea-code-editor";
import Stack from "@mui/material/Stack";
import useCopy from "@react-hook/copy";
import VariableEditor from "./variable_editor";
import variables from "variables";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { selectCurrentCompany } from "store/current_company_reducer";
import { selectEval } from "store/eval_reducer";
import { Button, Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "hooks";
import { selectPlan } from "store/plan_reducer";
import { Tier } from "generated/graphql";
import RouteNames from "route_names";
import { maskApiKey } from "utils";

function getCode(
  apiKey: string,
  templateId: string,
  variantSlug: string | undefined,
  inputs: { [key: string]: string }
) {
  // this must match the spec of /v1/generate
  const values = JSON.stringify({ values: inputs });
  return `curl ${variables.SERVER_HOST}/v1/generate/${templateId}${variantSlug ? `/${variantSlug}` : ""} \\
-X POST \\
-H 'Content-Type: application/json' \\
-H 'GISTS-API-KEY: ${apiKey ?? "<YOUR API KEY>"}' \\
-d '${values}'`;
}

export function ApiPanel() {
  const { template, values, variant } = useAppSelector(selectEval);
  const { currentCompany } = useAppSelector(selectCurrentCompany);
  const { currentCustomer } = useAppSelector(selectPlan);

  const canAccessApi =
    currentCustomer?.plans?.find((plan) => plan.tier === Tier.AiAutomation || plan.tier === Tier.AiEnterprise) !==
    undefined;

  const defaultApiKey = (currentCompany?.apiKeys ?? [])[0].id;

  const templateApiCode = variant ? getCode(defaultApiKey, variant.templateID, undefined, values) : "";
  const templateVariantApiCode = variant ? getCode(defaultApiKey, variant.templateID, variant?.slug, values) : "";

  const templateApiDisplayCode = variant
    ? getCode(maskApiKey(defaultApiKey), variant.templateID, undefined, values)
    : "";
  const templateVariantApiDisplayCode = variant
    ? getCode(maskApiKey(defaultApiKey), variant.templateID, variant?.slug, values)
    : "";

  const {
    copied: templateApiCodeCopied,
    copy: copyTemplateApiCode,
    reset: resetTemplateApiCopied,
  } = useCopy(templateApiCode);

  const {
    copied: templateVariantApiCodeCopied,
    copy: copyTemplateVariantApiCode,
    reset: resetTemplateVariantApiCopied,
  } = useCopy(templateVariantApiCode);

  function onCopyTemplateCode() {
    if (!templateApiCode) {
      return;
    }

    copyTemplateApiCode();
    setTimeout(() => {
      resetTemplateApiCopied();
    }, 3000);
  }

  function onCopyTemplateVariantCode() {
    if (!templateVariantApiCode) {
      return;
    }

    copyTemplateVariantApiCode();
    setTimeout(() => {
      resetTemplateVariantApiCopied();
    }, 3000);
  }

  return (
    <Box flex={1} style={{ overflow: "hidden", height: "100%" }}>
      <PanelGroup autoSaveId="variantEditorVariableListContent" direction="horizontal">
        <Panel defaultSize={40} maxSize={80}>
          <Box
            style={{
              height: "100%",
              width: "100%",
              background: "#f6f7f8",
              borderBottomLeftRadius: 6,
              overflow: "hidden",
            }}
          >
            <VariableEditor />
          </Box>
        </Panel>
        <PanelResizeHandle style={{ width: 8 }} />
        <Panel defaultSize={60} maxSize={80}>
          <Box
            style={{
              height: "100%",
              width: "100%",
              maxWidth: "100%",
              background: "#f6f7f8",
              borderBottomRightRadius: 6,
              overflowX: "hidden",
              overflowY: "auto",
            }}
            sx={{ p: 2 }}
          >
            <Stack
              style={{
                width: "100%",
                maxWidth: "100%",
                borderBottomRightRadius: 6,
                overflowX: "hidden",
              }}
              gap={2}
            >
              <Stack alignItems="flex-start">
                {canAccessApi ? (
                  <Button variant="contained" href="https://docs.gists.ai" target="_blank">
                    API Documentation
                  </Button>
                ) : (
                  <Button variant="contained" href={RouteNames.Plan}>
                    Upgrade to access the API
                  </Button>
                )}
              </Stack>
              <Stack>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Calling gist: {template?.name}
                </Typography>
                <Tooltip placement="left" title={templateApiCodeCopied ? "Copied!" : "Click to copy"}>
                  <CodeEditor
                    id={`template-api-curl-template`}
                    className="CurlBox"
                    value={templateApiDisplayCode}
                    language="shell"
                    data-color-mode="light"
                    onFocus={onCopyTemplateCode}
                    readOnly
                    style={{
                      margin: 0,
                      overflow: "auto",
                      width: "100%",
                      maxWidth: "100%",
                      flex: 1,
                      borderRadius: 6,
                      fontSize: 11,
                      backgroundColor: "#fff",
                      whiteSpace: "pre",
                      wordBreak: "keep-all",
                      fontFamily:
                        'Monaco, Menlo, "Ubuntu Mono", Consolas, "Source Code Pro", source-code-pro, monospace',
                    }}
                  />
                </Tooltip>
              </Stack>
              <Stack>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Calling variant: {variant?.name}
                </Typography>
                <Tooltip placement="left" title={templateVariantApiCodeCopied ? "Copied!" : "Click to copy"}>
                  <CodeEditor
                    id={`template-api-curl-template-variant`}
                    className="CurlBox"
                    value={templateVariantApiDisplayCode}
                    language="shell"
                    data-color-mode="light"
                    onFocus={onCopyTemplateVariantCode}
                    readOnly
                    style={{
                      margin: 0,
                      overflow: "auto",
                      width: "100%",
                      maxWidth: "100%",
                      flex: 1,
                      borderRadius: 6,
                      fontSize: 11,
                      backgroundColor: "#fff",
                      whiteSpace: "pre",
                      wordBreak: "keep-all",
                      fontFamily:
                        'Monaco, Menlo, "Ubuntu Mono", Consolas, "Source Code Pro", source-code-pro, monospace',
                    }}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </Box>
        </Panel>
      </PanelGroup>
    </Box>
  );
}
