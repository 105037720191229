import { useAppNavigate, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import RouteNames from "route_names";
import { selectCurrentUser } from "store/current_user_reducer";
import Frequency, { FrequencyDemo } from "./frequency";
import Goals, { GoalsDemo } from "./goals";
import styles from "./onboarding.module.sass";
import Roles, { RolesDemo } from "./roles";
import Company, { CompanyDemo } from "./company";

export interface OnboardingProgress {
  channel: boolean;
  companySize: boolean;
  frequency: boolean;
  goals: boolean;
  roles: boolean;
  confirmation?: string;
}

export default function Onboarding() {
  const [progress, setProgress] = useState<OnboardingProgress>({} as OnboardingProgress);
  const { currentUser } = useAppSelector(selectCurrentUser);
  const navigate = useAppNavigate();
  useEffect(() => {
    if (!currentUser?.onboarding?.needWebOnboarding) {
      navigate(RouteNames.Home);
    }
  }, [currentUser?.onboarding?.needWebOnboarding, navigate]);

  const component = (() => {
    if (!progress.frequency) {
      return <Frequency progress={progress} setProgress={setProgress} />;
    }
    if (!progress.goals) {
      return <Goals progress={progress} setProgress={setProgress} />;
    }
    if (!progress.roles) {
      return <Roles progress={progress} setProgress={setProgress} />;
    }
    return <Company progress={progress} setProgress={setProgress} />;
    // return <Channel progress={progress} setProgress={setProgress} />;
  })();

  const demo = (() => {
    if (!progress.frequency) {
      return <FrequencyDemo />;
    }
    if (!progress.goals) {
      return <GoalsDemo progress={progress} />;
    }
    if (!progress.roles) {
      return <RolesDemo progress={progress} />;
    }
    return <CompanyDemo progress={progress} />;
  })();

  return (
    <div className={styles.Onboarding}>
      <div className={styles.Secondary}>{demo}</div>
      <div className={styles.Primary}>{component}</div>
    </div>
  );
}
