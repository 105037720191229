import cloneDeep from "clone-deep";
import { MutationKey, QueryClient, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";

export const useOptimisticQuery = <TMutationData = unknown, TQueryData = unknown, TVariables = unknown>(
  queryClient: QueryClient,
  useMutationHook: (
    options?:
      | UseMutationOptions<TMutationData, Error, TVariables, { previousData: TQueryData; newData: TQueryData }>
      | undefined
  ) => UseMutationResult<TMutationData, Error, TVariables, { previousData: TQueryData; newData: TQueryData }>,
  key: MutationKey,
  updateData: (previousData: TQueryData, variables: TVariables) => TQueryData
) =>
  useMutationHook({
    onMutate: async (variables: TVariables): Promise<{ previousData: TQueryData; newData: TQueryData }> => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(key);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(key) as TQueryData;

      const newData = updateData(cloneDeep(previousData), variables);

      // Optimistically update to the new value
      queryClient.setQueryData(key, newData);

      // Return a context with the previous and new value
      return { previousData, newData };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, variables, context) => {
      queryClient.setQueryData(key, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(key);
    },
  });
