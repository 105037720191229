import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Team, CurrentTeam, useGetTeamsQuery, GetTeamsQueryVariables } from "generated/graphql";
import { selectCurrentTeam, updateCurrentTeam } from "store/current_team_reducer";
import { selectCurrentCompany } from "store/current_company_reducer";
import "./index.sass";

const SelectTeam = ({ imSuperAdmin = false }: { imSuperAdmin: boolean }) => {
  const dispatch = useAppDispatch();
  const { currentTeam } = useAppSelector(selectCurrentTeam);
  const { currentCompany } = useAppSelector(selectCurrentCompany);

  const gqlTeams = useGetTeamsQuery(
    {
      companyId: currentCompany?.id,
    } as GetTeamsQueryVariables,
    {
      enabled: imSuperAdmin && !!currentCompany?.id,
    }
  );

  const teamList: Team[] = [
    {
      id: "-",
      name: "All teams",
    } as Team,
  ];

  if ((imSuperAdmin || gqlTeams.isSuccess) && gqlTeams.data && gqlTeams.data?.getTeams.length > 0) {
    teamList.push(...gqlTeams.data.getTeams);
  }

  return (
    <Select
      value={currentTeam?.id}
      label="Team"
      className="TeamPicker"
      size="small"
      onChange={(e) => {
        if (teamList.length > 0) {
          const Team = teamList.find((c) => c.id === e.target.value);
          if (Team)
            dispatch(
              updateCurrentTeam({
                ...Team,
                ...({} as CurrentTeam),
              } as CurrentTeam)
            );
        }
      }}
    >
      {teamList?.map((Team) => (
        <MenuItem key={Team.id} value={Team.id}>
          {Team.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTeam;
