import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import IconDelete from "assets/v2/icon-delete.svg";
import IconDownArrow from "assets/v2/icon-down-arrow.svg";
import IconRun from "assets/v2/icon-run.svg";
import IconUpArrow from "assets/v2/icon-up-arrow.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { invalidateTemplateQuery } from "./function";
import { selectEval } from "store/eval_reducer";
import { useAppSelector } from "hooks";
import { useParams } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";
import {
  EvalCase,
  EvalCaseFieldsInput,
  EvalCaseValue,
  Template,
  TemplateVariantFieldsInput,
  useDoDeleteTemplateEvalCasesMutation,
  useDoUpdateTemplateEvalCaseMutation,
} from "generated/graphql";

function EvalCaseValueField({
  evalCase,
  value,
  onUpdate,
  onBlur,
}: {
  evalCase: EvalCase;
  value: EvalCaseValue;
  onUpdate: (change: { name: string; value: string }) => void;
  onBlur: (change: { name: string; value: string }) => void;
}) {
  return (
    <TextField
      fullWidth
      multiline
      autoComplete="off"
      id={`eval-case-variable-value-field-${evalCase.id}-${value.name}`}
      InputProps={{ disableUnderline: true, id: `eval-case-variable-value-input-${evalCase.id}-${value.name}` }}
      variant="standard"
      size="small"
      value={value.value ?? ""}
      onChange={(event) => {
        onUpdate({ name: value.name, value: event.currentTarget.value });
      }}
      onBlur={(event) => {
        onBlur({ name: value.name, value: event.currentTarget.value });
      }}
      placeholder="<empty>"
    />
  );
}

function combineValues(fieldNames: string[], values: EvalCaseValue[]): { [key: string]: string } {
  // combine variables in the template and the eval case
  const ret = fieldNames.reduce((acc, v) => {
    acc[v] = "";
    return acc;
  }, {} as { [key: string]: string });

  for (const v of values ?? []) {
    ret[v.name] = v.value ?? "";
  }

  return ret;
}

function EvalCaseRow({
  row,
  runTemplate,
}: {
  row: EvalCase;
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    values?: { [key: string]: string };
    fields: TemplateVariantFieldsInput;
    evalCaseIDs?: string[];
  }) => void;
}) {
  const { variant, evaluating } = useAppSelector(selectEval);
  const { id: templateID, variantId } = useParams();
  const [open, setOpen] = useState(false);

  const el = useRef<HTMLButtonElement | null>(null);
  const [showConfirmDeleteMenu, setShowConfirmDeleteMenu] = useState(false);

  const updateEvalCaseMutation = useDoUpdateTemplateEvalCaseMutation();
  const deleteEvalCaseMutation = useDoDeleteTemplateEvalCasesMutation();

  const { usedVariables } = useAppSelector(selectEval);
  const [stagingValues, setStagingValues] = useState<{ [key: string]: string }>(
    combineValues(usedVariables, row.variables ?? [])
  );

  const [stagingReferenceResponse, setStagingReferenceResponse] = useState<string>(row.fields.referenceResponse ?? "");
  const [stagingName, setStagingName] = useState<string>(row.name ?? "");
  // const [stagingProdution, setStagingProduction] = useState<boolean>(row.production);

  function onUpdateEvalCaseValues({ name, value }: { name: string; value: string }) {
    setStagingValues({ ...stagingValues, [name]: value });
  }

  const onSaveEvalCase = useCallback(async () => {
    const valueArray = Object.keys(stagingValues).map((k) => ({ name: k, value: stagingValues[k] }));
    try {
      const result = await updateEvalCaseMutation.mutateAsync({
        input: {
          id: row.id,
          templateID,
          name: stagingName,
          fields: { referenceResponse: stagingReferenceResponse } as EvalCaseFieldsInput,
          variables: valueArray,
          // production: stagingProdution ?? false,
        },
      });
      if (result?.updateTemplateEvalCase) {
        await invalidateTemplateQuery(templateID);
      }
    } catch (error) {}
  }, [
    row.id,
    stagingName,
    // stagingProdution,
    stagingReferenceResponse,
    stagingValues,
    templateID,
    updateEvalCaseMutation,
  ]);

  // const onToggleProduction = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newState = event.target.checked;
  //   setStagingProduction(() => newState);
  // }, []);

  function onRunEvalCase() {
    if (!variantId || !templateID) {
      return;
    }

    runTemplate({
      variantID: variantId,
      templateID: templateID,
      fields: { ...(variant?.fields ?? {}) } as TemplateVariantFieldsInput,
      evalCaseIDs: [row.id],
    });
  }

  async function onDeleteEvalCase() {
    try {
      const resp = await deleteEvalCaseMutation.mutateAsync({
        ids: [row.id],
      });
      if (resp?.deleteTemplateEvalCases) {
        await invalidateTemplateQuery(templateID);
      }
    } catch (error) {
    } finally {
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ background: "white" }}>
        <TableCell sx={{ p: 1 }} style={{ width: "10%" }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <img src={IconUpArrow} alt="Close row" height={16} />
            ) : (
              <img src={IconDownArrow} alt="Expand row" height={16} />
            )}
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          <EvalCaseValueField
            evalCase={row}
            value={{ name: "", value: stagingName ?? "Untitled test case" }}
            onUpdate={({ value }) => setStagingName(value)}
            onBlur={() => onSaveEvalCase()}
          />
        </TableCell>
        {/* <TableCell style={{ width: "20%" }}>
          <Checkbox
            size="small"
            checked={stagingProdution}
            onChange={onToggleProduction}
            onBlur={() => onSaveEvalCase()}
          />
        </TableCell> */}
        <TableCell style={{ width: "30%" }} align="right">
          <IconButton onClick={onRunEvalCase} color="success" size="small" disabled={evaluating}>
            <img src={IconRun} alt="Run" height={16} style={{ filter: evaluating ? "saturate(0)" : undefined }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ borderBottom: open ? "none" : "unset" }}
          style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          colSpan={4}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="variables">
                <TableBody>
                  <TableRow sx={{ "& > *": { background: "unset" } }}>
                    <TableCell style={{ width: "10%" }}></TableCell>
                    <TableCell style={{ width: "40%", fontWeight: 500 }}>Variable</TableCell>
                    <TableCell style={{ width: "50%", fontWeight: 500 }}>Value</TableCell>
                  </TableRow>
                  {Object.keys(stagingValues).map((v) => (
                    <TableRow key={`eval-case-variable-${row.id}-${v}`}>
                      <TableCell style={{ width: "10%" }}></TableCell>
                      <TableCell scope="row" style={{ width: "40%" }}>
                        {v}
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        <EvalCaseValueField
                          evalCase={row}
                          value={{ name: v, value: stagingValues[v] }}
                          onUpdate={onUpdateEvalCaseValues}
                          onBlur={() => onSaveEvalCase()}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={`eval-case-${row.id}-expected-output`}>
                    <TableCell style={{ width: "10%" }}></TableCell>
                    <TableCell scope="row" style={{ width: "40%" }}>
                      Expected
                    </TableCell>
                    <TableCell style={{ width: "50%" }}>
                      <EvalCaseValueField
                        evalCase={row}
                        value={{ name: "", value: stagingReferenceResponse ?? "" }}
                        onUpdate={({ value }) => setStagingReferenceResponse(value)}
                        onBlur={() => onSaveEvalCase()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "right" }}>
                      <Stack direction="row" justifyContent="flex-end" gap={1}>
                        <IconButton
                          ref={el}
                          onClick={() => setShowConfirmDeleteMenu(true)}
                          color="error"
                          size="small"
                          disabled={evaluating}
                        >
                          <img src={IconDelete} alt="Delete" height={16} style={{ opacity: 0.6 }} />
                        </IconButton>

                        <Menu
                          elevation={1}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          open={showConfirmDeleteMenu}
                          anchorEl={el.current}
                          onClose={() => setShowConfirmDeleteMenu(false)}
                        >
                          <MenuItem
                            onClick={() => {
                              onDeleteEvalCase();
                              setShowConfirmDeleteMenu(false);
                            }}
                          >
                            <span>Yes, delete!</span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setShowConfirmDeleteMenu(false);
                            }}
                          >
                            <span>No, cancel</span>
                          </MenuItem>
                        </Menu>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function FunctionEvalCases({
  template,
  runTemplate,
}: {
  template?: Template;
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    fields: TemplateVariantFieldsInput;
    values?: { [key: string]: string };
    evalCaseIDs?: string[];
  }) => void;
}) {
  const rows = template?.evalCases ?? [];

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        background: "#f6f7f8",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        overflow: "hidden",
      }}
    >
      <TableContainer style={{ height: "100%" }}>
        <Table size="small" stickyHeader sx={{ "& td": { px: 1 }, "& th": { px: 1 } }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1 }} style={{ width: "10%" }} />
              <TableCell>Name</TableCell>
              {/* <TableCell style={{ width: "20%" }}>Prod</TableCell> */}
              <TableCell style={{ width: "30%" }} align="right">
                {/* <LoadingButton
                  onClick={() => {}}
                  // variant="contained"
                  size="small"
                  // loading={runTemplateMutation.isLoading}
                >
                  <img src={IconRun} alt="Run" height={16} />
                  &nbsp;&nbsp;Run all
                </LoadingButton> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <EvalCaseRow key={row.id} row={row} runTemplate={runTemplate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
