import DOMPurify from "isomorphic-dompurify";
import Skeleton from "react-loading-skeleton";
import { formatText, PeriodLimit, periodRange, replaceEmoji } from "utils";
import { Paper } from "@mui/material";
import { useState } from "react";
import {
  GetPersonalEnergySummaryQueryVariables,
  TimePeriod,
  useGetPersonalEnergySummaryQuery,
} from "generated/graphql";

const PersonalEnergySummary = ({ hidden, startAt, endAt }: { hidden?: boolean; startAt: Date; endAt: Date }) => {
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const energySummary = useGetPersonalEnergySummaryQuery({
    startAt: periodRange(startAt, frequency, PeriodLimit.START),
    endAt: periodRange(endAt, frequency, PeriodLimit.END),
    frequency,
  } as GetPersonalEnergySummaryQueryVariables);

  const htmlContent: string = formatText(replaceEmoji(energySummary.data?.personalDashboard?.energySummary ?? ""));

  return (!hidden && energySummary.data?.personalDashboard?.energySummary) || energySummary.isLoading ? (
    <Paper className="EnergySummaryItem" sx={{ p: 2 }} elevation={0}>
      {energySummary.isLoading ? (
        <Skeleton />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }} />
      )}
    </Paper>
  ) : null;
};

export default PersonalEnergySummary;
