export const IPC_EVENT_OPEN_URL = "ipc_event_open_url";
export const IPC_EVENT_DEEP_LINK = "ipc_event_deep_link";
export const IPC_EVENT_AUTO_UPDATER = "ipc_event_auto_updater";
export const IPC_EVENT_WINDOW_MENU = "ipc_event_window_menu";
export const IPC_EVENT_WINDOW_CLOSE = "ipc_event_window_close";
export const IPC_EVENT_WINDOW_MINIMIZE = "ipc_event_window_minimize";
export const IPC_EVENT_WINDOW_MAXIMIZE = "ipc_event_window_maximize";

export const DEEP_LINK_PATH_GOOGLE_SIGN_IN = "google-signin";
export const DEEP_LINK_PATH_SLACK_SIGN_IN = "slack-signin";
