import IconAdd from "assets/v2/icon-add.svg";
import IconDownArrow from "assets/v2/icon-down-arrow.svg";
import IconMore from "assets/v2/icon-more.svg";
import IconRun from "assets/v2/icon-run.svg";
import IconUpArrow from "assets/v2/icon-up-arrow.svg";
import React from "react";
import Stack from "@mui/material/Stack";
import VariableEditor from "./variable_editor";
import { invalidateTemplateQuery } from "./function";
import { LoadingButton } from "@mui/lab";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { selectEval } from "store/eval_reducer";
import { useAppSelector, useSafeModelName } from "hooks";
import { useRef, useState } from "react";
import {
  Box,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  EvalCaseFieldsInput,
  GptParams,
  RunTemplateCaseResult,
  TemplateVariantFieldsInput,
  useDoCreateTemplateEvalCaseMutation,
} from "generated/graphql";
import { formatNumber } from "utils";

function promptBuilder(gpt: GptParams) {
  const message = [];
  if (gpt.systemMessage) {
    message.push(`system: ${gpt.systemMessage}`);
  }

  for (const msg of gpt.messages ?? []) {
    message.push(`${msg.role}: ${msg.text}`);
  }

  return message.join("\n");
}

export function OutputRow({
  row: r,
  templateID,
  setMessage,
}: {
  row: RunTemplateCaseResult;
  templateID?: string;
  setMessage: (message: string) => void;
}) {
  const [modelName] = useSafeModelName(r.fieldsUsed?.aiModelType, r.fieldsUsed?.customModelID);
  const el = useRef<HTMLButtonElement | null>(null);
  const [showCardMenu, setShowCardMenu] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const createEvalCaseMutation = useDoCreateTemplateEvalCaseMutation();

  async function createTestCase() {
    try {
      if (templateID) {
        const referenceResponse = r.response;
        const response = await createEvalCaseMutation.mutateAsync({
          input: {
            templateID,
            name: "Untitled test case",
            description: "",
            fields: { referenceResponse } as EvalCaseFieldsInput,
            variables: r.valuesUsed ?? [],
          },
        });

        if (response.createTemplateEvalCase) {
          // dispatch(updateTemplate(response.updateTemplate));
          invalidateTemplateQuery(templateID);
        }
      }
    } catch (error) {
      setMessage(`Failed to add test cases ${error}`);
    } finally {
      setMessage("Test cases added");
    }
  }

  const detailRows = (r.valuesUsed ?? []).length > 0 ? r.valuesUsed ?? [] : [null];
  const totalRows = detailRows.length;

  return (
    <React.Fragment>
      <TableRow style={{ background: "white" }}>
        <TableCell style={{ whiteSpace: "pre-wrap" }}>
          <Stack direction="row">
            <Box flex={1}>
              <Typography variant="body2">Result</Typography>
              {r.response}
            </Box>
            {r.evalFieldsUsed?.referenceResponse && <Divider sx={{ mx: 1 }} flexItem orientation="vertical" />}
            {r.evalFieldsUsed?.referenceResponse && (
              <Box flex={1}>
                <Typography variant="body2">Expected</Typography>
                {r.evalFieldsUsed?.referenceResponse}
              </Box>
            )}
          </Stack>
          <Stack className="OutputPromptParams" direction="row" spacing={0.5} sx={{ mt: 1 }}>
            {r.evaluatorResults?.map((er) => (
              <Chip
                key={`output-evaluator-result-${r.id}-${er.type}`}
                size="small"
                color={er.pass ? "success" : "error"}
                label={`${er.type}`}
                style={{ textTransform: "capitalize" }}
              />
            ))}
            {r.ms && <Chip size="small" label={`${r.ms}ms`} />}
          </Stack>
        </TableCell>
        <TableCell align="right" style={{ verticalAlign: "top" }}>
          <Stack alignItems="flex-end">
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? (
                <img src={IconUpArrow} alt="Close row" height={16} />
              ) : (
                <img src={IconDownArrow} alt="Expand row" height={16} />
              )}
            </IconButton>
            <IconButton ref={el} onClick={() => setShowCardMenu(true)}>
              <img src={IconMore} alt="Card options" style={{ height: 16 }} />
            </IconButton>
          </Stack>
        </TableCell>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={showCardMenu}
          anchorEl={el.current}
          onClose={() => setShowCardMenu(false)}
        >
          <MenuItem
            onClick={() => {
              setShowCardMenu(false);
              createTestCase();
            }}
          >
            <ListItemIcon>
              <img src={IconAdd} alt="Add" height={16} />
            </ListItemIcon>
            <span>Add to Tests</span>
          </MenuItem>
        </Menu>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0, borderBottom: open ? undefined : "unset" }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack>
              <Stack className="OutputPromptParams" direction="row" spacing={0.5} sx={{ m: 1 }}>
                {r.totalTokens && <Chip size="small" label={`${r.totalTokens} tokens`} />}
                <Chip size="small" label={modelName} />
                <Chip size="small" label={`Temperature: ${formatNumber(r.fieldsUsed?.gpt?.temperature ?? 0)}`} />
                {r.fieldsUsed?.gpt?.maxTokens && (
                  <Chip size="small" label={`Max length: ${r.fieldsUsed?.gpt?.maxTokens}`} />
                )}
                <Chip size="small" label={`Top P: ${formatNumber(r.fieldsUsed?.gpt?.topP ?? 0)}`} />
              </Stack>
              <Table size="small" aria-label="variables">
                <TableBody>
                  <TableRow sx={{ "& > *": { background: "unset" } }}>
                    <TableCell style={{ width: "40%", fontWeight: 500, fontSize: 11 }}>Prompt</TableCell>
                    <TableCell style={{ width: "15%", fontWeight: 500, fontSize: 11 }}>Variable</TableCell>
                    <TableCell style={{ width: "20%", fontWeight: 500, fontSize: 11 }}>Value</TableCell>
                    <TableCell style={{ width: "25%", fontWeight: 500, fontSize: 11 }}>Expected</TableCell>
                  </TableRow>
                  {detailRows.map((v, i) => (
                    <TableRow
                      key={`run-template-variable-${r.id}-row-${i}`}
                      sx={{ "& > *": { borderBottom: i === totalRows - 1 ? "unset" : undefined } }}
                    >
                      {i === 0 && (
                        <TableCell
                          rowSpan={totalRows}
                          style={{
                            width: "40%",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            borderBottom: "unset",
                          }}
                        >
                          {promptBuilder((r.fieldsUsed?.gpt ?? {}) as GptParams)}
                        </TableCell>
                      )}
                      <TableCell scope="row" style={{ width: "15%" }}>
                        {v?.name ?? ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>{v?.value ?? ""}</TableCell>

                      {i === 0 && (
                        <TableCell rowSpan={totalRows} style={{ width: "25%", borderBottom: "unset" }}>
                          {r?.evalFieldsUsed?.referenceResponse ?? "<unspecified>"}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function EvaluationSuite({
  runTemplate,
}: {
  runTemplate: (params: {
    variantID: string;
    templateID: string;
    values: { [key: string]: string };
    fields: TemplateVariantFieldsInput;
    evalCaseIDs?: string[];
  }) => void;
}) {
  const { values, variant, runResults, evaluating } = useAppSelector(selectEval);
  const [message, setMessage] = useState<string>();

  const createEvalCaseMutation = useDoCreateTemplateEvalCaseMutation();

  async function createTestCase() {
    try {
      if (variant?.templateID) {
        const valueArray = values ? Object.keys(values).map((k) => ({ name: k, value: values[k] })) : undefined;
        const response = await createEvalCaseMutation.mutateAsync({
          input: {
            templateID: variant.templateID,
            name: "Untitled test case",
            description: "",
            variables: valueArray,
          },
        });

        if (response.createTemplateEvalCase) {
          invalidateTemplateQuery(variant.templateID);
        }
      }
    } catch (error) {
      setMessage(`Failed to add test cases ${error}`);
    } finally {
      setMessage("Test cases added");
    }
  }

  async function onRunTemplate() {
    if (!variant) {
      return;
    }

    runTemplate({
      variantID: variant.id,
      templateID: variant.templateID,
      values,
      fields: { ...(variant?.fields ?? {}) } as TemplateVariantFieldsInput,
    });
  }

  const variablesEmpty = Object.keys(values).length === 0;

  return (
    <Box flex={1} style={{ height: "100%", overflow: "hidden" }}>
      <PanelGroup autoSaveId="variantEditorVariableListContent" direction="horizontal">
        <Panel defaultSize={40} maxSize={80}>
          <Stack style={{ height: "100%" }}>
            <Box
              style={{
                height: "100%",
                width: "100%",
                background: "#f6f7f8",
                overflow: "hidden",
              }}
            >
              <VariableEditor />
            </Box>
            <Divider />
            <Stack direction="row" alignItems="center" gap={1} sx={{ background: "white", py: 0.5, px: 1 }}>
              <LoadingButton
                onClick={onRunTemplate}
                variant="contained"
                color="success"
                size="small"
                loading={evaluating}
              >
                <img src={IconRun} alt="Run" height={16} style={{ filter: "brightness(100)" }} />
                &nbsp;&nbsp;Run
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                size="small"
                onClick={createTestCase}
                disabled={variablesEmpty}
                loading={createEvalCaseMutation.isLoading}
              >
                <img src={IconAdd} alt="Add" height={16} />
                &nbsp;&nbsp;Add to Tests
              </LoadingButton>
            </Stack>
          </Stack>
        </Panel>
        <PanelResizeHandle style={{ width: 8 }} />
        <Panel defaultSize={60} maxSize={80}>
          <Stack style={{ height: "100%" }}>
            <Box
              style={{
                height: "100%",
                width: "100%",
                background: "#f6f7f8",
                borderBottomRightRadius: 6,
                overflow: "hidden",
              }}
            >
              <TableContainer style={{ height: "100%" }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Output</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {runResults.map((batch) =>
                      batch.results?.map((r) => (
                        <OutputRow
                          key={`eval-result-${batch.runClientID}-${r.id}`}
                          templateID={variant?.templateID}
                          row={r}
                          setMessage={setMessage}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Panel>
      </PanelGroup>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={!!message}
        onClose={() => setMessage(undefined)}
        message={message}
        key="evaluation-suite-bottom-right"
        autoHideDuration={3000}
      />
    </Box>
  );
}
