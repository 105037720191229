import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import { useAppDispatch, useAppNavigate } from "hooks";
import IconAi from "assets/v2/icon-ai.svg";
import { createCharacter, updateCharacter } from "store/characters_reducer";
import {
  Character,
  GptPromptMessageRole,
  PromptFieldInput,
  useDoCreateCharacterMutation,
  useDoUpdateCharacterMutation,
} from "generated/graphql";
import { generatePath } from "react-router-dom";
import RouteNames from "route_names";

export default function CreateCharacterModal({
  character,
  open,
  onClose,
  onSucceed,
}: {
  character?: Character;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
}) {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(character?.name ?? "");
  const [description, setDescription] = useState<string>(character?.description ?? "");
  const [slug, setSlug] = useState<string>(character?.slug ?? "");
  const [prompt, setPrompt] = useState<string>(((character?.fragment?.fields ?? [])[0]?.values ?? [])[0] ?? "");
  const [saving, setSaving] = useState<boolean>(false);

  const createCharacterMutation = useDoCreateCharacterMutation();
  const updateCharacterMutation = useDoUpdateCharacterMutation();

  function close() {
    onClose();
  }

  function validate(): boolean {
    return name.trim() !== "";
  }

  async function saveAndClose() {
    if (validate()) {
      setSaving(true);

      try {
        const systemPrompt = {
          name: GptPromptMessageRole.System,
          values: [prompt],
        } as PromptFieldInput;
        if (character?.id) {
          const response = await updateCharacterMutation.mutateAsync({
            input: {
              id: character.id,
              name,
              description,
              slug,
              fragment: systemPrompt,
            },
          });

          if (response.updateCharacter) {
            dispatch(updateCharacter(response.updateCharacter));
            onSucceed?.();
          }
        } else {
          const response = await createCharacterMutation.mutateAsync({
            input: {
              description,
              name,
              fragment: systemPrompt,
            },
          });

          if (response.createCharacter) {
            dispatch(createCharacter(response.createCharacter));
            navigate(generatePath(RouteNames.CharacterEditor, { id: response.createCharacter.id }));
          }
        }

        close();
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="CreateCharacterModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconAi} alt="Create new character" />
          <Typography variant="h4">{!!character?.id ? "Edit AI character" : "New AI character"}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2} sx={{ py: 1 }}>
          <Box>
            <TextField
              required
              disabled={saving}
              label="Name of the character"
              className="CreateCharacterModal__name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Choose a name of your character"
              value={name}
              size="small"
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              disabled={saving}
              className="CreateCharacterModal__description"
              label="Description of the character"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe what you would like your character to specialize in"
              value={description ?? ""}
              size="small"
              fullWidth
            />
          </Box>
          {!!character?.id && (
            <Box>
              <TextField
                disabled={saving}
                className="CreateCharacterModal__slug"
                label="Slug"
                onChange={(e) => setSlug(e.target.value)}
                placeholder="Slug"
                value={slug ?? ""}
                size="small"
                fullWidth
              />
            </Box>
          )}
          <Box>
            <TextField
              disabled={saving}
              className="CreateCharacterModal__primpt"
              onChange={(e) => setPrompt(e.target.value)}
              label="GPT prompt"
              placeholder="Instruction for GPT on how should this character behave. Leave empty and we'll auto generate one for you."
              value={prompt ?? ""}
              size="small"
              multiline
              minRows={3}
              fullWidth
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={!validate() || saving}
              onClick={() => {
                saveAndClose();
              }}
              variant="contained"
            >
              {!!character ? "Update" : "Create"}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
