import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import {
  JournalPromptType,
  LogJournalEntryInput,
  useDoLogJournalMutation,
  useDoLogMindfulnessMutation,
  useGetPersonalHighlightsQuery,
} from "generated/graphql";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField/TextField";
import { useAppSelector } from "hooks";
import { selectAuth } from "store/auth_reducer";
import IconRoutine from "assets/icon-routine.svg";
import EnergyButtons from "./energy-buttons";
import { useSearchReminderID } from "utils";
import { queryClient } from "api";

export default function RoutineModal() {
  const navigate = useNavigate();
  const [priority, setPriority] = useState<string>("");
  const [gratitude, setGratitude] = useState<string>("");
  const [energy, setEnergy] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);

  const reminderID = useSearchReminderID();

  const auth = useAppSelector(selectAuth);
  const dailyHighlightsQuery = useGetPersonalHighlightsQuery(undefined, {
    enabled: auth.initialized && !!auth.currentAccount?.token,
  });

  const dailyHighlights = dailyHighlightsQuery?.data?.currentUser?.dailyHighlights;

  const logJournalMutation = useDoLogJournalMutation();
  const logMindfulnessMutation = useDoLogMindfulnessMutation();

  useEffect(() => {
    if (dailyHighlights && dailyHighlights.latestEnergy) {
      setEnergy(dailyHighlights.latestEnergy);
    }

    if (dailyHighlights && dailyHighlights.latestJournalEntries) {
      for (let entry of dailyHighlights.latestJournalEntries) {
        if (entry.prompt === JournalPromptType.Priority) {
          setPriority(entry.answer);
        } else if (entry.prompt === JournalPromptType.Gratitude) {
          setGratitude(entry.answer);
        }
      }
    }
  }, [dailyHighlights]);

  function close() {
    navigate("/");
  }

  function validate(): boolean {
    return energy >= 1 && energy <= 5;
  }

  async function saveAndClose() {
    if (validate()) {
      setSaving(true);
      const input: LogJournalEntryInput[] = [];
      if (priority) {
        input.push({
          type: JournalPromptType.Priority,
          text: priority,
          from: "web",
          reminderID,
        });
      }

      if (gratitude) {
        input.push({
          type: JournalPromptType.Gratitude,
          text: gratitude,
          from: "web",
          reminderID,
        });
      }

      try {
        await Promise.all([
          logJournalMutation.mutateAsync({
            input,
          }),
          logMindfulnessMutation.mutateAsync({
            input: {
              energy,
              reminderID,
            },
          }),
        ]);

        close();
        queryClient.invalidateQueries(["getCurrentUser"]);
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="RoutineModal" open={true} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconRoutine} alt="Daily check-in" />
          <Typography variant="h4">Daily Check-in</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack>
          <Box>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              ⚡️ What's your energy level now?
            </Typography>
            <EnergyButtons
              disabled={saving}
              onChange={(val) => {
                setEnergy(val);
              }}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              🎯 What's one thing that can make today a success?
            </Typography>
            <TextField
              disabled={saving}
              className="RoutineModal__priority"
              onChange={(e) => setPriority(e.target.value)}
              placeholder="Top priority of the day"
              value={priority ?? ""}
              size="small"
              fullWidth
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              🧡 What's one thing you are grateful for? (optional)
            </Typography>
            <TextField
              disabled={saving}
              className="RoutineModal__gratitude"
              onChange={(e) => setGratitude(e.target.value)}
              placeholder="What makes you happy"
              value={gratitude ?? ""}
              size="small"
              fullWidth
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ p: 2 }}>
          <Box>🔒 Only you can see your responses.</Box>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={!validate() || saving}
              onClick={() => {
                saveAndClose();
              }}
              variant="contained"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
