import React from "react";
import "./breath.sass";

function BreathSubtitle() {
  return (
    <div className="breath-subtitle">
      <div className="breath-text">
        <div>Inhale</div>
        <div>Hold</div>
        <div>Exhale</div>
        <div>Hold</div>
      </div>
    </div>
  );
}

export default BreathSubtitle;
