import { useRef, useState } from "react";

export default function usePickerPopover<T>(): [
  HTMLElement | null,
  T | null,
  (val: T, el: HTMLElement, confirm: (val?: T | null) => void) => void,
  (val?: T | null) => void,
  () => void
] {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [popoverValue, setPopoverValue] = useState<T | null>(null);
  const popoverConfirm = useRef<(val?: T | null) => void>(() => {});
  function openPopover(val: T, el: HTMLElement, confirm: (val?: T | null) => void) {
    setPopoverAnchor(el);
    setPopoverValue(val);
    popoverConfirm.current = confirm;
  }

  function closePopover() {
    setPopoverValue(null);
    setPopoverAnchor(null);
    popoverConfirm.current = () => {};
  }

  return [
    popoverAnchor,
    popoverValue,
    openPopover,
    (val?: T | null) => {
      popoverConfirm.current(val);
      closePopover();
    },
    closePopover,
  ];
}
