import PremiumMetricChart from "components/chart/premium_chart";
import { addDays, endOfWeek, PeriodLimit, periodRange, useCanAccessPersonalDashboard } from "utils";
import { factoryDefaultData } from "components/chart/types";
import { format, parseISO } from "date-fns";
import { Grid } from "@mui/material";
import { useState } from "react";
import {
  GetPersonalMeetingTrendsQueryVariables,
  TimePeriod,
  useGetPersonalMeetingTrendsQuery,
} from "generated/graphql";
// import RouteNames from "route_names";

const PersonalMeetingTrends = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
  // Local states
  const [frequency] = useState<TimePeriod>(TimePeriod.Week);

  const canAccessPersonalDashboard = useCanAccessPersonalDashboard();

  // GraphQL data call
  const personalEnergyTrend = useGetPersonalMeetingTrendsQuery(
    {
      startAt: periodRange(startAt, frequency, PeriodLimit.START),
      endAt: periodRange(endAt, frequency, PeriodLimit.END),
      frequency,
    } as GetPersonalMeetingTrendsQueryVariables,
    { enabled: canAccessPersonalDashboard }
  );

  // Chart control handlers

  // Chart props and variables
  const type = "line",
    dateFmt = "MMM do",
    descriptionDuration = `hours, `,
    descriptionCount = `meetings`,
    bgLine = "#21E0BB",
    bgBar = "#F584DE";
  let title = "Time in meetings",
    subtitle = "Meeting time report",
    description = "Average total time and number of meetings per week",
    chartData = factoryDefaultData();

  const xAxisLabels: string[] = [];
  if (!personalEnergyTrend.isLoading && personalEnergyTrend.isSuccess) {
    const rawData = personalEnergyTrend.data.personalDashboard?.meetingTrends.stats;
    if (rawData && rawData?.length > 0) {
      title = personalEnergyTrend.data?.personalDashboard?.meetingTrends?.name ?? title;
      subtitle =
        `${personalEnergyTrend.data?.personalDashboard?.meetingTrends?.stats?.[0]?.average} ${descriptionDuration}` +
          `${personalEnergyTrend.data?.personalDashboard?.meetingTrends?.stats?.[1]?.average} ${descriptionCount}` ??
        subtitle;
      chartData = {
        labels: rawData
          ? rawData[0].values
              ?.map((mt) => {
                if (!mt.value) return "";
                const start = format(parseISO(mt.date), dateFmt);
                const end = format(addDays(endOfWeek(parseISO(mt.date)), 1), dateFmt);
                xAxisLabels.push(start);
                return `${start} - ${end}`;
              })
              .filter((l) => l !== "")
          : [],
        datasets: [
          {
            type: "bar",
            label: "Hours",
            data: rawData ? rawData[0]?.values?.filter((mt) => mt.value).map((mt) => mt.value) : [],
            backgroundColor: rawData?.map((mt) => bgLine),
            borderWidth: 0,
          },
          {
            type: "line",
            label: "Meetings",
            data: rawData ? rawData[1]?.values?.filter((mt) => mt.value).map((mt) => mt.value) : [],
            backgroundColor: rawData?.map((mt) => bgBar),
            borderColor: bgBar,
          },
        ],
      };
    }
  }

  return (
    <Grid item container xs={12} md={6} lg={6}>
      <PremiumMetricChart
        canAccess={canAccessPersonalDashboard}
        type={type}
        title={title}
        subtitle={subtitle}
        description={description}
        dataSuccess={personalEnergyTrend.isSuccess}
        dataLoading={personalEnergyTrend.isLoading}
        hasEnoughData={chartData.datasets.length > 0} // for personal, always should have enough data
        data={chartData}
        overrideBaseHeight={175}
        options={{
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              ticks: {
                callback: (value: any, index: any, ticks: any) => xAxisLabels[index],
              },
            },
          },
        }}
      />
    </Grid>
  );
};

export default PersonalMeetingTrends;
