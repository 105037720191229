import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import { useAppDispatch, useAppNavigate } from "hooks";
import IconFunction from "assets/v2/icon-prompt-function.svg";
import { createTemplate, updateTemplate } from "store/templates_reducer";
import { Template, useDoCreateTemplateMutation, useDoUpdateTemplateMutation } from "generated/graphql";
import { generatePath } from "react-router-dom";
import RouteNames from "route_names";

export default function CreateFunctionModal({
  template,
  open,
  onClose,
  onSucceed,
}: {
  template?: Template;
  open: boolean;
  onClose: () => void;
  onSucceed?: () => void;
}) {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(template?.name ?? "New gist");
  const [description, setDescription] = useState<string>(template?.description ?? "");
  const [saving, setSaving] = useState<boolean>(false);

  const createTemplateMutation = useDoCreateTemplateMutation();
  const updateTemplateMutation = useDoUpdateTemplateMutation();

  function close() {
    onClose();
  }

  function validate(): boolean {
    return name.trim() !== "";
  }

  async function saveAndClose() {
    if (validate()) {
      setSaving(true);

      try {
        if (template?.id) {
          const response = await updateTemplateMutation.mutateAsync({
            input: {
              id: template.id,
              name,
              description,
            },
          });

          if (response.updateTemplate) {
            dispatch(updateTemplate(response.updateTemplate));
            onSucceed?.();
          }
        } else {
          const response = await createTemplateMutation.mutateAsync({
            input: {
              description,
              name,
            },
          });

          if (response.createTemplate) {
            dispatch(createTemplate(response.createTemplate));
            navigate(generatePath(RouteNames.Gist, { id: response.createTemplate.id }));
          }
        }

        close();
      } catch (error) {
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <Dialog className="CreateFunctionModal" open={open} onClose={close}>
      <DialogTitle sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={IconFunction} alt="Create a new gist" />
          <Typography variant="h4">{!!template?.id ? "Edit gist" : "New gist"}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 560 }}>
        <Stack gap={2} sx={{ py: 1 }}>
          <Box>
            <TextField
              required
              disabled={saving}
              label="Name of the gist"
              className="CreateFunctionModal__name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Choose a name for your gist"
              value={name}
              size="small"
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              disabled={saving}
              className="CreateFunctionModal__description"
              label="Description of the gist"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe what your gist is about"
              value={description ?? ""}
              size="small"
              fullWidth
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} sx={{ px: 2, pb: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
            <Button onClick={close} disabled={saving}>
              Cancel
            </Button>
            <Button
              disabled={!validate() || saving}
              onClick={() => {
                saveAndClose();
              }}
              variant="contained"
            >
              {!!template ? "Update" : "Create"}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
